import React from "react";
import "./App.scss";
import "antd/dist/antd.css";
import "./components/headerNew/header.style.scss";
import "./components/headerNew/header2.style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-edit-text/dist/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
// import { store  } from './redux/store'
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { myColor } from "./GlobalVar";
import ChatBotScreen from "./components/ChatBotScreen";
import Routes from "./views/routes/Routes";
import FullScreenLoader from "./components/FullScreenLoader";
import Layout from "./components/layouts/mainLayout";
import MarketResearchReport from "./views/Shell/Reports/marketResearchReport";
import MarketResearchReportView from "./views/Shell/Reports/marketResearchReport/indexView";
import { IdeaValidationReport } from "./views/Shell/Reports/ideaValidationAIReport/IdeaValidationReport";

// const store = configureStore().store
// const persist = configureStore().persistor

const store = configureStore();

function App({ Component, pageProps }) {
  return (
    <Provider store={store}>
      {/* <FullScreenLoader /> */}
      <PersistGate persistor={store.persistor} loading={null}>
        <BrowserRouter>
          <Routes />
          {/* <IdeaValidationReport /> */}
          {/* <Component {...pageProps} /> */}
        </BrowserRouter>
      </PersistGate>

      {/* =======================Chat bot button====================== */}
      {/* <div style={{ position: 'fixed', bottom: 80, right: 20, zIndex: 2 }}>
          <ChatBotScreen />
        </div> */}
    </Provider>
  );
}

export default App;
