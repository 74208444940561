import React, { useState } from "react";
import { Rate } from "antd";
import styled from "styled-components";

const StarRating = props => {
  const desc = props.matrixValues
    ? props.matrixValues
    : ["Highly irrelevant", "Irrelevant", "Neutral", "Relevant", "Highly relevant"];

  const handleChange = value => {
    props.onlyShow || props.setRatingValue({ ...props.ratingValue, rating: value });
  };

  let roundRating = props.onlyShow ? Math.round(props.ratingValue) : props.ratingValue.rating;
  return (
    <div>
      <RateWrapper
        tooltips={desc}
        disabled={props.onlyShow ? true : false}
        onChange={handleChange}
        value={roundRating}
      />
      <div style={{ marginTop: 10 }}>
        {props.ratingValue ? (
          <h4 style={{ fontSize: 18, fontFamily: "Inter-Medium", color: "#EBA53C" }}>
            {desc[roundRating - 1]}
          </h4>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default StarRating;

const RateWrapper = styled(Rate)`
  .ant-rate-star-first .anticon,
  .ant-rate-star-second .anticon {
    margin-right: 20px;
  }
  font-size: 70px;
  color: #eba53c;

  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;
