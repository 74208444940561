import React from "react";
import { Input } from "antd";
import styled from "styled-components";
import { myColor } from "../GlobalVar";
const { TextArea } = Input;

const TextAreaWrapper = styled(TextArea)`
  textarea.ant-input {
    resize: none;
    padding: 20px;
    font-size: 18px;
    /* box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.3); */
    border-radius: 8px !important;
    border-right-width: 2px !important;
    border: 2px solid ${myColor.light_gray};
    background-color: #fff;

    /* width */
    ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #d4d4d4;
      border: 0.1px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b8b8b8;
    }
  }
  /* .ant-input:hover {
    border-color: ${myColor.light_gray};
  } */

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.85);
    cursor: auto;
  }
  .ant-input[disabled]:hover{
    border-right-width: 2px !important;
  }
`;

const TextAreaContainer = props => {
  const { from, textAreaState, setTextAreaState } = props;
  const getTextAreaState = () => {
    if (from == "ideaSolution") {
      return textAreaState.ideaSolutionText;
    } else if (from == "problemStatement") {
      return textAreaState.problemStatementText;
    }
  };

  const settingTextAreaState = val => {
    if (from == "ideaSolution") {
      props.disable || setTextAreaState({ ...textAreaState, ideaSolutionText: val });
    } else if (from == "problemStatement") {
      props.disable || setTextAreaState({ ...textAreaState, problemStatementText: val });
    }
  };

  return (
    <TextAreaWrapper
      showCount={true}
      allowClear={true}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      rows={props.rows}
      disabled={props.disable}
      // defaultValue={'Test'}
      value={getTextAreaState()}
      onChange={e => settingTextAreaState(e.target.value)}
    />
  );
};

export default TextAreaContainer;
