import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row, Col, Slider } from "antd";
import Heading from "../landingPageHeading";
import { HiChevronDoubleRight, HiChevronDoubleLeft } from "react-icons/hi";
import Process1 from "../../assets/Assets-Landing/Relevance of Problem-.jpg";
import Process2 from "../../assets/Assets-Landing/Usefulness of  the solution.jpg";
import Process3 from "../../assets/Assets-Landing/Uniqueness of  the solution.jpg";
import Process4 from "../../assets/Assets-Landing/Likeliness to Promote.jpg";
import Process5 from "../../assets/Assets-Landing/Willingness to Buy.jpg";

import ProcessTopGray1 from "../../assets/Assets-Landing/Relevance of the problem-Gray.svg";
import ProcessTopColor1 from "../../assets/Assets-Landing/_Relevance of the problem.svg";

import ProcessTopGray2 from "../../assets/Assets-Landing/Usefulness of the solution-Gray.svg";
import ProcessTopColor2 from "../../assets/Assets-Landing/_Usefulness of the solution.svg";

import ProcessTopGray3 from "../../assets/Assets-Landing/Uniqueness of the solution-Gray.svg";
import ProcessTopColor3 from "../../assets/Assets-Landing/_Uniqueness of the solution.svg";

import ProcessTopGray4 from "../../assets/Assets-Landing/Likeliness to promote-Gray.svg";
import ProcessTopColor4 from "../../assets/Assets-Landing/_Likeliness to promote.svg";

import ProcessTopGray5 from "../../assets/Assets-Landing/Willingness to buy-Gray.svg";
import ProcessTopColor5 from "../../assets/Assets-Landing/_Willingness to buy.svg";
import { Fade, Zoom } from "react-awesome-reveal";

const StyledDiv = styled.div`
  background-color: #fff;
  padding: 3% 10%;

  .dots-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 160px;
    margin: 20px 10% 10px 10%;

    p {
      margin-bottom: 0;
      margin-left: -12px;
    }
  }

  .dot {
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #8e8e8e;
    box-sizing: border-box;
    z-index: 1;
    cursor: pointer;
    transition: 0.3s ease;

    /* &:hover {
      background-color: #0042e67a;
      border: 2px solid #0042e67a;
    } */
  }

  .moving-dot {
    position: absolute;
    border-radius: 50%;
    background-color: #0042e6;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    box-shadow: 0px 0px 15px #0042e6;
  }

  .dots-background {
    width: 50px;
    background-color: transparent;
    height: 38px;
    border-right: 11px solid #fff;
    border-left: 11px solid #fff;
    margin: 0px 0px 0px -13px;
  }

  .image-col {
    img {
      width: 100%;
    }
  }

  .above-slider-div {
    padding: 0px 15% 0px 0px;
    h2 {
      margin-bottom: 24px;
      font-weight: 600;
    }
    p {
      color: #4e4e4e;
      line-height: 2.5;
    }
  }

  .solutions-heading {
    display: flex;
    justify-content: space-between;
  }

  .arrow-icon {
    color: #0042e6;
    font-size: 24px;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
  }

  @media (max-width: 991px) {
    .dots-container {
      p {
        margin-bottom: 0;
        margin-left: -12px;
        font-size: 14px;
      }
    }

    .above-slider-div {
      p {
        line-height: 2;
      }
    }
  }

  @media (max-width: 767px) {
    .row {
      text-align: center;
    }
    .above-slider-div {
      padding: 0px;
      h2 {
        font-size: 24px;
      }
      p {
        line-height: 1.8;
      }
    }

    .image-col {
      img {
        width: 250px;
      }
    }
  }

  @media (max-width: 600px) {
  }

  @media (max-width: 480px) {
  }
`;

const steps = [
  {
    title: "Relevance of the problem",
    heading: "Relevance of the problem",
    para: `The Relevance of the problem factor measures how well a problem relates to the unique needs and preferences of the target market.`,
    image: Process1,
    topImageGray: ProcessTopGray1,
    topImageColor: ProcessTopColor1,
  },
  {
    title: "Usefulness of the solution",
    heading: "Usefulness of the solution",
    para: `The Usefulness of the Solution factor assesses the practical value and benefit a product or solution holds for the buyer's persona, ensuring it meets their specific requirements and contributes to their goals.`,
    image: Process2,
    topImageGray: ProcessTopGray2,
    topImageColor: ProcessTopColor2,
  },
  {
    title: "Uniqueness of the solution",
    heading: "Uniqueness of the solution",
    para: `The Uniqueness of the Solution factor determines how special and original a product or solution is for the target market making sure it stands out and offers something different.`,
    image: Process3,
    topImageGray: ProcessTopGray3,
    topImageColor: ProcessTopColor3,
  },
  {
    title: "Likeliness to promote",
    heading: "Likeliness to promote",
    para: `The Likeliness to Promote factor determines how likely the buyer's persona is to recommend a product or solution to others, based on their satisfaction and positive experience with it.`,
    image: Process4,
    topImageGray: ProcessTopGray4,
    topImageColor: ProcessTopColor4,
  },
  {
    title: "Willingness to buy",
    heading: "Willingness to buy",
    para: `The Willingness to Buy factor considers how interested the buyer's persona is in purchasing a product or solution.`,
    image: Process5,
    topImageGray: ProcessTopGray5,
    topImageColor: ProcessTopColor5,
  },
];

/**
 * Component Starts Here
 */
const LandingPageProcess = ({ type, size }) => {
  const [value, setValue] = useState(0);

  const myref = useRef({ offsetWidth: 0, clientHeight: 0 });
  const movingDotRef = useRef({ clientWidth: 0 });

  let movingBreadth,
    horizontal = true;
  if (type === "horizontal") {
    movingBreadth =
      (myref.current?.offsetWidth - movingDotRef.current?.clientWidth) / (steps.length - 1);
    horizontal = true;
  }
  if (type === "vertical") {
    movingBreadth =
      (myref.current?.clientHeight - movingDotRef.current.clientWidth) / (steps.length - 1);
    horizontal = false;
  }

  return (
    <StyledDiv>
      <Fade direction="up" triggerOnce cascade duration={1300} damping={0.2}>
        <Heading>
          Objectively Measure your Ideas with
          <br />
          our 5-Factor Assessment Framework
        </Heading>
        <p style={{ textAlign: "center", color: "#0B3558", fontSize: 16 }}>
          Unlock the full potential of your idea with our powerful 5-factor framework. Our tool
          provides a holistic assessment by utilizing both AI surveys and human respondents, giving
          you valuable insights to optimize your offerings.
        </p>
      </Fade>

      <Row>
        <Col xs={0} sm={0} md={24} lg={24} xxl={24} style={{ marginTop: 40 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 10%",
              textAlign: "center",
            }}
          >
            {steps.map((step, index) => {
              return (
                <img
                  src={value === index ? step.topImageColor : step.topImageGray}
                  onClick={() => {
                    setValue(index);
                  }}
                  style={{
                    width: 35,
                    cursor: "pointer",
                  }}
                />
              );
            })}
          </div>

          <div
            ref={myref}
            className="dots-container"
            style={{ flexDirection: horizontal ? "row" : "column" }}
          >
            {steps.map((step, index) => (
              <div
                key={index}
                style={{
                  width: size || 20,
                  height: size || 20,
                }}
                className="dot"
                onClick={() => {
                  setValue(index);
                }}
                id={index}
              >
                <div className="dots-background" />
              </div>
            ))}

            <div
              ref={movingDotRef}
              className="moving-dot"
              style={{
                width: size || 20,
                height: size || 20,
                [horizontal ? "left" : "top"]: `${movingBreadth * value}px`,
              }}
            />
            {/* Line */}
            <div
              style={{
                position: "absolute",
                height: horizontal ? "2px" : "100%",
                width: horizontal ? "100%" : "2px",
                backgroundColor: "#8E8E8E",
                zIndex: 0,
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 7%",
              textAlign: "center",
            }}
          >
            {steps.map((step, index) => {
              return (
                <p
                  onClick={() => {
                    setValue(index);
                  }}
                  style={{
                    color: value === index ? "#0042E6" : "#969595",
                    fontWeight: value === index ? 600 : 500,
                    userSelect: "none",
                    width: 125,
                    cursor: "pointer",
                    fontSize: 14,
                  }}
                >
                  {step.title}
                </p>
              );
            })}
          </div>
        </Col>

        <Col xs={24} sm={24} md={0} lg={0} xxl={0} style={{ marginTop: 40 }}>
          <div className="solutions-heading  align-items-center">
            <HiChevronDoubleLeft
              className="arrow-icon"
              onClick={() => setValue(prev => (prev > 0 ? prev - 1 : 0))}
            />
            <h3 style={{ color: "#0042e6", marginBottom: 0, textAlign: "center" }}>
              <b>{steps[value]?.title}</b>
            </h3>
            <HiChevronDoubleRight
              className="arrow-icon"
              onClick={() =>
                setValue(prev => (prev < steps.length - 1 ? prev + 1 : steps.length - 1))
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="row" style={{ alignItems: "center", padding: "5% 2%" }}>
        {steps.map((item, index) => (
          <>
            {index == value && (
              <>
                <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
                  <Fade triggerOnce duration={1300} damping={0.2}>
                    <div className="above-slider-div">
                      <h2 style={{ color: "#0B3558" }} className="d-none d-sm-none d-md-block">
                        {steps[index].heading}
                      </h2>
                      <p style={{ color: "#0B3558" }}>{steps[index].para}</p>
                    </div>
                  </Fade>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} className="image-col">
                  <Zoom triggerOnce>
                    <img alt="process-img" src={steps[index].image} />
                  </Zoom>
                </Col>
              </>
            )}
          </>
        ))}
      </Row>
    </StyledDiv>
  );
};

LandingPageProcess.defaultProps = {
  type: "horizontal",
};

export default LandingPageProcess;
