import React, { useState, useEffect } from "react";
import { Row, Col, Skeleton } from "antd";
import CreativeMind from "../assets/img/CreativeMind.png";
import MyButton from "./MyButton";
import { myColor } from "../GlobalVar";
import { MainContainer } from "./MainContainer";
import { connect } from "react-redux";
import getTeamInvitation from "../redux/actions/getTeamInvitation";

import { withReducer } from "../redux/withReducer";
import { createApiCallReducer } from "../utils/helperFunctions";
import { ACCEPT_TEAM_INVITATION } from "../redux/constants/teams";

const TeamInvitation = props => {
  const [message, setMessage] = useState("");

  const { data, err, loading } = props.teamInvitationData;
  useEffect(() => {
    props.getTeamInvitation(props.match.params);

    props.notShowExtraContent();
    return () => {
      props.showExtraContent();
    };
  }, []);

  useEffect(() => {
    if (props.teamInvitationData) {
      setMessage(props.teamInvitationData.data);
    }
  }, [props.teamInvitationData]);

  return (
    <MainContainer style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img width="330px" src={CreativeMind} />

          <div>
            <h2 style={{ textAlign: "center", color: myColor.green }}>{message}</h2>

            <p style={{ fontSize: 18, fontFamily: "Inter-Medium" }} align="center">
              We appreciate your time and effort to become part of this team.
              <br />
              Please visit{" "}
              <a
                style={{ color: "#000", textDecoration: "none", fontWeight: 700 }}
                href="https://validatr.io"
                target="_blank"
              >
                validatr.io
              </a>{" "}
              if you've got an idea too.
            </p>
          </div>

          <div>
            <MyButton
              onClick={() => props.history.push("/")}
              htmlType="submit"
              text={`validatr.io`}
              bg={true}
              style={{ marginTop: 40 }}
            />
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getTeamInvitation: data => dispatch(getTeamInvitation(data)),
    notShowExtraContent: () => dispatch({ type: "HEADER_FOOTER_HIDE" }),
    showExtraContent: () => dispatch({ type: "HEADER_FOOTER_NOT_HIDE" }),
  };
};

const mapStateToProps = state => {
  return {
    teamInvitationData: state.acceptTeamInvitationReducer,
  };
};

export default withReducer([
  { key: "acceptTeamInvitationReducer", reducer: createApiCallReducer(ACCEPT_TEAM_INVITATION) },
])(connect(mapStateToProps, mapDispatchToProps)(TeamInvitation));
