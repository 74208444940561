import { Fade } from "react-awesome-reveal";
import Heading from "../landingPageHeading";
import styled from "styled-components";
import FirstPic from "../../assets/Assets-Landing/Hybrid Process The Perfect Blend of AI and Human Expertise.svg";
import SecondPic from "../../assets/Assets-Landing/Complete Suite of Validation Tools.svg";
import ThirdPic from "../../assets/Assets-Landing/Validate your idea with Industry.svg";
import HomeCardButton from "../homeCardButton";

const StyledSection = styled.section`
  padding: 3% 10%;
  .main-wrapper {
    display: flex;
    justify-content: space-between;
    .inside-main {
      margin-top: 24px;
      display: block;
      padding: 0px 5%;
      max-width: 500px;
      img {
        width: 80px;
        height: 80px;
      }
      h5 {
        color: #0b3558;
        font-weight: 700;
        margin: 24px 0px;
        line-height: 26px;
      }
      p {
        color: #0b3558;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
      }
    }
  }

  .green-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5%;
    .inside-green-btn {
      padding: 28px 24px;
      color: #36b24a;
      border-color: #36b24a;
      font-size: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    .main-wrapper {
      display: inline-block;
    }

    .green-btn {
      .inside-green-btn {
        padding: 22px 16px;
        color: #36b24a;
        border-color: #36b24a;
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 676px) {
    .green-btn {
      .inside-green-btn {
        padding: 16px 10px;
        font-size: 10px;
      }
    }
  }
`;

const LandingPageStartupIdeas = () => {
  return (
    <StyledSection className="text-center">
      <Fade direction="up" triggerOnce duration={1300} cascade damping={0.2}>
        <Heading title={<>The Way Forward in Validating Your Startup Idea!</>} />
        {/* <p className="text-md-center text-left">
        Unlock your idea's full potential with our Unique Metrics. Our tool provides a holistic
        assessment by utilizing both AI surveys and human respondents,
        <br /> giving you valuable insights to optimize your offerings.
      </p> */}
      </Fade>

      <Fade direction="up" triggerOnce duration={1300} cascade damping={0.2}>
        <div className="main-wrapper">
          <div className="inside-main">
            <img src={FirstPic} />
            <h5>Hybrid Process The Perfect Blend of AI and Human Expertise</h5>
            <p>
              Harness the collective intelligence of cutting-edge AI technology and human inputs to
              tackle your business challenges effectively.
            </p>
          </div>

          <div className="inside-main">
            <img src={SecondPic} />
            <h5>
              Complete Suite of
              <br />
              Validation Tools
            </h5>
            <p>
              Empower your startup's success with a complete suite of resources tailored for the
              early stages. From problem-solution fit to market research and MVP prototyping, Our
              tools validate your ideas and drive your startup toward success.
            </p>
          </div>

          <div className="inside-main">
            <img src={ThirdPic} />
            <h5>
              Validate your idea with Industry Professionals and VC Firm Executives (Impersonated by
              AI)
            </h5>
            <p>
              These experts will skillfully examine your innovative solution, uncover untapped
              opportunities, and offer personalized evaluations that help maximize its chances of
              success.
            </p>
          </div>
        </div>
        <div className="green-btn">
          <HomeCardButton
            className="inside-green-btn"
            // style={{ padding: "28px 24px", color: "#36B24A", borderColor: "#36B24A", fontSize: 20 }}
            hoverBg="#36B24A"
            text="Don't Build Castles in the Air. Validate on Solid Grounds!"
          />
        </div>
      </Fade>
    </StyledSection>
  );
};
export default LandingPageStartupIdeas;
