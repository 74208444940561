import HeaderWrapper2 from "../headerNew/headerWrapper2.js";
import FooterContent from "../footerWrapper2.js";

const Layout = ({ children }) => {
  return (
    <div>
      <HeaderWrapper2 />
      {children}
      <FooterContent />
    </div>
  );
};

export default Layout;
