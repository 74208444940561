import React from 'react'
import { Empty, Spin } from 'antd'
import styled from 'styled-components'
import { myColor } from '../GlobalVar'

const EmptyStyled = styled(Empty)`
   
`

const EmptyWrapper = (props) => {

    return (
        <EmptyStyled
            {...props}
        >
            
        </EmptyStyled>
    )
}

export default EmptyWrapper