import React from "react";
import { Radio } from "antd";
import styled from "styled-components";
import { myColor } from "../GlobalVar";

const RadioButtonWrapper = styled(Radio.Group)`
  .ant-radio-inner {
    width: 40px;
    height: 40px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${myColor.blue};
    width: 40px;
    height: 40px;
  }
  .ant-radio-checked .ant-radio-inner:hover {
    border-color: ${myColor.blue};
  }
  .ant-radio-inner::after {
    background-color: ${myColor.blue};
    top: 9px;
    left: 9px;
    display: table;
    width: 20px;
    height: 20px;
    /* transform: scale(1.5); */
    border-radius: 10px;
  }
`;

const RadioButton = props => {
  return <RadioButtonWrapper {...props}></RadioButtonWrapper>;
};

export default RadioButton;
