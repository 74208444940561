import {createConstant} from '../../../utils/helperFunctions'

export const GET_TARGET_MARKET = createConstant('GET_TARGET_MARKET')
export const CURRENT_STEP = createConstant('CURRENT_STEP',['STEP'])
export const SELECT_BUSSINESS_MODEL = createConstant('SELECT_BUSSINESS_MODEL')
export const SELECT_GEOGRAPHICAL_LOCATION = createConstant('SELECT_GEOGRAPHICAL_LOCATION',['LOADING','SUCCESS','FAILURE'])
export const SELECT_GENDER = createConstant('SELECT_GENDER')
export const SELECT_AGE_RANGE = createConstant('SELECT_AGE_RANGE')
export const SELECT_MARTIAL_STATUS = createConstant('SELECT_MARTIAL_STATUS')
export const SELECT_EDUCATION = createConstant('SELECT_EDUCATION')
export const SELECT_OCCUPATION_LEVEL = createConstant('SELECT_OCCUPATION_LEVEL')
export const SELECT_INCOME_LEVEL = createConstant('SELECT_INCOME_LEVEL')
export const SELECT_BEHAVIORAL_CHARACTERSTICS = createConstant('SELECT_BEHAVIORAL_CHARACTERSTICS')
export const ADD_INTEREST_HOBBIES = createConstant('ADD_INTEREST_HOBBIES')
export const SELECT_BUYING_POWER = createConstant('SELECT_BUYING_POWER')