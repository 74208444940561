import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import styled from "styled-components";
import { Row, Col, Upload, Input, Divider } from "antd";
import MyButton from "./MyButton";
import ImageUpload from "../components/ImageUplaod";
import BotTopBanner from "../components/BotTopBanner";
import bot from "../assets/img/bot.png";
import TextAreaContainer from "../components/TextAreaContainer";
import { connect } from "react-redux";
import putIdeaSolution from "../redux/actions/putIdeaSolution";
import cogoToast from "cogo-toast";

import { withReducer } from "../redux/withReducer";
import { createApiCallReducer } from "../utils/helperFunctions";
import { IDEA_SOLUTION } from "../redux/constants/ideaValidation";
import { myColor } from "../GlobalVar";
import { openRatingModal } from "../redux/actions/getProjectById";
import { ratingModalReducer } from "../redux/reducers/projects";

const YourIdeaSolution = props => {
  const [showBotContainer, setShowBotContainer] = useState(false);

  const showBot = val => {
    setShowBotContainer(val);
  };

  const {
    data: updateIdeaSolutionStateData,
    isLoading: updateIdeaSolutionStateLoading,
    err: updateIdeaSolutionStateErr,
  } = props.stateUpdateIdeaSolution;

  // console.log("*******************RATING MODAL ***************", props.ratingModal);

  const [updatedFileList, setUpdatedFileList] = React.useState(null);
  const [textAreaVal, setTextAreaVal] = React.useState("");

  // useEffect(() => {
  //   if (props.currentScreen == 1 && updateIdeaSolutionStateLoading) {
  //     cogoToast.loading("AI is rephrasing your problem and solution...", {
  //       hideAfter: 0,
  //     });
  //   } else if (!updateIdeaSolutionStateLoading) {
  //     let getCogoToast = document.getElementsByClassName("ct-toast-loading");
  //     for (let i = 0; i < getCogoToast.length; i++) {
  //       getCogoToast[i].style.display = "none";
  //     }
  //   }
  // }, [updateIdeaSolutionStateLoading]);

  const updateIdeaSolution = () => {
    if (
      props.textAreaState.ideaSolutionText &&
      props.textAreaState.ideaSolutionText.split(" ").length >= 5
    ) {
      let filterdFileNameList = [];
      let filterdFilePathList = [];
      let filteredFileOrignalNameList = [];

      if (
        props.fileListState.ideaSolutionFileList &&
        props.fileListState.ideaSolutionFileList.length > 0
      ) {
        props.fileListState.ideaSolutionFileList.map(val => {
          if (val.size) {
            val.status == "done" &&
              filterdFileNameList.push(val.response.data.docName) &&
              filterdFilePathList.push(val.response.data.docPath) &&
              filteredFileOrignalNameList.push(val.response.data.docOriginalName);
          } else {
            filterdFileNameList.push(val.fileName) &&
              filterdFilePathList.push(val.filePath) &&
              filteredFileOrignalNameList.push(val.name);
          }
        });

        let payLoad = {
          industry: props.industryName,
          solution: {
            description: props.textAreaState.ideaSolutionText,
            fileName: filterdFileNameList,
            filePath: filterdFilePathList,
            fileOrignalName: filteredFileOrignalNameList,
          },
        };
        props.updateIdeaSolution(payLoad);
      } else {
        if (!props.beneficiary) {
          cogoToast.warn("Beneficiary / Buyers is a required field");
        } else {
          let payLoad = {
            industry: props.industryName,
            beneficiary: props.beneficiary,
            solution: {
              description: props.textAreaState.ideaSolutionText,
            },
          };
          props.updateIdeaSolution(payLoad);
          // if (updateIdeaSolutionStateData?.rate < 5) {
          //   props.setAllignmentModal({
          //     state: true,
          //     data: payLoad,
          //   });
          // }
        }
        // console.log("RATE HAI YEH =>>>>", updateIdeaSolutionStateData?.rate);
      }
    } else {
      cogoToast.warn("Minimum 5 words required");
    }
  };

  const prevIdeaSolutionId = React.useRef(
    updateIdeaSolutionStateData ? updateIdeaSolutionStateData._id : null
  );

  React.useEffect(() => {
    if (
      updateIdeaSolutionStateData &&
      updateIdeaSolutionStateData._id !== prevIdeaSolutionId.current
    ) {
      props.setStepStatus({ ...props.stepStatus, Step2: "finish" });
    }
  }, [updateIdeaSolutionStateData]);

  return (
    <div>
      <BotTopBanner
        showBot={showBot}
        style={{ marginTop: 20 }}
        bg={"#d1deff"}
        content="Please write a clear description of the identified solution, that frames the
        significance of your research through this project or service."
        avatar={bot}
        width="100%"
      />
      <h2 style={{ margin: "30px 0", fontFamily: "Inter-SemiBold" }} align="center">
        Your Idea (Solution)
      </h2>
      <div className="my-4 ">
        <h6 style={{ fontFamily: "Inter-SemiBold" }}>
          Write a few words about the beneficiary/buyer of your solution's product/service idea:{" "}
          <Input
            readOnly={props.edit}
            bordered={false}
            onChange={e => props.setbeneficiary(e.target.value)}
            value={props.beneficiary}
            style={{
              marginTop: "16px",
              width: 300,
              // fontSize: 16,
              fontFamily: "Inter-Regular",
              fontWeight: 300,
              borderBottom: "1px solid black",
            }}
            placeholder={"Beneficiary / Buyer"}
          />
        </h6>
        <p style={{ fontSize: 12, color: "gray" }}>
          Example: If your solution's product is a 'Safer Helmet' for motorbikes then the
          buyers/beneficiaries are 'Motorbike Riders'.
          <br />
          If your solution's service is through a 'Taxi App' then the buyers/beneficiaries are the
          'Taxi users'.
        </p>
      </div>
      <Divider />
      <h5 className="mb-3" style={{ fontFamily: "Inter-SemiBold" }} align="center">
        Please write a clear description of the <strong>SOLUTION</strong> that you are proposing.
      </h5>
      <div className="row justify-content-center flex-column-reverse flex-sm-row">
        <div className={`col-lg-${showBotContainer ? "7" : "9"} col-sm-12 mb-4`}>
          <TextAreaContainer
            rows={9}
            maxLength={1000}
            placeholder="Describe your proposed idea for that problem..."
            // setTextAreaVal={setTextAreaVal}
            // textAreaVal={textAreaVal}
            textAreaState={props.textAreaState}
            setTextAreaState={props.setTextAreaState}
            from="ideaSolution"
            disable={props.edit}
          />
          {/* <div>
            {props.edit == false ||
            (props.fileListState.ideaSolutionFileList &&
              props.fileListState.ideaSolutionFileList.length > 0) ? (
              <ImageUpload
                maxFile={"10"}
                // getUpdatedFileList = {setUpdatedFileList}
                // updatedFileList={updatedFileList}
                fileListState={props.fileListState}
                setFileListState={props.setFileListState}
                from="ideaSolution"
                optional={true}
                disable={props.edit}
              />
            ) : (
              <></>
            )}
          </div> */}
          <div
            style={{ justifyContent: "space-evenly", marginTop: 20, marginBottom: 40 }}
            className="col-lg-12 col-sm-12 d-flex align-items-center"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 20,
              }}
            >
              <MyButton text="Back" bg={false} width="130px" onClick={props.handleBackButton} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 20,
              }}
            >
              <MyButton
                text={
                  props.getProjectByIdState && props.getProjectByIdState.steps.solution
                    ? "Update"
                    : "Next"
                }
                bg={true}
                width="130px"
                onClick={updateIdeaSolution}
                // onClick={() => props.setAllignmentModal(true)}
                isLoading={updateIdeaSolutionStateLoading}
                disabled={updateIdeaSolutionStateLoading || props.disabledBtn || props.edit}
              />

              <span
                onClick={() => {
                  if (!updateIdeaSolutionStateLoading || !props.disabledBtn) {
                    props.setCurrentScreen(props.currentScreen + 1);
                  }
                }}
                style={{
                  marginLeft: 18,
                  color: myColor.gray,
                  fontWeight: 600,
                  cursor:
                    updateIdeaSolutionStateLoading || props.disabledBtn ? "pointer" : "pointer",
                }}
              >
                {props.disabledBtn ? "View next" : "Skip for now"}
              </span>
            </div>
          </div>
        </div>

        <div
          style={{ display: showBotContainer ? "initial" : "none" }}
          className="col-lg-4 col-sm-12 mb-4"
        >
          <BotTopBanner
            showBot={showBot}
            style={{ marginBottom: 30 }}
            bg={"red"}
            content="Please state your idea or solution to the identified 
                     problem that frames the significance of your research through this project or service. "
            avatar={bot}
            width="130px"
            height="100px"
            varticalCard={true}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    stateUpdateIdeaSolution: state.ideaSolutionReducer,
    getProjectByIdState: state.getProjectByIdReducer.data,
    ratingModal: state.ratingModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateIdeaSolution: data => dispatch(putIdeaSolution(data)),
    // updateRatingModal: data => dispatch(openRatingModal(data)),
  };
};

export default withReducer([
  { key: "ideaSolutionReducer", reducer: createApiCallReducer(IDEA_SOLUTION) },
  { key: "ratingModal", reducer: ratingModalReducer },
])(connect(mapStateToProps, mapDispatchToProps)(YourIdeaSolution));
