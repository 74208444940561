import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Drawer, Collapse, Menu, Divider, Col } from "antd";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../assets/svg/validatr-logo.svg";
import { myColor } from "../../GlobalVar";
import { getCookie } from "../../utils/cookies";
import cogoToast from "cogo-toast";
import postUserLogOut from "../../redux/actions/postUserLogOut";
import getUserProfile from "../../redux/actions/getUserProfile";
import { postUserRole } from "../../redux/actions/postUserRole";
import { LOGOUT, SET_USER_ROLE, USER_PROFILE } from "../../redux/constants/users";
import SwitchButton from "../SwitchButton";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import UserInfoDropdown from "../UserInfoDropdown";
import { withReducer } from "../../redux/withReducer";
import { createApiCallReducer } from "../../utils/helperFunctions";
import { menuData } from "./menuData";
import { FiChevronDown } from "react-icons/fi";
import HomeCardButton from "../homeCardButton";

const { Panel } = Collapse;

const MobileDrawer = props => {
  const dispatch = useDispatch();
  const { visible, onClose } = props;

  const history = useHistory();
  const [sidebar, toggleSidebar] = useState(false);
  const [path, setPath] = React.useState(history.location.pathname);
  const [showSwitch, setShowSwitch] = React.useState(true);

  useEffect(() => {
    setPath(history.location.pathname);
    if (
      history.location.pathname == "/Home" ||
      history.location.pathname == "/Knowledge Base" ||
      history.location.pathname == "/FAQ's"
    ) {
      setShowSwitch(true);
    } else {
      setShowSwitch(false);
    }
  }, [history.location.pathname]);

  const closeSidebar = () => {
    setTimeout(() => {
      onClose();
    }, 200);
  };

  return (
    <Drawer
      title=""
      placement="left"
      visible={visible}
      closable={false}
      drawerStyle={{ backgroundColor: "#fff", padding: 0, margin: 0 }}
      bodyStyle={{ padding: 0 }}
      width={"80%"}
      onClose={() => {
        closeSidebar();
      }}
    >
      <StyledContent>
        <div className="d-flex align-items-center justify-content-between p-4">
          <a onClick={closeSidebar} style={{ textDecoration: "none" }}>
            <img src={Logo} style={{ height: 40 }} className="img-fluid site-logo" />
          </a>
          <IoCloseCircleOutline onClick={onClose} style={{ fontSize: 32, color: "#000" }} />
        </div>

        <Divider className="mt-2" />

        <Col className="d-block text-center" span={24}>
          <a
            href="sample-report.pdf"
            target="_blank"
            style={{ fontFamily: "Inter-Bold", marginBottom: 24, textDecoration: "underline" }}
            className="me-3"
          >
            View Sample Report
          </a>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 24,
              marginBottom: 24,
            }}
          >
            <Link to={"/sign-in"}>
              <a
                style={{ textDecoration: "none" }}
                onClick={() => {
                  closeSidebar();
                }}
              >
                <HomeCardButton text="Login / Sign up" bg={true} width="150px" />
              </a>
            </Link>
          </div>
        </Col>

        <Divider className="mt-2" />

        <div className="side-menu">
          <Collapse ghost className="main-menu">
            {menuData.map((menu, i) => {
              if (menu.submenu) {
                return (
                  <Panel
                    key={i}
                    showArrow={false}
                    className="sidebar-item-wrapper"
                    header={
                      <div className={`sidebar-item`}>
                        {menu.title}
                        <FiChevronDown className="ml-3" style={{ fontSize: 20 }} />
                      </div>
                    }
                  >
                    <div>
                      {menu.submenu.map((subMenuItem, index) => {
                        if (subMenuItem.submenu) {
                          return (
                            <Collapse ghost>
                              <Panel
                                key={index}
                                showArrow={false}
                                header={
                                  <div className={`navigation-link`}>
                                    {subMenuItem.title}
                                    <FiChevronDown className="ml-3" style={{ fontSize: 16 }} />
                                  </div>
                                }
                              >
                                {subMenuItem.submenu.map((item, index) => (
                                  <div
                                    role="button"
                                    key={`sub-menu-${index}`}
                                    style={{ minWidth: 240 }}
                                    className="navigation-link-sub"
                                  >
                                    <a href={item.link || ""} onClick={closeSidebar}>
                                      {item.title}
                                    </a>
                                  </div>
                                ))}
                              </Panel>
                            </Collapse>
                          );
                        } else {
                          return (
                            <div className="navigation-link-main" key={index}>
                              <a href={subMenuItem.link || ""} onClick={closeSidebar}>
                                {subMenuItem.title}
                              </a>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Panel>
                );
              } else {
                return (
                  <div key={i} className={`sidebar-item`}>
                    <a
                      href={menu.link || ""}
                      onClick={closeSidebar}
                      className="hoverable dark"
                      style={{ textDecoration: "none" }}
                    >
                      {menu.title}
                    </a>
                  </div>
                );
              }
            })}
          </Collapse>
        </div>
      </StyledContent>
    </Drawer>
  );
};

const mapStateToProps = state => {
  return {
    getUserProfileReducer: state.getUserProfileReducer,
    setUserRoleReducer: state.setUserRoleReducer,
    getAllProjectReducer: state.getAllProjectReducer,
    isHeaderShow: state.removingHeaderFooterReducer,
    isHeaderShow: state.removingHeaderFooterReducer.isHeaderFooterShow,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postLogout: history => dispatch(postUserLogOut(history)),
    getUserProfile: () => dispatch(getUserProfile()),
    postUserRole: data => dispatch(postUserRole(data)),
  };
};

export default withReducer([
  { key: "logOutReducer", reducer: createApiCallReducer(LOGOUT) },
  { key: "setUserRoleReducer", reducer: createApiCallReducer(SET_USER_ROLE) },
  { key: "getUserProfileReducer", reducer: createApiCallReducer(USER_PROFILE) },
])(connect(mapStateToProps, mapDispatchToProps)(MobileDrawer));

const StyledContent = styled.div`
  .side-menu {
    padding: 0;
    /* margin-top: 48px; */
  }
  .ant-collapse-header,
  .ant-collapse-header-text {
    display: block;
    width: 100%;
    padding: 0 !important;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 4px 14px;
  }

  .sidebar-item {
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    font-weight: 600;
    font-size: 15px;
    color: #000;
  }

  .navigation-link-main {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 24px;
    color: #000;

    a {
      position: relative;
      font-size: 15px;
      text-decoration: none;
      :after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        height: 2px;
        width: 0%;
        background-color: #212529;
        transition: 0.3s;
      }
    }

    &:hover a {
      :after {
        width: 90%;
      }
    }
  }

  .navigation-link-sub {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 24px;
    color: #000;

    a {
      position: relative;
      text-decoration: none;
      font-size: 13px;
      :after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        height: 2px;
        width: 0%;
        background-color: #212529;
        transition: 0.3s;
      }
    }

    &:hover a {
      :after {
        width: 90%;
      }
    }
  }

  .navigation-link {
    margin-left: 24px;
    margin-top: 6px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 32px;
    color: #000;
  }
  a {
    color: #000;
    font-size: 15px;
  }

  .sub-menu-1 {
  }

  .sidebar-item-wrapper {
    border-top: 1px solid #ececec;
    :last-child {
      border-bottom: 1px solid #ececec;
    }
  }
`;
