import React from "react";
import { Button, Spin } from "antd";
import styled from "styled-components";
import { myColor } from "../GlobalVar";
import { LoadingOutlined, LockOutlined, SearchOutlined } from "@ant-design/icons";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const antIcon = <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />;
const lockicon = <LockOutlined style={{ fontSize: 23, color: myColor.blue }} />;

const StyledButton = styled(Button)`
    &.ant-btn{
      background-color: "#fff";
      border: 2px solid ${myColor.blue};
      color: ${myColor.blue};
        /* background-color: ${props =>
          props.colordisable ? "#f5f5f5" : props.bg ? props.bgcolor || myColor.blue : "#fff"}; */
        /* color: ${props => (props.bg ? "#fff" : myColor.gray)}; */
        /* border-color: ${props => (props.bg ? "transparent" : myColor.gray)}; */
        font-weight: 600;
        font-size: 16px;
        display: flex ;
        align-items: center ;
        justify-content: center;
        /* width: ${props => (props.size ? props.size : "auto")}; */
        border-radius: 50px;
        height: 39px;
        width: 39px;
    }
     /* .ant-btn[disabled] {
         color: red;
     } */
    :hover{
      border: 2px solid ${myColor.blue};
      color: #fff;
      background-color: ${myColor.hover_blue};
        /* border-color: ${props => (props.bg ? "transparent" : myColor.blue)}; */
        /* transform: translateY(-0.25em); */
        // background-color: ${myColor.hover_blue};
        /* color: ${props => (props.bg ? "#fff" : myColor.blue)}; */
    }
    :focus{
        border-color: ${myColor.blue}
    }
    @media (max-width: 1024px) {
        &.ant-btn{
            font-size: 12px;
        }
  }
`;

const MyButton = props => {
  return (
    // <StyledButton
    //   htmlType={props.htmlType}
    //   disabled={props.disabled}
    //   colordisable={props.disabled}
    //   bg={props.bg}
    //   bgcolor={props.bgColor}
    //   type="none"
    //   style={props.style}
    //   size={props.width}
    //   onClick={props.onClick}
    //   {...props}
    // >
    //   {props.lockicon ? (
    //     <span>{props.lockicon ? lockicon : props.text}</span>
    //   ) : (
    //     <>
    //       <span>{props.iconBefore}</span>
    //       {props.text}
    //       {props.isLoading ? <Spin className="ms-4" indicator={antIcon} /> : null}
    //       <span>{props.iconAfter}</span>
    //     </>
    //   )}
    // </StyledButton>
    <>
      <span
        onClick={props.disabled ? null : props.onClick}
        style={{
          marginRight: 8,
          fontWeight: 600,
          cursor: props.disabled ? "not-allowed" : "pointer",
          color: props.disabled ? "#afafaf" : myColor.blue,
        }}
      >
        {props.text == "Back" || props.text == "Cancel" ? null : props.text}
      </span>
      <StyledButton
        shape="circle"
        icon={
          props.text == "Back" || props.text == "Cancel" ? (
            <HiChevronLeft style={{ fontSize: 25 }} />
          ) : (
            <HiChevronRight style={{ fontSize: 25 }} />
          )
        }
        htmlType={props.htmlType}
        disabled={props.disabled}
        colordisable={props.disabled}
        bg={props.bg}
        bgcolor={props.bgColor}
        type="none"
        style={props.style}
        size={props.width}
        onClick={props.onClick}
        {...props}
      >
        {props.lockicon ? (
          lockicon
        ) : (
          <>
            {/* <span>{props.iconBefore}</span> */}
            {/* {props.text} */}
            {/* {props.isLoading ? <Spin className="ms-4" indicator={antIcon} /> : null} */}
            {/* <span>{props.iconAfter}</span> */}
          </>
        )}
      </StyledButton>
      <span
        onClick={props.disabled ? null : props.onClick}
        style={{
          marginLeft: 8,
          fontWeight: 600,
          cursor: props.disabled ? "not-allowed" : "pointer",
          color: props.disabled ? "#afafaf" : myColor.blue,
        }}
      >
        {props.text == "Back" || props.text == "Cancel" ? props.text : null}
      </span>
    </>
  );
};

export default MyButton;
