import React from "react";
import { Select, Button } from "antd";
import styled from "styled-components";
import { myColor } from "../GlobalVar";
import { DownOutlined, UserOutlined } from "@ant-design/icons";

const { Option } = Select;

const SelectWrapper = styled(Select)`
  &.ant-select-single {
    width: ${props => props.width};
  }
  .ant-select-selector {
    border-radius: 5px !important;
  }
  &.ant-select:hover {
    border-color: ${myColor.hover_blue};
  }
`;

const DropdownMenu = props => {
  return (
    <div>
      {props.title && (
        <h4 style={{ textAlign: "left", marginBottom: 8, fontSize: 16 }}>{props.title}</h4>
      )}
      <SelectWrapper
        name={props.name}
        placeholder={props.placeholder}
        width={props.width}
        size={"large"}
        value={props.currentVal ? props.currentVal.project?.title || props.currentVal : null}
        style={{ fontFamily: "Inter-Regular", fontSize: 16 }}
        onChange={val => props.changeVal(val)}
        listHeight={props.listHeight ? props.listHeight : 120}
      >
        {
          // props.menu && props.menu.length > 0 && props.menu.map((item, index) => {
          //     return <Option key={index} >{item.project.title}</Option>
          // })

          props.menu
        }
      </SelectWrapper>
    </div>
  );
};

export default DropdownMenu;
