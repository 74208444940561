import { rootReducer } from './reducers/rootReducer'
import { createStore, compose, applyMiddleware } from 'redux'
import thunk from "redux-thunk";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const configureStore = () => {

    const thunkMiddleware = [thunk];
    // const persistConfig = {
    //     key: 'root',
    //     storage: storage,
    //     whitelist: ['getAllProjectReducer'] // which reducer want to store
    // };
    // const pReducer = persistReducer(persistConfig, rootReducer());

    const store = createStore(
        // pReducer,
        rootReducer(),
        process.env.REACT_APP_MODE == 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ ? compose(
            applyMiddleware(...thunkMiddleware),
            window.__REDUX_DEVTOOLS_EXTENSION__()
        ) : applyMiddleware(...thunkMiddleware),
    )

    const persistor = persistStore(store);
    store.persistor = persistor
    // persistor.persist();

    store.asyncReducers = {};

    store.injectAsyncReducer = (key, reducer) => {
        if (store.asyncReducers[key]) {
            return
        }
        store.asyncReducers[key] = reducer;
        store.replaceReducer(rootReducer(store.asyncReducers))
        store.dispatch && store.persistor.persist();

        return store
    }

    store.removeAsyncReducer = (key) => {
        if (!store.asyncReducers[key]) {
            return
        }
        delete store.asyncReducers[key]
        store.replaceReducer(rootReducer(store.asyncReducers))
        store.dispatch && store.persistor.persist();

        return store
    }

    store.resetReducer = () => {
        // alert('Test > ')
        let keys = Object.keys({ ...store.asyncReducers })
        keys.map(val => {
            if (val !== 'loginReducer') {
                delete store.asyncReducers[val]
                store.replaceReducer(rootReducer(store.asyncReducer))
            }
            // delete store.asyncReducer[val]
        })

        // store.replaceReducer(rootReducer(store.asyncReducer))
        store.persistor.purge()
        // history.push('/')
        return store
    }

    return store;
}

// const injectAsyncReducer = (store,key,reducer) =>{
//     store.asyncReducers[key] = reducer;
//     store.replaceReducer(rootReducer(store.asyncReducers))
// }

export {
    configureStore,
    // injectAsyncReducer
}

