import { ideaValidation } from "../../config/axiosConfig";
import { getCookie } from "../../utils/cookies";
import { GET_PROJECT_BY_ID, CHANGE_IDEA_VALIDATION_STEPS } from "../constants/projects";
import { actions } from "../../utils/helperFunctions";
import { RATING_MODAL } from "../constants/modals";

export const getProjectById = () => async dispatch => {
  dispatch(actions.loading(GET_PROJECT_BY_ID));
  try {
    // let apiCall = await axios({
    //     method: 'get',
    //     url:'/api/projects/getProject',
    //     body:{
    //         "projectId": "6005332c44c7da056cc24be1"
    //     },
    //     headers:{authorization:getCookie('token')}
    // })
    let apiCall = await ideaValidation.get(
      `/api/projects/getProject/${getCookie("currentProject")}`,
      {
        headers: {
          authorization: getCookie("token"),
        },
      }
    );
    dispatch(actions.success(GET_PROJECT_BY_ID, apiCall.data.data));
  } catch (err) {
    dispatch(actions.failure(GET_PROJECT_BY_ID, err.response ? err.response.data.error : err));
  }
};
export const changeCurrentIdeaStep = step => {
  return {
    type: CHANGE_IDEA_VALIDATION_STEPS.SET,
    step: step,
  };
};

export const openRatingModal = (payload, solutionData) => {
  return {
    type: RATING_MODAL.OPEN,
    rating: payload.rate,
    message: payload.message,
    visibility: true,
    data: solutionData,
  };
};
export const closeRatingModal = rating => {
  return {
    type: RATING_MODAL.CLOSE,
    rating: "",
    message: "",
    visibility: false,
    data: "",
  };
};
