import {authModules} from '../../config/axiosConfig'
import {ACCEPT_TEAM_INVITATION} from '../constants/teams'
import {actions} from '../../utils/helperFunctions'


const getTeamInvitation = (params) => async dispatch => {
    dispatch(actions.loading(ACCEPT_TEAM_INVITATION))
    try{
        const apiCall = await authModules.get(`api/teams/acceptTeamInvitation/${params.memberId}/${params.teamId}`)
        dispatch(actions.success(ACCEPT_TEAM_INVITATION,apiCall.data.data))
    }
    catch(err){
        dispatch(actions.failure(ACCEPT_TEAM_INVITATION,err.response?err.response.data.error:err))
    }
}

export default getTeamInvitation
