import React, { useState } from "react";
import { Row, Input, Form } from "antd";
import CreativeMind from "../../assets/img/CreativeMind.png";
import MyButton from "../../components/MyButton";
import { myColor } from "../../GlobalVar";
import { MainContainer } from "../../components/MainContainer";
import InputField from "../../components/InputField";
import getResponderData from "../../redux/actions/respondent/getResponderData";
import { connect } from "react-redux";
import { createCookie } from "../../utils/cookies";

import { withReducer } from "../../redux/withReducer";
import { createApiCallReducer } from "../../utils/helperFunctions";
import { GET_RESPONDER_DATA } from "../../redux/constants/respondent/responder";
import HomeCardButton from "../../components/homeCardButton";

const LandingPage = props => {
  const { data, err, isLoading } = props.getResponderDataReducer;

  const [responderUser, setResponderUser] = useState({
    fullName: "",
    responderEmail: "",
  });

  React.useEffect(() => {
    if (data) {
      setResponderUser({ ...responderUser, responderEmail: data.responderEmail });
    }
  }, [props.getResponderDataReducer]);

  React.useEffect(() => {
    if (props.match.params.id && props.match.params.email) {
      props.getResponderData({ id: props.match.params.id, email: props.match.params.email });
    }
  }, []);

  React.useEffect(() => {
    if (err == "Response already submitted") {
      props.history.push({
        pathname: "/responder-confirm-page",
        state: { message: err },
      });
    }
  }, [props.getResponderDataReducer]);

  React.useEffect(() => {
    props.notShowExtraContent();
    return () => {
      props.showExtraContent();
    };
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setResponderUser({ ...responderUser, [name]: value });
  };

  const handleSubmit = () => {
    // createCookie('responerName',responderUser.fullName)
    // createCookie('responerEmail',responderUser.email)
    props.history.push({
      pathname: `/responderSteps/${props.match.params.id}/${props.match.params.email}`,
      state: { fullName: responderUser.fullName, responderData: data.data },
    });
  };
  console.log("data", data);
  return (
    <div className="p-lg-5 p-4">
      <h1
        style={{
          textAlign: "center",
          margin: "50px 0",
          fontSize: "42px",
          fontFamily: "Inter-Medium",
          color: myColor.black,
        }}
      >
        Welcome to Validatr.io
      </h1>

      <div className="row justify-content-between">
        <div className="col-lg-6 col-sm-12 col-xs-12 d-flex flex-column align-items-center justify-content-center">
          <img className="img-fluid" src={CreativeMind} />
          <div className="" style={{ padding: "0px 10%" }}>
            <h2 style={{ textAlign: "center", color: myColor.black }}>
              "{data?.data?.userId?.firstName} {data?.data?.userId?.lastName}" wants your feedback
              on the idea validation project: "{data?.data?.title}"
            </h2>
            <h4 className="my-4" style={{ fontFamily: "Inter-Medium" }} align="center">
              We'd appreciate if you can take few minutes of your time to share your thoughts on the
              business idea.
            </h4>
            <h4 style={{ fontFamily: "Inter-Medium" }} align="center">
              Please enter your particulars to start the survey.
            </h4>
          </div>
        </div>

        <div className="col-lg-5 col-sm-12 col-xs-12 mb-5">
          <Form onFinish={() => handleSubmit()}>
            <Form.Item
              name="fullName"
              rules={[
                {
                  type: "string",
                  required: true,
                  min: 3,
                  message: "Please enter your Valid Full Name",
                  // pattern: /^\S+(?:\s+\S+)+$/,
                },
              ]}
              validateFirst={false}
            >
              <InputField
                style={{ height: 60 }}
                name="fullName"
                placeholder="Full Name*"
                value=""
                onChange={handleChange}
                className="input-line-lander"
              />
            </Form.Item>

            <Form.Item
              // initialValue={responderUser.responderEmail}
              name="responderEmail"
              rules={[
                {
                  type: "email",
                },
              ]}
              validateFirst={false}
            >
              <InputField
                disabled
                style={{ height: 60, color: "gray" }}
                name="responderEmail"
                placeholder="Email"
                myValue={responderUser.responderEmail}
                onChange={handleChange}
                className="input-line-lander"
              />
            </Form.Item>

            <span style={{ marginTop: "40px", fontFamily: "Inter-Medium", fontSize: 18 }}>
              Answers displayed are not pre-moderated and are shown in real time. By proceeding, you
              agree to keep the information seen on the following screens confidential.
            </span>
            <div>
              <HomeCardButton
                htmlType="submit"
                text={`Let's get started!`}
                bg={true}
                width={"250px"}
                style={{ marginTop: 40 }}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    getResponderDataReducer: state.getResponderDataReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getResponderData: data => dispatch(getResponderData(data)),
    notShowExtraContent: () => dispatch({ type: "HEADER_FOOTER_HIDE" }),
    showExtraContent: () => dispatch({ type: "HEADER_FOOTER_NOT_HIDE" }),
  };
};

export default withReducer([
  { key: "getResponderDataReducer", reducer: createApiCallReducer(GET_RESPONDER_DATA) },
])(connect(mapStateToProps, mapDispatchToProps)(LandingPage));
