import {ideaValidation} from '../../../config/axiosConfig'
import { actions } from '../../../utils/helperFunctions'
import { POST_RESPONDER_DATA } from '../../constants/respondent/responder'


const postResponseAndResponder =  (payLoad) => async dispatch =>{
    const {response, endPoint, responder} = payLoad
    dispatch(actions.loading(POST_RESPONDER_DATA))
    try {
        let apiCall = await ideaValidation.post(`/api/responses/addResponseAndResponder/${endPoint.id}/${endPoint.email}`, {
            response: response, 
            responder: responder 
        })
        dispatch(actions.success(POST_RESPONDER_DATA,apiCall.data.data))
    } catch (err) {
        dispatch(actions.failure(POST_RESPONDER_DATA,err.response?err.response.data.error:err))
    }

}

export default postResponseAndResponder;