import React, { useState, useEffect } from "react";
import Logo from "../../assets/svg/validatr-logo.svg";
import "./header2.style.scss";
import "./header.style.scss";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { Row, Col, Collapse, Menu, Dropdown } from "antd";
import { IoMenuOutline } from "react-icons/io5";
import MobileDrawer from "./mobileDrawer";
import { menuData } from "./menuData";
import { GoChevronDown } from "react-icons/go";
import HomeCardButton from "../homeCardButton";

const { Panel } = Collapse;

const HeaderWrapper2 = props => {
  const history = useHistory();
  const [sidebar, toggleSidebar] = useState(false);
  const [path, setPath] = React.useState(history.location.pathname);
  const [showSwitch, setShowSwitch] = React.useState(true);

  useEffect(() => {
    setPath(history.location.pathname);
    if (
      history.location.pathname == "/Home" ||
      history.location.pathname == "/Knowledgs Base" ||
      history.location.pathname == "/FAQ's"
    ) {
      setShowSwitch(true);
    } else {
      setShowSwitch(false);
    }
  }, [history.location.pathname]);

  const [active, setActive] = useState("");
  const [click, setClick] = useState(false);
  const [switchChecked, setSwitchChecked] = React.useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div className="header-wrapper-2">
      <MobileDrawer visible={sidebar} onClose={() => toggleSidebar(false)} />

      <NavWrapper className="container-fluid " style={{ zIndex: 2 }}>
        <Row justify="space-between" align="middle">
          <Col xs={3} sm={3} md={1} lg={0} xl={0}>
            <IoMenuOutline
              style={{ fontSize: 32, cursor: "pointer" }}
              onClick={() => toggleSidebar(true)}
            />
          </Col>

          <Col xs={16} sm={16} md={10} lg={3} xl={4}>
            <a style={{ textDecoration: "none" }} href="/">
              <img style={{ width: 175 }} src={Logo} className="img-fluid site-logo" />
            </a>
          </Col>

          <Col xs={0} sm={0} md={10} lg={10} xl={12}>
            <menu
              className="menu-list d-none d-lg-flex justify-content-center align-items-center m-0"
              style={{
                backgroundColor: "#fff",
                zIndex: 2,
              }}
            >
              {menuData.map((menu, index) => {
                if (menu.submenu) {
                  return (
                    <Dropdown
                      key={index}
                      overlay={
                        <Menu style={{ minWidth: 200, marginTop: -4 }}>
                          {menu.submenu.map((subMenuItem, index) => {
                            if (subMenuItem.submenu) {
                              return (
                                <Menu.SubMenu
                                  key={index}
                                  title={<a className="navigation-link">{subMenuItem.title}</a>}
                                >
                                  {subMenuItem.submenu.map((item, index) => (
                                    <Menu.Item
                                      role="button"
                                      key={`sub-menu-${index}`}
                                      style={{ minWidth: 240 }}
                                    >
                                      <a href={item.link || ""} className="navigation-link">
                                        {item.title}
                                      </a>
                                    </Menu.Item>
                                  ))}
                                </Menu.SubMenu>
                              );
                            } else {
                              return (
                                <Menu.Item key={index}>
                                  <a href={subMenuItem.link || ""} className="navigation-link">
                                    {subMenuItem.title}
                                  </a>
                                </Menu.Item>
                              );
                            }
                          })}
                        </Menu>
                      }
                    >
                      <div
                        className={`menu-item ${
                          window.location.pathname === menu.link ? "active" : ""
                        }`}
                      >
                        <a style={{ textTransform: "uppercase" }}>{menu.title}</a>
                        <GoChevronDown style={{ marginBottom: 4 }} className="ml-2" />
                      </div>
                    </Dropdown>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className={`menu-item ${
                        window.location.pathname === menu.link ? "active" : ""
                      }`}
                    >
                      <Link to={menu.link}>{menu.title}</Link>
                    </div>
                  );
                }
              })}
            </menu>
          </Col>

          <Col
            className="d-none d-lg-flex justify-content-end align-items-center"
            xs={0}
            sm={0}
            md={0}
            lg={8}
          >
            <a
              href="sample-report.pdf"
              target="_blank"
              style={{ fontFamily: "Inter-Bold", textDecoration: "underline" }}
              className="me-3"
            >
              View Sample Report
            </a>
            <Link to={"/sign-in"}>
              <HomeCardButton text="Login / Sign up" bg={true} width="150px" />
            </Link>
          </Col>
        </Row>
      </NavWrapper>
    </div>
  );
};

export default HeaderWrapper2;

const NavWrapper = styled.div`
  padding: 0 10%;
`;
