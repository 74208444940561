import { createApiCallReducer } from "../../utils/helperFunctions";
import { RATING_MODAL } from "../constants/modals";
import {
  GET_PROJECTS,
  GET_PROJECT_BY_ID,
  CHANGE_IDEA_VALIDATION_STEPS,
  //    ADD_PROJECT,
  //    SET_CURRENT_USER_PROJECT
} from "../constants/projects";

// export const addProjectReducer = createApiCallReducer(ADD_PROJECT)
// export const setCurrentUserProjectReducer = createApiCallReducer(SET_CURRENT_USER_PROJECT)

const getAllProjectInititalState = {
  data: null,
  dataMember: null,
  projectDataMember: null,
  projectDataAdmin: null,
  isLoading: false,
  err: null,
};
export const getAllProjectReducer = (state = getAllProjectInititalState, action) => {
  switch (action.type) {
    case GET_PROJECTS.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROJECTS.SUCCESS:
      // let myProject = action.payLoad.filter(val=>val.myProject)
      // let iAmMemberProject = action.payLoad.filter(val=>val.myProject == false)
      return {
        ...state,
        isLoading: false,
        data: action.payLoad,
        projectDataMember: action.payLoad.filter(val => val.myProject == false),
        projectDataAdmin: action.payLoad.filter(val => val.myProject == true),
        dataMember: action.payLoad.filter(val => val.myProject == false),
        err: null,
      };
    case GET_PROJECTS.FAILURE:
      return {
        ...state,
        isLoading: false,
        err: action.err,
      };
    case GET_PROJECTS.RESET:
      return {
        data: null,
        dataMember: null,
        projectDataMember: null,
        projectDataAdmin: null,
        isLoading: false,
        err: null,
      };
    default:
      return state;
  }
};

const getProjectByIdReducerInititalState = {
  data: null,
  isLoading: false,
  err: null,
  currentStep: 0,
};
export const getProjectByIdReducer = (state = getProjectByIdReducerInititalState, action) => {
  switch (action.type) {
    case GET_PROJECT_BY_ID.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROJECT_BY_ID.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payLoad,
        err: null,
      };
    case GET_PROJECT_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        err: action.err,
      };
    case CHANGE_IDEA_VALIDATION_STEPS.SET:
      return {
        ...state,
        currentStep: action.step,
      };
    // case CHANGE_IDEA_VALIDATION_STEPS.INCREASE:
    //     let newIncreaseStep = state.currentStep < 3 ? state.currentStep+1 : state.currentStep
    //     return{
    //         ...state,
    //         currentStep: newIncreaseStep
    //     }
    // case CHANGE_IDEA_VALIDATION_STEPS.DECREASE:
    //     let newDecreaseStep = state.currentStep !== 0 ? state.currentStep-1 : state.currentStep
    //     return{
    //         ...state,
    //         currentStep: newDecreaseStep
    //     }

    default:
      return state;
  }
};
export const ratingModalReducer = (
  state = { visibility: false, rating: "", message: "", data: {} },
  action
) => {
  switch (action.type) {
    case RATING_MODAL.OPEN:
      return {
        ...state,
        visibility: true,
        rating: action?.rating,
        message: action?.message,
        data: action.data,
      };
    case RATING_MODAL.CLOSE:
      return {
        ...state,
        visibility: false,
        rating: "",
        message: "",
        data: {},
      };
    default:
      return state;
  }
};
