import React from "react";
import styled from "styled-components";
import HeroSection from "../components/heroSection/index";
import privacyPolicyBanner from "../../src/assets/img/term-of-use-banner.jpg";
import Heading from "../../src/components/headings/index";
import { LandingPageFooter } from "../components/landingPageFooter";

const StyledDiv = styled.div`
  .gray-portion-div {
    background-color: #fff;
    padding: 120px 10% 40px 10%;
    p {
      font-size: 16px;
      color: rgb(69, 80, 98);
      margin-bottom: 30px;
    }
    h3 {
      color: rgb(69, 80, 98);
    }
    h6 {
      font-weight: 700;
      color: rgb(69, 80, 98);
      margin-bottom: 20px;
    }
    h1 {
      font-weight: 600;
      color: rgb(69, 80, 98);
    }
    ul {
      padding-left: 30px;
      margin-bottom: 30px;
    }
    li {
      font-size: 16px;
      color: rgb(69, 80, 98);
      font-weight: 300;
    }
  }

  @media (max-width: 767px) {
    .top-banner-text {
      text-align: center;
      h6 {
        padding: 0px 0px 4% 0px;
      }
    }
    .banner-image-col {
      justify-content: center;
    }
    .top-banner {
      background-color: #fff;
      img {
        width: 250px;
      }
    }
    .gray-portion-div {
      background-color: #f6f6f6;
      p {
        font-size: 16px;
      }
      h3 {
        font-size: 22px;
      }
      li {
        font-size: 16px;
      }
    }
  }
`;

const TermAndConditions = () => {
  return (
    <>
      <StyledDiv>
        <HeroSection
          height="70vh"
          companySliderHide={true}
          type="image"
          background={privacyPolicyBanner}
        >
          <div>
            <Heading color="#fff" backgroundColor="#ed3c69">
              Terms and Conditions
            </Heading>
          </div>
        </HeroSection>
        <div className="gray-portion-div">
          <p>
            {`Welcome to Validatr.io, your go-to platform for idea validation. We aim to provide a seamless and secure experience for all users. Before using our services, please carefully read and understand the following terms and conditions. By accessing and using Validatr.io, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our platform.`}
          </p>

          <h3>Idea Validation</h3>
          <p>
            Validatr.io is designed to help you validate your ideas and concepts. While we provide
            tools and resources to assist you in this process, it is important to note that the
            results obtained through our platform are not guarantees of success. The success of any
            idea ultimately depends on various factors, including market dynamics, competition, and
            execution.
          </p>
          <h3>User Accounts</h3>
          <p>
            To access certain features of Validatr.io, you may need to create a user account. You
            are responsible for maintaining the confidentiality of your account information and for
            all activities that occur under your account. Please ensure that your account
            information is accurate and up-to-date. If you believe your account has been
            compromised, please notify us immediately.
          </p>

          {/* <h3>User Accounts</h3>
          <p>
            <span style={{ fontWeight: 700 }}>validatr.io</span> ensures 100% customer satisfaction
            with our unlimited free Revision Policy which enables you to improve on your initial
            design brief free of charge through your account area. <br />
            <br />
            In case of any duplicate charge due to processing error, your complete design order
            amount will be refunded. <br />
            <br />
            In case of any dissatisfaction from any design services provided by validatr.io, you can
            submit a refund request within 48 hours of your initial designs completion. However, it
            shall be assumed between both parties that you are satisfied with your initial designs
            if a refund request is not received within 48 hours of initial designs delivery. For any
            Special / Combo packages, refund will be applicable the same as it is on the single
            packages.
            <br />
            <br />
            Your refund would be processed as per the chart below:
            <br />
            <br />
            All refund requests will be fulfilled as per the following arrangement:
          </p>
          <ul>
            <li>
              If refund request is made after 48 hours of initial design samples delivery, then it
              won’t be eligible for any refund. It will be assumed that the customer is satisfied
              with design samples provided.
            </li>
            <li>
              No refund request will be entertained if you have placed any revision on the design
              samples provided.
            </li>
          </ul>
          <p>
            All refund requests will be subject to approval or disapproval. validatr.io has the
            right to disapprove or approve any refund request based on any policy violations made by
            the customer. This would differ from project to project.
          </p>

          <h6>How to claim your refund</h6>
          <p>
            To assure your refund request is approved, please make sure you meet the following
            requirements:
          </p>
          <p style={{ marginTop: 20, marginBottom: 20 }}>
            1. Claim your refund specifying your concern by contacting us via any of the following
            three modes:
          </p>
          <ul>
            <li>
              Call Customer support at{" "}
              <a href="tel:+14086220105">
                <a>+1-408-622-0105</a>
              </a>
            </li>
            <li>
              Live Chat.
              <a> Click here </a>
              to start chat now
            </li>
            <li>
              <a href="/contact-us">
                <a>Click here</a>
              </a>
              to contact us for a prompt response OR email us support@validatr.io
            </li>
          </ul>

          <p style={{ marginTop: 20, marginBottom: 20 }}>
            2. We will try to resolve your concern by virtue of our revision policy immediately or
            else will email you a refund request approval from our refund department.
          </p>
          <p>
            After the refund, your design rights would be obtained by validatr.io and you would not
            be able to display any version of the design sent by company. Let us also specify that:
          </p>
          <ul>
            <li>
              {" "}
              Since the design rights would now be transferred to the company, you agree that you
              will have no right (direct or indirect) to use any response or other content, work
              product or media, nor will you have any ownership interest in or to the same.
            </li>
            <li>
              Working in collaboration with the Government Copyright Agencies
              <span style={{ fontWeight: 700 }}> validatr.io </span>would share Copyright
              Acquisition information for the refunded designs that would restrict the re-use of the
              designs as original designs in the future.
            </li>
          </ul>
          <p>
            If you have any questions or concerns about our Refund Policy, please contact us by
            <a href="/contact-us">
              <a> clicking here </a>
            </a>
            .
          </p>

          <h6>Account Area</h6>
          <p>
            The Account Area is a convenient way to communicate. It is your sole responsibility to
            check the account area to address any queries, concerns, or additional instructions
            required by the designer. Not checking or using the Account Area frequently shall not
            provide you adequate grounds for a refund. However, if you are uncertain how to use the
            area, you may contact the customer support team at any time for assistance.
          </p> */}

          <h3>User Content</h3>
          <p>
            When using Validatr.io, you may submit content, such as ideas, surveys, or feedback. You
            retain ownership of your content, but by submitting it to our platform, you grant
            Validatr.io a worldwide, non-exclusive, royalty-free license to use, reproduce, modify,
            adapt, publish, translate, distribute, and display your content for the purpose of
            providing our services.
          </p>

          <h3>Privacy </h3>
          <p>
            We value your privacy and are committed to protecting your personal information. Our
            Privacy Policy outlines how we collect, use, and safeguard your data. By using
            Validatr.io, you consent to the practices described in our Privacy Policy.
          </p>

          <h3>Prohibited Activities</h3>
          <p>You agree not to engage in any of the following activities while using Validatr.io:</p>
          <ul>
            <li>Violating any applicable laws or regulations.</li>
            <li>Impersonating another person or entity.</li>
            <li>Transmitting harmful code or viruses.</li>
            <li>Attempting to gain unauthorized access to our systems or data.</li>
            <li>Using our platform for any unlawful or unethical purposes. </li>
          </ul>

          <h3>Intellectual Property</h3>
          <p>
            Validatr.io and its content, including but not limited to logos, trademarks, text,
            graphics, and software, are the property of Validatr.io and are protected by
            intellectual property laws. You may not use our intellectual property without our prior
            written consent.
          </p>

          <h3>Disclaimer of Warranty</h3>
          <p>
            Validatr.io provides its services on an "as-is" and "as-available" basis. We make no
            warranties or representations, either express or implied, regarding the accuracy,
            reliability, or suitability of our platform for your particular purposes.
          </p>
          <h3>Limitation of Liability</h3>
          <p>
            To the fullest extent permitted by law, Validatr.io and its affiliates shall not be
            liable for any direct, indirect, incidental, special, or consequential damages arising
            out of or in any way connected with your use of our platform.
          </p>
          <h3>Termination</h3>
          <p>
            We reserve the right to terminate or suspend your access to Validatr.io at our sole
            discretion, without prior notice, for any reason, including but not limited to violating
            these terms and conditions.
          </p>

          <h3>Changes to Terms and Conditions</h3>
          <p>
            Validatr.io may update these terms and conditions from time to time. Any changes will be
            posted on our platform, and it is your responsibility to review them periodically. Your
            continued use of Validatr.io after the posting of changes constitutes your acceptance of
            the modified terms.
          </p>
          <h3>Contact Information</h3>
          <p>
            If you have any questions or concerns regarding these terms and conditions, please get
            in touch with us at social@validatr.io. Thank you for choosing Validatr.io. We look
            forward to assisting you in validating your ideas and concepts.
          </p>
        </div>
      </StyledDiv>
      {/* <LandingPageFooter /> */}
    </>
  );
};

export default TermAndConditions;
