import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Collapse, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Heading from "../landingPageHeading";
import { Fade } from "react-awesome-reveal";

const StyledDiv = styled.div`
  background-color: #fff;
  padding: 3% 10%;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #0b3558;
    border-top: 1px solid rgb(221, 223, 225) !important;
    border-right: 1px solid rgb(221, 223, 225) !important;
    border-left: 1px solid rgb(221, 223, 225) !important;
    border-bottom: 1px solid rgb(221, 223, 225) !important;
    transition: 0.5s ease;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    border-top: 1px solid rgb(221, 223, 225) !important;
    border-right: 1px solid rgb(221, 223, 225) !important;
    border-left: 1px solid rgb(221, 223, 225) !important;
    border-bottom: 1px solid rgb(221, 223, 225) !important;
    padding: 16px 36px 16px 16px;
    transition: 0.5s ease;
    font-size: 18px;
    color: #0b3558;
    font-weight: 600;
    cursor: pointer;
  }

  .ant-collapse-content {
    // border-top: 1px solid rgb(221, 223, 225) !important;
    border-right: 1px solid rgb(221, 223, 225) !important;
    border-left: 1px solid rgb(221, 223, 225) !important;
    border-bottom: 1px solid rgb(221, 223, 225) !important;
    transition: 0.5s ease;
    p {
      font-size: 14px;
      color: #4c4a4a;
      font-weight: 500;
      line-height: 24px;
      margin: 0px;
    }
  }

  .ant-collapse-header {
    font-size: 18px;
    font-weight: 600;
    color: #0b3558;
    transition: 0.5s ease;
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      color: #0042e6 !important;
      transition: 0.5s ease;
    }
  }

  .ant-collapse-borderless > .ant-collapse-item-active {
    .ant-collapse-header {
      border-bottom: 0px !important;

      transition: 0.5s ease;
    }
  }

  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 16px;
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 0px;
    margin-bottom: 24px;
    background-color: #fff;
  }

  .ant-collapse-borderless {
    background-color: #fff;
  }

  @media (max-width: 991px) {
  }

  @media (max-width: 767px) {
    .ant-collapse-content {
      p {
        font-size: 14x;
      }
    }
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
  }
`;

const LandingPageFaq = props => {
  const { Panel } = Collapse;
  return (
    <StyledDiv>
      <Fade direction="up" triggerOnce duration={1300} damping={0.2}>
        <Heading title="Frequently Asked Questions"></Heading>
      </Fade>
      <Row gutter={[40, 10]}>
        {/* 1st faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion={false}
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 24 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What is Validatr.io and how can it help me validate my business ideas?"
              key="1"
            >
              <p>
                Validatr.io is the Perfect Blend of AI and Human Expertise, an idea validation tool
                that supports startups in validating their business ideas from multiple
                perspectives. Throughout the validation process, Validatr.io offers assistance with
                its unique metrics and a comprehensive 5-factor assessment framework, which helps
                validate the idea and provides insights into the potential for success or potential
                obstacles that may arise.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 2nd faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 24 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How does Validatr.io differentiate itself from other idea validation tools?"
              key="2"
            >
              <p>
                By leveraging the power of AI, Validatr.io provides a holistic solution to validate
                and refine your ideas effectively.Validatr.io is the differentiator as it brings
                together various sources of information and expertise, including rephrasing your
                statements, 5 factor rating by your targeted market buyer’s persona, insights from
                VC firm executives, and feedback from industry professionals; all impersonated by
                AI. This comprehensive approach helps streamline your idea validation journey,
                making it easier and more mature.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 3rd faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 24 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How can Validatr.io help me make my idea validation journey easy and mature?"
              key="3"
            >
              <p>
                Validatr.io serves as a valuable resource throughout the idea validation process,
                offering AI-powered guidance and support to startups seeking to validate their
                business ideas effectively. By automating tasks such as rephrasing, analysis, and
                gathering feedback, it saves you time and effort. The AI-powered insights and
                recommendations enable you to make informed decisions, refine your ideas, and
                present them more effectively. With Validatr.io, you can navigate your idea
                validation journey with confidence and maturity.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 4th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 24 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How can Validatr.io help me with my problem or solution statement?"
              key="4"
            >
              <p>
                Validatr's AI can rephrase your problem or solution statement, providing alternative
                ways of expressing your ideas. This helps you refine and clarify your message,
                making it more effective in communicating your concept to others.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 5th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 24 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What aspects does Validatr.io analyze for both the problem statement and solution statement, and how does this analysis contribute to idea validation??"
              key="5"
            >
              <p>
                Validatr.io conducts a comprehensive analysis of the problem statement, assessing
                its relevance to the target market. For the solution statement, Validatr.io
                evaluates the factors of usefulness, uniqueness, likeliness to promote, and
                willingness to buy, providing valuable insights for idea validation.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 6th faq */}
        {/* <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel header="What happens if I am not satisfied with the project delivery?" key="6">
              <p>
                We value our clients and strive to ensure their expectations are met. However, in
                case of any dissatisfaction with the project delivery, our project manager can
                discuss the pain points and accommodate you with the best of our abilities in
                improving/ resolving the issues to earn your satisfaction. Since we document
                everything, the outcome of a milestone delivery aligns with the agreed
                documentation. However, if a client is not satisfied with the team or their manager
                discuss and bring required change in the team too.
              </p>
            </Panel>
          </Collapse>
        </Col> */}

        {/* 7th faq */}
        {/* <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel header="How long will my project take to complete?" key="7">
              <p>
                Whether the project is based on modern or legacy systems, the delivery is dependent
                on the size and complexity of the project, and the number of resources allocated to
                it. The design phase, can take 2 - 6 weeks for most projects, while the development
                phase for non-complex projects can take 3 - 6 months. Medium complexity projects can
                take 6 - 12 months. Large, complex projects can take up to 12 - 24 months.
              </p>
            </Panel>
          </Collapse>
        </Col> */}

        {/* 8th faq */}
        {/* <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel header="Who is the standard project team as resource?" key="8">
              <p>
                In general, our development team includes two developers for front–end and back-end,
                one graphics designer, a QA tester, a DevOps and a project manager. However we can
                add more resources to the team if a project demands.
              </p>
            </Panel>
          </Collapse>
        </Col> */}

        {/* 9th faq */}
        {/* <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="Do you provide maintenance support for a completed web/mobile application?"
              key="9"
            >
              <p>
                Yes, we offer our maintenance and support services to our clients across the globe.
                There are various maintenance agreements that include bug-fixing, performance
                optimization, database maintenance, and security analysis.
              </p>
            </Panel>
          </Collapse>
        </Col> */}

        {/* 10th faq */}
        {/* <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel header="Do you offer code reviews?" key="10">
              <p>
                Yes, we run Sonar Cube and follow OWASP guidelines for code reviews. We provide a
                report covering static and dynamic code analysis, quality, documentation, dependency
                management, test coverage, and performance monitoring.
              </p>
            </Panel>
          </Collapse>
        </Col> */}

        {/* 11th faq */}
        {/* <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel header="Can you provide references to your work?" key="11">
              <p>
                You can review our client references and testimonials in the portfolio section of
                the website.
              </p>
            </Panel>
          </Collapse>
        </Col> */}

        {/* 12th faq */}
        {/* <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#0042E6", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel header="When will the project get started?" key="12">
              <p>
                Once we have completed the brainstorming session to gather client's project vision
                and have agreed to proceed, the project can be initiated upon signing of an
                agreement.
              </p>
            </Panel>
          </Collapse>
        </Col> */}
      </Row>
    </StyledDiv>
  );
};

export default LandingPageFaq;
