import React, { useState, useEffect, useRef } from "react";
import { Modal, Row, Col, Form, Select, Tooltip } from "antd";
import MyButton from "../MyButton";
import { myColor } from "../../GlobalVar";
import styled from "styled-components";
import InputFeild from "../InputField";
import { connect } from "react-redux";
import postAddProject from "../../redux/actions/postAddProject";
import postUserLogOut from "../../redux/actions/postUserLogOut";
import ImgeUpload from "../ImageUplaod";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { createApiCallReducer, industryData } from "../../utils/helperFunctions";
import { withReducer } from "../../redux/withReducer";
import { ADD_PROJECT } from "../../redux/constants/projects";
import HomeCardButton from "../homeCardButton";
import DropdownMenu from "../DropdownMenu";
import SelectWrapper from "../select";
import InputWrapper from "..";
const { Option } = Select;

const StyledIdeaNameModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-close-x {
    display: ${props => (props.notClose ? "none" : "block")};
  }
`;
const SubTitle = styled.h4({
  fontFamily: "Inter-Regular",
  transform: "translate3d(0,80px,0)",
  transition: "transform 1s ease",
});

const LinkText = styled.p`
  font-size: 16px;
  // fontSize: 18, fontFamily: 'Inter-Medium', color: myColor.black
  color: ${myColor.blue};
  position: relative;
  margin: 0;
  :hover {
    // color:red;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const IdeaNameModal = props => {
  const history = useHistory();

  const { data, isLoading, err } = props.addProjectState;

  const [projectName, setProjectName] = useState("");
  const [industryName, setIndustryName] = useState("");

  const [projectImage, setProjectImage] = useState(null);
  useEffect(() => {
    if (data) {
      props.setIdeaNameModal(false);
      form.resetFields();
    }
  }, [data]);

  const handleSave = () => {
    const newProject = props.getAllProjectsState.data.find(
      item => item.project.title == projectName
    );

    if (!newProject) {
      // let payLoad = projectImage ? { title: projectName, image: projectImage } : { title: projectName }
      // props.postAddProject(payLoad)
      if (projectImage && projectImage[0].status == "done") {
        let payLoad = {
          title: projectName,
          image: projectImage[0].response.data.docPath,
          industry: industryName,
        };
        props.postAddProject(payLoad);
      } else {
        let payLoad = { title: projectName, industry: industryName };
        props.postAddProject(payLoad);
      }
    } else {
      cogoToast.info("Project with specified name already exist");
    }
  };

  const [form] = Form.useForm();
  return (
    <StyledIdeaNameModal
      visible={props.visible}
      onCancel={() => props.notClose == false && props.setIdeaNameModal(false)}
      width={props.width && props.width}
      footer={false}
      notClose={props.notClose}
    >
      <div style={{ padding: "20px 40px 0 40px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div
              style={{
                background: "#F8FAFE",
                padding: "10px 5px",
                width: 80,
                height: 80,
                overflow: "hidden",
                borderRadius: 100,
                marginRight: 30,
              }}
            >
              <img width="70" src={props.avatar} />
            </div>
          </div>
          <span style={{ fontSize: 18, fontFamily: "Inter-Medium", color: myColor.black }}>
            Give a unique name to your idea that communicate your product or services to stand out.
          </span>
        </div>

        <div style={{ margin: "50px 0 20px 0" }}>
          <h3>Give your idea a short name</h3>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 style={{ fontSize: 18 }}>Please enter your idea name below.</h5>
            {props.notClose && (
              <LinkText onClick={() => props.postLogout(history)}>Logout</LinkText>
            )}
          </div>
        </div>

        <Form form={form} onFinish={handleSave}>
          <Form.Item
            name="Project Name"
            rules={[
              {
                type: "string",
                required: true,
                min: 5,
                message: "Please enter valid Project Name",
              },
            ]}
            validateFirst={false}
          >
            <InputFeild
              maxLength={50}
              name="Project Name"
              value={projectName}
              placeholder="Project Name"
              onChange={e => setProjectName(e.target.value)}
              style={{ margin: 0, height: 40, fontSize: 16 }}
            />
          </Form.Item>

          <Form.Item
            name="industryName"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please enter Niche",
              },
            ]}
          >
            <InputFeild
              style={{ margin: 0, height: 40, fontSize: 16 }}
              placeholder="Enter Niche"
              name="industryName"
              defaultValue={props.getProjectByIdState?.industry}
              value={industryName}
              onChange={e => setIndustryName(e.target.value)}
              suffix={
                <Tooltip
                  placement="bottom"
                  title="A niche refers to a specialized area or market segment that focuses on meeting the unique needs of a specific group of customers. For instance, Tesla's niche is centered around Electric Vehicles (EVs) within the broader industry of Automotive."
                >
                  <InfoCircleOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                    }}
                  />
                </Tooltip>
              }
            />
            {/* <Select
              style={{ margin: 0, height: 40, fontSize: 16, borderRadius: 12 }}
              size="large"
              showSearch
              name="industryName"
              placeholder="Select industry"
              className="w-100"
              onChange={e => setIndustryName(e)}
            >
              {industryData.map((val, idx) => {
                return (
                  <Option key={idx} value={val.value}>
                    {val.value}
                  </Option>
                );
              })}
            </Select> */}
          </Form.Item>

          <ImgeUpload
            from="ideaName"
            projectIcon={true}
            maxFile={"Optional"}
            setProjectImage={setProjectImage}
          />

          {props.notClose == false ? (
            <Row justify="space-between" style={{ margin: "25px 0px 0 0" }}>
              <Col>
                <HomeCardButton
                  text="Cancel"
                  bg={false}
                  width="150px"
                  onClick={() => props.notClose == false && props.setIdeaNameModal(false)}
                />
              </Col>
              <Col style={{ marginLeft: "10px" }}>
                <HomeCardButton
                  text="Save"
                  htmlType="submit"
                  bg={true}
                  width="150px"
                  disabled={isLoading}
                  // onClick={handleSave}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          ) : (
            <Row justify="center">
              <Col>
                <HomeCardButton
                  text="Save"
                  htmlType="submit"
                  bg={true}
                  width="150px"
                  disabled={isLoading}
                  // onClick={handleSave}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </StyledIdeaNameModal>
  );
};

const mapStateToProps = state => {
  return {
    getAllProjectsState: state.getAllProjectReducer,
    addProjectState: state.addProjectReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postAddProject: data => dispatch(postAddProject(data)),
    postLogout: history => dispatch(postUserLogOut(history)),
  };
};

export default withReducer([
  { key: "addProjectReducer", reducer: createApiCallReducer(ADD_PROJECT) },
])(connect(mapStateToProps, mapDispatchToProps)(IdeaNameModal));
