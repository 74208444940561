import React from "react";
import { Button, Spin } from "antd";
import styled from "styled-components";
import { myColor } from "../GlobalVar";
import { LoadingOutlined, LockOutlined, SearchOutlined } from "@ant-design/icons";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: myColor.blue }} spin />;
const lockicon = <LockOutlined style={{ fontSize: 23, color: myColor.blue }} />;

const StyledButton = styled(Button)`
  &.ant-btn {
    border: 2px solid ${myColor.blue};
    background-color: ${props =>
      props.colordisable ? "#f5f5f5" : props.bg ? props.bgcolor || myColor.blue : "#fff"};
    color: ${props => (props.bg ? "#fff" : myColor.gray)};
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => (props.size ? props.size : "auto")};
    border-radius: 50px;
    height: 42px;
    transition: 0.1s ease;
  }
  .icon-before {
    margin-right: 8px;
    color: ${myColor.blue};
    transition: 0.1s ease;
  }
  :hover {
    transition: 0.1s ease;
    border: 2px solid ${myColor.blue};
    color: #fff !important;
    background-color: ${props => (props.hoverBg ? props.hoverBg : myColor.hover_blue)};
    .icon-before {
      color: #fff;
      transition: 0.1s ease;
    }
  }
  :focus {
    border-color: ${myColor.blue};
  }
  @media (max-width: 1024px) {
    &.ant-btn {
      font-size: 12px;
    }
  }
`;

const HomeCardButton = props => {
  return (
    <StyledButton
      htmlType={props.htmlType}
      disabled={(props.isLoading || props.disabled) == true ? true : false}
      colordisable={props.disabled}
      bg={props.bg}
      bgcolor={props.bgColor}
      type="none"
      style={props.style}
      size={props.width}
      onClick={props.onClick}
      {...props}
    >
      {props.lockicon ? (
        <span>{props.lockicon ? lockicon : props.text}</span>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="icon-before"
            style={{
              display: props.iconBefore ? "flex" : "none",
            }}
          >
            {props.iconBefore}
          </span>
          <p style={{ marginBottom: props.iconBefore ? 2 : 0 }}>
            {props.isLoading ? <Spin indicator={antIcon} /> : props.text}
          </p>
          <span>{props.iconAfter}</span>
        </div>
      )}
    </StyledButton>
  );
};

export default HomeCardButton;
