import { ArrowRightOutlined, FilePdfFilled } from "@ant-design/icons";
import { Divider } from "antd";
import React from "react";
import { BsFileEarmarkText, BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import StartWithAIInput from "../button/StartWithAIInput";
import avatar3 from "../../assets/Assets-Landing/--16.png";
import avatar4 from "../../assets/Assets-Landing/--17.png";
import avatar1 from "../../assets/Assets-Landing/--15.png";
import avatar2 from "../../assets/Assets-Landing/--18.png";

const LandingPageBanner = () => {
  return (
    <BannerWrapper>
      <div className="top-img-section d-flex justify-content-between">
        <img src={avatar1} width={120} height={120} />
        <img src={avatar2} width={100} height={120} />
      </div>
      <div className="d-flex flex-column align-items-center text-center">
        <h4>Don't waste your time and money on a product or service that no one wants.</h4>

        <h2>Validate your Idea now!</h2>
        <h6>
          Our extensive suite of validation tools help you validate your idea using AI and <br />{" "}
          human intelligence, ensuring the success of your product.
        </h6>
      </div>
      <StartWithAIInput />

      <a
        style={{ zIndex: 1, fontWeight: 600 }}
        href="sample-report.pdf"
        target="_blank"
        className="mx-2"
      >
        <div className="d-flex align-items-center">
          <BsFileEarmarkText style={{ color: "#4E6B8F", fontSize: 24, marginRight: 6 }} />
          View Sample Report
          <BsArrowRight style={{ color: "#4E6B8F", fontSize: 24, marginLeft: 6 }} />
        </div>
      </a>
      <div className="bottom-img-section d-flex justify-content-between">
        <img src={avatar3} width={120} height={120} />
        <img src={avatar4} width={120} height={120} />
      </div>

      <Divider />
      <div style={{ width: "100%" }}>
        <p style={{ color: "#0b3558", fontSize: 11, fontWeight: 600 }}>
          *According to a study by CB Insights, 29% of startups fail because there is no market need
          for their product or service.
        </p>
      </div>
    </BannerWrapper>
  );
};

export default LandingPageBanner;

const BannerWrapper = styled.div`
  background-image: linear-gradient(to right, #d8e4f3, #e5dcf3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 10% 34px 10%;
  h4 {
    font-family: "Inter-Bold";
    color: #0b3558;
  }
  h2 {
    margin-top: 10px;
    font-family: "Inter-Bold";
    font-weight: 800;
    font-size: 4rem;
    color: #0b3558;
  }
  h6 {
    margin-top: 20px;

    font-family: "Inter-Light";
    color: #0b3558;
    font-size: 1.5rem;
    text-align: center;
  }
  a {
    font-family: "Inter-Bold";
    font-size: 1.2rem;
    color: #4e6b8f;
    text-decoration: none;
  }

  .top-img-section {
    width: 100%;
    position: relative;
    top: 130px;
    @media (max-width: 1200px) {
      top: 10px;
    }
  }

  .bottom-img-section {
    width: 70%;
    position: relative;
    bottom: 20px;
    @media (max-width: 1200px) {
      width: 100%;
      bottom: 0px;
    }
  }
`;
