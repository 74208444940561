import React, { useEffect, useState } from "react";
import { Row, Col, Empty, Form, Divider, Checkbox, Input } from "antd";
import MyButton from "./MyButton";
import { CheckOutlined, CheckSquareFilled, MinusCircleOutlined } from "@ant-design/icons";
import bot from "../assets/img/bot.png";
import BotTopBanner from "./BotTopBanner";
import InputField from "./InputField";
import { myColor } from "../GlobalVar";
import styled from "styled-components";
import { connect } from "react-redux";
import putUpdateRespondentEmail from "../redux/actions/putUpdateRespondentEmail";
import NoEmails from "../assets/svg/You have no emails.svg";
import EmptyWrapper from "./EmptyWrapper";
import cogoToast from "cogo-toast";

import { withReducer } from "../redux/withReducer";
import { createApiCallReducer, actions } from "../utils/helperFunctions";
import { UPDATE_EMAIL_LIST } from "../redux/constants/ideaValidation";
import { SEND_IDEA_VALIDATION_CONFIRM_MODAL } from "../redux/constants/modals";
import { BotIcon } from "./SlectResponseMethod";
import { CheckboxLarge } from "./CheckBox";
import { getCookie } from "../utils/cookies";
import { getProjectById } from "../redux/actions/getProjectById";
import InputWrapper from ".";
import BorderBottomInput from "./BorderBottomInput";

const { modalOpen, modalClose } = actions;

const CreateEmailList = props => {
  const { respondentEmails, setRespondentEmails, emailCheckBox, setEmailCheckBox } = props;
  const [showBotContainer, setShowBotContainer] = useState(false);
  const [email, setEmail] = React.useState("");
  const [emailList, setEmailList] = React.useState([]);
  const showBot = val => {
    setShowBotContainer(val);
  };

  const addEmail = () => {
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (
      !respondentEmails.includes(email) &&
      emailRegex.test(email) &&
      respondentEmails.length < 25
    ) {
      let newEmailList = [...respondentEmails];
      newEmailList.unshift(email);
      props.disable || setRespondentEmails(newEmailList);
      props.disable || setEmailList(newEmailList);
      props.disable || setEmail("");
    } else if (!emailRegex.test(email)) {
      cogoToast.error("Email format is not correct");
    } else if (respondentEmails.includes(email)) {
      cogoToast.error(`${email} is already added in the list`);
    } else if (respondentEmails.length >= 25) {
      cogoToast.error(`Cannot add more than 25 emails`);
    }
  };

  const removeEmail = mail => {
    let newEmailList = [...respondentEmails];
    props.disable || setRespondentEmails(newEmailList.filter(val => val !== mail));
  };
  const prevIsModalOpen = React.useRef(false);

  const updatingRespondent = () => {
    if (emailCheckBox) {
      if (respondentEmails.length >= 5) {
        props.updateRespondentEmail({ emailList: respondentEmails });
        prevIsModalOpen.current = true;
      } else if (respondentEmails.length < 5) {
        cogoToast.warn("Atleast 5 emails required");
      }
    } else {
      props.updateRespondentEmail({ emailList: [] });
      prevIsModalOpen.current = true;
    }
  };

  const prevEmailListId = React.useRef(
    props.updateEmailListState.data ? props.updateEmailListState.data._id : null
  );

  React.useEffect(() => {
    if (
      props.updateEmailListState.data &&
      props.updateEmailListState.data._id !== prevEmailListId.current
    ) {
      props.setStepStatus({ ...props.stepStatus, Step4: "finish" });
    }
  }, [props.updateEmailListState]);

  React.useEffect(() => {
    if (
      prevIsModalOpen.current &&
      props.stepStatus.Step1 == "finish" &&
      props.stepStatus.Step2 == "finish" &&
      props.stepStatus.Step3 == "finish" &&
      props.stepStatus.Step4 == "finish" &&
      props?.getProjectByIdState?.emailSent == false &&
      props?.updateEmailListState?.isLoading == false
    ) {
      props.openModal();
      prevIsModalOpen.current = false;
    }
  }, [props.updateEmailListState, prevIsModalOpen, props.stepStatus]);

  useEffect(() => {
    if (getCookie("currentProject") && !props.disable) {
      setTimeout(() => {
        props.getProjectById();
      }, 700);
    }
  }, []);

  return (
    <div>
      <BotTopBanner
        showBot={showBot}
        style={{ marginTop: 20 }}
        bg={"#d1deff"}
        content="Focus on clearly articulating how your proposed solution effectively addresses the identified
        problem, highlighting its unique value proposition, and emphasizing its potential to meet the needs
        and expectations of the target market."
        avatar={bot}
        width="100%"
      />

      <h2 style={{ margin: "30px 0", fontFamily: "Inter-SemiBold" }} align="center">
        Get your idea validated from Human & AI
      </h2>

      <div className="row justify-content-center flex-column-reverse flex-sm-row">
        <div className="col-lg-6 col-sm-12">
          <div className="d-flex align-items-start" style={{ minHeight: 88 }}>
            <BotIcon />
            <div className="ms-3">
              <p className="fw-bold mb-0">
                Get feedback in the voice of renowned industry professionals <br /> by AI, selected
                for you.
              </p>
              {/* <p style={{ fontSize: 12 }}>
                Our AI can impersonate any famous person and respond in character. <br />
                We've identified experts that are relevant to your industry.
              </p> */}
            </div>
          </div>
          <EmailListContainer className="mt-2">
            {props.aiFamousPerson?.map((item, index) => {
              return (
                <div key={index}>
                  <p className="mb-1 fw-bold mx-3">{item.name}</p>
                  <p className="mb-1 mx-3" style={{ lineHeight: 1.1, fontSize: 14, color: "gray" }}>
                    {item.comment}
                  </p>
                  <Divider style={{ margin: "10px 0 10px 0" }} />
                </div>
              );
            })}
          </EmailListContainer>
        </div>

        <div className={`col-lg-6 col-sm-12 mb-4`}>
          <div className="d-flex align-items-start" style={{ minHeight: 88 }}>
            <CheckboxLarge
              disabled={props.disable}
              checked={emailCheckBox}
              onChange={e => setEmailCheckBox(!emailCheckBox)}
              className="d-flex align-items-start"
            >
              <div className="ms-3">
                <h6 className="fw-bold mb-1">
                  In Addition, you can also email this form to your friends and family for their
                  input. A minimum of five emails should be sent for this purpose.
                  <span style={{ fontSize: 12, fontWeight: 300 }}>(Optional)</span>
                </h6>
              </div>
            </CheckboxLarge>
          </div>
          <Form>
            <Form.Item
              name="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter valid email",
                },
              ]}
              validateFirst={false}
            >
              <InputField
                placeholder="John@example.com"
                value={"sdasdsa"}
                myValue={email}
                disabled={!emailCheckBox || props.disabledBtn}
                // name='email'
                suffix={
                  !emailCheckBox ? null : (
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: 0,
                        cursor: "pointer",
                        color: myColor.green,
                        fontFamily: "Inter-Regular",
                      }}
                      onClick={() => {
                        if (props.disabledBtn) {
                          cogoToast.error("You can not edit in Read-only");
                        } else {
                          addEmail();
                        }
                      }}
                    >
                      + Add Email{" "}
                    </p>
                  )
                }
                size="large"
                style={{ width: "100%", borderColor: myColor.gray }}
                onChange={e => setEmail(e.target.value.toLowerCase())}
                onKeyPress={e => {
                  if (props.disabledBtn) {
                    cogoToast.error("You can not edit in Read-only");
                  } else {
                    e.which === 13 && addEmail();
                  }
                }}
              />
            </Form.Item>
          </Form>
          <div>
            <EmailListContainer>
              {respondentEmails.length == 0 ? (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Empty description={null} />
                </div>
              ) : (
                <div>
                  {respondentEmails.map((val, index) => {
                    return (
                      <ListItem key={index}>
                        <p style={{ margin: 0, fontSize: 16 }}>{val}</p>
                        <div
                          title={"Remove"}
                          style={{ margin: 0, cursor: "pointer" }}
                          onClick={() => {
                            if (props.disabledBtn) {
                              cogoToast.error("You can not edit in Read-only");
                            } else {
                              removeEmail(val);
                            }
                          }}
                        >
                          <MinusCircleOutlined style={{ fontSize: "22px" }} />
                        </div>
                      </ListItem>
                    );
                  })}
                </div>
              )}
            </EmailListContainer>
            <div className="d-flex align-items-center justify-content-between">
              {!emailCheckBox && respondentEmails?.length >= 1 ? (
                <div style={{ textAlign: "left" }}>
                  <span style={{ color: "red", fontFamily: "Inter-Regular", fontSize: 12 }}>
                    Note: These email will not be send
                  </span>
                </div>
              ) : (
                <div></div>
              )}
              <div style={{ textAlign: "right" }}>
                <span style={{ color: myColor.gray, fontFamily: "Inter-Regular", fontSize: 12 }}>
                  Total Emails:{" "}
                </span>
                <span style={{ color: myColor.gray, fontSize: 15 }}>{respondentEmails.length}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          justifyContent: "space-evenly",
          marginTop: 20,
          marginBottom: 40,
          paddingBottom: 10,
        }}
        className="col-lg-12 col-sm-12 d-flex align-items-center"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 20,
          }}
        >
          <MyButton text="Back" bg={false} width="130px" onClick={props.handleBackButton} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 20,
          }}
        >
          <MyButton
            // text={props.getProjectByIdState && props.getProjectByIdState.steps.emailList ? 'Update' : 'Done'}
            text={"Done"}
            bg={true}
            width="130px"
            onClick={updatingRespondent}
            isLoading={props.updateEmailListState.isLoading}
            disabled={props.disabledBtn || props.disable || props.updateEmailListState.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    getProjectByIdState: state.getProjectByIdReducer.data,
    updateEmailListState: state.emailListReducer,
    modalState: state.sendIdeaMailConfirmModalReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRespondentEmail: data => dispatch(putUpdateRespondentEmail(data)),
    openModal: () => dispatch(modalOpen(SEND_IDEA_VALIDATION_CONFIRM_MODAL)),
    getProjectById: () => dispatch(getProjectById()),
  };
};

export default withReducer([
  { key: "emailListReducer", reducer: createApiCallReducer(UPDATE_EMAIL_LIST) },
])(connect(mapStateToProps, mapDispatchToProps)(CreateEmailList));

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 0.5px solid ${myColor.light_gray};
  :last-child {
    border-bottom: 0px;
  }
  div:hover {
    color: red;
  }
`;
const EmailListContainer = styled.div`
  /* box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.3); */

  width: 100%;
  min-height: 300px;
  background-color: #fff;
  margin-top: 20px;
  border: 2px solid ${myColor.light_gray};
  border-radius: 8px;
  padding: 8px 10px;
  overflow: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border: 0.1px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b8b8b8;
  }
`;
