import React from "react";
import styled from "styled-components";
import { Input, Select } from "antd";
import { myColor } from "../../GlobalVar";

const SelectWrapper = props => {
  return <SelectStyle {...props} />;
};
export default SelectWrapper;

const SelectStyle = styled(Select)`
    
    
    .ant-select-selector{
      background-color: ${props => (props.selected ? myColor.blue : "#fff")} !important;
      /* background-color: ${props =>
        props.colordisable ? "#8D8D8D" : props.bg ? props.bgcolor || myColor.blue : "#fff"}; */
      color: ${props => (props.selected ? "#fff" : "#86909F")};
      border-color: ${props => (props.selected ? myColor.blue : "#A9B4C6")} !important;
      font-weight: 600;
      font-size: 15px;
      display: flex ;
      align-items: center ;
      width: ${props => (props.size ? props.size : "420px")};
      border-radius: 3px;
      box-shadow: none !important;
      height: 52px !important;
    }

    .ant-select-selection-search-input{
      height: 100% !important;
      padding-left: 62px !important;
    }

    .ant-select-selection-placeholder{
      display: flex;
      align-items: center;
    }
    
    &.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
      padding-left: 62px !important;
    }

    .ant-select-selector:hover{
        border-color: ${props => (props.bg ? "transparent" : myColor.blue)} !important;
        transform: translateY(-0.25em) !important;
        // background-color: ${myColor.hover_blue};    
    }

    :hover .ant-select-arrow{
        transform: translateY(-0.28em) !important;
        transition: 0.3s ease;
    }

    .ant-select-selector:focus{
        border-color: ${myColor.blue} !important
    }

    .ant-select-item .ant-select-item-option{
      .ant-select-item-option-content{
        font-weight: 600 !important;
        font-size: 15px !important;
        padding: 20px 12px !important;
      }
    }

    .ant-select-arrow{
      width: 90%;
      left: 17px !important;
      /* top: 38%; */
      transition: 0.3s ease;
      div{
        width: 100%;
      }
    }
`;
