import { ideaValidation } from "../../config/axiosConfig";
import { UPDATE_EMAIL_LIST } from "../constants/ideaValidation";
import { getCookie } from "../../utils/cookies";
import { getProjectByIdSuccess } from "./getProjectById";
import cogoToast from "cogo-toast";
import { actions } from "../../utils/helperFunctions";

const putUpdateRespondentEmail = payLoad => async dispatch => {
  dispatch(actions.loading(UPDATE_EMAIL_LIST));
  try {
    let apiCall = await ideaValidation.put(
      `/api/projects/updateProject/${getCookie("currentProject")}`,
      payLoad,
      {
        headers: {
          authorization: getCookie("token"),
        },
      }
    );
    dispatch(actions.success(UPDATE_EMAIL_LIST, apiCall.data.data));
    // cogoToast.success('Responder email have been updated')
    // dispatch(getProjectByIdSuccess(apiCall.data.data))

    //show modal with happy face by text , your request has been sent to your respondent ,you can view report
    //after atlest 5 respondents thanks
  } catch (err) {
    dispatch(actions.failure(UPDATE_EMAIL_LIST, err.response ? err.response.data.error : err));
  }
};

export default putUpdateRespondentEmail;
