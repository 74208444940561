import { combineReducers } from 'redux'
import { persistReducer , persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';


import {
    getAllProjectReducer ,
} from './projects'
import {
    removingHeaderFooterReducer
} from './removingHeaderFooter'

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['getAllProjectReducer'] // which reducer want to store
};

const rootReducer = asyncReducers => persistReducer(persistConfig,combineReducers(
    {
        getAllProjectReducer ,
        removingHeaderFooterReducer ,
        ...asyncReducers
    }
))

// const rootReducer = (state,action) =>{
//     if (action.type === 'RESET_REDUCER') {
//         state = undefined
//     }
//     return appReducer(state,action)
// }

export { rootReducer }