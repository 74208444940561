import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown, Menu, Slider } from "antd";
import { DownOutlined } from "@ant-design/icons";
import CreativeMind from "../../assets/img/CreativeMind.png";
import MyButton from "../../components/MyButton";
import styled from "styled-components";
import { myColor } from "../../GlobalVar";
import { MainContainer } from "../../components/MainContainer";
import ResponderDropdown from "../../components/ResponderDropdown";
import { CountryList } from "../../components/CountryList";
import { Select } from "antd";
import postResponseAndResponder from "../../redux/actions/respondent/postResponseAndResponder";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { withReducer } from "../../redux/withReducer";
import { createApiCallReducer } from "../../utils/helperFunctions";
import { POST_RESPONDER_DATA } from "../../redux/constants/respondent/responder";
import HomeCardButton from "../../components/homeCardButton";

const { Option } = Select;
const StyledSlider = styled(Slider)`
  .ant-slider-handle {
    background-color: ${myColor.blue};
    border: none !important;
  }
  .ant-slider-rail {
    background-color: #000;
    height: 1.5px;
  }

  .ant-slider-dot {
    width: 0px;
    top: -3px;
    height: 10px;
    border: 0.3px solid black;
    border-radius: none;
    margin-left: 0px;
  }

  &.ant-slider:hover {
    .ant-slider-rail {
      background-color: #000;
      height: 1.5px;
    }

    // .ant-slider-track {
    //   background-color: #ef3854;
    // }
  }
`;
let gender = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
];

let target = [
  { name: "First", vlaue: "first" },
  { name: "Second", vlaue: "second" },
  { name: "Third", vlaue: "second" },
  { name: "Fourth", vlaue: "fourth" },
];

const ExitPage = props => {
  const history = useHistory();
  const { data, err, isLoading } = props.postResponderData;
  const { id, email } = props.match.params;
  const { fullName, response } = props.location.state;

  const [responder, setResponder] = useState({
    age: 24,
    country: "",
    gender: "",
    targetGroup: "",
    fullName: fullName,
  });

  const handleSubmit = () => {
    const endPoint = {
      id: id,
      email: email,
    };

    // if(responder.age !== '' && responder.country !== '' && responder)

    props.postResponseAndResponder({
      endPoint: endPoint,
      responder: responder,
      response: response,
    });
  };

  React.useEffect(() => {
    props.notShowExtraContent();
    // return props.showExtraContent()
    return () => {
      props.showExtraContent();
    };
  }, []);

  useEffect(() => {
    if (data || err == "Response already submitted") {
      history.push({
        pathname: "/responder-confirm-page",
        state: { message: "Successfully submitted your response." },
      });
    }
  }, [props.postResponderData]);

  const marks = {
    18: {
      style: {
        color: "#000",
      },
      label: <p>18</p>,
    },
    25: {
      style: {
        color: "#000",
      },
      label: <p>25</p>,
    },
    35: {
      style: {
        color: "#000",
      },
      label: <p>35</p>,
    },
    45: {
      style: {
        color: "#000",
      },
      label: <p>45</p>,
    },
    55: {
      style: {
        color: "#000",
      },
      label: <p>55</p>,
    },
    65: {
      style: {
        color: "#000",
      },
      label: <p>65</p>,
    },
    75: {
      style: {
        color: "#000",
      },
      label: <p>75</p>,
    },
  };

  return (
    <MainContainer>
      <h1
        style={{
          textAlign: "center",
          margin: "50px 0",
          fontFamily: "Inter-SemiBold",
          color: myColor.font_color,
        }}
      >
        Thank you!
      </h1>

      <div className="row justify-content-between">
        <div
          className="col-lg-5 col-sm-12 col-xs-12 mb-4"
          style={{ backgroundColor: "#fff" }}
          align="center"
        >
          <img className="img-fluid" src={CreativeMind} />

          <h5 align="center" style={{ fontFamily: "Inter-SemiBold", lineHeight: 2 }}>
            We appreciate your help in answering the questions.
            <br />
            Please visit{" "}
            <a target="_blank" href="/" style={{ color: myColor.blue }}>
              http://validatr.io
            </a>{" "}
            if you've got an idea too.
          </h5>
        </div>
        <div
          className="col-lg-5 col-sm-12 col-xs-12"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <h3 style={{ fontSize: "24px", margin: 0, fontFamily: "Inter-SemiBold" }}>
            Tell us about yourself <span style={{ fontSize: 12 }}>(Optional)</span>
          </h3>
          {/* <h5 style={{ marginTop: '40px', bottom: 0 }}> Language of the questionnaire</h5> */}

          <div className="row my-5">
            <div className="col-lg-6 col-sm-12">
              <ResponderDropdown
                title="Select Country"
                placeholder="Select Country"
                width="100%"
                listHeight="300px"
                menu={CountryList.map((item, index) => {
                  return <Option key={index}>{item.name}</Option>;
                })}
                value={responder.country}
                setResponder={setResponder}
                onChange={e => setResponder({ ...responder, country: CountryList[e].name })}
                showSearch
              />
            </div>

            <div className="col-lg-6 col-sm-12">
              <ResponderDropdown
                title="Your Occupation Industry"
                placeholder={"Your Occupation Industry"}
                width="100%"
                menu={target.map((item, index) => {
                  return <Option key={index}>{item.name}</Option>;
                })}
                value={responder.targetGroup}
                setResponder={setResponder}
                onChange={e => setResponder({ ...responder, targetGroup: target[e].name })}
              />
            </div>
            <div className="col-lg-6 col-sm-12 my-3">
              <ResponderDropdown
                title="Select Gender"
                placeholder="Select gender"
                width="100%"
                menu={gender.map((item, index) => {
                  return <Option key={index}>{item.name}</Option>;
                })}
                value={responder.gender}
                setResponder={setResponder}
                onChange={e => setResponder({ ...responder, gender: gender[e].name })}
              />
            </div>
          </div>

          <div style={{ width: "100%", margin: "60px 0" }}>
            <StyledSlider
              marks={marks}
              included={false}
              defaultValue={responder.age}
              min={18}
              max={75}
              onChange={e => setResponder({ ...responder, age: e })}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "38px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "30px",
                  width: "40px",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "5px 5px #CCCCC",
                }}
              >
                {responder.age}
              </div>
              <p style={{ marginTop: "16px", marginLeft: "10px", fontSize: "15px" }}>Years</p>
            </div>
          </div>

          <HomeCardButton
            className="mb-5"
            text={`Submit`}
            width="150px"
            bg={true}
            onClick={handleSubmit}
            isLoading={isLoading}
            // disabled={responder.country && responder.targetGroup && responder.gender ? false : true}
            // color={myColor.blue}
          />
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = state => {
  return {
    postResponderData: state.postResponderDataReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postResponseAndResponder: data => dispatch(postResponseAndResponder(data)),
    notShowExtraContent: () => dispatch({ type: "HEADER_FOOTER_HIDE" }),
    showExtraContent: () => dispatch({ type: "HEADER_FOOTER_NOT_HIDE" }),
  };
};

export default withReducer([
  { key: "postResponderDataReducer", reducer: createApiCallReducer(POST_RESPONDER_DATA) },
])(connect(mapStateToProps, mapDispatchToProps)(ExitPage));
