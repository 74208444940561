import React from "react";
import styled, { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
import { myColor } from "../GlobalVar";

const otherFontTheme = {
  background: "#f5f8fb",
  headerBgColor: myColor.blue,
  headerFontColor: "#fff",
  headerFontSize: "16px",
  botBubbleColor: "#6E48AA",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

const steps = [
  {
    id: "1",
    message: " ",
    end: true,
  },
];

const ChatBotScreen = () => (
  <ThemeProvider theme={otherFontTheme}>
    <ChatBot
      floating={true}
      headerTitle="Questions Chat with us!"
      speechSynthesis={{ enable: true, lang: "en" }}
      steps={steps}
    />
  </ThemeProvider>
);

export default ChatBotScreen;
