import { authModules } from "../../config/axiosConfig";
import { LOGOUT } from "../constants/users";
import { setItem, removeItem } from "../../utils/localStorage";
import { removeCookie, getCookie } from "../../utils/cookies";
import { useHistory } from "react-router-dom";
import cogoToast from "cogo-toast";
import { actions } from "../../utils/helperFunctions";
import { GET_PROJECTS } from "../constants/projects";
import { getAllProjectReset } from "./getAllProjects";

const postUserLogOut = history => async (dispatch, getState) => {
  dispatch(actions.loading(LOGOUT));
  if (getState().logOutReducer.isLoading) {
    cogoToast.loading(`Logging out...`, { hideAfter: 1 }).then(() => {});
  }
  try {
    let apiCall = await authModules.post(
      "/api/users/logout",
      { deviceId: getCookie("deviceId") },
      {
        headers: {
          authorization: getCookie("token"),
        },
      }
    );
    dispatch(actions.success(LOGOUT, apiCall.data.data));
    // cogoToast.success('You have been successfully logout');
    // dispatch(logoutSuccess(apiCall.data.data))
    // removeItem('token').history
    removeCookie("token");
    removeCookie("deviceId");
    removeCookie("currentProject");
    removeCookie("userRole");
    // removeItem('persist:root')
    dispatch(getAllProjectReset());
    history.push("/");
    // dispatch(resetReducer())
    window.location.reload();
  } catch (err) {
    dispatch(actions.failure(LOGOUT, err.response ? err.response.data.error : err));
  }
};

export const resetReducer = () => {
  return {
    type: "RESET_REDUCER",
  };
};

export default postUserLogOut;
