import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom'
import { getCookie } from '../utils/cookies'
import {connect} from 'react-redux'

const NotFound = (props) => {
  const history = useHistory()
  React.useEffect(()=>{
    // let headerComponent = document.getElementById('header')
    // console.log('Header Coponent > ' , headerComponent)
    // let footerComponent = document.getElementById('footerComponent')
    // headerComponent && headerComponent.remove()
    // footerComponent && footerComponent.remove()  // after goin on main route / nothing is showing up
    props.notShowExtraContent()
    // return props.showExtraContent()
    return () =>{
      props.showExtraContent()
    }
  },[])

  return (
    <div >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={() => props.history.push('/')}>Back Home</Button>}
        style={{height:'80vh',display:'flex',flexDirection:'column'}}
      />
    </div>
   
  )
}

const mapDispatchToProps = (dispatch) =>{
  return{
    notShowExtraContent : () => dispatch({type:'HEADER_FOOTER_HIDE'}) ,
    showExtraContent : () => dispatch({type:'HEADER_FOOTER_NOT_HIDE'}) ,
  }
}

export default connect(null,mapDispatchToProps)(NotFound)
