import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Radio, Divider, Avatar } from "antd";
import MyButton from "./MyButton";
import styled from "styled-components";
import { myColor } from "../GlobalVar";
import RadioButton from "./RadioButton";
import putUpdateResponseMethod from "../redux/actions/putUpdateResponseMethod";
import { connect } from "react-redux";
import bot from "../assets/img/bot.png";
import { withReducer } from "../redux/withReducer";
import { createApiCallReducer } from "../utils/helperFunctions";
import { UPDATE_RESPONSE_METHOD } from "../redux/constants/ideaValidation";
import cogoToast from "cogo-toast";
import BotTopBanner from "./BotTopBanner";
import { UserOutlined } from "@ant-design/icons";
import BotTypeWriter from "./BotTypeWriter";
import { getProjectById } from "../redux/actions/getProjectById";
import { getCookie } from "../utils/cookies";

export const BotIcon = () => {
  return (
    <div
      style={{
        background: "#f8fafe",
        padding: "7px 5px",
        width: "50px",
        height: "50px",
        overflow: "hidden",
        borderRadius: "100px",
      }}
    >
      <img width="40" src={bot} />
    </div>
  );
};

const SelectresponseMethod = props => {
  const {
    responseMethod,
    setResponseMethod,
    problemStatementChecked,
    setProblemStatementChecked,
    solutionStatementChecked,
    setSolutionStatementChecked,
    selectProblemStatement,
    selectSolutionStatement,
  } = props;
  const [showBotContainer, setShowBotContainer] = useState(false);
  const [typeWriter, setTypeWriter] = useState(false);
  const {
    data: updateResponseMethodData,
    isLoading: updateResponseMethodLoading,
    err: updateResponseMethodErr,
  } = props.updateResponseMethodState;

  console.log("getProjectByIdState", props.getProjectByIdState);

  const handleSelect = e => {
    // setValue(e.target.value);
    setResponseMethod(e.target.value);
  };

  // ========FIND SELECTED PROBLEM AND SOLUTION STATEMENT==========
  let getSelectedProblem = selectProblemStatement.find(
    item => item.isSelected == problemStatementChecked
  );
  let getSelectedSolution = selectSolutionStatement.find(
    item => item.isSelected == solutionStatementChecked
  );
  // =============
  const updateResponse = () => {
    props.updateResponseMethod({
      responseMethod: "contacts",
      finalSolution: solutionStatementChecked,
      finalProblem: problemStatementChecked,
      // generateFiveAIPersons: true,
    });
  };
  const prevResponseMethodId = useRef(
    updateResponseMethodData ? updateResponseMethodData._id : null
  );
  useEffect(() => {
    if (updateResponseMethodData && updateResponseMethodData._id !== prevResponseMethodId.current) {
      props.setStepStatus({ ...props.stepStatus, Step3: "finish" });
    }
  }, [updateResponseMethodData]);

  const showBot = val => {
    setShowBotContainer(val);
  };

  const UserIcon = () => {
    return (
      <Avatar
        style={{ backgroundColor: "#f8fafe", width: 50, height: 50 }}
        size={40}
        icon={<UserOutlined style={{ color: "black" }} />}
      />
    );
  };

  useEffect(() => {
    if (getCookie("currentProject") && !props.edit) {
      setTimeout(() => {
        props.getProjectById();
      }, 700);
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     (props.currentScreen == 2 &&
  //       selectProblemStatement[0].problemStatement ==
  //         "Please provide Problem and solution statement first.") ||
  //     selectSolutionStatement[0].solutionStatement ==
  //       "Please provide Problem and solution statement first."
  //   ) {
  //     setTypeWriter(false);
  //   } else if (props.currentScreen == 2 && !props.edit) {
  //     setTypeWriter(true);
  //   } else {
  //     setTypeWriter(false);
  //   }
  // }, [props.currentScreen]);

  // useEffect(() => {
  //   if (typeWriter) {
  //     cogoToast.loading("AI is rephrasing your problem and solution...", {
  //       hideAfter: 0,
  //     });
  //   } else if (typeWriter == false) {
  //     let getCogoToast = document.getElementsByClassName("ct-toast-loading");
  //     for (let i = 0; i < getCogoToast.length; i++) {
  //       getCogoToast[i].style.display = "none";
  //     }
  //     // cogoToast.success(
  //     //   "Please select which problem and solution best describes the main issue you are trying to solve"
  //     // );
  //   }
  // }, [typeWriter]);

  return (
    <div>
      <BotTopBanner
        showBot={showBot}
        style={{ marginTop: 20 }}
        bg={"#d1deff"}
        content="Please choose the problem statement that best captures the essence and gravity of the problem, and in the
        solution statement, select the option that you think clearly and realistically defines the proposed solution."
        avatar={bot}
        width="100%"
      />
      <h2 style={{ margin: "30px 0", fontFamily: "Inter-SemiBold" }} align="center">
        SELECT APPROPRIATE STATEMENTS{" "}
      </h2>

      <Row justify="center">
        <h5>
          Select which <strong>PROBLEM STATEMENT</strong> best describes the main issue you are
          trying to solve
        </h5>
        {props?.selectProblemStatement?.map((item, index) => {
          return (
            <Col lg={24} sm={24} xs={24} className="mt-4" key={index}>
              <div className="d-flex align-items-center">
                <RadioButton
                  disabled={typeWriter || props.edit}
                  onChange={e => setProblemStatementChecked(e.target.value)}
                  value={problemStatementChecked}
                >
                  <Radio value={item.isSelected}></Radio>
                </RadioButton>
                <SelectCardWrapper
                  className="w-100 ms-5"
                  style={{
                    border:
                      problemStatementChecked == item.isSelected
                        ? `2px solid ${myColor.blue}`
                        : "2px solid #fff",
                    pointerEvents: typeWriter || props.edit ? "none" : null,
                  }}
                  onClick={() => {
                    setProblemStatementChecked(item.isSelected);
                    setTypeWriter(false);
                  }}
                >
                  <div className="row p-4 w-100 align-items-start">
                    <div className="col-lg-2 col-sm-12 border-end">
                      {item.isSelected == "PROBLEM_STATEMENT_BY_AI" ? <BotIcon /> : <UserIcon />}
                      <br />
                      <strong>{item.createdBy}</strong>
                    </div>
                    <div className="col-lg-10 col-sm-12 ">
                      {item.isSelected == "PROBLEM_STATEMENT_BY_AI" && typeWriter ? (
                        <BotTypeWriter
                          title={""}
                          content={item.problemStatement}
                          key={Math.random()}
                        />
                      ) : (
                        item.problemStatement
                      )}
                    </div>
                  </div>
                </SelectCardWrapper>
              </div>
            </Col>
          );
        })}

        <Divider
          className="w-75 my-5"
          style={{ minWidth: "50%", borderWidth: 2, backgroundColor: "#cbcbcb" }}
        />

        <h5>
          Select which <strong>SOLUTION STATEMENT</strong> best describes the solution you are
          proposing
        </h5>

        {props?.selectSolutionStatement?.map((item, index) => {
          return (
            <Col lg={24} sm={24} xs={24} className="mt-4" key={index}>
              <div className="d-flex align-items-center w-100">
                <RadioButton
                  disabled={typeWriter || props.edit}
                  onChange={e => setSolutionStatementChecked(e.target.value)}
                  value={solutionStatementChecked}
                >
                  <Radio value={item.isSelected}></Radio>
                </RadioButton>
                <SelectCardWrapper
                  className="w-100 ms-5 "
                  style={{
                    border:
                      solutionStatementChecked == item.isSelected
                        ? `2px solid ${myColor.blue}`
                        : "2px solid #fff",
                    pointerEvents: typeWriter || props.edit ? "none" : null,
                  }}
                  onClick={() => {
                    setTypeWriter(false);
                    setSolutionStatementChecked(item.isSelected);
                  }}
                >
                  <div className="row p-4 w-100 align-items-start">
                    <div className="col-lg-2 col-sm-12 border-end">
                      {item.isSelected == "SOLUTION_STATEMENT_BY_AI" ? <BotIcon /> : <UserIcon />}
                      <br />
                      <strong>{item.createdBy}</strong>
                    </div>
                    <div className="col-lg-10 col-sm-12 ">
                      {item.isSelected == "SOLUTION_STATEMENT_BY_AI" && typeWriter ? (
                        <BotTypeWriter
                          title={""}
                          content={item.solutionStatement}
                          key={Math.random()}
                          setTypeWriter={setTypeWriter}
                        />
                      ) : (
                        item.solutionStatement
                      )}
                    </div>
                  </div>
                </SelectCardWrapper>
              </div>
            </Col>
          );
        })}
      </Row>

      <div
        style={{
          justifyContent: "space-evenly",
          marginTop: 20,
          marginBottom: 40,
          paddingBottom: 10,
        }}
        className="col-lg-12 col-sm-12 d-flex align-items-center"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 20,
          }}
        >
          <MyButton text="Back" bg={false} width="130px" onClick={props.handleBackButton} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 20,
          }}
        >
          <MyButton
            text={
              props.getProjectByIdState && props.getProjectByIdState.steps.responseMethod
                ? "Update"
                : "Next"
            }
            bg={true}
            width="130px"
            onClick={updateResponse}
            isLoading={updateResponseMethodLoading}
            disabled={updateResponseMethodLoading || props.disabledBtn || typeWriter || props.edit}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    updateResponseMethodState: state.responseMethodReducer,
    getProjectByIdState: state.getProjectByIdReducer.data,
    getProjecByIdIsLoading: state.getProjectByIdReducer.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateResponseMethod: data => dispatch(putUpdateResponseMethod(data)),
    getProjectById: () => dispatch(getProjectById()),
  };
};
export default withReducer([
  { key: "responseMethodReducer", reducer: createApiCallReducer(UPDATE_RESPONSE_METHOD) },
])(connect(mapStateToProps, mapDispatchToProps)(SelectresponseMethod));

const SelectCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border: 2px solid #fff;
  :hover {
    cursor: pointer;
    border: 2px solid ${myColor.blue};
    background-color: "#f8fafe" !important;
  }
`;
