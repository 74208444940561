import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { Fade } from "react-awesome-reveal";

const StyledDiv = styled.div`
  h1 {
    text-align: center;
    font-weight: 600;
    margin: 0px 0px 10px;
    line-height: 40px;
    font-size: 38px;
    color: #0b3558;
    // width: max-content;
  }
  @media (max-width: 1024px) {
    h1 {
      text-align: center;
      font-size: 24px !important;
    }
  }
`;

const Heading = props => {
  return (
    <StyledDiv>
      <div
        style={{
          display: props.display == "none" ? "none" : "flex",
          justifyContent: "center",
          margin: "24px 0px",
        }}
      >
        <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Fade direction="up" triggerOnce cascade damping={0.2}>
            <h1
              style={{
                color: props.color ? props.color : "#0B3558",
                fontWeight: props.fontWeight ? props.fontWeight : "-moz-initial",
                fontSize: props.headingFontSize ? props.headingFontSize : 33,
              }}
            >
              {props.children || props.title}
              <div
                style={{
                  margin: "8px 30%",
                  height: 3,
                  backgroundColor: props.backgroundColor ? props.backgroundColor : "#0B3558",
                  borderRadius: 50,
                }}
              ></div>
            </h1>
          </Fade>
        </Col>
      </div>
    </StyledDiv>
  );
};

export default Heading;
