import {authModules} from '../../config/axiosConfig'
import {JOIN_NEW_TEAM_MEMBER} from '../constants/teams'
import { getCookie } from '../../utils/cookies'
import cogoToast from 'cogo-toast'
import {actions} from '../../utils/helperFunctions'

const postJoinTeamNewMember = (memberId,teamId,payLoad) => async dispatch =>{
    dispatch(actions.loading(JOIN_NEW_TEAM_MEMBER))
    try {
        let apiCall = await authModules.post(`/api/teams/newMemberInvitation/${memberId}/${teamId}`,payLoad,{
            headers:{
                authorization: getCookie('token')
            }
        })
        dispatch(actions.success(JOIN_NEW_TEAM_MEMBER,apiCall.data.data))
        cogoToast.success('Your account is created now')
    } catch (err) {
        dispatch(actions.failure(JOIN_NEW_TEAM_MEMBER,err.response?err.response.data.error:err))
        cogoToast.error('Something went wrong try again!')
    }

}

export default postJoinTeamNewMember


