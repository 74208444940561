import React from "react";
import styled from "styled-components";
import Loading from "../assets/svg/Validator-Logo-for-animation.gif";

const FullScreenLoader = () => {
  return (
    <FullScreenWrapper>
      <Middle>
        <img width="500px" src={Loading} />
      </Middle>
    </FullScreenWrapper>
  );
};

export default FullScreenLoader;

const FullScreenWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  overflow: hidden;
  position: fixed;
  height: 100%;
  // overflow-y: hidden !important;
  // overflow-x: hidden !important;
  width: 100%;
  background: rgba(252, 252, 252, 0.77);
  // background: gray;
  margin: 0px;
`;

const Middle = styled.div`
  opacity: 1px;
  // width: 100%;
`;
