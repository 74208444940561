import React, { useEffect, useState } from "react";
import { Modal, Row, Alert, Form } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import MyButton from "../MyButton";
import { myColor } from "../../GlobalVar";
import styled from "styled-components";
import InputFeild from "../InputField";
import { connect } from "react-redux";
import BotTypeWriter from "../BotTypeWriter";
import postAddProject from "../../redux/actions/postAddProject";
import DropdownMenu from "../DropdownMenu";
import {
  postAddTeamMember,
  addTeamMemberSuccess,
  resetTeamMemberState,
} from "../../redux/actions/postAddTeamMember";
import getAllProjects from "../../redux/actions/getAllProjects";
import { getCookie } from "../../utils/cookies";
import { Select } from "antd";

import { withReducer } from "../../redux/withReducer";
import { addTeamMemberReducer } from "../../redux/reducers/teams";
import HomeCardButton from "../homeCardButton";

const { Option } = Select;

const StyledIdeaNameModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
`;

const AddTeamMemberModal = props => {
  const [error, setError] = useState("");
  const { projectDataAdmin, projectIsLoading, projectErr } = props.getAllProjectsState;
  const { data: addTeamData, isLoading: addTeamLoading, err: addTeamErr } = props.addTemMemberState;

  const [teamInfo, setTeamInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    title: "",
  });

  React.useEffect(() => {
    let toEmail = addTeamData ? addTeamData.split(" ") : null;
    if (addTeamData && toEmail[toEmail.length - 1] == teamInfo.email.toLocaleLowerCase()) {
      console.log("Close Modal > ");
      setTeamInfo({
        firstName: "",
        lastName: "",
        email: "",
        title: "",
      });
      form.resetFields();
      props.setAddTeamModal(false);
      props.addTeamMemberSuccess(null);
      // props.resetTeamMemberState()
      props.setConfirmModalState(true);
      setError("");
    } else if (addTeamErr) {
      setError(addTeamErr);
    }
  }, [props.addTemMemberState]);

  const [projectId, setProjectId] = React.useState(null);

  React.useEffect(() => {
    props.callProjectData && props.getAllProjects();
  }, []);

  // React.useEffect(() => {
  //     if (addTeamErr) {
  //         setError(addTeamErr)
  //     }
  // }, [addTeamErr])

  const handleChange = e => {
    const { name, value } = e.target;
    setTeamInfo({ ...teamInfo, [name]: value });
  };

  const handleAddTeamMember = () => {
    if (projectId == null || projectId == "") {
      setError("Please select project.");
    }

    let changedProjectId =
      props.from == "teamTable" ? projectId && projectId.project._id : props.projectId;

    if (
      teamInfo.firstName !== "" &&
      teamInfo.lastName !== "" &&
      teamInfo.email !== "" &&
      teamInfo.title !== "" &&
      changedProjectId
    ) {
      if (props.getUserProfileReducer && props.getUserProfileReducer.data.email == teamInfo.email) {
        setError("You cannot add your own email");
      } else {
        props.postAddTeamMember(teamInfo, changedProjectId);
        setError("");
      }
    }
  };

  const changeProject = val => {
    setProjectId(projectDataAdmin[val]);
  };
  const [form] = Form.useForm();
  return (
    <StyledIdeaNameModal
      visible={props.visible}
      onCancel={() => props.setAddTeamModal(false)}
      width={props.width && props.width}
      footer={false}
      centered
      // maskStyle={{height:"100vh", width:"100vw" , overflow:"hidden"}}
    >
      <div style={{ padding: "20px 20px 0 20px" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: 40 }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div
              style={{
                background: "#F8FAFE",
                padding: "10px 5px",
                width: 80,
                height: 80,
                overflow: "hidden",
                borderRadius: 100,
                marginRight: 30,
              }}
            >
              <img width="70" src={props.avatar} />
            </div>
          </div>
          <div>
            <h3 style={{ fontFamily: "Inter-SemiBold" }}>Create team members</h3>
            <span style={{ fontSize: 15, fontFamily: "Inter-SemiBold", color: myColor.black }}>
              <BotTypeWriter
                content="Teams are subsets of people in your Organization
                                that collaborate on projects with each other.
                                Each Team has its own members and projects."
              />
            </span>
          </div>
        </div>

        <Form form={form} onFinish={handleAddTeamMember}>
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
            <Form.Item
              name="firstName"
              rules={[
                {
                  type: "string",
                  min: 3,
                  required: true,
                  message: "Please enter valid First Name",
                  pattern: /^([A-Za-z]{3,12})+$/,
                },
              ]}
              validateFirst={false}
            >
              <InputFeild
                maxLength={8}
                label="First Name"
                name="firstName"
                value={teamInfo.firstName}
                placeholder="John"
                onChange={handleChange}
                style={{ margin: 0, width: 220 }}
              />
            </Form.Item>

            <Form.Item
              name="lastName"
              rules={[
                {
                  type: "string",
                  min: 1,
                  required: true,
                  message: "Please enter valid Last Name",
                  pattern: /^([A-Za-z]{3,12})+$/,
                },
              ]}
              validateFirst={false}
            >
              <InputFeild
                maxLength={8}
                label="Last Name"
                name="lastName"
                value={teamInfo.lastName}
                placeholder="Wick"
                onChange={handleChange}
                style={{ margin: 0, width: 220 }}
              />
            </Form.Item>
          </div>

          <Form.Item
            name="Email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter valid email",
              },
            ]}
            validateFirst={false}
          >
            <InputFeild
              label="Email"
              name="email"
              value={teamInfo.email}
              placeholder="abc@xyz.com"
              onChange={handleChange}
              style={{ margin: 0 }}
            />
          </Form.Item>
          <Form.Item
            name="Title"
            rules={[
              {
                type: "string",
                required: true,
                min: 2,
                message: "Please enter valid Project Name",
              },
            ]}
            validateFirst={false}
          >
            <InputFeild
              maxLength={20}
              label="Title"
              name="title"
              value={teamInfo.title}
              placeholder="Manager / Secretary "
              onChange={handleChange}
              style={{ margin: 0 }}
            />
          </Form.Item>
          {props.from == "teamTable" && (
            <DropdownMenu
              width="100%"
              // menu={projectData}
              title="Select Project"
              placeholder="Select Project"
              listHeight={100}
              menu={
                projectDataAdmin &&
                projectDataAdmin.length > 0 &&
                projectDataAdmin.map((val, idx) => {
                  return <Option key={idx}>{val.project.title}</Option>;
                })
              }
              // changeProject={changeProject}
              // currentProject={projectId}
              changeVal={changeProject}
              currentVal={projectId}
            />
          )}

          {/* {
                        addTeamErr ?
                            <div style={{ marginBottom: 20 }}>
                                <Alert message={addTeamErr} type="error" showIcon />
                            </div>
                            : ''
                    } */}
          {error ? (
            <div style={{ margin: "20px 0" }}>
              <Alert message={error} type="error" showIcon />
            </div>
          ) : (
            ""
          )}

          <div style={{ margin: "35px 0px 0 0", width: "100%" }}>
            <HomeCardButton
              htmlType="submit"
              text="Save"
              bg={true}
              width="100%"
              // onClick={handleAddTeamMember}
              isLoading={addTeamLoading}
            />
          </div>
        </Form>
      </div>
    </StyledIdeaNameModal>
  );
};

const mapStateToProps = state => {
  return {
    getAllProjectsState: state.getAllProjectReducer,
    addTemMemberState: state.addTeamMemberReducer,
    getUserProfileReducer: state.getUserProfileReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postAddTeamMember: (payLoad, projectId) => dispatch(postAddTeamMember(payLoad, projectId)),
    getAllProjects: () => dispatch(getAllProjects()),
    addTeamMemberSuccess: data => dispatch(addTeamMemberSuccess(data)),
    // resetTeamMemberState: () => dispatch(resetTeamMemberState())
  };
};

export default withReducer([{ key: "addTeamMemberReducer", reducer: addTeamMemberReducer }])(
  connect(mapStateToProps, mapDispatchToProps)(AddTeamMemberModal)
);
