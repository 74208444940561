import { AntDesignOutlined, CheckOutlined } from "@ant-design/icons";
import { Avatar, message } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import ImageOne from "../assets/landingpage/image1.jpg";
import ImageTwo from "../assets/landingpage/image2.jpg";
import ImageThree from "../assets/landingpage/image3.jpg";
import ImageFour from "../assets/landingpage/image4.jpg";

import Image1 from "../assets/landingpage/male1.jpg";
import Image2 from "../assets/landingpage/female1.jpg";
import Image3 from "../assets/landingpage/female2.jpg";
import Image4 from "../assets/landingpage/male2.jpg";
import Image5 from "../assets/landingpage/female3.jpg";
import Image6 from "../assets/landingpage/female4.webp";
import Image7 from "../assets/landingpage/male3.jpg";
import Image8 from "../assets/landingpage/male4.jpg";
import Image9 from "../assets/landingpage/male5.jpg";
import Image10 from "../assets/landingpage/male6.jpg";
import Image11 from "../assets/landingpage/male7.jpg";
import Image12 from "../assets/landingpage/male8.jpg";
import Image13 from "../assets/landingpage/female5.jpg";
import Image14 from "../assets/landingpage/male9.png";
import Image15 from "../assets/landingpage/male10.png";

import ValidatrLogo from "../assets/svg/validatr-logo.svg";
import InputWrapper from "../components";
import HomeCardButton from "../components/homeCardButton";
import { myColor } from "../GlobalVar";
import { useSelector } from "react-redux";
import LandingPageBanner from "../components/banners/LandingPageBanner";
import LandingPageBenefit from "../components/landingPageBenefit";
import LandingPageFactors from "../components/landingPageFactors";
import LandingPageStartupIdeas from "../components/landingPageStartupIdeas";
import LandingPageProcess from "../components/landingPageProcess";
import LandingPageAffiliate from "../components/landingPageAffliate";
import LandingPage6 from "../components/landingPage6";
import LandingPageTestimony from "../components/landingPageTestimony";
import LandingPageFaq from "../components/landingPageFaq";
import { LandingPageFooter } from "../components/landingPageFooter";

const userData = [
  { id: "1", image: Image1 },
  { id: "2", image: Image2 },
  { id: "3", image: Image3 },
  { id: "4", image: Image4 },
  { id: "5", image: Image5 },
  { id: "6", image: Image6 },
  { id: "7", image: Image7 },
  { id: "8", image: Image8 },
  { id: "9", image: Image9 },
  { id: "10", image: Image10 },
  { id: "11", image: Image11 },
  { id: "12", image: Image12 },
  { id: "13", image: Image13 },
];

const NewMainLandingPage = props => {
  const [email, setEmail] = useState("");
  const data = useSelector(state => state?.getUserProfileReducer);

  const handleSubmit = () => {
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      message.success("Submited successfully");
    } else {
      message.error("Please enter you valid email");
    }
  };
  return (
    <MainStyleWrapper className="">
      <LandingPageBanner />
      <LandingPageBenefit />
      <LandingPageFactors />
      <LandingPageStartupIdeas />
      <LandingPageProcess size={28} />
      <LandingPage6 />
      <LandingPageAffiliate />
      <LandingPageTestimony />
      <div className="ready-to-get-validated">
        <h1
          style={{
            fontWeight: 600,
            margin: "0px 0px 10px",
            lineHeight: "60px",
            fontSize: 38,
            color: "#0b3558",
          }}
        >
          Ready to get your idea validated?
        </h1>

        <HomeCardButton
          style={{
            padding: "24px 52px",
            color: "#36B24A",
            borderColor: "#36B24A",
            fontSize: 20,
            marginTop: 40,
          }}
          hoverBg="#36B24A"
          text="Let's do this"
        />
      </div>

      <LandingPageFaq singleCol={true} />
      {/* <LandingPageFooter /> */}
    </MainStyleWrapper>
  );
};

export default NewMainLandingPage;

const MainStyleWrapper = styled.div`
  .ready-to-get-validated {
    margin-top: 3%;
    background-color: #f7faff;
    padding: 5% 10%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
