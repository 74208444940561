import React from "react";
import styled from "styled-components";
import { Menu, Dropdown, Avatar, Image } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { myColor } from "../GlobalVar";
import { ReactComponent as PrfileImage } from "../assets/svg/team-avatar-3.svg";

const UserInfoDropdownWrapper = styled(Dropdown)`
  /* margin-left: 50px; */
  cursor: pointer;
`;

const UserInfoDropdown = props => {
  return (
    <UserInfoDropdownWrapper
      overlayStyle={{ width: 200 }}
      placement="bottomRight"
      arrow
      overlay={props.menu}
      style={props.style}
    >
      <UserName className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        <PrfileImage style={{ width: 40, marginRight: 8 }} />
        <span style={{ fontWeight: 600 }}>{props.userName}</span> <DownOutlined />
      </UserName>
    </UserInfoDropdownWrapper>
  );
};

export default UserInfoDropdown;

const UserName = styled.span`
  font-size: 16px;
`;
