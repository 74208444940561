import styled from "styled-components";
import { Row, Col } from "antd";

const StyledSection = styled.section`
  background-color: #f7faff;
  padding: 0px 5%;
  // margin-bottom: 80px;
  text-align: center;
  .number {
    font-size: 48px;
    margin: 0;
    font-weight: 800;
    color: #0b3558;
  }

  .title {
    color: #0b3558;
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    .number {
      font-size: 28px;
    }
    .title {
      font-size: 15px;
    }
  }
`;

const LandingPageBenefit = props => {
  return (
    <StyledSection className="container-fluid">
      {/* <div className="container"> */}
      <Row justify="space-between" gutter={[24, 24]}>
        <Col xs={12} sm={6} md={6} lg={6} xxl={6} style={{ paddingTop: 40, paddingBottom: 40 }}>
          <p className="number">2500+</p>
          <h1 className="title">Projects validated</h1>
        </Col>

        <Col xs={12} sm={6} md={6} lg={6} xxl={6} style={{ paddingTop: 40, paddingBottom: 40 }}>
          <p className="number">97.2%</p>
          <h1 className="title">Customer Satisfaction</h1>
        </Col>

        <Col xs={12} sm={6} md={6} lg={6} xxl={6} style={{ paddingTop: 40, paddingBottom: 40 }}>
          <p className="number">100%</p>
          <h1 className="title">Downloadable Project Reports</h1>
        </Col>

        <Col xs={12} sm={6} md={6} lg={6} xxl={6} style={{ paddingTop: 40, paddingBottom: 40 }}>
          <p className="number">24/7</p>
          <h1 className="title">Availability</h1>
        </Col>
      </Row>

      {/* </div> */}
    </StyledSection>
  );
};
export default LandingPageBenefit;
