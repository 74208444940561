import React, { useState, useEffect } from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import YourIdeaSolution from "../../../../components/YourIdeaSolution";
import ProblemStatement from "../../../../components/ProblemStatement";
import SelectResponseMethod from "../../../../components/SlectResponseMethod";
import CreateEmailList from "../../../../components/CreateEmailList";
import { getProjectById, getProjectByIdSuccess } from "../../../../redux/actions/getProjectById";
import { connect } from "react-redux";
import { getCookie } from "../../../../utils/cookies";

const StepsContainer = props => {
  switch (
    props.getProjectByIdState.currentStep //props.getProjectByIdState.currentStep
  ) {
    case 0:
      return (
        <ProblemStatement
          industryName={props.industryName}
          setCurrentScreen={props.setCurrentScreen}
          currentScreen={props.currentScreen}
          handleBackButton={props.handleBackButton}
          handleNextButton={props.handleNextButton}
          textAreaState={props.textAreaState}
          setTextAreaState={props.setTextAreaState}
          fileListState={props.fileListState}
          setFileListState={props.setFileListState}
          disabledBtn={props.disabledBtn}
          setStepStatus={props.setStepStatus}
          stepStatus={props.stepStatus}
          edit={props.edit}
        />
      );
    case 1:
      return (
        <YourIdeaSolution
          setbeneficiary={props.setbeneficiary}
          // rate={props.stateUpdateIdeaSolution.data.rate}
          setAllignmentModal={props.setAllignmentModal}
          beneficiary={props.beneficiary}
          industryName={props.industryName}
          setCurrentScreen={props.setCurrentScreen}
          currentScreen={props.currentScreen}
          handleBackButton={props.handleBackButton}
          handleNextButton={props.handleNextButton}
          textAreaState={props.textAreaState}
          setTextAreaState={props.setTextAreaState}
          fileListState={props.fileListState}
          setFileListState={props.setFileListState}
          disabledBtn={props.disabledBtn}
          setStepStatus={props.setStepStatus}
          stepStatus={props.stepStatus}
          edit={props.edit}
        />
      );
    case 2:
      return (
        <SelectResponseMethod
          problemStatementChecked={props.problemStatementChecked}
          setProblemStatementChecked={props.setProblemStatementChecked}
          solutionStatementChecked={props.solutionStatementChecked}
          setSolutionStatementChecked={props.setSolutionStatementChecked}
          selectProblemStatement={props.selectProblemStatement}
          selectSolutionStatement={props.selectSolutionStatement}
          setSelectProblemStatement={props.setSelectProblemStatement}
          setSelectSolutionStatement={props.setSelectSolutionStatement}
          handleBackButton={props.handleBackButton}
          handleNextButton={props.handleNextButton}
          disabledBtn={props.disabledBtn}
          setStepStatus={props.setStepStatus}
          stepStatus={props.stepStatus}
          edit={props.edit}
          currentScreen={props.currentScreen}
        />
      );
    case 3:
      return (
        <CreateEmailList
          emailCheckBox={props.emailCheckBox}
          setEmailCheckBox={props.setEmailCheckBox}
          aiFamousPerson={props.aiFamousPerson}
          handleBackButton={props.handleBackButton}
          handleNextButton={props.handleNextButton}
          respondentEmails={props.respondentEmails}
          setRespondentEmails={props.setRespondentEmails}
          disabledBtn={props.disabledBtn}
          setStepStatus={props.setStepStatus}
          stepStatus={props.stepStatus}
          disable={props.edit}
        />
      );
  }
};

const mapStateToProps = state => {
  return {
    getProjectByIdState: state.getProjectByIdReducer,
    stateUpdateIdeaSolution: state.ideaSolutionReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProjectById: () => dispatch(getProjectById()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepsContainer);
