import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row, Col, Slider } from "antd";
import Heading from "../landingPageHeading";
import { Fade, Zoom } from "react-awesome-reveal";
import BackgroundPicture from "../../assets/Assets-Landing/validatr1.png";

const StyledDiv = styled.div`
  background-color: #fff;
  padding: 3% 10%;
`;

/**
 * Component Starts Here
 */
const LandingPage6 = () => {
  return (
    <StyledDiv>
      <Fade direction="up" triggerOnce cascade duration={1300} damping={0.2}>
        <Heading>
          Combine the power of <span style={{ fontWeight: 800 }}>Y Combinator</span> and{" "}
          <span style={{ fontWeight: 800 }}>Lean Startup</span>
          <br />
          <span style={{ fontWeight: 800 }}>methodologies</span> to your product development with
          our
          <br />
          hybrid validation tools.
        </Heading>
        <p style={{ textAlign: "center", color: "#0B3558", fontSize: 16 }}>
          Empower your startup's success with a complete suite of resources tailored for the early
          stages. From problem-solution fit to market
          <br />
          research and MVP prototyping, our tools validate your ideas and drive your startup toward
          success.
        </p>
      </Fade>

      <Fade direction="up" triggerOnce cascade duration={1300} damping={0.2}>
        <img src={BackgroundPicture} style={{ width: "100%" }} />
      </Fade>
    </StyledDiv>
  );
};

export default LandingPage6;
