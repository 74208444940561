import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import styled from "styled-components";
import { Row, Col, Upload } from "antd";
import MyButton from "./MyButton";
import ImageUpload from "../components/ImageUplaod";
import BotTopBanner from "../components/BotTopBanner";
import bot from "../assets/img/bot.png";
import TextAreaContainer from "../components/TextAreaContainer";
import putProblemStatement from "../redux/actions/putProblemStatement";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";

import { withReducer } from "../redux/withReducer";
import { createApiCallReducer } from "../utils/helperFunctions";
import { PROBLEM_STATEMENT } from "../redux/constants/ideaValidation";
import { myColor } from "../GlobalVar";
import Cookies from "js-cookie";

const ProblemStatement = props => {
  const [showBotContainer, setShowBotContainer] = useState(false);

  const showBot = val => {
    setShowBotContainer(val);
  };

  const {
    data: updateProblemStatementData,
    isLoading: updateProblemStatementLoading,
    err: updateProblemStatementErr,
  } = props.stateUpdateProblemStatement;

  const updateProblemStatement = () => {
    if (
      props.textAreaState.problemStatementText &&
      // && props.textAreaState.problemStatementText.length > 200
      props.textAreaState.problemStatementText.split(" ").length >= 5
    ) {
      let filterdFileNameList = [];
      let filterdFilePathList = [];
      let filteredFileOrignalNameList = [];

      if (
        props.fileListState.problemStateFileList &&
        props.fileListState.problemStateFileList.length > 0
      ) {
        props.fileListState.problemStateFileList.map(val => {
          if (val.size) {
            val.status == "done" &&
              filterdFileNameList.push(val.response.data.docName) &&
              filterdFilePathList.push(val.response.data.docPath) &&
              filteredFileOrignalNameList.push(val.response.data.docOriginalName);
          } else {
            filterdFileNameList.push(val.fileName) &&
              filterdFilePathList.push(val.filePath) &&
              filteredFileOrignalNameList.push(val.name);
          }
          // else{
          //     filterdFileNameList.push(val.response.data.docName)
          //     && filterdFilePathList.push(val.response.data.docPath) &&
          //     filteredFileOrignalNameList.push(val.response.data.docOriginalName)
          // }
        });
        let payLoad = {
          industry: props.industryName,
          statement: {
            description: props.textAreaState.problemStatementText,
            fileName: filterdFileNameList,
            filePath: filterdFilePathList,
            fileOrignalName: filteredFileOrignalNameList,
          },
        };
        props.updateProblemSatement(payLoad);
        Cookies.remove("problemStatement");
      } else {
        let payLoad = {
          industry: props.industryName,
          statement: {
            description: props.textAreaState.problemStatementText,
            // fileName: filterdFileNameList ,
            // filePath: filterdFilePathList ,
            // fileOrignalName: filteredFileOrignalNameList
          },
        };
        props.updateProblemSatement(payLoad);
        Cookies.remove("problemStatement");
      }
    } else {
      cogoToast.warn("Minimum 5 words required");
    }
  };

  const prevProblemStatementId = React.useRef(
    updateProblemStatementData ? updateProblemStatementData._id : null
  );

  React.useEffect(() => {
    if (
      updateProblemStatementData &&
      updateProblemStatementData._id !== prevProblemStatementId.current
    ) {
      // props.handleNextButton()
      props.setStepStatus({ ...props.stepStatus, Step1: "finish" });
    }
  }, [updateProblemStatementData]);

  return (
    <div>
      <BotTopBanner
        style={{ marginTop: 20 }}
        bg={"#d1deff"}
        // title={`Welcome !`}
        content="Ensure your problem statement clearly defines the specific problem
        or pain point your idea aims to solve, providing context, highlighting its
        impact, and emphasizing the need for a solution."
        avatar={bot}
        width="100%"
      />
      <h2 style={{ margin: "30px 0", fontFamily: "Inter-SemiBold" }} align="center">
        Problem Statement
      </h2>
      <h5 className="mb-3" style={{ fontFamily: "Inter-SemiBold" }} align="center">
        Please write a clear description of the identified problem that you are trying to solve.
      </h5>
      <div className="row justify-content-center flex-column-reverse flex-sm-row">
        <div className={`col-lg-${showBotContainer ? "7" : "9"} col-sm-12 mb-4`}>
          <TextAreaContainer
            rows={9}
            maxLength={props.maxLength || 1000}
            placeholder="Describe your problem statement..."
            // setTextAreaVal={setTextAreaVal}
            // textAreaVal={textAreaVal}
            textAreaState={props.textAreaState}
            setTextAreaState={props.setTextAreaState}
            from="problemStatement"
            disable={props.edit}
          />
          {/* <div>
            {props.edit == false ||
            (props.fileListState.problemStateFileList &&
              props.fileListState.problemStateFileList.length > 0) ? (
              <ImageUpload
                maxFile={"10"}
                // getUpdatedFileList = {setUpdatedFileList}
                // updatedFileList={updatedFileList}
                fileListState={props.fileListState}
                setFileListState={props.setFileListState}
                from="problemStatement"
                optional={true}
                disable={props.edit}
              />
            ) : (
              <></>
            )}
          </div> */}
          <div
            style={{ justifyContent: "space-evenly", marginTop: 20, marginBottom: 40 }}
            className="col-lg-12 col-sm-12 d-flex align-items-center"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 20,
              }}
            >
              <MyButton
                text="Back"
                bg={false}
                width="130px"
                disabled={true}
                onClick={props.handleBackButton}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 20,
              }}
            >
              <MyButton
                htmlType="submit"
                text={
                  props.getProjectByIdState && props.getProjectByIdState.steps.statement
                    ? "Update"
                    : "Next"
                }
                bg={true}
                width="130px"
                onClick={updateProblemStatement}
                isLoading={updateProblemStatementLoading}
                disabled={
                  updateProblemStatementLoading || props.disabledBtn || props.edit ? true : false
                }
              />

              <span
                onClick={() => {
                  if (!updateProblemStatementLoading || !props.disabledBtn) {
                    props.setCurrentScreen(props.currentScreen + 1);
                    window.scrollTo(0, 0);
                  }
                }}
                style={{
                  marginLeft: 18,
                  color: myColor.gray,
                  fontWeight: 600,
                  cursor:
                    updateProblemStatementLoading || props.disabledBtn ? "not-allowed" : "pointer",
                }}
              >
                {props.disabledBtn ? "View next" : "Skip for now"}
              </span>
            </div>
          </div>
        </div>

        <div
          style={{ display: showBotContainer ? "initial" : "none" }}
          className="col-lg-4 col-sm-12 mb-4"
        >
          <BotTopBanner
            showBot={showBot}
            style={{ marginBottom: 30 }}
            bg={"red"}
            content="Please write a clear
                        description of the identified
                        problem, that frames the
                        significance of your research
                        through this project or service."
            avatar={bot}
            width="130px"
            height="100px"
            varticalCard={true}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    stateUpdateProblemStatement: state.problemStatementReducer,
    getProjectByIdState: state.getProjectByIdReducer.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProblemSatement: data => dispatch(putProblemStatement(data)),
  };
};
export default withReducer([
  { key: "problemStatementReducer", reducer: createApiCallReducer(PROBLEM_STATEMENT) },
])(connect(mapStateToProps, mapDispatchToProps)(ProblemStatement));
