import React, { useState, useEffect } from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import QuestionOne from "./responderQuestions/QuestionOne";
import { useHistory } from "react-router-dom";

const ResponderStepsContainer = props => {
  const history = useHistory();

  const { id, email } = props.properties.match.params;
  const { fullName } = props.properties.location.state;
  const { statement, solution, steps, emailList } = props.responderData && props.responderData;
  const { Step1, Step2, Step3, Step4, Step5 } = props.stepStatus;

  console.log("props.responderData", props.responderData);

  const [response1, setResponse1] = useState({
    rating: 0,
    feedback: "",
  });
  const [response2, setResponse2] = useState({
    rating: 0,
    feedback: "",
  });
  const [response3, setResponse3] = useState({
    rating: 0,
    feedback: "",
  });
  const [response4, setResponse4] = useState({
    rating: 0,
    feedback: "",
  });
  const [response5, setResponse5] = useState({
    rating: 0,
    feedback: "",
  });

  const questionHandle = () => {
    if (props.currentScreen == 4) {
      handleConfirm();
    }
    if (props.currentScreen < 4) {
      props.handleNextButton();
    }
  };

  console.log(
    "statement",
    statement?.final == "PROBLEM_STATEMENT_BY_AI"
      ? statement.descriptionByAI
      : statement.description
  );

  const handleConfirm = () => {
    const response = {
      response1: response1,
      response2: response2,
      response3: response3,
      response4: response4,
      response5: response5,
    };
    if (Step1 == "finish" && Step2 == "finish" && Step3 == "finish" && Step4 == "finish") {
      history.push({
        pathname: `/thankyou/${id}/${email}`,
        state: { response: response, fullName: fullName },
      });
    }
  };
  switch (props.currentScreen) {
    case 0:
      return (
        <QuestionOne
          name="Relevancy of the problem"
          question="Do you think the problem will be relevant to the target market?"
          buttonText="Now let's go to the idea!"
          questionHandle={questionHandle}
          response1={response1}
          setResponse1={setResponse1}
          statement={statement}
          description={
            statement?.final == "PROBLEM_STATEMENT_BY_AI"
              ? statement.descriptionByAI
              : statement.description
          }
          matrixValues={[
            "Not Relevant",
            "Slightly relevant",
            "Somewhat relevant",
            "Moderately relevant",
            "Very Relevant",
          ]}
        />
      );
    case 1:
      return (
        <QuestionOne
          name="Usefulness of the Solution"
          question="Do you think the solution will be useful to the target market?"
          buttonText="Great! Three more screens and we're done!"
          questionHandle={questionHandle}
          response1={response2}
          setResponse1={setResponse2}
          statement={solution}
          description={
            solution?.final == "SOLUTION_STATEMENT_BY_AI"
              ? solution.descriptionByAI
              : solution.description
          }
          matrixValues={[
            "Not useful",
            "Somewhat useful",
            "Moderately useful",
            "Very useful",
            "Extremely useful",
          ]}
        />
      );
    case 2:
      return (
        <QuestionOne
          name="Uniqueness of the Solution"
          question="Will the target market find the suggested idea unique and new?"
          buttonText="Great! Two more screens and we're done!"
          questionHandle={questionHandle}
          response1={response3}
          setResponse1={setResponse3}
          statement={solution}
          description={
            solution?.final == "SOLUTION_STATEMENT_BY_AI"
              ? solution.descriptionByAI
              : solution.description
          }
          matrixValues={[
            "Not unique",
            "Slightly unique",
            "Somewhat unique",
            "Fairly unique",
            "Highly unique",
          ]}
        />
      );
    case 3:
      return (
        <QuestionOne
          name="Likeliness to Promote"
          question="How likely will the target market tell their friends about this idea?"
          buttonText="Great! One more screens and we're done!"
          questionHandle={questionHandle}
          response1={response4}
          setResponse1={setResponse4}
          statement={solution}
          description={
            solution?.final == "SOLUTION_STATEMENT_BY_AI"
              ? solution.descriptionByAI
              : solution.description
          }
          matrixValues={[
            "Very Unlikely",
            "Slightly likely",
            "Somewhat likely",
            "Likely",
            "Highly Likely",
          ]}
        />
      );
    case 4:
      return (
        <QuestionOne
          name="Willingness to Buy"
          question="How willing is the target market to pay for this Product or Service?"
          buttonText="Awesome! Click to confirm your answers!"
          questionHandle={questionHandle}
          response1={response5}
          setResponse1={setResponse5}
          statement={solution}
          description={
            solution?.final == "SOLUTION_STATEMENT_BY_AI"
              ? solution.descriptionByAI
              : solution.description
          }
          matrixValues={[
            "Not willing",
            "Slightly willing ",
            "Somewhat willing",
            "Willing",
            "Highly willing",
          ]}
        />
      );
  }
};

export default ResponderStepsContainer;
