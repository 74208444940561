import React from "react";
import { object } from "prop-types";
import { useStore } from 'react-redux'

const withReducer = (injectIngReducerArr) => WrappedComponent => {
  const Extended = (props) => {
    const store = useStore()
    injectIngReducerArr.length>0 && injectIngReducerArr.map(val=>{
      store.injectAsyncReducer(val.key, val.reducer);
    })
    return <WrappedComponent {...props} />;
  };
  Extended.contextTypes = {
    store: object
  };
  return Extended;
};

export { withReducer };
