import React from 'react'
import { Row, Col } from 'antd'
// import MyEditor from '../../components/MyEditor'
// import Emoji_1 from '../../assets/svg/Emoji_1.svg'
// import Emoji_2 from '../../assets/svg/Emoji_2.svg'
// import Emoji_3 from '../../assets/svg/Emoji_3.svg'
// import Emoji_4 from '../../assets/svg/Emoji_4.svg'
// import Emoji_5 from '../../assets/svg/Emoji_5.svg'
import MyButton from '../../components/MyButton'
import { ReactComponent as Emoji_1 } from '../../assets/svg/Emoji_1.svg'
import { ReactComponent as Emoji_2 } from '../../assets/svg/Emoji_2.svg'
import { ReactComponent as Emoji_3 } from '../../assets/svg/Emoji_3.svg'
import { ReactComponent as Emoji_4 } from '../../assets/svg/Emoji_4.svg'
import { ReactComponent as Emoji_5 } from '../../assets/svg/Emoji_5.svg'




const Response = (props) => {

    const { currentStep } = props
    const [currnetEmoji, setCurrentEmoji] = React.useState('')

    const emojis = [
        { val: 'very poor', img: Emoji_1, color: '#EE3553' },
        { val: 'highly unlikely', img: Emoji_2, color: '#F9AE42' },
        { val: 'unique', img: Emoji_3, color: '#FCD070' },
        { val: 'highly relevant', img: Emoji_4, color: '#92C777' }, ,
        { val: 'unique totaly different', img: Emoji_5, color: '#36B24A' }
    ]

    let handleEmojiClick = (emojiVal) => {
        if (currnetEmoji.val !== emojiVal.val) {
            setCurrentEmoji(emojiVal)
        } else {
            setCurrentEmoji('')
        }
    }

    let handleButton = () => {
        if (currentStep.stp !== 5) {
            props.setCurrentStep(props.steps[currentStep.stp])
        }
    }

    const getSmileyClass = (val) => {
        if (val == 'very poor') {
            return 'smiley-svg-1'
        } else if (val == 'highly unlikely') {
            return 'smiley-svg-2'
        } else if (val == 'unique') {
            return 'smiley-svg-3'
        } else if (val == 'highly relevant') {
            return 'smiley-svg-4'
        } else if (val == 'unique totaly different') {
            return 'smiley-svg-5'
        }
    }

    return (
            <div style={{ marginTop: '40px' }}>
                <div style={{ display: 'flex', width: '100%'}}>
                        {
                            emojis.map((val, idx) => {
                                let myFill = val.val == currnetEmoji.val ? val.color : '#CCCCCC'
                                return (
                                    <div style={{ width: '100px', height: '100px', padding: '20px', cursor: 'pointer', color: 'purple' }}
                                        onClick={() => handleEmojiClick(val)}>
                                        <val.img fill={myFill} className={getSmileyClass(val.val)}
                                        />
                                    </div>
                                )
                            })
                        }
                </div>
            </div>
    )
}

export default Response;







