import React from "react";
import { Row, Col } from "antd";
import NavigationSteps from "../../components/NavigationSteps";
import StepsContainer from "../Shell/Dashboard/IdeaValidation/StepsContainer";
import ResponderStepsContainer from "./ResponderStepsContainer";
import getResponderData from "../../redux/actions/respondent/getResponderData";
import { connect } from "react-redux";

const ResponderSteps = props => {
  const { responderData } = props.location.state;
  const [currentScreen, setCurrentScreen] = React.useState(0);
  const [stepStatus, setStepStatus] = React.useState({
    Step1: "wait",
    Step2: "wait",
    Step3: "wait",
    Step4: "wait",
    Step5: "wait",
  });

  React.useEffect(() => {
    props.notShowExtraContent();
    return () => {
      props.showExtraContent();
    };
  }, []);

  // React.useEffect(()=>{
  //     if(props.match.params.id && props.match.params.email){
  //         props.getResponderData({id:props.match.params.id,email:props.match.params.email})
  //     }
  // },[])

  const handleNextButton = () => {
    if (currentScreen == 0) {
      setStepStatus({ ...stepStatus, Step1: "finish" });
    } else if (currentScreen == 1) {
      setStepStatus({ ...stepStatus, Step2: "finish" });
    } else if (currentScreen == 2) {
      setStepStatus({ ...stepStatus, Step3: "finish" });
    } else if (currentScreen == 3) {
      setStepStatus({ ...stepStatus, Step4: "finish" });
    } else if (currentScreen == 4) {
      setStepStatus({ ...stepStatus, Step5: "finish" });
    }
    setCurrentScreen(currentScreen + 1);
  };

  return (
    <div className="p-lg-5 p-4">
      <div style={{ width: "100%", height: "100%", backgroundColor: "#fff" }}>
        <Row justify="center">
          <NavigationSteps
            responderSteps={true}
            currentScreen={currentScreen}
            setCurrentScreen={setCurrentScreen}
            stepStatus={stepStatus}
          />
        </Row>
        <div>
          <ResponderStepsContainer
            currentScreen={currentScreen}
            handleNextButton={handleNextButton}
            properties={props}
            responderData={responderData}
            stepStatus={stepStatus}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getResponderData: data => dispatch(getResponderData(data)),
    notShowExtraContent: () => dispatch({ type: "HEADER_FOOTER_HIDE" }),
    showExtraContent: () => dispatch({ type: "HEADER_FOOTER_NOT_HIDE" }),
  };
};

export default connect(null, mapDispatchToProps)(ResponderSteps);
