export const menuData = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Team",
    link: "/team",
  },
  {
    title: "Projects",
    link: "/projects",
  },
];
