import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const DonutChart = ({ data }) => {
  const size = 200; // The size of the chart
  const strokeWidth = 40; // The width of the donut ring
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const total = data.reduce((sum, value) => sum + value, 0);
  const angles = data.map(value => (value / total) * Math.PI * 2);
  let startAngle = 0;
  let endAngle = 0;
  return (
    <View style={styles.container}>
      {data.map((value, index) => {
        endAngle = startAngle + angles[index];
        const color = index === 0 ? "green" : index === 1 ? "blue" : "red";
        const startX = size / 2 + Math.cos(startAngle) * radius;
        const startY = size / 2 + Math.sin(startAngle) * radius;
        const endX = size / 2 + Math.cos(endAngle) * radius;
        const endY = size / 2 + Math.sin(endAngle) * radius;
        const largeArcFlag = endAngle - startAngle <= Math.PI ? 0 : 1;
        return (
          <View key={index}>
            <View
              d={`
                    M ${startX} ${startY}
                    A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}
                    L ${size / 2} ${size / 2}
                    Z
                  `}
              fill={color}
            />
          </View>
        );
      })}
      <circle cx={size / 2} cy={size / 2} r={radius - strokeWidth / 2} fill="white" />
    </View>
  );
};
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
export default DonutChart;
