import {authModules} from '../../config/axiosConfig'
import { getCookie } from '../../utils/cookies'
import {GET_ALL_TEAM_MEMBERS} from '../constants/teams'
import {actions} from '../../utils/helperFunctions'

const getAllTeamMembers = () => async dispatch =>{
    dispatch(actions.loading(GET_ALL_TEAM_MEMBERS))
    try {
        let apiCall = await authModules.get('/api/teams/getAllUserTeam',{
            headers:{
                authorization : getCookie('token')
            }
        })
        dispatch(actions.success(GET_ALL_TEAM_MEMBERS,apiCall.data.data))
     } catch (err) {
        dispatch(actions.failure(GET_ALL_TEAM_MEMBERS,err.response?err.response.data.error:err))
    }
}

export {
    getAllTeamMembers
}
