import store from 'store'

const setItem = (name,val) =>{
    store.set(name,val)
}

const getItem = (name) =>{
    return store.get(name)
}

const removeItem = (name) =>{
    store.remove(name)
}


export {
    setItem ,
    getItem ,
    removeItem
}