import { authModules } from "../../config/axiosConfig";
import { createCookie, getCookie } from "../../utils/cookies";
import { SET_USER_ROLE } from "../constants/users";
import cogoToast from "cogo-toast";
import { actions } from "../../utils/helperFunctions";

export const postUserRole = payLoad => async (dispatch, getState) => {
  dispatch(actions.loading(SET_USER_ROLE));
  // if(getState().setUserRoleReducer.isLoading){
  //     cogoToast.loading(`Switching to ${getCookie('userRole') == 'admin' ? 'member' : 'admin'}`).then(()=>{

  //     })
  // }

  cogoToast.loading(`Switching to ${getCookie("userRole") == "admin" ? "member" : "admin"}`, {
    hideAfter: 1.5,
  });
  // cogoToast.loading(`Switching to ${getCookie('userRole') == 'admin' ? 'member' : 'admin'}`,{hideAfter:1.5}).then(()=>{
  //     cogoToast.success(`Switched to ${getCookie('userRole')}`)
  // })
  // if(getState().setUserRoleReducer.isLoading){
  //     cogoToast.loading(`Switching to ${getCookie('userRole') == 'admin' ? 'member' : 'admin'}`,{hideAfter:0}).then(()=>{
  //             cogoToast.success(`Switched to ${getCookie('userRole')}`)
  //         })
  // }
  try {
    let apiCall = await authModules.post("/api/users/setCurrentProjectType", payLoad, {
      headers: {
        authorization: getCookie("token"),
      },
    });
    dispatch(actions.success(SET_USER_ROLE, apiCall.data.data));
    console.log(apiCall, "apiCall===>>>>>>");
    createCookie("userRole", apiCall.data.data.currentProjectType);
    cogoToast.success(`Switched to ${getCookie("userRole")}`);
  } catch (err) {
    cogoToast.error(`Something went wrong!`);
    dispatch(actions.failure(SET_USER_ROLE, err.response ? err.response.data.error : err));
  }
};
