import React from "react";
import { View, Text, Image, Font, Document, StyleSheet } from "@react-pdf/renderer";

const Header = props => {
  return (
    <View {...props}>
      <Image src={props.img} style={{ width: 30 }} />
      <Text style={styles.header}>{props.heading}</Text>
    </View>
  );
};

export const NewHeader = props => {
  return (
    <View
      style={{
        padding: 10,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.image ? (
        <Image src={props.image} style={{ width: 28, marginRight: 8 }} />
      ) : (
        <Text></Text>
      )}
      <Text
        style={{
          width: 350,
          fontSize: 20,
          fontWeight: "bold",
          fontFamily: "roboto",
          color: "#060139",
          textAlign: "center",
        }}
      >
        {props.title}
      </Text>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  header: {
    fontWeight: "bold",
    fontSize: 25,
    marginBottom: 4,
    textAlign: "center",
    color: "black",
    fontFamily: "roboto",
  },
});
