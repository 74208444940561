import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "antd";
import CreativeMind from "../assets/img/CreativeMind.png";
import MyButton from "./MyButton";
import { myColor } from "../GlobalVar";
import { MainContainer } from "./MainContainer";
import { connect } from "react-redux";
import HomeCardButton from "./homeCardButton";

const ResponderConfirmPage = props => {
  const { message } = props.location.state;

  React.useEffect(() => {
    props.notShowExtraContent();
    return () => {
      props.showExtraContent();
    };
  }, []);

  return (
    <MainContainer style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img width="330px" src={CreativeMind} />

          <div>
            <h2 style={{ textAlign: "center", color: myColor.green }}>{message}</h2>
            <p style={{ fontSize: 18, fontFamily: "Inter-Medium" }} align="center">
              We appreciate your help in answering the questions.
              <br />
              Please visit{" "}
              <a
                style={{ color: "#000", textDecoration: "none", fontWeight: 700 }}
                href="https://validatr.io"
                target="_blank"
              >
                validatr.io
              </a>{" "}
              if you've got an idea too.
            </p>
            {/* <p style={{ fontSize: 18, fontFamily: 'Inter-Medium' }} align='center'>
                            If you continue, you agree to keep anything you see on the next screens confidential.
                        </p> */}
          </div>

          <div>
            <HomeCardButton
              onClick={() => props.history.push("/")}
              htmlType="submit"
              text={`Back to home`}
              bg={true}
            />
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    notShowExtraContent: () => dispatch({ type: "HEADER_FOOTER_HIDE" }),
    showExtraContent: () => dispatch({ type: "HEADER_FOOTER_NOT_HIDE" }),
  };
};

export default connect(null, mapDispatchToProps)(ResponderConfirmPage);
