import React from 'react'
import { Select, Button } from 'antd'
import styled from 'styled-components'
import { myColor } from '../GlobalVar'
import { DownOutlined, UserOutlined } from '@ant-design/icons';

const { Option } = Select;

const SelectWrapper = styled(Select)`
  
&.ant-select-single{
    width: ${props => props.width};
}
.ant-select-selector{
    border-radius: 5px !important;
}
&.ant-select:hover{
    border-color:  ${myColor.hover_blue};
}
`

const ResponderDropdown = (props) => {
    return (
        <div>
            {props.title &&
                <h4 style={{ textAlign: "left", marginBottom: 8, fontSize: 16 }}>{props.title}</h4>
            }
                
                <SelectWrapper
                    showSearch={props.showSearch}
                    defaultValue="lucy"
                    placeholder={props.placeholder}
                    width={props.width}
                    size={'large'}
                    value={props.value ? props.value : null}
                    optionFilterProp="children"
                    style={{ fontFamily: 'Inter-Regular', fontSize: 16, }}
                    onChange={props.onChange}
                    listHeight={props.listHeight ? props.listHeight : 120}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    // {...props}

                >
                    {
                       props.menu
                    }
                </SelectWrapper> 
        </div>
    )
}

export default ResponderDropdown