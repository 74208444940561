import styled from "styled-components";
import { Row, Col, Timeline } from "antd";
import { useState } from "react";
import { HiChevronDoubleDown, HiChevronDoubleRight, HiChevronDoubleLeft } from "react-icons/hi";
import { Bounce, Slide, Fade, Zoom } from "react-awesome-reveal";
import Heading from "../landingPageHeading";
import Image1 from "../../assets/Assets-Landing/Power to Succeed.svg";
import Image2 from "../../assets/Assets-Landing/Intensity of the Problem.svg";
import Image3 from "../../assets/Assets-Landing/WOW Factor.svg";
import Image4 from "../../assets/Assets-Landing/Problem Solution Impact.svg";
import Image5 from "../../assets/Assets-Landing/Idea Propagation Score.svg";

const solutions = [
  {
    title: "POWER TO SUCCEED",
    image: Image1,
    heading: "Power to Succeed",
    subHeading: ``,
    description: `The Power to Succeed is a comprehensive metric that evaluates an idea's potential for success. It summarizes the likelihood of success by considering how highly evaluators rate the idea across all factors. The metric takes into account five factors and emphasizes positive attributes, aiming to capture the confidence and endorsement of evaluators.`,
    list: [
      //   "Requirement Analysis through a questionnaire",
      //   "Detailed Level of Efforts Estimate (for transparent costing)",
      //   "Prototype of App Design with Admin panel for KPI measurement ",
      //   "Testing, Technical and milestone deliveries documents",
      //   "World class customer support",
    ],
  },
  {
    title: "INTENSITY OF THE PROBLEM",
    image: Image2,
    heading: "Intensity of the Problem",
    subHeading: ``,
    description: `The Intensity of the Problem metric evaluates the severity or magnitude of the problem that your idea intends to address. It provides an understanding of the pressing problem's gravity, helping you better grasp and appreciate its significance.`,
    list: [
      // "Automation of complex business processes",
      // "Visual presentation of performance measures",
      // "Ability to generate detailed reports",
      // "Manage Users, Admins and process flows",
    ],
  },
  {
    title: "WOW FACTOR",
    image: Image3,
    heading: "Wow Factor",
    subHeading: ``,
    description: `This metric emphasizes creating a distinct and captivating experience that sets your idea apart. It measures the ability of an idea to captivate buyers through a unique and useful solution. It emphasizes the capacity to stand out from competitors and create a positive impression that attracts potential customers.`,
    list: [
      // "Investment Portals",
      // "Recruitment Portals",
      // "Online food delivery",
      // "Transport portals",
    ],
  },
  {
    title: "PROBLEM SOLUTION IMPACT",
    image: Image4,
    heading: "Problem Solution Impact",
    subHeading: ``,
    description: `Problem Solution Impact is a cumulative metric that evaluates the problem's relevance and the solution's usefulness together. It aims to ensure that your idea addresses a relevant problem and offers a useful solution. A higher score indicates a stronger fitness in terms of both the problem and the solution, highlighting the potential for success.`,
    list: [
      // "SaaS Portals",
      // "Business Process Automation",
      // "B2B and B2C portals"
    ],
  },
  {
    title: "IDEA PROPAGATION SCORE",
    image: Image5,
    heading: "Idea Propagation Score",
    subHeading: ``,
    description: `Gauge the potential for your idea to spread organically among your target audience. This metric helps you prioritize ideas with higher propagation potential, maximizing your reach and user engagement for greater overall benefits.`,
    list: [
      // "The Human Resources Industry",
      // "The Legal Industry",
      // "The Healthcare Industry",
      // "The Real Estate Industry",
      // "The Online Music Industry",
    ],
  },
];

const StyledSection = styled.section`
  padding: 3% 10%;
  .arrow-icon {
    color: #0042e6;
    font-size: 24px;
    cursor: pointer;
  }
  p {
    color: #0b3558;
    font-size: 16px;
  }

  h3 {
    font-size: 22px;
  }

  .solutions-heading {
    display: flex;
    justify-content: space-between;
  }

  .main-div-content {
    display: flex;
    padding: 0px 2% 24px 0px;
    text-align: left;
    align-items: center;
  }

  .image-col {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
  }

  .solution-option h5 {
    transition: 0.3s;
    color: #8e8e8e;
    padding: 12px 21px;
    border-bottom: 1px solid #8e8e8e;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    :after {
      content: "";
      height: 28px;
      width: 1px;
      background-color: #8e8e8e;
      position: absolute;
      right: -8px;
    }
  }

  .solution-option:last-child h5:after {
    display: none;
  }

  .solution-description {
    color: #0b3558;
    line-height: 2;
    font-weight: 400;
  }

  .solution-sub-title {
    color: #050607;
    line-height: 1.8;
    font-weight: 600;
  }

  .twoLineList ul {
    color: #0b3558;
    padding: 5px 18px;
    margin: 0;
    padding: 0px 20px;
  }

  .twoLineList li {
    width: 50%;
    float: left;
    font-weight: 400;
    margin-top: 6px;
    margin-bottom: 6px;
    // white-space: nowrap;
  }

  .image-tag {
    max-width: 450px;
  }

  @media (max-width: 576px) {
    .twoLineList li {
      width: 100%;
    }
    .image-tag {
      max-width: 300px;
    }
  }

  @media (max-width: 991px) {
    .main-div-content {
      display: contents;
    }
    .image-col {
      height: 100%;
    }
    h3 {
      font-size: 18px;
    }
  }

  @media (max-width: 1200px) {
    .solution-option {
      h5 {
        font-size: 12px;
        padding: 12px 24px;
      }
    }
  }
`;

const LandingPageFactors = () => {
  const [solution, setSolution] = useState(0);
  return (
    <StyledSection className="text-center">
      <Fade direction="up" triggerOnce duration={1300} cascade damping={0.2}>
        <Heading title={<>Objectively Measure your Ideas with our Unique Metrics!</>} />
        <p className="text-md-center text-left">
          Unlock your idea's full potential with our Unique Metrics. Our tool provides a holistic
          assessment by utilizing both AI surveys and human respondents,
          <br /> giving you valuable insights to optimize your offerings.
        </p>
      </Fade>

      <div className="solutions-wrapper mt-5">
        <Row className="justify-content-center">
          <Col style={{ maxWidth: 1114 }} xs={0} sm={0} md={0} lg={24} xxl={24}>
            <div className="solutions-heading">
              {solutions.map((item, index) => (
                <div key={index} className="solution-option">
                  <Fade triggerOnce cascade damping={0.2}>
                    <h5
                      onClick={() => setSolution(index)}
                      style={{
                        color: index == solution ? "#0042E6" : "#8E8E8E",
                        borderColor: index == solution ? "#0042E6" : "#8E8E8E",
                        fontWeight: index == solution ? 700 : 500,
                      }}
                    >
                      {item.title}
                    </h5>
                    <div style={{ display: "block" }}>
                      {index == solution && (
                        <Bounce>
                          <HiChevronDoubleDown className="arrow-icon" />
                        </Bounce>
                      )}
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={0} xxl={0}>
            <div className="solutions-heading  align-items-center">
              <HiChevronDoubleLeft
                className="arrow-icon"
                onClick={() => setSolution(prev => (prev > 0 ? prev - 1 : 0))}
              />
              <h3 style={{ color: "#0042E6" }}>
                <b>{solutions[solution]?.title}</b>
              </h3>
              <HiChevronDoubleRight
                className="arrow-icon"
                onClick={() =>
                  setSolution(prev =>
                    prev < solutions.length - 1 ? prev + 1 : solutions.length - 1
                  )
                }
              />
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <Row style={{ padding: "0 5%" }} className="text-left" justify="space-between">
          {solutions.map((item, index) => (
            <>
              {index == solution && (
                <div className="main-div-content">
                  <Col xl={15}>
                    <Fade triggerOnce>
                      <h3 style={{ color: "#0B3558" }} className="solution-title">
                        <b>{solutions[solution].heading}</b>
                      </h3>
                      <h5 className="solution-sub-title my-4">{solutions[solution].subHeading}</h5>
                      <p className="solution-description">{solutions[solution].description}</p>
                      <div className="twoLineList">
                        <Timeline>
                          {solutions[solution].list.map(listItem => (
                            <Timeline.Item>{`${listItem}`}</Timeline.Item>
                          ))}
                        </Timeline>
                      </div>
                    </Fade>
                  </Col>
                  <Col xl={7} className="image-col text-center mx-auto">
                    <Fade cascade triggerOnce>
                      <img
                        alt="web-solution"
                        src={item.image}
                        style={{ borderRadius: 8, width: 500 }}
                        className="image-tag"
                      />
                    </Fade>
                  </Col>
                </div>
              )}
            </>
          ))}
        </Row>
      </div>
    </StyledSection>
  );
};

export default LandingPageFactors;
