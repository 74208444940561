import React, { useState } from "react";
import { Col, Row, Steps } from "antd";
import styled from "styled-components";
import { myColor } from "../GlobalVar";
import { SolutionOutlined } from "@ant-design/icons";

const { Step } = Steps;

const NavigationSteps = props => {
  const onChange = current => {
    props.setCurrentScreen(current);
  };
  return (
    <>
      <StepsWrapper
        type="navigation"
        current={props.currentScreen}
        onChange={onChange}
        className="site-navigation-steps pt-5"
        responsive={false}
      >
        <Step title="Step 1" status={props.stepStatus && props.stepStatus.Step1} />
        <Step title="Step 2" status={props.stepStatus && props.stepStatus.Step2} />
        <Step title="Step 3" status={props.stepStatus && props.stepStatus.Step3} />
        <Step title="Step 4" status={props.stepStatus && props.stepStatus.Step4} />
        {props.responderSteps && (
          <Step title="Step 5" status={props.stepStatus && props.stepStatus.Step5} />
        )}
      </StepsWrapper>
    </>
  );
};

export default NavigationSteps;

const StepsWrapper = styled(Steps)`
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${myColor.blue};
    border-color: ${myColor.blue};
  }
  .ant-steps-item-title{
    font-weight: 500;
  }
  /* 
  
  .ant-steps-item-icon {
    width: 40px;
    height: 40px;
    font-size: 19px;
  }
  &.ant-steps-navigation .ant-steps-item::before {
    background-color: ${myColor.blue};
    height: 3px;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: ${myColor.blue};
  }
  .anticon {
    color: ${myColor.blue};
  }
  .ant-steps-item-icon .ant-steps-icon {
    top: 0px;
  }
  :hover {
    color: ${myColor.blue};
  } */
`;
