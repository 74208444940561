import {authModules} from '../../config/axiosConfig'
import { getCookie } from '../../utils/cookies' 
import {USER_PROFILE} from '../constants/users'
import {actions} from '../../utils/helperFunctions'

const getUserProfile = () => async dispatch => {
    dispatch(actions.loading(USER_PROFILE))
    try{
        let apiCall = await authModules.get(`api/users/getProfile`, {
            headers:{
                authorization : getCookie('token')
            }
        })
        dispatch(actions.success(USER_PROFILE,apiCall.data.data))
    }
    catch(err){
        dispatch(actions.failure(USER_PROFILE,err.response?err.response.data.error:err))
    }
}
export default getUserProfile