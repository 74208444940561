import React from "react";
import { Row, Button, Card, message } from "antd";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";
import TimeCost from "../assets/svg/time-cost.svg";
import MyButton from "./MyButton";
import { myColor } from "../GlobalVar";
import { FormOutlined, InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import cogoToast from "cogo-toast";
import HomeCardButton from "./homeCardButton";
import { Document, Page, pdf, PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import MarketResearchReportView from "../views/Shell/Reports/marketResearchReport/indexView";
import { useState } from "react";
import { IdeaValidationReport } from "../views/Shell/Reports/ideaValidationAIReport/IdeaValidationReport";
import moment from "moment";

const StyleCard = styled(Card)`
  margin-bottom: 36px;
  // margin-right: 10px;
  // margin-left: 10px;
  width: 100%;
  transition: transform 1s ease;
  background-color: #f7f6ff;
  :hover {
    /* cursor: pointer; */
    transform: translateY(-0.3em);
    transition: transform 1s ease;
  }
  .ant-card-body {
    padding: 20px;
    height: 230px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  &.ant-card-bordered {
    border-radius: 15px;
    box-shadow: 0 0 2px lightgray;
    border: 0.2px solid lightgray;
  }
  &.ant-card-bordered:hover {
    background: #c9d9ff;
  }
  p {
    margin-bottom: 0;
  }
`;
const LinkText = styled.p`
  font-size: 18px;
  color: ${myColor.blue};
  position: relative;
  :hover {
    // color:red;
    text-decoration: underline;
  }
`;

const DisplayOver = styled.div({
  // height: 0,
  position: "absolute",
  right: 0,
  bottom: 0,
  // width: 0,
  zIndex: 2,
  transition: "transform 1s ease",
  backgroundColor: "transparent",
  padding: "0px",
  boxSizing: "border-box",
  borderRadius: 15,
});

const SubTitle = styled.h4({
  fontFamily: "Inter-Regular",
  transform: "translate3d(0,80px,0)",
  transition: "transform 1s ease",
});

const Paragraph = styled.p({
  transform: "translate3d(0,80px,0)",
  transition: "transform 1s ease",
});

const Hover = styled.div({
  opacity: 0,
  background: "transparent",
  display: "none",
  transform: "translate3d(0,0px,0)",
  transition: "1s ease",
});

const Wrapper = styled.div({
  [`:hover ${DisplayOver}`]: {
    backgroundColor: "rgba(0,0,0,.8)",
    transform: "translate3d(0,0px,0)",
    height: "100%",
    width: "100%",
    padding: "20px",
    // border: `1.8px solid ${myColor.hover_blue}`,
  },
  [`:hover ${SubTitle}, :hover ${Paragraph}`]: {
    transform: "translate3d(0,0px,0)",
    color: "#fff",
  },
  [`:hover ${Hover}`]: {
    opacity: 1,
    display: "block",
  },
});

const DashBoardCard = props => {
  const history = useHistory();
  const { reportData } = props;
  const [showPdf, setShowPdf] = useState(false);

  const handleReport = e => {
    if (props.moduleData.ideaValSubmittedResponders > 4) {
      history.push(props.linkRoute, {
        from: "dashboard",
        // projectTitle: props.moduleData?.project.title,
        projectTitle: props.moduleData,
      });
    } else {
      cogoToast.warn("Atleast five responder is required to view report", { hideAfter: 3 });
    }
    // history.push('/report',{from : 'dashboard'})
    e.stopPropagation();
  };

  React.useEffect(() => {
    setTimeout(() => {
      setShowPdf(true);
    }, 5000);
  }, [props.showViewReport]);

  return (
    <div
    // onClick={() =>
    //   history.push({
    //     pathname: `${props.pathname}`,
    //     state: { testing: "test", moduleData: props.moduleData },
    //   })
    // }
    >
      <StyleCard>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p
            style={{
              width: "60%",
              color: myColor.font_color,
              fontSize: "24px",
              wordWrap: "break-word",
              margin: "6px 8px",
              fontWeight: "bold",
              fontFamily: "Inter-Light",
            }}
          >
            {props.text}
          </p>
          <div>
            <HomeCardButton
              isLoading={props.isLoading}
              disabled={props.disabled}
              lockicon={props.lockIcon}
              text={props.btnText}
              onClick={() =>
                history.push({
                  pathname: `${props.pathname}`,
                  state: { testing: "test", moduleData: props.moduleData },
                })
              }
              style={{
                fontSize: 14,
                borderRadius: 8,
                width: `${props.lockIcon ? "100%" : "130px"}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: `${props.btnBgColor}`,
                border: `${props.btnBorder}`,
                color: `${props.btnTextColor}`,
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: 20,
          }}
        >
          <div style={{ width: "40%" }}>
            <img width="70" src={props.img} />
          </div>
          <div style={{ width: "50%", marginBottom: 0 }} align="right">
            {props.secondaryTextShow ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                {/* <FormOutlined style={{ fontSize: 20 }} /> */}
                <p style={{ fontSize: 14, color: myColor.font_color }}>{props.secondaryText}</p>
              </div>
            ) : null}

            {props.showViewReport && showPdf ? (
              <PDFDownloadLink
                document={<IdeaValidationReport reportData={reportData} />}
                fileName={`Idea Validation-${reportData?.projectDetails?.title}-${moment().format(
                  "YYYY-MM-DD"
                )}`}
              >
                {({ blob, url, loading, error }) => {
                  return loading ? (
                    <LoadingOutlined style={{ fontSize: 20 }} />
                  ) : (
                    <LinkText>Download Report</LinkText>
                  );
                }}
                {/* {({ blob, url, loading, error }) => {
                  return loading ? null : (
                    <a href={url} target="_blank" download >
                      Download Report
                    </a>
                  );
                }} */}
              </PDFDownloadLink>
            ) : (
              <></>
            )}
          </div>
          <Wrapper>
            <InfoCircleOutlined
              style={{ position: "absolute", zIndex: 9, right: 11, bottom: 12 }}
            />
            <DisplayOver>
              <Hover>
                <SubTitle>Detail:</SubTitle>
                <Paragraph
                  style={{
                    overflow: "auto",
                    maxHeight: "140px",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  {props.info}
                </Paragraph>
              </Hover>
            </DisplayOver>
          </Wrapper>
        </div>
      </StyleCard>
    </div>
  );
};

export default DashBoardCard;
