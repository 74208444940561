import {marketResearch} from '../../../config/axiosConfig'
import {actions} from '../../../utils/helperFunctions'
import { getCookie } from '../../../utils/cookies'
import {GET_MARKET_RESEARCH_BY_PROJECT} from '../../constants/marketResearch/getmarketResearchByProjectId'

export const getMarketResearchByProjectId = () => async dispatch =>{
    dispatch(actions.loading(GET_MARKET_RESEARCH_BY_PROJECT))
    try {
        let apiCall= await marketResearch.get(`/api/market-research/getMarketResearchByProjectId/${getCookie('currentProject')}`,{
            headers:{
                authorization: getCookie('token')
            }
        })
        dispatch(actions.success(GET_MARKET_RESEARCH_BY_PROJECT,apiCall.data.data))
    } catch (err) {
        dispatch(actions.failure(GET_MARKET_RESEARCH_BY_PROJECT,err.response ? err.response?.data.error : err))
    }
}