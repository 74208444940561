import {ideaValidation} from '../../../config/axiosConfig'
import { actions } from '../../../utils/helperFunctions'
import { GET_RESPONDER_DATA } from '../../constants/respondent/responder'
// import { getItem } from '../../utils/localStorage'
// import {getCookie} from '../../utils/cookies'

const getResponderData =  (params) => async dispatch =>{
    dispatch(actions.loading(GET_RESPONDER_DATA))
    try {
        let apiCall = await ideaValidation.get(`api/projects/projectResponse/${params.id}/${params.email}`)
        dispatch(actions.success(GET_RESPONDER_DATA,apiCall.data.data))
    } catch (err) {
        dispatch(actions.failure(GET_RESPONDER_DATA,err.response?err.response.data.error:err))
    }
}

export default getResponderData;