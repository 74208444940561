import { InfoCircleOutlined, SmileOutlined, WarningOutlined } from "@ant-design/icons";
import { notification } from "antd";
import CryptoJS from "crypto-js";

const generateDeviceId = length => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const encryption = plianText => {
  let hasKey = process.env.REACT_APP_HASH_KEY || "eforte@Validator2021";

  // var ciphertext = CryptoJS.AES.encrypt(plianText, `${hasKey}`).toString();
  // return ciphertext

  let encJson = CryptoJS.AES.encrypt(JSON.stringify(plianText), `${hasKey}`).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
};

const decryption = cipherText => {
  let hasKey = process.env.REACT_APP_HASH_KEY || "eforte@Validator2021";
  // var bytes  = CryptoJS.AES.decrypt(cipherText, `${hasKey}`);
  // var originalText = bytes.toString(CryptoJS.enc.Utf8);
  // return originalText
  if (cipherText !== "null") {
    let decData = CryptoJS.enc.Base64.parse(cipherText).toString(CryptoJS.enc.Utf8);
    let bytes = CryptoJS.AES.decrypt(decData, hasKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(bytes);
  }
};

const uploadCloudinaryImage = async image => {
  let apiUrl = "https://api.cloudinary.com/v1_1/dx2md0gy6/image/upload";

  let data = {
    file: `data:image/jpg;base64,${image}`,
    upload_preset: `${process.env.REACT_APP_CLOUDINARY_PRESET}`,
  };

  try {
    const uploadData = await fetch(apiUrl, {
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
    });
    const response = await uploadData.json();
    return response.secure_url;
  } catch (err) {
    return err;
  }
};

// const GetUserIdFromUrl = () => {
//     let link = window.location.href
//     let startIndex = link.indexOf("/", 10)
//     let lastIndex = link.indexOf("/", startIndex+1);
//     let userId = link.slice(startIndex+1, lastIndex);
//       return userId
// }

const roundOf = val => {
  if (val == 1 || val < 1.2) {
    return 1;
  } else if (val > 1.1 && val < 2.2) {
    return 2;
  } else if (val > 2.1 && val < 3.2) {
    return 3;
  } else if (val > 3.1 && val < 4.2) {
    return 4;
  } else if (val > 4.1 && val < 5.1) {
    return 5;
  }
};
const isObjEmpty = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const createConstant = (objName, constants = ["LOADING", "SUCCESS", "FAILURE"]) => {
  let myObj = new Object();
  for (const val of constants) {
    myObj[`${val}`] = `${objName}_${val}`;
  }
  return myObj;
};

const initialStateApiCallReducer = {
  data: null,
  isLoading: false,
  err: null,
};
const createApiCallReducer = (
  actionName,
  createCustomReducer = false,
  customReducer = () => null,
  state = initialStateApiCallReducer
) => {
  if (createCustomReducer) {
    return customReducer;
  } else {
    let initialState = state;
    return (state = initialState, action) => {
      switch (action.type) {
        case actionName.LOADING:
          return {
            ...state,
            isLoading: true,
          };
        case actionName.SUCCESS:
          return {
            ...state,
            isLoading: false,
            data: action.payLoad,
            err: null,
          };
        case actionName.FAILURE:
          return {
            ...state,
            isLoading: false,
            err: action.err,
          };
        default:
          return state;
      }
    };
  }
};

export const createModalStateReducer = actionName => {
  let initialState = { isOpen: false };
  return (state = initialState, action) => {
    switch (action.type) {
      case actionName.OPEN:
        return {
          ...state,
          isOpen: true,
        };
      case actionName.CLOSE:
        return {
          ...state,
          isOpen: false,
        };
      default:
        return state;
    }
  };
};

export const createCustomReducer = (customReducerArr = [], initialState) => {
  // let state = initialState
  return (state = initialState, action) => {
    let myObj = new Object();
    customReducerArr.length > 0 &&
      customReducerArr.map(val => {
        myObj[val.key] = { ...state, ...val.state };
      });
    if (action.type) {
      return myObj[action.type];
    } else {
      return state;
    }
  };
};

const actions = {
  loading: actiontype => {
    return {
      type: actiontype.LOADING,
    };
  },

  success: (actionType, data) => {
    return {
      type: actionType.SUCCESS,
      payLoad: data,
    };
  },

  failure: (actionType, err) => {
    return {
      type: actionType.FAILURE,
      err: err,
    };
  },

  reset: actionType => {
    return {
      type: actionType.SUCCESS,
      payLoad: null,
    };
  },

  modalOpen: actionType => {
    return {
      type: actionType.OPEN,
    };
  },

  modalClose: actionType => {
    return {
      type: actionType.CLOSE,
    };
  },
};

export const removeAsyncReducers = (store, reducerToRemoveArr) => {
  reducerToRemoveArr.length > 0 && reducerToRemoveArr.map(val => store.removeAsyncReducer(val));
};

export const regexValidate = (reg, str) => {
  let myReg = new RegExp(reg);
  return myReg(str);
};

const openNotificationSuccess = (message, description) => {
  notification.success({
    message: "Completed",
    description: description,
    icon: <SmileOutlined style={{ color: "green" }} />,
  });
};

const openNotificationWarning = (message, description) => {
  notification.warning({
    message: "Incompleted",
    description: description,
    icon: <InfoCircleOutlined style={{ color: "#ff4d4f" }} />,
  });
};

const industryData = [
  {
    label: "Accounting",
    value: "Accounting",
    id: 1,
  },
  {
    label: "Addiction recovery",
    value: "Addiction recovery",
    id: 2,
  },
  {
    label: "Adult education",
    value: "Adult education",
    id: 3,
  },
  {
    label: "Aging services",
    value: "Aging services",
    id: 4,
  },
  {
    label: "Agriculture and Farming",
    value: "Agriculture and Farming",
    id: 5,
  },
  {
    label: "Animal care",
    value: "Animal care",
    id: 6,
  },
  {
    label: "Apparel design",
    value: "Apparel design",
    id: 7,
  },
  {
    label: "Architecture and Interior Design",
    value: "Architecture and Interior Design",
    id: 8,
  },
  {
    label: "Art and Design",
    value: "Art and Design",
    id: 9,
  },
  {
    label: "Art therapy",
    value: "Art therapy",
    id: 10,
  },
  {
    label: "Automotive and Transportation",
    value: "Automotive and Transportation",
    id: 11,
  },
  {
    label: "Beauty and Personal Care",
    value: "Beauty and Personal Care",
    id: 12,
  },
  {
    label: "Beauty services",
    value: "Beauty services",
    id: 13,
  },
  {
    label: "Beverage Production",
    value: "Beverage Production",
    id: 14,
  },
  {
    label: "Biotechnology and Life Sciences",
    value: "Biotechnology and Life Sciences",
    id: 15,
  },
  {
    label: "Bookkeeping",
    value: "Bookkeeping",
    id: 16,
  },
  {
    label: "Business Coaching",
    value: "Business Coaching",
    id: 17,
  },
  {
    label: "Childcare",
    value: "Childcare",
    id: 18,
  },
  {
    label: "Cleaning services",
    value: "Cleaning services",
    id: 19,
  },
  {
    label: "Coaching and Mentoring",
    value: "Coaching and Mentoring",
    id: 20,
  },
  {
    label: "Construction",
    value: "Construction",
    id: 21,
  },
  {
    label: "Consulting",
    value: "Consulting",
    id: 22,
  },
  {
    label: "Consumer Goods and Retail",
    value: "Consumer Goods and Retail",
    id: 23,
  },
  {
    label: "Copywriting",
    value: "Copywriting",
    id: 24,
  },
  {
    label: "Crafting",
    value: "Crafting",
    id: 25,
  },
  {
    label: "Data Analytics and Business Intelligence",
    value: "Data Analytics and Business Intelligence",
    id: 26,
  },
  {
    label: "Digital Marketing and SEO",
    value: "Digital Marketing and SEO",
    id: 27,
  },
  {
    label: "Dietitian",
    value: "Dietitian",
    id: 28,
  },
  {
    label: "E-commerce and Online Retail",
    value: "E-commerce and Online Retail",
    id: 29,
  },
  {
    label: "Education and E-learning",
    value: "Education and E-learning",
    id: 30,
  },
  {
    label: "Energy and Utilities",
    value: "Energy and Utilities",
    id: 31,
  },
  {
    label: "Environmental Services and Sustainability",
    value: "Environmental Services and Sustainability",
    id: 32,
  },
  {
    label: "Event Management and Planning",
    value: "Event Management and Planning",
    id: 33,
  },
  {
    label: "Event planning",
    value: "Event planning",
    id: 34,
  },
  {
    label: "Fashion and Apparel",
    value: "Fashion and Apparel",
    id: 35,
  },
  {
    label: "Financial planning",
    value: "Financial planning",
    id: 36,
  },
  {
    label: "Financial Services and Banking",
    value: "Financial Services and Banking",
    id: 37,
  },
  {
    label: "Financial Technology (Fintech)",
    value: "Financial Technology (Fintech)",
    id: 38,
  },
  {
    label: "Fitness instruction",
    value: "Fitness instruction",
    id: 39,
  },
  {
    label: "Florist",
    value: "Florist",
    id: 40,
  },
  {
    label: "Food and Beverage",
    value: "Food and Beverage",
    id: 41,
  },
  {
    label: "Food truck",
    value: "Food truck",
    id: 42,
  },
  {
    label: "Freight forwarding",
    value: "Freight forwarding",
    id: 43,
  },
  {
    label: "Fundraising",
    value: "Fundraising",
    id: 44,
  },
  {
    label: "Gaming and E-sports",
    value: "Gaming and E-sports",
    id: 45,
  },
  {
    label: "Gardening",
    value: "Gardening",
    id: 46,
  },
  {
    label: "Graphic design",
    value: "Graphic design",
    id: 47,
  },
  {
    label: "Green and Renewable Energy",
    value: "Green and Renewable Energy",
    id: 48,
  },
  {
    label: "Hairdressing",
    value: "Hairdressing",
    id: 49,
  },
  {
    label: "Health and Beauty Services",
    value: "Health and Beauty Services",
    id: 50,
  },
  {
    label: "Healthcare and Pharmaceuticals",
    value: "Healthcare and Pharmaceuticals",
    id: 51,
  },
  {
    label: "Home care",
    value: "Home care",
    id: 52,
  },
  {
    label: "Home Renovation and Interior Design",
    value: "Home Renovation and Interior Design",
    id: 53,
  },
  {
    label: "Home Services and Maintenance",
    value: "Home Services and Maintenance",
    id: 54,
  },
  {
    label: "Housecleaning",
    value: "Housecleaning",
    id: 55,
  },
  {
    label: "Hospitality and Tourism",
    value: "Hospitality and Tourism",
    id: 56,
  },
  {
    label: "Human Resources and Recruitment",
    value: "Human Resources and Recruitment",
    id: 57,
  },
  {
    label: "Industrial and Manufacturing",
    value: "Industrial and Manufacturing",
    id: 58,
  },
  {
    label: "Information Technology (IT) Services",
    value: "Information Technology (IT) Services",
    id: 59,
  },
  {
    label: "Investment management",
    value: "Investment management",
    id: 60,
  },
  {
    label: "Lawn care",
    value: "Lawn care",
    id: 61,
  },
  {
    label: "Legal Services and Law",
    value: "Legal Services and Law",
    id: 62,
  },
  // {
  //   label: "Mobile App Development",
  //   value: "Mobile App Development",
  //   id: 63,
  // },
  {
    label: "Marketing and Advertising",
    value: "Marketing and Advertising",
    id: 64,
  },
  {
    label: "Massage therapy",
    value: "Massage therapy",
    id: 65,
  },
  {
    label: "Media and Entertainment",
    value: "Media and Entertainment",
    id: 66,
  },
  {
    label: "Media production",
    value: "Media production",
    id: 67,
  },
  {
    label: "Medical billing",
    value: "Medical billing",
    id: 68,
  },
  {
    label: "Mental Health and Well-being Services",
    value: "Mental Health and Well-being Services",
    id: 69,
  },
  {
    label: "Mobile App Development",
    value: "Mobile App Development",
    id: 70,
  },
  {
    label: "Music lessons",
    value: "Music lessons",
    id: 71,
  },
  {
    label: "Nonprofit and Social Services",
    value: "Nonprofit and Social Services",
    id: 72,
  },
  {
    label: "On-demand Services",
    value: "On-demand Services",
    id: 73,
  },
  {
    label: "Online Education and Training",
    value: "Online Education and Training",
    id: 74,
  },
  {
    label: "Photography",
    value: "Photography",
    id: 75,
  },
  {
    label: "Real Estate and Property Management",
    value: "Real Estate and Property Management",
    id: 76,
  },
  {
    label: "Recycling",
    value: "Recycling",
    id: 77,
  },
  {
    label: "Restaurant",
    value: "Restaurant",
    id: 78,
  },
  {
    label: "Retail",
    value: "Retail",
    id: 79,
  },
  {
    label: "Roofing",
    value: "Roofing",
    id: 80,
  },
  {
    label: "Security services",
    value: "Security services",
    id: 81,
  },
  {
    label: "Social media marketing",
    value: "Social media marketing",
    id: 82,
  },
  {
    label: "Software Development",
    value: "Software Development",
    id: 83,
  },
  {
    label: "Sports and Recreation",
    value: "Sports and Recreation",
    id: 84,
  },
  {
    label: "Startup Incubation and Acceleration",
    value: "Startup Incubation and Acceleration",
    id: 85,
  },
  {
    label: "Telecommunications and Networking",
    value: "Telecommunications and Networking",
    id: 86,
  },
  {
    label: "Tourism",
    value: "Tourism",
    id: 87,
  },
  {
    label: "Transportation and Logistics",
    value: "Transportation and Logistics",
    id: 88,
  },
  {
    label: "Travel and Hospitality Services",
    value: "Travel and Hospitality Services",
    id: 89,
  },
  {
    label: "Tutoring",
    value: "Tutoring",
    id: 90,
  },
  {
    label: "Virtual Assistant",
    value: "Virtual Assistant",
    id: 91,
  },
  {
    label: "Waste management",
    value: "Waste management",
    id: 92,
  },
  {
    label: "Web Design and Development",
    value: "Web Design and Development",
    id: 93,
  },
  {
    label: "Wedding planning",
    value: "Wedding planning",
    id: 94,
  },
  {
    label: "Wellness and Fitness",
    value: "Wellness and Fitness",
    id: 95,
  },
  {
    label: "Wholesale and Distribution",
    value: "Wholesale and Distribution",
    id: 96,
  },
  {
    label: "Writing and Editing",
    value: "Writing and Editing",
    id: 97,
  },
  {
    label: "Yoga and Meditation",
    value: "Yoga and Meditation",
    id: 98,
  },
  {
    label: "Youth Development",
    value: "Youth Development",
    id: 99,
  },
  {
    label: "Zoological Services",
    value: "Zoological Services",
    id: 100,
  },
];

export {
  generateDeviceId,
  encryption,
  decryption,
  uploadCloudinaryImage,
  roundOf,
  isObjEmpty,
  createConstant,
  createApiCallReducer,
  openNotificationSuccess,
  openNotificationWarning,
  actions,
  industryData,
};
