import React, { useState } from "react";
import styled from "styled-components";
import { Typography, Col, Row } from "antd";
import LoginTopImage from "../assets/img/bannerImage.png";
import { CloseOutlined } from "@ant-design/icons";
import { CSSTransition } from "react-transition-group";
import "../App.scss";
import { myColor } from "../GlobalVar";
import BotTypeWriter from "./BotTypeWriter";
import { useEffect } from "react";

const StyledImage = styled.div`
  background: #d1deff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  background: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 4px;
  border: 1px solid gray;
  :hover {
    cursor: pointer;
    background: #ececec;
  }
`;

const BotTopBanner = props => {
  const [topBanner, setTopBanner] = useState(true);

  if (props.varticalCard == true) {
    return (
      <>
        <CSSTransition in={topBanner} timeout={500} classNames="alert" unmountOnExit>
          <div style={{ boxShadow: "0 0 10px rgba(0,0,0,0.3)", borderRadius: "5px" }}>
            <StyledImage>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  top: 50,
                }}
              >
                {props.avatar && (
                  <div
                    style={{
                      background: "#F8FAFE",
                      padding: "10px 5px",
                      width: 80,
                      height: 80,
                      overflow: "hidden",
                      borderRadius: 100,
                      marginLeft: 30,
                    }}
                  >
                    <img width="70" src={props.avatar} />
                  </div>
                )}
              </div>
              <div>
                <div style={{ position: "relative" }}>
                  <IconWrapper
                    title="Close"
                    onClick={() => {
                      setTopBanner(false);
                      if (props.showBot) {
                        props?.showBot(false);
                      }
                    }}
                  >
                    <CloseOutlined style={{ fontSize: 14 }} />
                  </IconWrapper>
                </div>
                <img
                  className="img-fluid"
                  src={LoginTopImage}
                  alt="Logo"
                  width={props.width}
                  height={props.height}
                />
              </div>
            </StyledImage>
            <div
              style={{
                background: "#F8FAFE",
                borderRadius: "5px",
                padding: "70px 30px 20px 30px",
                minHeight: 300,
              }}
            >
              <p style={{ fontSize: 20, fontFamily: "Inter-Regular", color: myColor.font_color }}>
                <BotTypeWriter title={props.title} content={props.content} />
                {/* {props.content} */}
              </p>
            </div>
          </div>
        </CSSTransition>
      </>
    );
  } else {
    return (
      <>
        <CSSTransition in={topBanner} timeout={500} classNames="alert" unmountOnExit>
          <StyledImage style={props.style}>
            <Row justify="center" align="middle" style={{ width: "100%" }}>
              <Col
                xl={3}
                lg={4}
                md={6}
                sm={24}
                xs={24}
                className="p-4 d-flex justify-content-center justify-content-lg-start"
              >
                {props.avatar && (
                  <>
                    <div
                      style={{
                        background: "#F8FAFE",
                        padding: "10px 5px",
                        width: 80,
                        height: 80,
                        overflow: "hidden",
                        borderRadius: 100,
                        // marginLeft: 30,
                      }}
                    >
                      <img width="70" src={props.avatar} />
                    </div>
                  </>
                )}
              </Col>
              <Col
                xl={17}
                lg={14}
                md={12}
                sm={24}
                xs={24}
                className="p-4 text-center text-lg-start"
              >
                <span
                  style={{ fontSize: 19, color: myColor.font_color, fontFamily: "Inter-Medium" }}
                >
                  <BotTypeWriter title={props.title} content={props.content} />
                  <Typography.Link
                    href={props.link}
                    style={{ color: myColor.blue, fontWeight: "bold", marginBottom: 0 }}
                  >
                    {" "}
                    {props.routeName}
                  </Typography.Link>
                </span>
              </Col>
              <Col xl={4} lg={4} md={6} sm={24} xs={24}>
                <img src={LoginTopImage} alt="Logo" width={props.width} height={props.height} />
              </Col>
            </Row>

            <div style={{ position: "relative" }}>
              <IconWrapper
                title="Close"
                onClick={() => {
                  setTopBanner(false);
                  if (props.showBot) {
                    props?.showBot(false);
                  }
                }}
              >
                <CloseOutlined style={{ fontSize: 14 }} />
              </IconWrapper>
            </div>
          </StyledImage>
        </CSSTransition>
      </>
    );
  }
};

export default BotTopBanner;
