import axios from "axios";

export const ideaValidation = axios.create({
  baseURL: `${process.env.REACT_APP_IDEA_VALIDATION}`,
  // baseURL: "http://192.168.18.224:4000/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

export const authModules = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_MODULES}`,
  // baseURL: "http://192.168.18.224:4001/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

export const marketResearch = axios.create({
  baseURL: `${process.env.REACT_APP_MARKET_RESEARCH}`,
  // baseURL: "http://192.168.88.8:4002/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});
