import React, { useState, useEffect, Suspense, useRef } from "react";
import { Avatar, Tooltip, Row, Col, Select } from "antd";
import cogoToast from "cogo-toast";
import styled from "styled-components";
import bot from "../../../assets/img/bot.png";
import TimeCost from "../../../assets/svg/time-cost.svg";
import Ideavalidation from "../../../assets/svg/idea-validation.svg";
import MarketResearch from "../../../assets/svg/market-research.svg";
import RequirementAnalysis from "../../../assets/svg/requirement-analysis.svg";
import UserJourneyFlow from "../../../assets/svg/user-journey.svg";
import MvpFeature from "../../../assets/svg/mvp-features.svg";
import ProductDevelopment from "../../../assets/svg/product-development.svg";
import PrototypeAnimate from "../../../assets/svg/prototype&animate.svg";
import BotTopBanner from "../../../components/BotTopBanner";
import DropdownMenu from "../../../components/DropdownMenu";
import "bootstrap/dist/css/bootstrap.min.css";
import { MainContainer } from "../../../components/MainContainer";
import { myColor } from "../../../GlobalVar";
import { connect, useSelector } from "react-redux";
import getAllProjects from "../../../redux/actions/getAllProjects";
import postCurrentUserProject from "../../../redux/actions/postCurrentUserProject";
import { useHistory } from "react-router-dom";
import IdeaNameModal from "../../../components/Modals/IdeaNameModal";
import { getCookie, createCookie } from "../../../utils/cookies";
import AddTeamMemberModal from "../../../components/Modals/AddTeamMemberModal";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import FullScreenLoader from "../../../components/FullScreenLoader";
import { BsPlusLg } from "react-icons/bs";
import DashboardCard from "../../../components/DashBoardCard";
// const DashboardCard = React.lazy(() => import('../../../components/DashBoardCard'));
import { withReducer } from "../../../redux/withReducer";
import { createApiCallReducer } from "../../../utils/helperFunctions";
import { SET_CURRENT_USER_PROJECT } from "../../../redux/constants/projects";
import { CheckOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { getMarketResearchByProjectId } from "../../../redux/actions/marketResearch/getMarketResearchByProjectId";
import { getMarketResearchDataReducer } from "../../../redux/reducers/marketResearch/targetMarket";
import getProjectReport from "../../../redux/actions/getProjectReport";
import { SEND_GENERATE_AI_REPORT } from "../../../redux/constants/ideaValidation";
import { GET_PROJECT_REPORT } from "../../../redux/constants/report";
import { getProjectById } from "../../../redux/actions/getProjectById";
import { getProjectByIdReducer, ratingModalReducer } from "../../../redux/reducers/projects";

const { Option } = Select;

const Dashboard = props => {
  const history = useHistory();
  const {
    data: getAllProjectsData,
    dataMember: getAllProjectDataMember,
    isLoading: getAllProjectsLoading,
    err: getAllProjectsErr,
    projectDataAdmin,
    projectDataMember,
  } = props.getAllProjectsState;

  const {
    data: userRoleData,
    isLoading: userRoleIsLoading,
    err: userRoleErr,
  } = props.setUserRoleReducer;

  const { isLoading: marketResearchLoading } = props?.marketResearchData;

  const [currentProject, setCurrentProject] = React.useState(null);
  const [currenctReportData, setCurrenctReportData] = useState("");
  const [ideaValidationStepsCompleted, setIdeaValidationStepsCompleted] = React.useState(false);
  const [ideaNameModal, setIdeaNameModal] = useState(false);
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [ideaValidationBtnText, setIdeaValidationBtnText] = useState("Start");
  const [marketResearchBtnText, setMarketResearchBtnText] = useState("Start");
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [userRight, setUserRight] = React.useState(getCookie("userRole"));
  const [reportPreparing, setReportPreparing] = useState(false);

  React.useEffect(() => {
    // props.resetMarketResearchData()
    if (getCookie("token")) {
      props.getMarketResearchData();
      props.getAllProjects();
      props.getProjectById();
      props.getProjectReport(getCookie("currentProject"));
    }
  }, []);

  // =====================CHECK IDEA VALIDATION STEPS COMPLETED OR NOT=====================
  useEffect(() => {
    if (
      props.projectReportReducer?.data?.projectDetails?.analysis &&
      props.projectReportReducer?.data?.projectDetails?.analysis != "" &&
      props.projectReportReducer?.data?.projectDetails?.AIPersonalities &&
      props.projectReportReducer?.data?.projectDetails?.AIPersonalities != ""
    ) {
      setCurrenctReportData(props.projectReportReducer?.data);
      setIdeaValidationStepsCompleted(true);
      // cogoToast.success(`You can view ${currentProject?.project?.title}'s idea validation report`);
      props.getAllProjects();
    } else {
      setIdeaValidationStepsCompleted(false);
      setCurrenctReportData("");
    }
  }, [props.projectReportReducer?.data]);
  // ==================================================

  useEffect(() => {
    if (getAllProjectsData) {
      // console.log("CALL HOJA BANDAR");
      props.getProjectReport(getCookie("currentProject"));
    }
  }, [getCookie("currentProject")]);

  useEffect(() => {
    props.getMarketResearchData();
  }, [props.addNewProjectState?.data]);

  // console.log("ideaValidationStepsCompleted===>>>>", ideaValidationStepsCompleted);
  // ========MARKET RESEARCH STEPS CHECKING==============
  useEffect(() => {
    if (props?.marketResearchData?.data && props?.marketResearchData?.data !== undefined) {
      const { data: marketResData } = props?.marketResearchData;
      let stepsName = [
        "targetMarket",
        "marketSize",
        "marketDemand",
        "competition",
        "barriersToEntry",
      ];

      stepsName.map(item => {
        marketResData[item].map(item1 => {
          if (
            item1.response == null ||
            item1.response?.length < 1 ||
            !marketResData?.targetMarketCheck ||
            !marketResData?.marketSizeCheck ||
            !marketResData?.marketDemandCheck ||
            !marketResData?.competitionCheck ||
            !marketResData?.barriersToEntryCheck
          ) {
            setMarketResearchBtnText("Continue");
          } else if (
            item1.response !== null &&
            marketResData?.targetMarketCheck &&
            marketResData?.marketSizeCheck &&
            marketResData?.marketDemandCheck &&
            marketResData?.competitionCheck &&
            marketResData?.barriersToEntryCheck
          ) {
            setMarketResearchBtnText("Completed");
          } else {
            setMarketResearchBtnText("Start");
          }
        });
      });
    } else {
      setMarketResearchBtnText("Start");
    }
  }, [props?.marketResearchData?.data, props.addNewProjectState]);
  // ==============================END===============

  // ===============GET IDEA VALIDATION BUTTON TEXT============
  const getBtnText = () => {
    if (currentProject) {
      let ideaVlidationStepsCompletedArr = Object.values(currentProject.project.steps);
      if (ideaVlidationStepsCompletedArr.every(v => v === false)) {
        setIdeaValidationBtnText("Start");
      } else if (
        ideaVlidationStepsCompletedArr.includes(true) &&
        !currentProject.project.emailSent &&
        currentProject?.project?.analysis == ""
      ) {
        setIdeaValidationBtnText("Continue");
        setTimeout(() => {
          window.location.reload();
          setReportPreparing(false);
        }, 50000);
      }
      if (reportPreparing) {
        setIdeaValidationBtnText("Preparing");
      }
      // else if (
      //   currentProject.project.emailSent &&
      //   currentProject?.ideaValSubmittedResponders < 5
      // ) {
      //   setIdeaValidationBtnText("Awaiting");
      // }
      else if (
        (currentProject.project.emailSent && currentProject?.ideaValSubmittedResponders >= 5) ||
        currentProject?.project?.analysis !== ""
      ) {
        setIdeaValidationBtnText("Completed");
      }
    } else {
      setIdeaValidationBtnText("Start");
    }
  };

  React.useEffect(() => {
    if (getAllProjectsData) {
      // console.log("HAN DATA BHI HAI");
      if (getAllProjectsData.length == 0) {
        // console.log("HAN LENGTH IS ZERO");

        setIdeaNameModal(true);
      } else if (getAllProjectsData.length == 1) {
        setCurrentProject(getAllProjectsData[0]);
        // console.log("REPORT KI API CALL HOWI HAI");
        createCookie("currentProject", getAllProjectsData[0].project._id);
      } else if (getAllProjectsData.length > 1) {
        let filterProject = getAllProjectsData.filter(
          val => val.project._id == getCookie("currentProject")
        );
        filterProject.length == 1 && setCurrentProject(filterProject[0]);
      }
    }
  }, [getAllProjectsData]);

  React.useEffect(() => {
    setUserRight(getCookie("userRole"));

    if (getAllProjectsData) {
      if (getAllProjectsData.length == 0) {
        setIdeaNameModal(true);
      } else if (getAllProjectsData.length === 1) {
        setCurrentProject(getAllProjectsData[0]);
        createCookie("currentProject", getAllProjectsData[0].project._id);
      } else if (getAllProjectsData.length > 1) {
        // let filterProject = getAllProjectsData.filter(val => val.project._id == getCookie('currentProject'))
        // filterProject.length == 1 && setCurrentProject(filterProject[0])
        let isAdminProject =
          projectDataAdmin.length > 0
            ? projectDataAdmin.find(val => val.project._id == getCookie("currentProject"))
            : null;
        let isMemberProject =
          projectDataMember.length > 0
            ? projectDataMember.find(val => val.project._id == getCookie("currentProject"))
            : null;
        if (isAdminProject && getCookie("userRole") == "member") {
          if (projectDataMember.length > 0) {
            props.postCurrentUserProject({ projectId: projectDataMember[0].project._id });
            setCurrentProject(projectDataMember[0]);
          }
        } else if (isMemberProject && getCookie("userRole") == "admin") {
          if (projectDataAdmin.length > 0) {
            props.postCurrentUserProject({ projectId: projectDataAdmin[0].project._id });
            setCurrentProject(projectDataAdmin[0]);
          }
        }
      }
    }
  }, [userRoleData]);

  const changeProject = idx => {
    props.postCurrentUserProject({ projectId: getAllProjectsData[idx].project._id });
    setCurrentProject(getAllProjectsData[idx]);
    // if (
    //   getAllProjectsData[idx]?.project?.analysis &&
    //   getAllProjectsData[idx]?.project?.analysis != "" &&
    //   getAllProjectsData[idx]?.project?.AIPersonalities &&
    //   getAllProjectsData[idx]?.project?.AIPersonalities != ""
    // ) {
    props.getProjectReport(getAllProjectsData[idx].project._id);
    // }

    cogoToast.success(
      `You switch project ${currentProject && currentProject.project.title} to ${
        getAllProjectsData[idx].project.title
      }`
    );
  };

  React.useEffect(() => {
    currentProject && getBtnText();
  }, [currentProject, props.projectReportReducer?.data]);

  let dashboardCard = [
    {
      id: 1,
      text: "Idea Validation",
      prcnt: `${currentProject ? currentProject.status : 0}`,
      img: Ideavalidation,
      route: "/ideaValidation",
      linkRoute: "/report",
      btnText: ideaValidationBtnText,
      reportData: currenctReportData,
      showViewReport: ideaValidationStepsCompleted,
      secondaryText:
        currentProject && currentProject.ideaValTotalResponders > 0
          ? `${currentProject.ideaValSubmittedResponders} out of ${currentProject.ideaValTotalResponders} respondent`
          : "",
      secondaryTextShow: currentProject?.project?.emailList?.length > 0,
      info: `Idea Validation Module: Simplifying idea validation through a 5-Factor assessment — Problem Relevance, Solution Usefulness, Solution Uniqueness, Likeliness to Promote, and Willingness to Buy. Enhanced by metrics like Power to Succeed, Intensity of the Problem, WOW Factor, Problem-Solution Impact, and Idea Propagation Score. Validatr's AI impersonates your target market, gathering insights from VC Executives and experts. Add team members, collect ratings on the idea via surveys, and receive a comprehensive post-validation report for informed decisions.`,
    },
    {
      id: 2,
      text: "Market Research",
      img: MarketResearch,
      route: "/dashboard",
      lockIcon: "LockOutlined",
      linkRoute: "/",
      // btnText: marketResearchBtnText,
      info: `Market Research Module (Under-development): Uncover five key steps for deep business landscape insights. Whether B2B or B2C, start with Target Market identification. Then, measure Market Size, assess Market Demand, analyze Competition, and spot Barriers to Entry. Receive a comprehensive report of these insights at the module's end.`,
    },
    {
      id: 3,
      text: "Requirement Analysis",
      img: RequirementAnalysis,
      route: "/",
      linkRoute: "/",
      lockIcon: "LockOutlined",
      info: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
              molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
              numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
              optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
              obcaecati tenetur iure eius earum ut molestias`,
    },
    {
      id: 4,
      text: "MVP Features ",
      img: MvpFeature,
      route: "/",
      linkRoute: "/",
      lockIcon: "LockOutlined",
      info: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
              molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
              numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
              optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
              obcaecati tenetur iure eius earum ut molestias`,
    },
    {
      id: 5,
      text: "User Journey Flow",
      img: UserJourneyFlow,
      route: "/",
      linkRoute: "/",
      lockIcon: "LockOutlined",
      info: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
              molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
              numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
              optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
              obcaecati tenetur iure eius earum ut molestias`,
    },
    {
      id: 6,
      text: "Time Cost Analysis",
      img: TimeCost,
      route: "/",
      linkRoute: "/",
      lockIcon: "LockOutlined",
      info: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
              molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
              numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
              optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
              obcaecati tenetur iure eius earum ut molestias`,
    },
    {
      id: 7,
      text: "Prototype and Animate",
      img: PrototypeAnimate,
      route: "/",
      linkRoute: "/",
      lockIcon: "LockOutlined",
      info: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
              molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
              numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
              optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
              obcaecati tenetur iure eius earum ut molestias`,
    },
    {
      id: 8,
      text: "Product Development",
      img: ProductDevelopment,
      route: "/",
      linkRoute: "/",
      lockIcon: "LockOutlined",
      info: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
              molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
              numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
              optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
              obcaecati tenetur iure eius earum ut molestias`,
    },
  ];

  React.useEffect(() => {
    console.log("reportPreparing", reportPreparing);
    if (props.postGenerateAIReportReducer.isLoading) {
      cogoToast.loading("Loading...", {
        hideAfter: 0,
      });
      setReportPreparing(false);
    } else if (props.postGenerateAIReportReducer?.data?.data) {
      setReportPreparing(true);
      setTimeout(() => {
        props.getProjectReport(getCookie("currentProject"));
      }, 30000);
      let getCogoToast = document.getElementsByClassName("ct-toast-loading");
      for (let i = 0; i < getCogoToast.length; i++) {
        getCogoToast[i].style.display = "none";
      }
      cogoToast.info(
        "Your report is being processed by our AI. You will be able to view it later."
      );
      window.scrollTo(0, 0);
      props.removeDataFromGenerateAIReport(); //Remove data from reducer
    } else if (props.postGenerateAIReportReducer.err) {
      setReportPreparing(false);
      let getCogoToast = document.getElementsByClassName("ct-toast-loading");
      for (let i = 0; i < getCogoToast.length; i++) {
        getCogoToast[i].style.display = "none";
      }
      cogoToast.error("Something went wrong");
    }
  }, [props.postGenerateAIReportReducer, reportPreparing]);

  return (
    <>
      {(getAllProjectsData == null && getAllProjectsLoading) ||
        (props.projectReportReducer?.isLoading && <FullScreenLoader />)}
      <MainContainer>
        <BotTopBanner
          style={{ marginBottom: 30 }}
          bg={"#d1deff"}
          // title={`Welcome ${userName}!`}
          title={`"Welcome to the Suite of Validation Modules".`}
          content="Choose what you want to work on and i'll help you get started."
          avatar={bot}
          width="100%"
          // height="120px"
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DropdownMenu
            width="200px"
            // menu={getAllProjectsData}
            placeholder="Select Project"
            listHeight={200}
            menu={
              getAllProjectsData?.length > 0 &&
              getAllProjectsData.map((val, idx) => {
                return <Option key={idx}>{val.project.title}</Option>;
              })
            }
            // changeProject={changeProject}
            // currentProject={currentProject && currentProject}
            changeVal={changeProject}
            currentVal={currentProject && currentProject}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              flexWrap: "wrap",
              width: "70%",
            }}
          >
            {currentProject?.team?.firstNameList.map((val, idx) => {
              // const initial = item.name.slice(0, 1)
              if (idx < 3) {
                return (
                  <Avatar.Group maxCount={3} key={idx}>
                    <Tooltip title={val}>
                      <Avatar
                        size={40}
                        style={{
                          background: myColor.blue,
                          border: `1px solid ${myColor.blue}`,
                          color: "#ffff",
                          margin: "0 5px",
                          fontWeight: "bold",
                        }}
                      >
                        {val.charAt(0).toUpperCase()}
                      </Avatar>
                    </Tooltip>
                  </Avatar.Group>
                );
              }
            })}
            {userRight == "admin" && (
              <Tooltip title="Add Team Member" onClick={() => setAddTeamModal(true)}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 40,
                    width: 40,
                    borderRadius: 100,
                    border: `2px solid ${myColor.blue}`,
                    cursor: "pointer",
                  }}
                >
                  <BsPlusLg style={{ color: myColor.blue }} />
                </div>
                {/* <AvatarWrapper
                  size={40}
                  style={{
                    background: "#fff",
                    border: `1px solid ${myColor.blue}`,
                    color: myColor.blue,
                    // margin: "0 5px",
                    // display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  +
                </AvatarWrapper> */}
              </Tooltip>
            )}
          </div>
        </div>
        {/* <CardsContainer> */}
        <RowWrapper style={{ marginTop: "40px" }} gutter={{ xs: 8, sm: 8, md: 24, lg: 32 }}>
          {dashboardCard.map((val, index) => {
            return (
              // <div>
              <Col className="gutter-row" key={index}>
                <DashboardCard
                  id={val.id}
                  isLoading={marketResearchLoading || getAllProjectsLoading}
                  disabled={marketResearchLoading || getAllProjectsLoading}
                  secondaryTextShow={val.secondaryTextShow}
                  reportData={val.reportData}
                  lockIcon={val.lockIcon}
                  text={val.text}
                  prcnt={val.prcnt}
                  img={val.img}
                  pathname={val.route}
                  info={val.info}
                  onClick={() => {
                    if (val.btnText == "Completed" && val.text == "Idea Validation") {
                      cogoToast.info("You are in Read-only Format");
                    }
                  }}
                  moduleData={currentProject}
                  btnText={
                    val.btnText == "Completed" ? (
                      <p
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        Completed <CheckOutlined style={{ marginLeft: 4 }} />
                      </p>
                    ) : val.btnText == "Continue" ? (
                      <p
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        Continue <EditOutlined style={{ marginLeft: 4 }} />
                      </p>
                    ) : val.btnText === "Preparing" ? (
                      <p
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        Preparing <LoadingOutlined style={{ marginLeft: 4, fontSize: 18 }} />
                      </p>
                    ) : (
                      val.btnText
                    )
                  }
                  btnTextColor={"#fff"}
                  btnBorder={
                    val.btnText == "Completed"
                      ? "0px"
                      : val.btnText == "Start"
                      ? "0px"
                      : val.btnText == "Continue"
                      ? "1x solid lightgray"
                      : "1x solid lightgray"
                  }
                  btnBgColor={
                    val.btnText == "Completed"
                      ? myColor.green
                      : val.btnText == "Start"
                      ? myColor.blue
                      : val.btnText == "Continue"
                      ? myColor.yellow
                      : val.btnText == "Preparing"
                      ? myColor.yellow
                      : "#f7f6ff"
                  }
                  secondaryText={
                    val.text == "Idea Validation" ? val.secondaryText : "15 out of 25 respondent"
                  }
                  linkRoute={val.linkRoute}
                  userRight={userRight}
                  showViewReport={val.showViewReport}
                />
              </Col>
              // </div>
            );
          })}
        </RowWrapper>
        {/* </CardsContainer> */}

        <IdeaNameModal
          visible={ideaNameModal}
          setIdeaNameModal={setIdeaNameModal}
          avatar={bot}
          width={600}
          notClose={getAllProjectsData && getAllProjectsData.length > 0 ? false : true}
        />

        <AddTeamMemberModal
          visible={addTeamModal}
          setAddTeamModal={setAddTeamModal}
          setConfirmModalState={setConfirmModalState}
          avatar={bot}
          callProjectData={false}
          width={600}
          projectId={getCookie("currentProject")}
        />

        <ConfirmModal
          visible={confirmModalState}
          setConfirmModalState={setConfirmModalState}
          width={500}
          message="Team member successfully added."
        />
      </MainContainer>
    </>
  );
};

const mapStateToProps = state => {
  // injectAsyncReducer('dumyReducer',dumyReducer)
  return {
    marketResearchData: state.getMarketResearchDataReducer,
    getAllProjectsState: state.getAllProjectReducer,
    addNewProjectState: state.setCurrentUserProjectReducer,
    getUserProfileReducer: state.getUserProfileReducer,
    setUserRoleReducer: state.setUserRoleReducer,
    postGenerateAIReportReducer: state.postGenerateAIReportReducer,
    projectReportReducer: state.getProjectReportReducer,
    getProjectByIdState: state.getProjectByIdReducer,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getMarketResearchData: () => dispatch(getMarketResearchByProjectId()),
    getAllProjects: () => dispatch(getAllProjects()),
    postCurrentUserProject: data => dispatch(postCurrentUserProject(data)),
    resetMarketResearchData: () =>
      dispatch({ type: "GET_MARKET_RESEARCH_BY_PROJECT_SUCCESS", payLoad: null }),
    getProjectReport: projectId => dispatch(getProjectReport(projectId)),
    getProjectById: () => dispatch(getProjectById()),
    removeDataFromGenerateAIReport: () =>
      dispatch({
        type: "SEND_GENERATE_AI_REPORT_SUCCESS",
        payload: null,
      }),
  };
};

export default withReducer([
  { key: "getProjectByIdReducer", reducer: getProjectByIdReducer },
  { key: "getProjectReportReducer", reducer: createApiCallReducer(GET_PROJECT_REPORT) },
  { key: "getMarketResearchDataReducer", reducer: getMarketResearchDataReducer },
  { key: "setCurrentUserProjectReducer", reducer: createApiCallReducer(SET_CURRENT_USER_PROJECT) },
  { key: "ratingModal", reducer: ratingModalReducer },
  {
    key: "postGenerateAIReportReducer",
    reducer: createApiCallReducer(SEND_GENERATE_AI_REPORT),
  },
])(connect(mapStateToProps, mapDispatchToProps)(Dashboard));

const CardsContainer = styled.div`
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row;
`;

const RowWrapper = styled(Row)`
  .ant-col {
    width: 33%;
    @media only screen and (min-width: 700px) and (max-width: 1200px) {
      width: 50%;
      justify-content: space-between !important;
    }
    @media only screen and (max-width: 699px) {
      width: 100%;
      // justify-content: center !important;
    }
  }

  // @media only screen and (max-width: 7px) {
  //     width: 50%;
  //     justify-content: center !important;
  //   }
`;
const AvatarWrapper = styled(Avatar)`
  :hover {
    cursor: pointer;
  }

  .ant-avatar-string {
    line-height: 32px !important;
    font-size: 30px;
  }
`;
