import React, { useState } from "react";
import { Typography, Row, Col, Form, Alert } from "antd";
import InputFeild from "../components/InputField";
import CardTopImage from "../components/CardTopImage";
import MyButton from "../components/MyButton";
import { myColor } from "../GlobalVar";
import { connect, useStore } from "react-redux";
import postJoinTeamNewMember from "../redux/actions/postJoinTeamNewMember";

import { withReducer } from "../redux/withReducer";
import { createApiCallReducer, removeAsyncReducers } from "../utils/helperFunctions";
import { JOIN_NEW_TEAM_MEMBER } from "../redux/constants/teams";
import HomeCardButton from "../components/homeCardButton";

const JoinTeamNew = props => {
  const store = useStore();

  const [newMemberPassword, setNewMemberPassword] = useState({ password: "" });
  const [joinTeam, setJoinTeam] = useState(null);

  const {
    data: joinNewTeamMemberData,
    isLoading: joinNewTeamMemberIsLoding,
    err: joinNewTeamMemberErr,
  } = props.joinNewTeamMemberState;

  React.useEffect(() => {
    console.log("Join Team Mounted > ");
    props.notShowExtraContent();
    return () => {
      removeAsyncReducers(store, ["joinNewTeamMemberReducer"]);
      props.showExtraContent();
    };
  }, []);

  React.useEffect(() => {
    if (props.match.params.memberId && props.match.params.teamId) {
      setJoinTeam(props.match.params);
    }
  }, []);

  React.useEffect(() => {
    if (joinNewTeamMemberData) {
      props.history.push("/");
    }
  }, [joinNewTeamMemberData]);

  const handleSubmit = () => {
    props.postJoinTeamNewMember(joinTeam.memberId, joinTeam.teamId, newMemberPassword);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setNewMemberPassword({ ...newMemberPassword, [name]: value });
  };

  return (
    <div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: 40, marginBottom: "50px" }}>
          {joinTeam ? "Join Team" : "Reset Password!"}
        </h1>
        <div
          style={{
            width: "30%",
            height: "auto",
            backgroundColor: "#fff",
            boxShadow: "0 0 10px rgba(0,0,0,0.3)",
            borderRadius: "5px",
          }}
        >
          <div style={{ width: "100%" }}>
            {/* ===================Card Top Image=====================     */}
            <CardTopImage
              title={"Don't worry"}
              content={"Joining team is easy, just set your password and contribute to project"}
              link={"/"}
              routeName="validatr.io"
              width="100%"
              height="140px"
            />
          </div>

          {/* ===================Form Card========================== */}

          <div style={{ padding: "30px" }}>
            {/* {handling ?
              <div style={{ marginBottom: 20 }}>
                <Alert message={handling} type={messageType} showIcon />
              </div>
              : ''
            } */}
            <Form onFinish={handleSubmit}>
              <Form.Item
                name="Password"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter min 8 character password",
                    min: 8,
                  },
                ]}
                validateFirst={false}
              >
                <InputFeild
                  label="Password"
                  name="password"
                  type="password"
                  value={newMemberPassword.password}
                  placeholder="***********"
                  onChange={handleChange}
                  onKeyPress={e => e.which === 13 && handleSubmit()}
                  style={{ margin: 0 }}
                />
              </Form.Item>

              <HomeCardButton
                style={{ marginTop: 30 }}
                htmlType="submit"
                text={"Join"}
                width="100%"
                bg={`${myColor.blue}`}
                isLoading={joinNewTeamMemberIsLoding}
                // onClick={handleSubmit}
              />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    joinNewTeamMemberState: state.joinNewTeamMemberReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postJoinTeamNewMember: (memberId, teamId, data) =>
      dispatch(postJoinTeamNewMember(memberId, teamId, data)),
    notShowExtraContent: () => dispatch({ type: "HEADER_FOOTER_HIDE" }),
    showExtraContent: () => dispatch({ type: "HEADER_FOOTER_NOT_HIDE" }),
  };
};

export default withReducer([
  { key: "joinNewTeamMemberReducer", reducer: createApiCallReducer(JOIN_NEW_TEAM_MEMBER) },
])(connect(mapStateToProps, mapDispatchToProps)(JoinTeamNew));
