export const SEND_IDEA_VALIDATION_CONFIRM_MODAL = {
  OPEN: "SEND_IDEA_VALIDATION_CONFIRM_MODAL_OPEN",
  CLOSE: "SEND_IDEA_VALIDATION_CONFIRM_MODAL_CLOSE",
};

export const GENERATE_MARKET_RESEARCH_REPORT_CONFIRM_MODAL = {
  OPEN: "GENERATE_MARKET_RESEARCH_REPORT_CONFIRM_MODAL_OPEN",
  CLOSE: "GENERATE_MARKET_RESEARCH_REPORT_CONFIRM_MODAL_CLOSE",
};

export const QUESTION_INFO_MODAL = {
  OPEN: "QUESTION_INFO_MODAL_OPEN",
  CLOSE: "QUESTION_INFO_MODAL_CLOSE",
};
export const RATING_MODAL = {
  OPEN: "OPEN_RATING_MODAL",
  CLOSE: "CLOSE_RATING_MODAL",
};
