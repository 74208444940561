import React from "react";
import styled from "styled-components";
import { Typography } from "antd";
import LoginTopImage from "../assets/img/bannerImage.png";
import { CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { myColor } from "../GlobalVar";

const StyledImage = styled.div`
  background: #f8fafe;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
`;

const IconWrapper = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const CardTopImage = props => {
  return (
    <StyledImage style={props.style}>
      <div
        style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "60%" }}
      >
        {props.avatar && (
          <>
            <div
              style={{
                background: "#d1deff",
                padding: "10px 5px",
                width: 80,
                height: 80,
                overflow: "hidden",
                borderRadius: 100,
                marginLeft: 30,
              }}
            >
              <img width="70" src={props.avatar} />
            </div>
          </>
        )}

        <div
          style={{
            paddingTop: 30,
            paddingLeft: 30,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: 20, fontWeight: 600, color: myColor.black, marginBottom: 0 }}>
            {props.title}
          </span>
          <p style={{ fontSize: 16, fontWeight: 400, color: myColor.black }}>
            {props.content}
            <br />
            <Link
              to={props.link}
              style={{ color: myColor.blue, fontWeight: "bold", marginBottom: 0 }}
            >
              {" "}
              {props.routeName}
            </Link>
          </p>
        </div>
      </div>

      <div style={{ width: "40%" }}>
        <img src={LoginTopImage} alt="Logo" width={props.width} height={props.height} />
      </div>
    </StyledImage>
  );
};

export default CardTopImage;
