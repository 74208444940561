import React from "react";
import Typewriter from "typewriter-effect";

const BotTypeWriter = props => {
  return (
    <Typewriter
      options={{
        delay: 60,
      }}
      onInit={typewriter => {
        props.title && typewriter.typeString(`<p><strong>${props.title}</strong></p>`);
        typewriter
          .typeString(`${props.content || ""}`)
          .pauseFor(2500)
          .callFunction(() => {
            props.setTypeWriter && props.setTypeWriter(false);
            return null;
          })
          .start();
      }}
    />
  );
};

export default BotTypeWriter;
