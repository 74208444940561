import React, { useEffect, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Note,
  PDFViewer,
  Canvas,
  Font,
  Image,
} from "@react-pdf/renderer";
import bottomImage from "../../../../assets/img/Report-image.png";
import bgImage from "../../../../assets/img/project-cover-Backgroun.png";
import strengthImg from "../../../../assets/img/strengths-icon.png";
import opportunityImg from "../../../../assets/img/opportunities-icon.png";
import weaknesImg from "../../../../assets/img/weaknesses-icon.png";
import threatImg from "../../../../assets/img/threats-icon.png";
import lowChart from "../../../../assets/img/lowchart.png";
import mediumChart from "../../../../assets/img/mediumchart.png";
import highChart from "../../../../assets/img/highchart.png";
import veryHighChart from "../../../../assets/img/veryHighchart.png";
import ValidatrLogo from "../../../../assets/img/Validator-watermark-color.png";
import BorderImg from "../../../../assets/img/boder-img.png";
import Page1Img from "../../../../assets/img/page-1.png";
import Page1HeadingImg from "../../../../assets/img/page1_heading.png";
import FooterLine from "../../../../assets/img/Footer-Line.png";
import TargetMarketHead from "../../../../assets/img/target_market/one.png";
import TargetMarketBottom from "../../../../assets/img/target_market/two.png";
import MarketSizeHead from "../../../../assets/img/Market-Size-Assets/Header-Market-Size.png";
import MarketSizeIcon1 from "../../../../assets/img/Market-Size-Assets/TotalMarketVolume.png";
import MarketSizeIcon2 from "../../../../assets/img/Market-Size-Assets/TargetMarketVolume.png";
import MarketSizeIcon3 from "../../../../assets/img/Market-Size-Assets/MarketPenetration.png";
import MarketSizeIcon4 from "../../../../assets/img/Market-Size-Assets/Market-Value.png";
import MarketSizeIcon5 from "../../../../assets/img/Market-Size-Assets/MarketValue01.png";
import MarketDemandIcon1 from "../../../../assets/img/MarketDemand/MarketDemand.png";
import MarketDemandBottom from "../../../../assets/img/MarketDemand/MarketDemandBottom.png";
import MarketDemandHeading from "../../../../assets/img/MarketHeading.png";
import MarketDemandIcon2 from "../../../../assets/img/MarketDemand/06.png";
import MarketDemandIcon3 from "../../../../assets/img/MarketDemand/07.png";
import MarketDemandIcon4 from "../../../../assets/img/MarketDemand/08.png";
import MarketDemandIcon5 from "../../../../assets/img/MarketDemand/09.png";
import MarketDemandIcon6 from "../../../../assets/img/MarketDemand/10.png";
import SWOTImg from "../../../../assets/img/SWOT.png";
import BarrierstoEntry from "../../../../assets/img/BarrierstoEntry.png";
import CompetitionAnalysis from "../../../../assets/img/CompetitionAnalysis.png";
import ValidatrLogoPng from "../../../../assets/img/validatorLogo.png";

import BoxImage from "../../../../assets/img/All-Pages-Top.png";

import moment from "moment";
import robotoBold from "../../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import robotoMedium from "../../../../assets/fonts/Roboto/Roboto-Medium.ttf";
import robotoRegular from "../../../../assets/fonts/Roboto/Roboto-Regular.ttf";
import robotoLight from "../../../../assets/fonts/Roboto/Roboto-Light.ttf";
import robotoItalic from "../../../../assets/fonts/Roboto/Roboto-Italic.ttf";

Font.register({
  family: "roboto",
  format: "truetype",
  fonts: [
    {
      src: robotoBold,
      fontWeight: "bold",
    },
    {
      src: robotoMedium,
    },
    {
      src: robotoRegular,
    },
    {
      src: robotoLight,
      fontWeight: "light",
    },
    {
      src: robotoItalic,
      fontWeight: "light",
      fontStyle: "italic",
    },
  ],
});

const glossaryData1 = [
  {
    title: "Accessible customer",
    description: "= total market volume x % of customers who have access to the product",
  },
  {
    title: "Market value",
    description: "= accessible customers x % of the customers accessible through marketing budget.",
  },
  { title: "Total Market Value", description: "= accessible customers x average price" },
  { title: "Accessible Market Value", description: "= market value x average price" },
  {
    title: "Students:",
    description:
      "Dependable, under learning with objective of acquiring knowledge, enthusiastic and abstain the need of continuous change.",
  },
  {
    title: "Entry-level employees:",
    description:
      "Learners, minimal experienced/ fresh candidates, developing young professionals who intend to achieve employment at desired field.",
  },
  {
    title: "Skilled Workers:",
    description:
      "Skilful, Engineers, Craftsmen, experts, professionals, in-demand, creative, have special skill, training, knowledge that can be applied to their work.",
  },
  {
    title: "Managers:",
    description:
      "Planners, Organizers, Team leaders, possess adequate knowledge and experience in professional environment, confident in their abilities, experienced professionals, and decision makers.",
  },
  {
    title: "Senior Executives:",
    description:
      "Highly Experienced, Influencers, Leaders, approachable “People Persons” and Calculated Risk Takers and strong decision makers",
  },
  {
    title: "Homemakers:",
    description:
      "Self-Managers, Intelligent, Committed, Imaginative, Enthusiastic, perseverance nature, adoptable, resourceful,",
  },
  {
    title: "Retired Personnel:",
    description:
      "Experienced, seniors, Investors, Fulfilled, Enjoy life, Travelers, Family persons, pensioner, residential, social.",
  },
  {
    title: "Low-level Income group:",
    description: "mostly the lower-level staff like clerks, housekeeping, security guards — etc.",
  },
  {
    title: "Mid-level Income group:",
    description:
      "in general; freshly employed, management trainees, graduate trainees, technicians, technical service providers, accountants — etc.",
  },
  {
    title: "High-level Income group:",
    description:
      "in general; managers, senior executives, head of departments, directors, C-level executives— etc.",
  },
  {
    title: "Industry Trend - Stable:",
    description:
      "This means that the targeted product/service has passed the growth phase. The earnings and sales tend to grow slower in stable/mature industries as compared to growing industry.",
  },
  {
    title: "Industry Trend - Volatile:",
    description:
      "This means that the industry trend for the targeted product/service is unpredictable. It is necessary for some movement within the market to sell commodities, however a volatile market represents the most risk to investors.",
  },
  {
    title: "Industry Trend - Growing:",
    description:
      "This means that the market related to the potential product/service is expanding and competition continues to develop. Growth industries are often associated with new or pioneer industries that did not exist in the past.",
  },
  {
    title: "Industry Trend - Declining:",
    description:
      "This means that the targeted product/service-related industry is experiencing negative growth or remains stagnant due to decline in their demand for varied reasons.",
  },
  {
    title: "Price:",
    description:
      "The product or service being offered is at a great price by targeting the cost aspect of it, so as compared to competitors and substitutes, a good market share is likely to be captured.",
  },
];
const glossaryData2 = [
  {
    title: "Quality:",
    description:
      "The quality or product or service being offered should be impeccable to gain competitive edge.",
  },
  {
    title: "Technology:",
    description: "The product or service is being offered with advanced technological features.",
  },
  {
    title: "Customer Service:",
    description:
      "The product or service being offered provides value added services to make the business stand-out, such as being readily responsive to customers concerns.",
  },
  {
    title: "Accessibility:",
    description: "The product or service is accessible to its target market.",
  },
  {
    title: "Performance",
    description:
      "The performance of offered product or service can achieve the desired outcome in terms of sales volume and revenues generated.",
  },
];
const swotData = [
  {
    title: "Strengths:",
    description:
      "Characteristics of product or service that give a competitive advantage over others. E.g. Highly skilled labour, strong marketing strategy, etc.",
  },
  {
    title: "Weakness:",
    description:
      "Characteristics of product or service that gives business disadvantage to its potential competitors, e.g., limited resources, lack of distribution channels.",
  },
  {
    title: "Opportunities:",
    description:
      "External factors that improve efficiency / product demand. E.g. Rising marketing trends.",
  },
  {
    title: "Threats:",
    description:
      "External factors that may be out of control. E.g. Change in regulation, change in consumer behaviour.",
  },
];

// Create Document Component
const MarketResearchReport = props => {
  const {
    businessModel,
    targetMarket,
    marketSize,
    marketDemand,
    competition,
    barriersToEntry,
  } = props?.data;

  const ListItem = ({ children }) => {
    return (
      <View style={styles.row}>
        <View style={styles.bullet}>
          <Text style={styles.bullitPara}>{"\u2022" + " "}</Text>
        </View>
        <Text style={styles.bullitPara}>{children}</Text>
      </View>
    );
  };

  const calculateCompetinces = (data, showValue) => {
    let getSumValue = data?.reduce((item, prevItem) => {
      return item + parseInt(prevItem.value);
    }, 0);
    let getTotalValue = getSumValue / data?.length;

    if (getTotalValue <= 25) {
      return (
        <View
          style={{
            backgroundColor: "#E3FFF1",
            padding: 8,
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <Text style={[styles.highlitFont, { color: "#36B24A", fontSize: 10 }]}>
            {showValue ? `Low Competition` : `Low`}
          </Text>
        </View>
      );
    } else if (getTotalValue > 25 && getTotalValue <= 50) {
      return (
        <View
          style={{
            backgroundColor: "#fbf6d9",
            color: "#F6BF0A",
            padding: 8,
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <Text style={[styles.highlitFont, { color: "#F6BF0A", fontSize: 10 }]}>
            {showValue ? `Medium Competition` : `Medium`}
          </Text>
        </View>
      );
    } else if (getTotalValue > 50 && getTotalValue <= 75) {
      return (
        <View
          style={{
            backgroundColor: "#FFE9D4",
            color: "#E1861B",
            padding: 8,
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <Text style={[styles.highlitFont, { color: "#E1861B", fontSize: 10 }]}>
            {showValue ? `High Competition` : `High`}
          </Text>
        </View>
      );
    } else if (getTotalValue > 75) {
      return (
        <View
          style={{
            backgroundColor: "#FCEBEA",
            color: "#E92A20",
            padding: 8,
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <Text style={[styles.highlitFont, { color: "#E92A20", fontSize: 10 }]}>
            {showValue ? `Very High Competition` : `Very High`}
          </Text>
        </View>
      );
    }
  };

  const ShowSliderValues = value => {
    if (value == "0") {
      return <Text style={[styles.value, { color: "#36B24A" }]}>Low</Text>;
    } else if (value == "33") {
      return <Text style={[styles.value, { color: "#F6BF0A" }]}>Medium</Text>;
    } else if (value == "66") {
      return <Text style={[styles.value, { color: "#E1861B" }]}>High</Text>;
    } else if (value == "99") {
      return <Text style={[styles.value, { color: "#E92A20" }]}>Very High</Text>;
    }
  };

  const calculateCompetincesChart = data => {
    let getSumValue = data?.reduce((item, prevItem) => {
      return item + parseInt(prevItem.value);
    }, 0);
    let getTotalValue = getSumValue / data?.length;

    if (getTotalValue <= 25) {
      return (
        <View>
          <Text style={{ fontSize: 14, fontWeight: "bold", width: "30%" }}>
            Your product/service Competitveness in the market is:
          </Text>
          <Image src={lowChart} style={{ width: 150, height: 120 }} />
        </View>
      );
    } else if (getTotalValue > 25 && getTotalValue <= 50) {
      return (
        <View>
          <Text style={{ fontSize: 14, fontWeight: "bold", width: "30%" }}>
            Your product/service Competitveness in the market is:
          </Text>
          <Image src={mediumChart} style={{ width: 150, height: 120 }} />
        </View>
      );
    } else if (getTotalValue > 50 && getTotalValue <= 75) {
      return (
        <View>
          <Text style={{ fontSize: 14, fontWeight: "bold", width: "30%" }}>
            Your product/service Competitveness in the market is:
          </Text>
          <Image src={highChart} style={{ width: 150, height: 120 }} />
        </View>
      );
    } else if (getTotalValue > 75) {
      return (
        <View>
          <Text style={{ fontSize: 14, fontWeight: "bold", width: "30%" }}>
            Your product/service Competitveness in the market is:
          </Text>
          <Image src={veryHighChart} style={{ width: 150, height: 120 }} />
        </View>
      );
    }
  };

  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US");

  const WaterMark = () => {
    return (
      <View
        style={{
          position: "absolute",
          top: "45%",
          height: "100%",
          width: "100%",
          zIndex: 1,
        }}
        render={({ pageNumber, totalPages }) => {
          return (
            <Image
              src={ValidatrLogo}
              style={{
                transform: "rotate(-65deg)",
                width: 700,
                zIndex: 2,
              }}
            />
          );
        }}
        fixed
      ></View>
    );
  };

  return (
    // <PDFViewer style={styles.pdfViewer}>
    <Document>
      <Page size="A4">
        <Image src={bgImage} width={"100%"} height={"100%"} style={styles.backgroundImage} />
        <Image
          src={ValidatrLogoPng}
          style={{
            width: 150,
            position: "absolute",
            top: 100,
            left: 60,
          }}
        />
        <Text
          style={{
            zIndex: 2,
            position: "absolute",
            top: 200,
            left: 60,
            fontSize: 28,
            fontWeight: 600,
            fontFamily: "roboto",
          }}
        >
          Market Research Report
        </Text>
        <Text
          style={[
            styles.para,
            {
              fontSize: 18,
              fontFamily: "roboto",
              fontStyle: "italic",
              position: "absolute",
              top: 250,
              left: 120,
            },
          ]}
        >
          for
        </Text>
        <Text
          style={{
            fontSize: 21,
            fontFamily: "roboto",
            position: "absolute",
            fontWeight: 600,
            top: 290,
            left: 60,
          }}
        >
          "{props?.currentProjectData?.title}"
        </Text>

        <Text
          style={[
            styles.para,
            {
              position: "absolute",
              top: 330,
              left: 60,
              fontSize: 14,
              fontFamily: "roboto",
              color: "#686868",
            },
          ]}
        >
          Date: {moment(props?.currentProjectData?.createdAt).format("DD MMM YYYY")}
        </Text>
      </Page>
      {/* ================Table of content==================== */}
      <Page size="A4" style={{ paddingHorizontal: 60, paddingVertical: 50 }}>
        <View style={{}}>
          <Text style={styles.title25}>Table of Contents</Text>
          <View style={{ width: "100%", height: 1, backgroundColor: "#3C4048", marginTop: 10 }} />
        </View>

        <View style={styles.headingRow}>
          <View>
            <Text style={styles.title20}>Market Research</Text>
            <View
              style={{ width: "100%", height: 1.8, backgroundColor: "#3C4048", marginTop: 1 }}
            />
          </View>
          <Text style={styles.tableNumber}>01</Text>
        </View>

        <View style={styles.headingRow}>
          <View>
            <Text style={styles.title20}>Target Market</Text>
            <View
              style={{
                width: "100%",
                height: 1.8,
                backgroundColor: "#3C4048",
                marginTop: 1,
                marginBottom: 15,
              }}
            />
            <Text style={styles.font12}>1.&nbsp;&nbsp; Location</Text>
            <Text style={styles.font12}>2.&nbsp;&nbsp; Gender/Age/Marital Status</Text>
            <Text style={styles.font12}>3.&nbsp;&nbsp; Education/Occupation Level</Text>
            <Text style={styles.font12}>4.&nbsp;&nbsp; Income Level</Text>
            <Text style={styles.font12}>5.&nbsp;&nbsp; Creating Buyer’s Persona</Text>
            <Text style={styles.font12}>6.&nbsp;&nbsp; Location</Text>
          </View>
          <Text style={styles.tableNumber}>02 - 03</Text>
        </View>

        <View style={styles.headingRow}>
          <View>
            <Text style={styles.title20}>Market Size</Text>
            <View
              style={{
                width: "60%",
                height: 1.8,
                backgroundColor: "#3C4048",
                marginTop: 1,
                marginBottom: 15,
              }}
            />
            <Text style={styles.font12}>1.&nbsp;&nbsp; Total Market Volume</Text>
            <Text style={styles.font12}>2.&nbsp;&nbsp; Target Market Volume</Text>
            <Text style={styles.font12}>3.&nbsp;&nbsp; Market Penetration</Text>
            <Text style={styles.font12}>
              4.&nbsp;&nbsp; Average Price of similar products/services
            </Text>
            <Text style={styles.font12}>5.&nbsp;&nbsp; Market Value</Text>
          </View>
          <Text style={styles.tableNumber}>04</Text>
        </View>

        <View style={styles.headingRow}>
          <View>
            <Text style={styles.title20}>Market Demand</Text>
            <View
              style={{
                width: "100%",
                height: 1.8,
                backgroundColor: "#3C4048",
                marginTop: 1,
                marginBottom: 15,
              }}
            />
            <Text style={styles.font12}>1.&nbsp;&nbsp; Product-Fit in the Current Market</Text>
            <Text style={styles.font12}>2.&nbsp;&nbsp; Industry Trend</Text>
          </View>
          <Text style={styles.tableNumber}>05 - 06</Text>
        </View>

        <View style={styles.headingRow}>
          <View>
            <Text style={styles.title20}>Competition Analysis</Text>
            <View
              style={{
                width: "100%",
                height: 1.8,
                backgroundColor: "#3C4048",
                marginTop: 1,
                marginBottom: 15,
              }}
            />
            <Text style={styles.font12}>1.&nbsp;&nbsp; Core Competencies</Text>
            <Text style={styles.font12}>2.&nbsp;&nbsp; Core Competency of Competitors</Text>
            <Text style={styles.font12}>2.&nbsp;&nbsp; SWOT Analysis</Text>
          </View>
          <Text style={styles.tableNumber}>07 - 09</Text>
        </View>

        <View style={styles.headingRow}>
          <View>
            <Text style={styles.title20}>Barriers to Entry</Text>
            <View
              style={{
                width: "100%",
                height: 1.8,
                backgroundColor: "#3C4048",
                marginTop: 1,
                marginBottom: 15,
              }}
            />
            <Text style={styles.font12}>a.&nbsp;&nbsp; Investment</Text>
            <Text style={styles.font12}>b.&nbsp;&nbsp; Technology</Text>
            <Text style={styles.font12}>c.&nbsp;&nbsp; Brand Marketing</Text>
          </View>
          <Text style={styles.tableNumber}>10 - 11</Text>
        </View>

        <View style={styles.headingRow}>
          <View>
            <Text style={styles.title20}>Glossary</Text>
            <View
              style={{ width: "100%", height: 1.8, backgroundColor: "#3C4048", marginTop: 1 }}
            />
          </View>
          <Text style={styles.tableNumber}>12 - 13</Text>
        </View>
      </Page>

      <Page size="A4">
        {/* <WaterMark /> */}
        {/* =================Market Research===================== */}
        <View
          style={{
            paddingLeft: 65,
            paddingRight: 65,
            paddingTop: 65,
            textAlign: "justify",
          }}
        >
          <View
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              flexDirection: "row",
              marginBottom: 10,
            }}
          >
            <Text style={styles.title}>Market Research</Text>
            <Image src={Page1HeadingImg} style={{ width: 70, height: 70 }} />
          </View>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <Image src={BorderImg} style={{ width: 5, height: "95%", marginRight: 18 }} />
            <View style={{ paddingRight: 20 }}>
              <Text style={[styles.para, { lineHeight: 1.5 }]}>
                Market Research is the process of gathering, analysing and interpretation of
                relative information about the buyer’s personas and target market through meticulous
                research, in order to determine the viability factors of a new product or service
                being offered. This can be achieved through analysis of many dynamic aspects that
                play a key role in influencing the purchase decision and behaviour of potential
                targeted audience.
              </Text>
              <Text style={[styles.para, { lineHeight: 1.5, marginTop: 10 }]}>
                The determination of a business model holds significance in providing the viability
                of targeted business. General business model types are business to business and
                business to customer defined as follows:
              </Text>

              {/* =========bullits=========== */}
              <View style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
                <View
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 12,
                    borderWidth: 1,
                    borderColor: "#7B8FA1",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 8,
                    marginTop: 2,
                  }}
                >
                  <View
                    style={{
                      height: 5,
                      width: 5,
                      borderRadius: 6,
                      backgroundColor: "#0081C9",
                    }}
                  />
                </View>
                <View
                  style={{
                    width: 1,
                    height: 22,
                    backgroundColor: "#7B8FA1",
                    position: "absolute",
                    left: 4.5,
                    top: 11,
                  }}
                />
                <Text style={[styles.bullitPara, { width: "95%" }]}>
                  Business to Business (B2B) – This model refers to selling products and services
                  between businesses.
                </Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
                <View
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 12,
                    borderWidth: 1,
                    borderColor: "#7B8FA1",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 8,
                    marginTop: 2,
                  }}
                >
                  <View
                    style={{
                      height: 5,
                      width: 5,
                      borderRadius: 6,
                      backgroundColor: "#F55050",
                    }}
                  />
                </View>
                <Text style={[styles.bullitPara, { width: "95%" }]}>
                  Business to Customer (B2C) – This model refers to selling products and services
                  between business and customers.
                </Text>
              </View>

              <Text style={[styles.para, { marginTop: 8 }]}>
                This report provides a detailed evaluation in formulating a solid foundation for the
                start-up/existing business based on selected{" "}
                <Text style={styles.highlitFont}>
                  {businessModel.type == "B2C"
                    ? "Business to Customer (B2C)"
                    : "Business to Business (B2B)"}{" "}
                </Text>
                model.
              </Text>
            </View>
          </View>
        </View>
        <Image src={Page1Img} style={{ width: "100%", position: "absolute", bottom: 0 }} />

        {/* ============FOOTER========= */}
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text
            // render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
            fixed
          >
            Market Research Report • 1
          </Text>
        </View>
      </Page>

      <Page size="A4" style={{ paddingBottom: 60 }}>
        {/* <WaterMark /> */}
        <Image src={TargetMarketHead} style={{ width: "100%" }} />

        {/* =================Target Market B2C===================== */}
        {businessModel.type == "B2C" ? (
          <View
            style={{
              paddingHorizontal: 60,
              textAlign: "justify",
            }}
          >
            <Text style={styles.title}>Target Market</Text>
            <Text style={styles.para}>
              In an effort to conduct successful market research, having the understanding of target
              market helps to formulate the selling strategy to reach the potential clients and
              generate business. Target market refers to the specific group of consumers to whom the
              products or services are offered, based on geographic, demographic and psychographic
              segmentation.
            </Text>
            <View>
              <View style={[styles.row, { marginLeft: 0 }]}>
                <View style={styles.radioBtn}>
                  <View
                    style={[
                      styles.radioDot,
                      {
                        backgroundColor: "#F55050",
                      },
                    ]}
                  />
                </View>
                <Text style={styles.subTitle}>Location</Text>
              </View>
              {targetMarket[0]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[0]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              The geographic segmentation refers to grouping of the potential customers based on
              their physical location. In this scenario, the target audience of{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>{" "}
              geographically caters across the{" "}
              <Text style={styles.highlitFont}>
                {targetMarket[0]?.response?.value}
                {targetMarket[0]?.locationResponse &&
                  `, for ${targetMarket[0]?.locationResponse?.value}`}
              </Text>
              .
            </Text>
            <View>
              <View style={[styles.row, { marginLeft: 0 }]}>
                <View style={styles.radioBtn}>
                  <View
                    style={[
                      styles.radioDot,
                      {
                        backgroundColor: "#F55050",
                      },
                    ]}
                  />
                </View>
                <Text style={styles.subTitle}>Gender/Age/Marital Status</Text>
              </View>
              {targetMarket[1]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[1]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              The demographic segmentation helps in formulating overall marketing strategies as the
              consumer preferences are prone to change with age and marital status. Based on the key
              demographics of{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>, the target
              audience is <Text style={styles.highlitFont}>{targetMarket[1]?.response?.text} </Text>{" "}
              {targetMarket[1]?.response?.text == "All" ? "gender(s)" : "gender"}, who maybe{" "}
              {targetMarket[2]?.response.length > 1 ? (
                targetMarket[2]?.response?.map((item, index) => {
                  let getText = item.split("(")[1]?.replace(")", "");
                  return (
                    <Text style={styles.highlitFont}>
                      {getText}
                      {targetMarket[2]?.response?.length - 1 == index ? null : "/ "}
                    </Text>
                  );
                })
              ) : (
                <Text style={styles.highlitFont}>{targetMarket[2]?.response[0]}</Text>
              )}{" "}
              of any age group and hold marital status of being{" "}
              <Text style={styles.highlitFont}>{targetMarket[3]?.response?.text}</Text>.
            </Text>
            <View>
              <View style={[styles.row, { marginLeft: 0 }]}>
                <View style={styles.radioBtn}>
                  <View
                    style={[
                      styles.radioDot,
                      {
                        backgroundColor: "#F55050",
                      },
                    ]}
                  />
                </View>
                <Text style={styles.subTitle}>Education/Occupation Level</Text>
              </View>
              {targetMarket[2]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[2]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              The literacy level of the targeted audience also has a significant influence on the
              marketing strategies for the product or services being offered. For instance, the
              target market for{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> includes
              group of individuals whose minimum level of education is{" "}
              <Text style={styles.highlitFont}>{targetMarket[4]?.response?.text}</Text>. This also
              supports in crafting relevant marketing messages that the target audience may
              understand and relate to.
            </Text>
            <Text style={styles.subPara}>
              Targeting the occupation is also a considerable factor as speaking the language of
              profession that target segment belongs to, goes a long way. In addition to the
              literacy level, the target audience for{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> in terms of
              their occupation are{" "}
              {targetMarket[5]?.response.length > 1 ? (
                targetMarket[5]?.response?.map((item, index) => {
                  return (
                    <Text style={styles.highlitFont}>
                      {item}
                      {targetMarket[5]?.response?.length - 1 == index ? null : "/"}
                    </Text>
                  );
                })
              ) : (
                <Text style={styles.highlitFont}>{targetMarket[5]?.response[0]}</Text>
              )}
              .
            </Text>
          </View>
        ) : (
          // ==================B2B TARGET MARKETING===============
          <View
            style={{
              paddingHorizontal: 60,
              textAlign: "justify",
            }}
          >
            <Text style={styles.title}>Target Market</Text>
            <Text style={styles.para}>
              In an effort to conduct successful market research, having the understanding of target
              market helps to formulate the selling strategy to reach the potential clients and
              generate business. Target market refers to the specific group of consumers to whom the
              products or services are offered, based on geographic, demographic and psychographic
              segmentation.
            </Text>

            <View>
              <Text style={styles.subTitle}>•&nbsp;&nbsp; Business Model</Text>
            </View>
            <Text style={styles.subPara}>
              Having a better understanding of the target market helps in selecting the business
              model to choose from.{" "}
            </Text>
            <Text style={styles.subPara}>
              The B2B business model aims to exchange the product, services or information between
              companies.
            </Text>

            <View>
              <Text style={styles.subTitle}>•&nbsp;&nbsp; Nature of Business</Text>
              {targetMarket[0]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[0]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              The business to business (B2B) organizations work in many industries. In order to
              successfully identify the target market, it is essential to understand the type of the
              business, the services they provide and the overall objectives and goals of that
              business. The{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> tends to
              target businesses in the{" "}
              <Text style={styles.highlitFont}>{targetMarket[0]?.response?.text}</Text>.
            </Text>

            <View>
              <Text style={styles.subTitle}>•&nbsp;&nbsp; Industry Sector</Text>
              {targetMarket[1]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[1]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              It's important to consider the level of fit between the product or service being
              offered and their relative industry. For the{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>, the
              targeted industry relates to{" "}
              {targetMarket[1]?.response.length > 1 ? (
                targetMarket[1]?.response?.map((item, index) => {
                  return (
                    <Text key={index} style={styles.highlitFont}>
                      {item}
                      {targetMarket[1]?.response?.length - 1 == index ? null : "/"}
                    </Text>
                  );
                })
              ) : (
                <Text style={styles.highlitFont}>{targetMarket[1]?.response[0]}</Text>
              )}
              {targetMarket[1]?.secondResponse && (
                <Text style={styles.highlitFont}>
                  {"/"}
                  {targetMarket[1]?.secondResponse?.value}{" "}
                </Text>
              )}
              sector.
            </Text>

            <View>
              <Text style={styles.subTitle}>•&nbsp;&nbsp; Location</Text>
              {targetMarket[2]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[2]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              The geographic segmentation refers to grouping of the potential customers based on
              their physical location. In this scenario, the target audience of{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>{" "}
              geographically caters across the{" "}
              <Text style={styles.highlitFont}>
                {targetMarket[2]?.response?.value}
                {targetMarket[2]?.locationResponse &&
                  `, for ${targetMarket[2]?.locationResponse?.value}`}
              </Text>
              .
            </Text>
          </View>
        )}
        {/* ============FOOTER========= */}
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text
            // render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
            fixed
          >
            Market Research Report • 2
          </Text>
        </View>
      </Page>

      <Page size={"A4"} style={{ paddingBottom: 60 }}>
        <Image
          src={BoxImage}
          style={{ width: "100%", position: "absolute", right: 0, width: 120, height: 120 }}
        />
        {/* ========================B2C PAGE 2 TARGET MARKET============== */}
        {businessModel.type == "B2C" ? (
          <View
            style={{
              paddingHorizontal: 60,
              marginTop: 60,
              textAlign: "justify",
            }}
          >
            <View style={styles.row}>
              <Text style={styles.title}>Target Market </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: "#2A2A2A",
                  fontStyle: "italic",
                  fontFamily: "roboto",
                }}
              >
                {" "}
                Continued
              </Text>
            </View>
            <View>
              <View style={[styles.row, { marginLeft: 0 }]}>
                <View style={styles.radioBtn}>
                  <View
                    style={[
                      styles.radioDot,
                      {
                        backgroundColor: "#F55050",
                      },
                    ]}
                  />
                </View>
                <Text style={styles.subTitle}>Income Level</Text>
              </View>
              {targetMarket[3]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[3]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              The income level of potential customers allows to better understand the buying
              capacity of the targeted audience. That helps in coming up with a good pricing
              strategy and also results in getting to know the target market spending habits. For
              the <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>, the
              target audience ideally belongs to the{" "}
              {targetMarket[6]?.response.length > 1 ? (
                targetMarket[6]?.response?.map((item, index) => {
                  return (
                    <Text style={styles.highlitFont}>
                      {item}
                      {targetMarket[6]?.response?.length - 1 == index ? null : "/ "}
                    </Text>
                  );
                })
              ) : (
                <Text style={styles.highlitFont}>{targetMarket[6]?.response[0]}</Text>
              )}{" "}
              group, since there is expected to be a higher buying potential within this group.
            </Text>
            <View>
              <View style={[styles.row, { marginLeft: 0 }]}>
                <View style={styles.radioBtn}>
                  <View
                    style={[
                      styles.radioDot,
                      {
                        backgroundColor: "#F55050",
                      },
                    ]}
                  />
                </View>
                <Text style={styles.subTitle}>Creating Buyer’s Persona</Text>
              </View>
              {targetMarket[4]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[4]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              In order to create the buyer’s persona, psychological traits and behavioural
              characteristics of targeted audience play a significant role to further refining the
              marketing and business strategy. For the{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>, the target
              group holds general characteristics of being{" "}
              {targetMarket[7]?.response.length > 1 ? (
                targetMarket[7]?.response?.map((item, index) => {
                  return (
                    <Text style={styles.highlitFont}>
                      {item}
                      {targetMarket[7]?.response?.length - 1 == index ? null : ", "}
                    </Text>
                  );
                })
              ) : (
                <Text style={styles.highlitFont}>{targetMarket[7]?.response[0]}</Text>
              )}
              .
            </Text>

            <Text style={styles.subPara}>
              The interests and hobbies exhibit the personality of a targeted audience through their
              behavioural characteristics. The targeted group tends to be interested in{" "}
              {targetMarket[8]?.response.length > 1 ? (
                targetMarket[8]?.response?.map((item, index) => {
                  return (
                    <Text style={styles.highlitFont}>
                      {item}
                      {targetMarket[8]?.response?.length - 1 == index ? null : ", "}
                    </Text>
                  );
                })
              ) : (
                <Text style={styles.highlitFont}>{targetMarket[8]?.response[0]},</Text>
              )}{" "}
              the buying power of this group of individuals’ lies with there{" "}
              <Text style={styles.highlitFont}>{targetMarket[9]?.response?.value}</Text>.
            </Text>
            <View
              style={{
                width: 300,
                height: 200,
                marginHorizontal: 100,
                marginTop: 40,
              }}
            >
              {targetMarket[7]?.response?.includes("Innovator") ? (
                <View style={[styles.circle_one, styles.circle]}>
                  <Text style={styles.circleText}>Innovator</Text>
                </View>
              ) : (
                <Text> </Text>
              )}

              {targetMarket[7]?.response?.includes("Thinker") ? (
                <View style={[styles.circle_two, styles.circle]}>
                  <Text style={styles.circleText}>Thinker</Text>
                </View>
              ) : (
                <Text> </Text>
              )}

              {targetMarket[7]?.response?.includes("Believer") ? (
                <View style={[styles.circle_three, styles.circle]}>
                  <Text style={styles.circleText}>Believer</Text>
                </View>
              ) : (
                <Text></Text>
              )}

              {targetMarket[7]?.response?.includes("Achiever") ? (
                <View style={[styles.circle_four, styles.circle]}>
                  <Text style={styles.circleText}>Achiever</Text>
                </View>
              ) : (
                <Text></Text>
              )}

              {targetMarket[7]?.response?.includes("Striver") ? (
                <View style={[styles.circle_five, styles.circle]}>
                  <Text style={styles.circleText}>Striver</Text>
                </View>
              ) : (
                <Text></Text>
              )}

              {targetMarket[7]?.response?.includes("Experiencer") ? (
                <View style={[styles.circle_six, styles.circle]}>
                  <Text style={styles.circleText}>Experiencer</Text>
                </View>
              ) : (
                <Text></Text>
              )}

              {targetMarket[7]?.response?.includes("Makers") ? (
                <View style={[styles.circle_seven, styles.circle]}>
                  <Text style={styles.circleText}>Makers</Text>
                </View>
              ) : (
                <Text></Text>
              )}

              {targetMarket[7]?.response?.includes("Survivors") ? (
                <View style={[styles.circle_eight, styles.circle]}>
                  <Text style={styles.circleText}>Survivors</Text>
                </View>
              ) : (
                <Text></Text>
              )}
            </View>
            {/* ========================end page 2 B2C TARGET MARKET============== */}
          </View>
        ) : (
          <View
            style={{
              paddingHorizontal: 60,
              marginTop: 70,
              textAlign: "justify",
            }}
          >
            {/* ============================B2B PAGE 2 TARGET MARKET================== */}
            <View style={styles.row}>
              <Text style={styles.title}>Target Market </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: "#2A2A2A",
                  fontStyle: "italic",
                  fontFamily: "roboto",
                }}
              >
                {" "}
                Continued
              </Text>
            </View>
            <View>
              <Text style={styles.subTitle}>
                •&nbsp;&nbsp; End Users of product/service being offered
              </Text>
              {targetMarket[3]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[3]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              In a B2B (business-to-business) business model, the end users are other businesses
              rather than individual consumers. These businesses may use the products or services
              provided by the B2B company to improve their own operations or to resell to their own
              customers. The end users of{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> are mainly{" "}
              {targetMarket[3]?.response.length > 1 ? (
                targetMarket[3]?.response?.map((item, index) => {
                  return (
                    <Text key={index} style={styles.highlitFont}>
                      {item}
                      {targetMarket[3]?.response?.length - 1 == index ? null : "/"}
                    </Text>
                  );
                })
              ) : (
                <Text style={styles.highlitFont}>{targetMarket[3]?.response[0]}</Text>
              )}{" "}
            </Text>

            <View>
              <Text style={styles.subTitle}>
                •&nbsp;&nbsp; Scale of Targeted Business (Business Size)
              </Text>
              {targetMarket[4]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[4]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              The business size is focused on the scale of company’ operations as to how big or
              small they are. When targeting B2B, Micro, Small, Medium, and Large refer to the size
              of the targeted businesses in terms of the number of employees they have. The
              potential customers for{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> are{" "}
              {targetMarket[4]?.response.length > 1 ? (
                targetMarket[4]?.response?.map((item, index) => {
                  return (
                    <Text key={index} style={styles.highlitFont}>
                      {item}
                      {targetMarket[4]?.response?.length - 1 == index ? null : "/"}
                    </Text>
                  );
                })
              ) : (
                <Text style={styles.highlitFont}>{targetMarket[4]?.response[0]}</Text>
              )}{" "}
              scale businesses.
            </Text>

            <View>
              <Text style={styles.subTitle}>•&nbsp;&nbsp; Add Value to the Target business</Text>
              {targetMarket[5]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[5]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              It is considerably important to evaluate how a product or service can be utilized to
              its full potential. The potential product or service can add value to the targeted
              business/industry by improving their process efficiency and/or revenue efficiency.
              Process efficiency refers to the effectiveness and efficiency with which a business is
              able to complete its tasks and achieve its goals. Revenue efficiency refers to the
              ability of a business to generate revenue in proportion to its expenses.
            </Text>
            <Text style={styles.subPara}>
              The <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> is
              going to benefit the targeted businesses in improving their Revenue
              <Text style={styles.highlitFont}>
                {" "}
                {targetMarket[5]?.response?.text == "Both"
                  ? "Revenue and Process Efficiency"
                  : targetMarket[5]?.response?.text}
              </Text>
              .
            </Text>

            <View>
              <Text style={styles.subTitle}>•&nbsp;&nbsp; Purchase Decision</Text>
              {targetMarket[6]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {targetMarket[6]?.feedback}
                </Note>
              ) : null}
            </View>
            <Text style={styles.subPara}>
              It is important to note that the decision-making process in a B2B setting can be
              complex and may involve multiple stakeholders. Therefore, it is important to
              understand the needs of the targeted business and to build relationships with key
              decision makers in order to successfully sell a product or service. The purchase
              decision of <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>{" "}
              lies with{" "}
              <Text style={styles.highlitFont}>
                {targetMarket[6]?.response?.text == "Other"
                  ? targetMarket[6]?.response?.value
                  : targetMarket[6]?.response?.text}
              </Text>{" "}
              of the targeted business/organization.
            </Text>
          </View>
        )}
        {/* ========================end page 2 B2B TARGET MARKET============== */}
        {businessModel.type == "B2C" ? (
          <Image
            src={TargetMarketBottom}
            style={{ width: "100%", position: "absolute", bottom: 0 }}
          />
        ) : null}
        {/* ============FOOTER========= */}
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text
            // render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
            fixed
          >
            Market Research Report • 3
          </Text>
        </View>
      </Page>

      {/* ===================B2B TARGET MARKET */}
      {/* <View style={styles.section}>
        <Text style={styles.title}>Target Market</Text>
        <Text style={styles.para}>
          In an effort to conduct successful market research, having the understanding of target
          market helps to formulate the selling strategy to reach the potential clients and generate
          business. Target market refers to the specific group of consumers to whom the products or
          services are offered, based on geographic, demographic and psychographic segmentation.
        </Text>

        <View>
          <Text style={styles.subTitle}>•&nbsp;&nbsp; Business Model</Text>
        </View>
        <Text style={styles.subPara}>
          Having a better understanding of the target market helps in selecting the business model
          to choose from.{" "}
        </Text>
        <Text style={styles.subPara}>
          The B2B business model aims to exchange the product, services or information between
          companies.
        </Text>

        <View>
          <Text style={styles.subTitle}>•&nbsp;&nbsp; Nature of Business</Text>
          {targetMarket[0]?.feedback ? (
            <Note style={{ position: "absolute", right: 20, top: 20 }}>
              {targetMarket[0]?.feedback}
            </Note>
          ) : null}
        </View>
        <Text style={styles.subPara}>
          The business to business (B2B) organizations work in many industries. In order to
          successfully identify the target market, it is essential to understand the type of the
          business, the services they provide and the overall objectives and goals of that business.
          The <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> tends to
          target businesses in the{" "}
          <Text style={styles.highlitFont}>{targetMarket[0]?.response?.text}</Text> industry.
        </Text>

        <View>
          <Text style={styles.subTitle}>•&nbsp;&nbsp; Industry Sector</Text>
          {targetMarket[1]?.feedback ? (
            <Note style={{ position: "absolute", right: 20, top: 20 }}>
              {targetMarket[1]?.feedback}
            </Note>
          ) : null}
        </View>
        <Text style={styles.subPara}>
          It's important to consider the level of fit between the product or service being offered
          and their relative industry. For the{" "}
          <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>, the targeted
          industry relates to{" "}
          {targetMarket[1]?.response.length > 1 ? (
            targetMarket[1]?.response?.map((item, index) => {
              return (
                <Text key={index} style={styles.highlitFont}>
                  {item}
                  {targetMarket[1]?.response?.length - 1 == index ? null : "/"}
                </Text>
              );
            })
          ) : (
            <Text style={styles.highlitFont}>{targetMarket[1]?.response[0]}</Text>
          )}
          {targetMarket[1]?.secondResponse && (
            <Text style={styles.highlitFont}>
              {"/"}
              {targetMarket[1]?.secondResponse?.value}{" "}
            </Text>
          )}
          sector.
        </Text>

        <View>
          <Text style={styles.subTitle}>•&nbsp;&nbsp; Location</Text>
          {targetMarket[2]?.feedback ? (
            <Note style={{ position: "absolute", right: 20, top: 20 }}>
              {targetMarket[2]?.feedback}
            </Note>
          ) : null}
        </View>
        <Text style={styles.subPara}>
          The geographic segmentation refers to grouping of the potential customers based on their
          physical location. In this scenario, the target audience of{" "}
          <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> geographically
          caters across the{" "}
          <Text style={styles.highlitFont}>
            {targetMarket[2]?.response?.value}
            {targetMarket[2]?.locationResponse &&
              `, For ${targetMarket[2]?.locationResponse?.value}`}
          </Text>
          .
        </Text>

        <View>
          <Text style={styles.subTitle}>
            •&nbsp;&nbsp; End Users of product/service being offered
          </Text>
          {targetMarket[3]?.feedback ? (
            <Note style={{ position: "absolute", right: 20, top: 20 }}>
              {targetMarket[3]?.feedback}
            </Note>
          ) : null}
        </View>
        <Text style={styles.subPara}>
          In a B2B (business-to-business) business model, the end users are other businesses rather
          than individual consumers. These businesses may use the products or services provided by
          the B2B company to improve their own operations or to resell to their own customers. The
          end users of <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>{" "}
          are mainly{" "}
          {targetMarket[3]?.response.length > 1 ? (
            targetMarket[3]?.response?.map((item, index) => {
              return (
                <Text key={index} style={styles.highlitFont}>
                  {item}
                  {targetMarket[3]?.response?.length - 1 == index ? null : "/"}
                </Text>
              );
            })
          ) : (
            <Text style={styles.highlitFont}>{targetMarket[3]?.response[0]}</Text>
          )}{" "}
        </Text>

        <View>
          <Text style={styles.subTitle}>
            •&nbsp;&nbsp; Scale of Targeted Business (Business Size)
          </Text>
          {targetMarket[4]?.feedback ? (
            <Note style={{ position: "absolute", right: 20, top: 20 }}>
              {targetMarket[4]?.feedback}
            </Note>
          ) : null}
        </View>
        <Text style={styles.subPara}>
          The business size is focused on the scale of company’ operations as to how big or small
          they are. When targeting B2B, Micro, Small, Medium, and Large refer to the size of the
          targeted businesses in terms of the number of employees they have. The potential customers
          for <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> are{" "}
          {targetMarket[4]?.response.length > 1 ? (
            targetMarket[4]?.response?.map((item, index) => {
              return (
                <Text key={index} style={styles.highlitFont}>
                  {item}
                  {targetMarket[4]?.response?.length - 1 == index ? null : "/"}
                </Text>
              );
            })
          ) : (
            <Text style={styles.highlitFont}>{targetMarket[4]?.response[0]}</Text>
          )}{" "}
          scale businesses.
        </Text>

        <View>
          <Text style={styles.subTitle}>•&nbsp;&nbsp; Add Value to the Target business</Text>
          {targetMarket[5]?.feedback ? (
            <Note style={{ position: "absolute", right: 20, top: 20 }}>
              {targetMarket[5]?.feedback}
            </Note>
          ) : null}
        </View>
        <Text style={styles.subPara}>
          It is considerably important to evaluate how a product or service can be utilized to its
          full potential. The potential product or service can add value to the targeted
          business/industry by improving their process efficiency and/or revenue efficiency. Process
          efficiency refers to the effectiveness and efficiency with which a business is able to
          complete its tasks and achieve its goals. Revenue efficiency refers to the ability of a
          business to generate revenue in proportion to its expenses.
        </Text>
        <Text style={styles.subPara}>
          The <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> is going to
          benefit the targeted businesses in improving their Revenue
          <Text style={styles.highlitFont}>
            {" "}
            {targetMarket[5]?.response?.text == "Both"
              ? "Revenue and Process Efficiency"
              : targetMarket[5]?.response?.text}
          </Text>
          .
        </Text>

        <View>
          <Text style={styles.subTitle}>•&nbsp;&nbsp; Purchase Decision</Text>
          {targetMarket[6]?.feedback ? (
            <Note style={{ position: "absolute", right: 20, top: 20 }}>
              {targetMarket[6]?.feedback}
            </Note>
          ) : null}
        </View>
        <Text style={styles.subPara}>
          It is important to note that the decision-making process in a B2B setting can be complex
          and may involve multiple stakeholders. Therefore, it is important to understand the needs
          of the targeted business and to build relationships with key decision makers in order to
          successfully sell a product or service. The purchase decision of{" "}
          <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> lies with{" "}
          <Text style={styles.highlitFont}>
            {targetMarket[6]?.response?.text == "Other"
              ? targetMarket[6]?.response?.value
              : targetMarket[6]?.response?.text}
          </Text>{" "}
          of the targeted business/organization.
        </Text>
      </View> */}
      {/* )} */}

      {/* =================Market Size===================== */}
      <Page size="A4">
        {/* <WaterMark /> */}
        <View
          style={{
            paddingLeft: 60,
            paddingTop: 40,
            textAlign: "justify",
            backgroundColor: "#EEEEEE",
            height: 248,
          }}
        >
          <Text style={styles.title}>Market Size</Text>
          <View style={styles.row}>
            <Text
              style={{
                width: "45%",
                marginLeft: -8,
                letterSpacing: 0.4,
                fontFamily: "roboto",
                fontSize: 12,
                color: "#2A2A2A",
                fontWeight: "light",
                lineHeight: 1.4,
              }}
            >
              For a new business it is detrimental to measure the market size before launching a new
              product or service. Market size is an estimation of the customer potential of a market
              i.e. evaluating the total number of individuals in a specific market segment who are
              potential buyers. The market size is determined by two factors, the market volume and
              the market value.
            </Text>
            <Image
              src={MarketSizeHead}
              style={{ width: "55%", height: 190, position: "absolute", bottom: -15, right: 0 }}
            />
          </View>
        </View>
        <View
          style={{
            paddingHorizontal: 60,
            textAlign: "justify",
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "flex-start", marginTop: 20 }}>
            <Image src={MarketSizeIcon1} style={{ width: 40, height: 40 }} />
            <View style={{ marginLeft: 15, paddingRight: 40, textAlign: "justify" }}>
              <View>
                <Text style={[styles.subTitle, { marginTop: 0 }]}>Total Market Volume</Text>
                {marketSize[0]?.feedback ? (
                  <Note style={{ position: "absolute", right: 20, top: 20 }}>
                    {marketSize[0]?.feedback}
                  </Note>
                ) : null}
              </View>
              <Text style={[styles.subPara, { marginLeft: 0 }]}>
                Market volume refers to the number of potential customers to be attracted towards
                purchasing a product, based on the profiling of the customer base. The total Market
                Volume of <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>{" "}
                is{" "}
                <Text style={styles.highlitFont}>{formatter.format(marketSize[0]?.response)}</Text>{" "}
                individuals.
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row", alignItems: "flex-start", marginTop: 20 }}>
            <Image src={MarketSizeIcon2} style={{ width: 40, height: 40 }} />
            <View style={{ marginLeft: 15, paddingRight: 40, textAlign: "justify" }}>
              <View>
                <Text style={[styles.subTitle, { marginTop: 0 }]}>Target Market Volume</Text>
                {marketSize[1]?.feedback ? (
                  <Note style={{ position: "absolute", right: 20, top: 20 }}>
                    {marketSize[1]?.feedback}
                  </Note>
                ) : null}
              </View>
              <Text style={[styles.subPara, { marginLeft: 0 }]}>
                Target Market Volume of{" "}
                <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> is{" "}
                <Text style={styles.highlitFont}>{formatter.format(marketSize[1]?.response)}</Text>{" "}
                individuals
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row", alignItems: "flex-start", marginTop: 20 }}>
            <Image src={MarketSizeIcon3} style={{ width: 40, height: 40 }} />
            <View style={{ marginLeft: 15, paddingRight: 40, textAlign: "justify" }}>
              <View>
                <Text style={[styles.subTitle, { marginTop: 0 }]}>Market Penetration</Text>
                {marketSize[2]?.feedback ? (
                  <Note style={{ position: "absolute", right: 20, top: 20 }}>
                    {marketSize[2]?.feedback}
                  </Note>
                ) : null}
              </View>
              <Text style={[styles.subPara, { marginLeft: 0 }]}>
                Market penetration is a measure of how much a product or service is being used by
                customers compared to the total estimated market volume for that product or service.
                For <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>, the
                Market Penetration rate is{" "}
                <Text style={styles.highlitFont}>{marketSize[2]?.response}</Text> %.
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row", alignItems: "flex-start", marginTop: 20 }}>
            <Image src={MarketSizeIcon4} style={{ width: 40, height: 40 }} />
            <View style={{ marginLeft: 15, paddingRight: 40, textAlign: "justify" }}>
              <View>
                <Text style={[styles.subTitle, { marginTop: 0 }]}>
                  Average Price of similar products/services
                </Text>
                {marketSize[3]?.feedback ? (
                  <Note style={{ position: "absolute", right: 20, top: 20 }}>
                    {marketSize[3]?.feedback}
                  </Note>
                ) : null}
              </View>
              <Text style={[styles.subPara, { marginLeft: 0 }]}>
                To be competitive, it is essential to research on the prices of the similar products
                or service in the market as it helps in achieving competitive pricing. In accordance
                with the conducted research on market size, the average price for product/service
                correspondent to product or service is USD{" "}
                <Text style={styles.highlitFont}>{formatter.format(marketSize[3]?.response)}</Text>{" "}
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row", alignItems: "flex-start", marginTop: 20 }}>
            <Image src={MarketSizeIcon5} style={{ width: 40, height: 40 }} />
            <View style={{ marginLeft: 15, paddingRight: 40, textAlign: "justify" }}>
              <View>
                <Text style={[styles.subTitle, { marginTop: 0 }]}>Market Value</Text>
                {marketSize[4]?.feedback ? (
                  <Note style={{ position: "absolute", right: 20, top: 20 }}>
                    {marketSize[4]?.feedback}
                  </Note>
                ) : null}
              </View>
              <Text style={[styles.subPara, { marginLeft: 0 }]}>
                The total market value of this product is USD{" "}
                <Text style={styles.highlitFont}>{formatter.format(marketSize[4]?.response)}</Text>{" "}
                which indicates the value you can get if you could attract all the customers that
                have access to the product.
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text
            // render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
            fixed
          >
            Market Research Report • 4
          </Text>
        </View>
      </Page>

      {/* =================Market Demand===================== */}
      <Page size="A4">
        {/* <WaterMark /> */}
        <Image src={MarketDemandIcon1} style={{ width: "100%" }} />
        <View
          style={{
            paddingHorizontal: 60,
            textAlign: "justify",
          }}
        >
          <View
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              flexDirection: "row",
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            <Text style={styles.title}>Market Demand</Text>
            <Image
              src={MarketDemandHeading}
              style={{ width: 80, height: 100, position: "absolute", bottom: 10, right: 0 }}
            />
          </View>
          <Text style={[styles.subPara, { marginLeft: 0 }]}>
            Market demand is the total amount of goods and services that all consumers are willing
            and able to purchase at a specific price in a marketplace. This results toward
            identification of the market volume which is fundamental aspect in deciding on creating
            a new product.
          </Text>

          <View style={[styles.row, { marginLeft: 0 }]}>
            <View style={styles.radioBtn}>
              <View
                style={[
                  styles.radioDot,
                  {
                    backgroundColor: "#F55050",
                  },
                ]}
              />
            </View>
            <View>
              <Text style={styles.subTitle}>Product-Fit in the Current Market</Text>
              {marketDemand[0]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {marketDemand[0]?.feedback}
                </Note>
              ) : null}
            </View>
          </View>

          <Text style={styles.subPara}>
            When the market volume of the product or a service is known, it is easier to evaluate
            how well that product is going to fit into the current market as per the user
            experience, feature set and targeted customers. Acquiring a market share requires a
            point of differentiation or unique selling point to overcome any strategic disadvantage.
          </Text>
          <Text style={styles.subPara}>
            In this scenario, the{" "}
            <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>{" "}
            <Text style={styles.highlitFont}>{marketDemand[0]?.response?.text}</Text> fit(s) into
            the current market, i.e.
          </Text>
          {marketDemand[0]?.response?.text == "Yes" ? (
            <View>
              <Text style={styles.subPara}>
                The <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>{" "}
                satisfies a strong market demand and has successfully identified its targeted
                customers and tends to serve them with the right product. This allows the{" "}
                <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> to have
                the ability to analyze the existing factors prevailing in the market, some of them
                are:
              </Text>
              <View
                style={{
                  backgroundColor: "#2B3A55",
                  height: 12,
                  width: "100%",
                  borderBottomWidth: 2,
                  borderBottomColor: "red",
                }}
              />
              <View style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
                <Text style={styles.bullitPara}>
                  •&nbsp;&nbsp; Customer segmentation (Target Market)
                </Text>
              </View>
              <View style={{ backgroundColor: "#fff", padding: 5 }}>
                <Text style={styles.bullitPara}>•&nbsp;&nbsp; Product features</Text>
              </View>
              <View style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
                <Text style={styles.bullitPara}>•&nbsp;&nbsp; Purchase/Buying situation</Text>
              </View>
              <View style={{ backgroundColor: "#fff", padding: 5 }}>
                <Text style={styles.bullitPara}>
                  •&nbsp;&nbsp; Complementary products and services analysis
                </Text>
              </View>
              <View style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
                <Text style={styles.bullitPara}>
                  •&nbsp;&nbsp; Expected retaliation and barriers to entry, i.e., ability of
                  competitors to control access to resources, key suppliers and market channels.
                </Text>
              </View>
            </View>
          ) : (
            <View>
              <Text style={styles.subPara}>
                The product/service provider have the partial ability to analyze the existing
                factors prevailing in the market, some of them are:
              </Text>
              <View
                style={{
                  backgroundColor: "#2B3A55",
                  height: 12,
                  width: "100%",
                  borderBottomWidth: 2,
                  borderBottomColor: "red",
                }}
              />
              <View style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
                <Text style={styles.bullitPara}>
                  •&nbsp;&nbsp; Customer segmentation (Target Market)
                </Text>
              </View>
              <View style={{ backgroundColor: "#fff", padding: 5 }}>
                <Text style={styles.bullitPara}>•&nbsp;&nbsp; Product features</Text>
              </View>
              <View style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
                <Text style={styles.bullitPara}>•&nbsp;&nbsp; Purchase/Buying situation</Text>
              </View>
              <View style={{ backgroundColor: "#fff", padding: 5 }}>
                <Text style={styles.bullitPara}>
                  •&nbsp;&nbsp; Complementary products and services analysis
                </Text>
              </View>
              <View style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
                <Text style={styles.bullitPara}>
                  •&nbsp;&nbsp; Expected retaliation and barriers to entry, i.e., ability of
                  competitors to control access to resources, key suppliers and market channels.
                </Text>
              </View>
              {/* <Text style={[styles.subPara, { marginTop: 8 }]}>
                Thus, the current market already has several established companies with substantial
                resources to retaliate, i.e., the competitors of{" "}
                <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> have more
                familiarity with the existing industry trends, business operations and targeted
                customers that affects the threat of new entrants. Therefore, it requires a
                significant investment to enter the market since there is a need to establish a
                brand reputation and promote it to attract many customers.
              </Text> */}
            </View>
          )}
        </View>
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text
            // render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
            fixed
          >
            Market Research Report • 5
          </Text>
        </View>
      </Page>

      <Page size={"A4"} style={{ paddingBottom: 60 }}>
        <Image
          src={BoxImage}
          style={{ width: "100%", position: "absolute", right: 0, width: 120, height: 120 }}
        />

        <View
          style={{
            paddingHorizontal: 60,
            marginTop: 60,
            textAlign: "justify",
          }}
        >
          <View style={styles.row}>
            <Text style={styles.title}>Market Demand </Text>
            <Text
              style={{ fontSize: 12, color: "#2A2A2A", fontStyle: "italic", fontFamily: "roboto" }}
            >
              {" "}
              Continued
            </Text>
          </View>
          {marketDemand[0]?.response?.text == "Yes" ? null : (
            <Text style={[styles.subPara, { marginTop: 8 }]}>
              Thus, the current market already has several established companies with substantial
              resources to retaliate, i.e., the competitors of{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> have more
              familiarity with the existing industry trends, business operations and targeted
              customers that affects the threat of new entrants. Therefore, it requires a
              significant investment to enter the market since there is a need to establish a brand
              reputation and promote it to attract many customers.
            </Text>
          )}
          <View style={[styles.row, { marginLeft: 0 }]}>
            <View style={styles.radioBtn}>
              <View
                style={[
                  styles.radioDot,
                  {
                    backgroundColor: "#F55050",
                  },
                ]}
              />
            </View>
            <View>
              <Text style={styles.subTitle}>Industry Trend</Text>
              {marketDemand[1]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {marketDemand[1]?.feedback}
                </Note>
              ) : null}
            </View>
          </View>

          <Text style={styles.subPara}>
            Industry trends are patterns or trends that occur within a specific industry. They can
            provide companies with important data such as trend analysis, statistics, references,
            considerations and guidelines to help them remain competitive in the marketplace. The
            industry trend of{" "}
            <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> is:{" "}
            <Text style={styles.highlitFont}>{marketDemand[1]?.response?.text}</Text>.
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              paddingVertical: 5,
              position: "absolute",
              top: 440,
              left: 65,
            }}
          >
            <Image src={MarketDemandIcon2} style={{ width: 60, height: 60 }} />
            <Image src={MarketDemandIcon3} style={{ width: 60, height: 60 }} />
            <Image src={MarketDemandIcon4} style={{ width: 60, height: 60 }} />
            <Image src={MarketDemandIcon5} style={{ width: 60, height: 60 }} />
            <Image src={MarketDemandIcon6} style={{ width: 60, height: 60 }} />
          </View>
        </View>

        <Image
          src={MarketDemandBottom}
          style={{ width: "100%", position: "absolute", bottom: 0 }}
        />
        {/* ============FOOTER=========== */}
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text
            // render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
            fixed
          >
            Market Research Report • 6
          </Text>
        </View>
      </Page>

      {/* =================Competition Analysis===================== */}
      <Page size="A4">
        <Image src={CompetitionAnalysis} style={{ width: "100%" }} />
        {/* <WaterMark /> */}
        <View
          style={{
            paddingHorizontal: 60,
            textAlign: "justify",
          }}
        >
          <Text style={styles.title}>Competition Analysis</Text>
          <Text style={[styles.subPara, { marginLeft: 0 }]}>
            Knowing the market competition, makes the launch of products and services of a specific
            industry to stand out in the market. The competition analysis tends to help the
            customers to get the best possible prices, quantity and quality of products or services.
          </Text>

          <View style={[styles.row, { marginLeft: 0 }]}>
            <View style={styles.radioBtn}>
              <View
                style={[
                  styles.radioDot,
                  {
                    backgroundColor: "#F55050",
                  },
                ]}
              />
            </View>
            <View>
              <Text style={styles.subTitle}>Core Competencies</Text>
              {competition[0]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {competition[0]?.feedback}
                </Note>
              ) : null}
            </View>
          </View>
          <Text style={styles.subPara}>
            Core competencies are the defining capabilities or advantages that a business has which
            distinguishes it from its competition A company’s core competency in the marketplace is
            important to define its strength which make them stand out amongst its competitors. The
            competitiveness of{" "}
            <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> in the market
            is{" "}
            {calculateCompetinces(competition[0]?.response[0]?.sliderStates?.sliderMockData, false)}
            .
          </Text>
          {/* ===========Card============= */}
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: 20,
            }}
          >
            <View style={styles.card}>
              <View
                style={{
                  marginBottom: 10,
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text style={{ color: "#000", fontSize: 11 }}>
                  {competition[0]?.response[0]?.sliderStates?.companyName}
                </Text>

                <View>
                  <Image
                    src={competition[0]?.response[0]?.sliderStates?.imageUrl}
                    style={{ width: 25, height: 25 }}
                  />
                </View>
              </View>
              {competition[0]?.response[0]?.sliderStates?.sliderMockData?.map((item, index) => {
                return (
                  <View key={index}>
                    <View
                      style={{
                        // marginTop: 5,
                        // display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Text style={styles.card_title}>{item.title}</Text>

                      {ShowSliderValues(item.value)}
                    </View>
                    {competition[0]?.response[0]?.sliderStates?.sliderMockData?.length - 1 ==
                    index ? null : (
                      <View
                        style={{
                          marginVertical: 8,
                          borderWidth: 0.5,
                          borderColor: "#a9b4c6",
                          height: 1,
                          width: "100%",
                        }}
                      />
                    )}
                  </View>
                );
              })}
            </View>
            {calculateCompetincesChart(
              competition[0]?.response[0]?.sliderStates?.sliderMockData,
              false
            )}
          </View>
        </View>
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text
            // render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
            fixed
          >
            Market Research Report • 7
          </Text>
        </View>
      </Page>

      <Page size={"A4"} style={{ paddingBottom: 60 }}>
        <Image
          src={BoxImage}
          style={{ width: "100%", position: "absolute", right: 0, width: 120, height: 120 }}
        />

        <View
          style={{
            paddingHorizontal: 60,
            marginTop: 60,
            textAlign: "justify",
          }}
        >
          <View style={styles.row}>
            <Text style={styles.title}>Competition Analysis </Text>
            <Text
              style={{ fontSize: 12, color: "#2A2A2A", fontStyle: "italic", fontFamily: "roboto" }}
            >
              {" "}
              Continued
            </Text>
          </View>
          <View style={[styles.row, { marginLeft: 0 }]}>
            <View style={styles.radioBtn}>
              <View
                style={[
                  styles.radioDot,
                  {
                    backgroundColor: "#F55050",
                  },
                ]}
              />
            </View>
            <View>
              <Text style={styles.subTitle}>Core Competency of Competitors</Text>
              {competition[1]?.feedback ? (
                <Note style={{ position: "absolute", right: 20, top: 20 }}>
                  {competition[1]?.feedback}
                </Note>
              ) : null}
            </View>
          </View>

          <Text style={styles.subPara}>
            The main competitors in an industry are all businesses that offer the same product(s) or
            service(s) and are competing for the same market, they are in general referred to as
            direct competitors. In this scenario, the main competitors of{" "}
            <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> are:
          </Text>

          {/* ===========Card============= */}
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
              marginTop: 40,
            }}
          >
            {competition[1]?.response?.map((item, index) => {
              return (
                <View style={styles.card} key={index}>
                  <View
                    style={{
                      marginBottom: 10,
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Text style={{ color: "#000", fontSize: 11 }}>
                      {item?.sliderStates?.companyName}
                    </Text>

                    <View>
                      <Image src={item?.sliderStates?.imageUrl} style={{ width: 25, height: 25 }} />
                    </View>
                  </View>
                  {item?.sliderStates?.sliderMockData?.map((item, index) => {
                    return (
                      <View key={index}>
                        <View
                          style={{
                            // marginTop: 5,
                            // display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Text style={styles.card_title}>{item.title}</Text>

                          {ShowSliderValues(item.value)}
                        </View>
                        {item?.sliderStates?.sliderMockData?.length - 1 == index ? null : (
                          <View
                            style={{
                              marginVertical: 8,
                              borderWidth: 0.5,
                              borderColor: "#a9b4c6",
                              height: 1,
                              width: "100%",
                            }}
                          />
                        )}
                      </View>
                    );
                  })}
                  {calculateCompetinces(item?.sliderStates?.sliderMockData, true)}
                </View>
              );
            })}
          </View>
        </View>
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text
            // render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
            fixed
          >
            Market Research Report • 8
          </Text>
        </View>
      </Page>

      {/* =================SWOT ANALYSIS=============== */}
      <Page size="A4">
        <Image src={SWOTImg} style={{ width: "100%" }} />
        {/* <WaterMark /> */}
        <Text
          style={{
            position: "absolute",
            top: 50,
            textAlign: "center",
            fontWeight: 600,
            fontSize: 18,
            fontFamily: "roboto",
            color: "#fff",
          }}
        >
          {" "}
          SWOT Analysis
        </Text>
        <View
          style={{
            paddingHorizontal: 60,
            textAlign: "justify",
          }}
        >
          <View>
            {competition[2]?.feedback ? (
              <Note style={{ position: "absolute", right: 20, top: 20 }}>
                {competition[2]?.feedback}
              </Note>
            ) : null}
          </View>
          <Text style={[styles.subPara, { textAlign: "center", marginVertical: 15 }]}>
            The SWOT Analysis is conducted to evaluate the{" "}
            <Text style={{ fontFamily: "roboto", fontWeight: 400 }}>strengths,</Text>{" "}
            <Text style={{ fontFamily: "roboto", fontWeight: 400 }}>weaknesses,</Text>{" "}
            <Text style={{ fontFamily: "roboto", fontWeight: 400 }}>opportunities</Text> and{" "}
            <Text style={{ fontFamily: "roboto", fontWeight: 400 }}>threats</Text> of targeted
            product or service.
          </Text>

          <View style={styles.swotHeader}>
            <View style={[styles.swotbox, { backgroundColor: "#36b24a" }]}>
              <Text style={{ fontSize: 50, color: "#fff", fontWeight: 600, fontFamily: "roboto" }}>
                S
              </Text>
              <Text
                style={{ fontSize: 10, color: "#fff", fontWeight: "bold", fontFamily: "roboto" }}
              >
                <Image src={strengthImg} style={{ width: 16, height: 16 }} /> STRENGTHS
              </Text>
            </View>
            <View style={[styles.swotbox, { backgroundColor: "#F6BF0A" }]}>
              <Text style={{ fontSize: 50, color: "#fff", fontWeight: 600, fontFamily: "roboto" }}>
                W
              </Text>
              <Text style={{ fontSize: 10, color: "#fff", fontWeight: 600, fontFamily: "roboto" }}>
                <Image src={weaknesImg} style={{ width: 16, height: 16 }} /> WEAKNESSES
              </Text>
            </View>
            <View style={[styles.swotbox, { backgroundColor: "#E1861B" }]}>
              <Text
                style={{
                  fontSize: 50,
                  color: "#fff",
                  fontWeight: 600,
                  fontFamily: "roboto",
                }}
              >
                O
              </Text>
              <Text style={{ fontSize: 10, color: "#fff", fontWeight: 400, fontFamily: "roboto" }}>
                <Image src={opportunityImg} style={{ width: 16, height: 16 }} /> OPORTUNITIES
              </Text>
            </View>
            <View style={[styles.swotbox, { backgroundColor: "#E92A20" }]}>
              <Text style={{ fontSize: 50, color: "#fff", fontWeight: 600, fontFamily: "roboto" }}>
                T
              </Text>
              <Text style={{ fontSize: 10, color: "#fff", fontFamily: "roboto" }}>
                <Image src={threatImg} style={{ width: 16, height: 16 }} /> THREATS
              </Text>
            </View>
          </View>

          <View style={styles.swotBody}>
            <View style={[styles.swotContent, { backgroundColor: "#F5FBF6" }]}>
              {competition[2]?.response?.strength?.answer?.split("•")?.map((item, index) => {
                if (item) {
                  return (
                    <View
                      key={index}
                      style={{
                        flexDirection: "row",
                        marginTop: 8,
                        paddingHorizontal: 5,
                      }}
                    >
                      <View style={styles.bullet}>
                        <View style={styles.smallradioBtn}>
                          <View
                            style={[
                              styles.smallradioDot,
                              {
                                backgroundColor: "#7B2869",
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <Text style={{ fontSize: 10, color: "#36b24a" }}>{item}</Text>
                    </View>
                  );
                } else {
                  return false;
                }
              })}
            </View>
            <View style={[styles.swotContent, { backgroundColor: "#FFFCF3" }]}>
              {competition[2]?.response?.weakness?.answer?.split("•")?.map((item, index) => {
                if (item) {
                  return (
                    <View
                      key={index}
                      style={{ flexDirection: "row", marginTop: 8, paddingHorizontal: 5 }}
                    >
                      <View style={styles.bullet}>
                        <View style={styles.smallradioBtn}>
                          <View
                            style={[
                              styles.smallradioDot,
                              {
                                backgroundColor: "#7743DB",
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <Text style={{ fontSize: 10, color: "#F6BF0A" }}>{item}</Text>
                    </View>
                  );
                } else {
                  return false;
                }
              })}
            </View>
            <View style={[styles.swotContent, { backgroundColor: "#FDF6EF" }]}>
              {competition[2]?.response?.opportunity?.answer?.split("•")?.map((item, index) => {
                if (item) {
                  return (
                    <View
                      key={index}
                      style={{ flexDirection: "row", marginTop: 8, paddingHorizontal: 5 }}
                    >
                      <View style={styles.bullet}>
                        <View style={styles.smallradioBtn}>
                          <View
                            style={[
                              styles.smallradioDot,
                              {
                                backgroundColor: "#10A19D",
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <Text style={{ fontSize: 10, color: "#E1861B" }}>{item}</Text>
                    </View>
                  );
                } else {
                  return false;
                }
              })}
            </View>
            <View style={[styles.swotContent, { backgroundColor: "#FDEEEE" }]}>
              {competition[2]?.response?.threat?.answer?.split("•")?.map((item, index) => {
                if (item) {
                  return (
                    <View
                      key={index}
                      style={{ flexDirection: "row", marginTop: 8, paddingHorizontal: 5 }}
                    >
                      <View style={styles.bullet}>
                        <View style={styles.smallradioBtn}>
                          <View
                            style={[
                              styles.smallradioDot,
                              {
                                backgroundColor: "#F55050",
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <Text style={{ fontSize: 10, color: "#E92A20" }}>{item}</Text>
                    </View>
                  );
                } else {
                  return false;
                }
              })}
            </View>
          </View>
        </View>
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text
            // render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
            fixed
          >
            Market Research Report • 9
          </Text>
        </View>
      </Page>

      {/* =================Barriers to Entry===================== */}
      <Page size="A4">
        {/* <WaterMark /> */}
        <Image src={BarrierstoEntry} style={{ width: "100%" }} />

        <View
          style={{
            paddingHorizontal: 60,
            textAlign: "justify",
          }}
        >
          <View>
            <Text style={styles.title}>Barriers to Entry</Text>
            {barriersToEntry[0]?.feedback ? (
              <Note style={{ position: "absolute", right: 20, top: 30 }}>
                {barriersToEntry[0]?.feedback}
              </Note>
            ) : null}
          </View>
          <Text style={styles.para}>
            Barriers to entry is described as the forces that deter company or businesses from
            entering the new market. This may come in the form of high start-up costs, government
            regulations, the need for licenses, and to compete with large corporations. Following
            are the common barriers to entry for the{" "}
            <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text>.
          </Text>

          <View
            style={{
              backgroundColor: "#EFF2F9",
              borderLeftWidth: 5,
              borderLeftColor: "#C72D42",
              paddingHorizontal: 30,
              marginBottom: 15,
            }}
          >
            <Text style={styles.subTitle}>a) Investment</Text>
            <Text style={styles.para}>
              Investment is the procurement of money that is acquired by the company to pursue its
              organizational objectives. As per the analysis, Rated stars:{" "}
              <Text style={styles.highlitFont}>{barriersToEntry[0]?.response[0]?.rating}</Text>
            </Text>
          </View>

          <View
            style={{
              backgroundColor: "#F2F2F2",
              borderLeftWidth: 5,
              borderLeftColor: "#7211E3",
              paddingHorizontal: 30,
              marginBottom: 15,
            }}
          >
            <Text style={styles.subTitle}>b) Technology</Text>
            <Text style={styles.para}>
              The barriers to entry may be technological, that prevent potential customers from
              entering the market. The technology results in enhancing product performance and
              serves as facilitator for the competitive advantage. Because of superior technology,
              the existing firms enjoy a cost advantage over the potential entrants. Rated stars:{" "}
              <Text style={styles.highlitFont}>{barriersToEntry[0]?.response[1]?.rating}</Text>
            </Text>
          </View>

          <View
            style={{
              backgroundColor: "#F0F4FC",
              borderLeftWidth: 5,
              borderLeftColor: "#F3BD17",
              paddingHorizontal: 30,
              marginBottom: 15,
            }}
          >
            <Text style={styles.subTitle}>c) Brand Marketing</Text>
            <Text style={styles.para}>
              Brand marketing tends to promote the products and services that highlight an overall
              brand image of the product or service. It is the process of establishing relationship
              between brand and the customer. Strong brand identity and customer loyalty are one of
              the most common yet significant barriers to entry. Rated stars:{" "}
              <Text style={styles.highlitFont}>{barriersToEntry[0]?.response[2]?.rating}</Text>
            </Text>
            <Text style={styles.para}>
              Therefore, the Start-ups must find an effective positioning, which often requires
              marketing resources beyond their means.
            </Text>
          </View>
        </View>
        {/* =============FOOTER=========== */}
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text
            // render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
            fixed
          >
            Market Research Report • 10
          </Text>
        </View>
      </Page>

      <Page size={"A4"} style={{ paddingBottom: 60 }}>
        <Image
          src={BoxImage}
          style={{ width: "100%", position: "absolute", right: 0, width: 120, height: 120 }}
        />
        <View
          style={{
            paddingHorizontal: 60,
            marginTop: 60,
            textAlign: "justify",
          }}
        >
          <View style={styles.row}>
            <Text style={styles.title}>Barriers to Entry </Text>
            <Text
              style={{ fontSize: 12, color: "#2A2A2A", fontStyle: "italic", fontFamily: "roboto" }}
            >
              {" "}
              Continued
            </Text>
          </View>

          <View
            style={{
              backgroundColor: "#F0F4FC",
              borderLeftWidth: 5,
              borderLeftColor: "#2D17E3",
              paddingHorizontal: 30,
              marginBottom: 15,
            }}
          >
            <Text style={styles.subTitle}>d) Regulation</Text>
            <Text style={styles.para}>
              The government may limit or restrict entry into a market by imposing licencing
              requirements or controlled access to raw materials. These barriers are intended to
              protect public safety but have the unintended effect of favouring incumbent
              businesses. Rated stars:{" "}
              <Text style={styles.highlitFont}>{barriersToEntry[0]?.response[3]?.rating}</Text>
            </Text>
          </View>

          <View
            style={{
              backgroundColor: "#F0F4FC",
              borderLeftWidth: 5,
              borderLeftColor: "#367BD8",
              paddingHorizontal: 30,
              marginBottom: 15,
            }}
          >
            <Text style={styles.subTitle}>e) Access to resources</Text>
            <Text style={styles.para}>
              <Text style={styles.highlitFont}>{barriersToEntry[0]?.response[4]?.rating}</Text>
            </Text>
          </View>

          <View
            style={{
              backgroundColor: "#F0F4FC",
              borderLeftWidth: 5,
              borderLeftColor: "#11D0B1",
              paddingHorizontal: 30,
              marginBottom: 15,
            }}
          >
            <Text style={styles.subTitle}>f) Access to distribution</Text>
            <Text style={styles.para}>
              Distribution channels are a key element in the entire marketing strategy that revolves
              around a product. They can also be referred to as a set of interdependent
              intermediaries that help make a product available to the end customer. When access to
              distribution channels is an entry barrier – it can both make an industry more
              competitive and lower the profit potential for the existing competitors in the market.
              The expected reaction of industry incumbents towards a new entrant influences the
              prospect or threat of entry by a new competitor. This means that if there are a
              limited number of wholesale or retail distribution channels, or existing firms
              (competitors) have exclusive long-term contracts with distributors, this may cause a
              threat for the{" "}
              <Text style={styles.highlitFont}>{props?.currentProjectData?.title}</Text> to reach
              the customer as new entrant. Rated stars:{" "}
              <Text style={styles.highlitFont}>{barriersToEntry[0]?.response[5]?.rating}</Text>
            </Text>
          </View>
        </View>

        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text fixed>Market Research Report • 11</Text>
        </View>
      </Page>

      {/* =================Glossary===================== */}
      <Page size="A4">
        {/* <WaterMark /> */}
        <View
          style={{
            paddingHorizontal: 60,
            paddingTop: 60,
            textAlign: "justify",
          }}
        >
          <Text style={styles.title}>Glossary</Text>
          {glossaryData1.map((item, index) => {
            return (
              <View style={styles.glossaryRow} key={index}>
                <View style={styles.bullet}>
                  <Text style={styles.glossarySubTitle}>•&nbsp;&nbsp; </Text>
                </View>
                <Text style={styles.glossaryBullitPara}>
                  <Text style={styles.glossarySubTitle}>{item.title} </Text>
                  {item.description}
                </Text>
              </View>
            );
          })}
        </View>

        {/* =================FOOTER============== */}
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text fixed>Market Research Report • 12</Text>
        </View>
      </Page>

      <Page size={"A4"} style={{ paddingBottom: 60 }}>
        <Image
          src={BoxImage}
          style={{ width: "100%", position: "absolute", right: 0, width: 120, height: 120 }}
        />
        <View
          style={{
            paddingHorizontal: 60,
            marginTop: 60,
            textAlign: "justify",
          }}
        >
          <View style={styles.row}>
            <Text style={styles.title}>Glossary </Text>
            <Text
              style={{ fontSize: 12, color: "#2A2A2A", fontStyle: "italic", fontFamily: "roboto" }}
            >
              {" "}
              Continued
            </Text>
          </View>

          {glossaryData2.map((item, index) => {
            return (
              <View style={styles.glossaryRow} key={index}>
                <View style={styles.bullet}>
                  <Text style={styles.glossarySubTitle}>•&nbsp;&nbsp; </Text>
                </View>
                <Text style={styles.glossaryBullitPara}>
                  <Text style={styles.glossarySubTitle}>{item.title} </Text>
                  {item.description}
                </Text>
              </View>
            );
          })}

          <View style={styles.glossaryRow}>
            <View style={styles.bullet}>
              <Text style={styles.glossarySubTitle}>•&nbsp;&nbsp; </Text>
            </View>
            <Text style={styles.glossaryBullitPara}>
              <Text style={styles.glossarySubTitle}>SWOT: </Text>
            </Text>
          </View>

          {swotData.map((item, index) => {
            return (
              <View style={[styles.glossaryRow, { marginLeft: 30 }]} key={index}>
                <View style={styles.bullet}>
                  <Text style={styles.glossarySubTitle}>•&nbsp;&nbsp; </Text>
                </View>
                <Text style={styles.glossaryBullitPara}>
                  <Text style={styles.glossarySubTitle}>{item.title} </Text>
                  {item.description}
                </Text>
              </View>
            );
          })}
        </View>

        {/* =================FOOTER============== */}
        <View style={styles.pageNumber}>
          <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
          <Text fixed>Market Research Report • 13</Text>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};
export default MarketResearchReport;

// Create styles
const styles = StyleSheet.create({
  pdfViewer: {
    height: "100vh",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5F5",
    padding: 10,
    position: "absolute",
    top: 0,
  },
  headerText: {
    fontSize: 18,
    textAlign: "center",
  },
  body: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingHorizontal: 65,
    textAlign: "justify",
  },
  title: {
    marginTop: 12,
    marginBottom: 12,
    fontSize: 16,
    fontFamily: "roboto",
    fontWeight: "bold",
    color: "#2A2A2A",
  },
  subTitle: {
    fontSize: 13,
    fontWeight: 400,
    fontFamily: "roboto",
    color: "#2A2A2A",
    marginTop: 8,
    marginBottom: 5,
    zIndex: 1,
  },
  para: {
    display: "inline-block",
    marginBottom: 6,
    fontSize: 12,
    color: "#2A2A2A",
    lineHeight: 1.2,
    marginLeft: 0,
    fontFamily: "roboto",
    fontWeight: "light",
  },
  bullitPara: {
    display: "inline-block",
    // marginBottom: 4,
    fontSize: 12,
    color: "#2A2A2A",
    lineHeight: 1.2,
    marginLeft: 0,
    fontFamily: "roboto",
    fontWeight: "light",
  },
  subPara: {
    display: "inline-block",
    marginBottom: 6,
    fontSize: 12,
    color: "#2A2A2A",
    lineHeight: 1.3,
    letterSpacing: 0.2,
    marginLeft: 16,
    fontFamily: "roboto",
    fontWeight: "light",
  },

  highlitFont: {
    color: "#2A2A2A",
    fontSize: 12,
    fontFamily: "roboto",
    fontWeight: 400,
  },
  rowDirection: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },

  image: {
    height: 80,
    width: "80%",
    marginVertical: 30,
    // marginHorizontal: 100,
  },
  pageNumber: {
    alignItems: "center",
    position: "absolute",
    fontSize: 12,
    fontFamily: "roboto",
    bottom: 20,
    // left: "10%",
    width: "100%",
    color: "grey",
    // textAlign: "center",
  },

  // ==================card================

  card: {
    marginRight: 10,
    marginBottom: 20,
    paddingHorizontal: 12,
    paddingVertical: 15,
    // border: "1px solid #a9b4c6",
    borderWidth: 1,
    borderColor: "#a9b4c6",
    elevation: 5,
    borderRadius: 8,
    // boxShadow: "0px 0px 3px #a9b4c6",
    // backgroundColor: "red",
    width: 150,
  },
  card_title: {
    fontSize: 8,
    color: "#565656",
    // fontWeight: 500,
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // width: "80%",
  },
  value: {
    fontSize: 8,
    // color: "#4e4e4e",
  },
  //   ----------List item
  row: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 10,
    alignItems: "center",
  },
  bullet: {
    height: "100%",
  },

  glossaryRow: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 10,
    marginTop: 4,
  },
  glossarySubTitle: {
    fontSize: 12,
    fontWeight: 400,
    color: "#2A2A2A",
    marginBottom: 6,
    zIndex: 1,
    fontFamily: "roboto",
  },
  glossaryBullitPara: {
    display: "inline-block",
    fontSize: 10,
    color: "#2A2A2A",
    lineHeight: 1.3,
    marginLeft: 0,
    fontFamily: "roboto",
    fontWeight: "light",
    letterSpacing: 0.2,
  },

  backgroundImage: {
    zIndex: 0,
    position: "relative",
    // height: 845,
  },

  swotHeader: {
    flex: 1,
    flexDirection: "row",
  },
  swotbox: {
    width: "25%",
    textAlign: "center",
    padding: 8,
  },

  swotBody: {
    flex: 1,
    flexDirection: "row",
  },
  swotContent: {
    padding: 5,
    width: "25%",
    textAlign: "left",
  },

  canvas: {
    backgroundColor: "black",
    height: 500,
    width: 500,
  },

  circle: {
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    borderColor: "#E7F6F2",
    borderWidth: 2,
  },

  circle_one: {
    left: 0,
    top: 0,
    width: 65,
    height: 65,
    backgroundColor: "#7286D3",
  },

  circle_two: {
    top: 10,
    left: 70,
    width: 60,
    height: 60,
    backgroundColor: "#7978FF",
  },

  circle_three: {
    top: 0,
    left: 130,
    width: 50,
    height: 50,
    backgroundColor: "#8D8DAA",
  },

  circle_four: {
    top: 0,
    left: 190,
    width: 65,
    backgroundColor: "#7286D3",
    height: 65,
  },

  circle_five: {
    top: 50,
    left: 40,
    width: 40,
    backgroundColor: "#E83D68",
    height: 40,
  },

  circle_six: {
    top: 60,
    left: 100,
    width: 80,
    backgroundColor: "#00D7FF",
    height: 80,
  },

  circle_seven: {
    top: 90,
    left: 25,
    width: 68,
    backgroundColor: "#7286D3",
    height: 68,
  },

  circle_eight: {
    top: 80,
    right: 25,
    width: 95,
    backgroundColor: "#11D0B1",
    height: 95,
  },
  circleText: {
    fontSize: 10,
    color: "#fff",
  },

  radioBtn: {
    height: 10,
    width: 10,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "#7B8FA1",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
    marginTop: 3,
  },

  radioDot: {
    height: 5,
    width: 5,
    borderRadius: 6,
  },

  smallradioBtn: {
    height: 6,
    width: 6,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "#7B8FA1",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
    marginTop: 2,
  },

  smallradioDot: {
    height: 2,
    width: 2,
    borderRadius: 6,
  },

  title25: {
    fontSize: 21,
    fontWeight: 400,
    fontFamily: "roboto",
    color: "#2A2A2A",
    textAlign: "center",
  },
  title20: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "roboto",
    color: "#32037C",
  },
  headingRow: {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
  },
  tableNumber: {
    fontSize: 22,
    fontWeight: 400,
    fontFamily: "roboto",
    color: "#686868 ",
  },
  font12: {
    fontSize: 10,
    fontWeight: "light",
    fontFamily: "roboto",
    color: "#2A2A2A",
    marginBottom: 3,
  },
});
