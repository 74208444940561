import {authModules} from '../../config/axiosConfig'
import { getCookie } from '../../utils/cookies'
import {ADD_TEAM_MEMBER} from '../constants/teams'
import getAllProjects from './getAllProjects'
import { getAllTeamMembers } from './getAllTeamMembers'
import {actions} from '../../utils/helperFunctions'

export const postAddTeamMember = (payLoad,projectId) => async dispatch =>{
    dispatch(actions.loading(ADD_TEAM_MEMBER))
    try {

        let apiCall = await authModules.post(`/api/teams/addTeam/${projectId}`,payLoad,{
            headers:{
                authorization: getCookie('token')
            }
        })
        dispatch(actions.success(ADD_TEAM_MEMBER,apiCall.data.data))
        dispatch(getAllProjects())
        dispatch(getAllTeamMembers())
        // dispatch()   /* update team member by calling getTeamMember */
     } catch (err) {
        dispatch(actions.failure(ADD_TEAM_MEMBER,err.response?err.response.data.error:err))
    }
}

export const addTeamMemberSuccess = (data) =>{
    return{
        type: ADD_TEAM_MEMBER.SUCCESS ,
        payLoad: data
    }
}

export const resetTeamMemberState = () =>{
    return{
        type: ADD_TEAM_MEMBER.RESET
    }
}