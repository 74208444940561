import React, { useState } from "react";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import styled from "styled-components";
import { myColor } from "../GlobalVar";
import { CloudUploadOutlined } from "@ant-design/icons";
import { getCookie } from "../utils/cookies";
import cogoToast from "cogo-toast";

const ImgeUpload = props => {
  const { fileListState, setFileListState, from } = props;

  const [fileList, setFileList] = useState([]);

  const getFileListState = () => {
    if (from == "ideaSolution") {
      return fileListState.ideaSolutionFileList;
    } else if (from == "problemStatement") {
      return fileListState.problemStateFileList;
    }
  };

  const settingfileListState = val => {
    if (from == "ideaSolution") {
      setFileListState({ ...fileListState, ideaSolutionFileList: val });
    } else if (from == "problemStatement") {
      setFileListState({ ...fileListState, problemStateFileList: val });
    } else if (from == "ideaName") {
      props.setProjectImage && props.setProjectImage(val);
    }
  };

  React.useEffect(() => {
    if (getFileListState()) {
      setFileList(getFileListState());
    }
  }, [props]);

  const onChange = ({ fileList: newFileList, file: newFile }) => {
    // props.getUpdatedFileList(newFileList)
    props.disable || settingfileListState(newFileList);
    props.disable || setFileList(newFileList);
  };

  const onPreview = async file => {
    let src = file.url;
    if (src) {
      window.open(file.url, "_blank");
    } else {
      let url =
        file.status == "done"
          ? `${process.env.REACT_APP_REMOTE_FILE_FOLDER}/${file.response.data.docName}`
          : null;
      url && window.open(url, "_blank");
    }

    // let src = file.url;
    // if (!src) {
    //   src = await new Promise(resolve => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file.originFileObj);
    //     reader.onload = () => resolve(reader.result);
    //   });
    // }
    // const image = new Image();
    // image.src = src;
    // const imgWindow = window.open(src);
    // imgWindow.document.write(image.outerHTML);
  };

  const onDownload = async file => {
    let src = file.url;
    if (src) {
      window.open(file.url, "_blank");
    } else {
      let url =
        file.status == "done"
          ? `${process.env.REACT_APP_REMOTE_FILE_FOLDER}/${file.response.data.docName}`
          : null;
      url && window.open(url, "_blank");
    }
  };

  const beforeUpload = file => {
    // let fileSize = false
    if (from == "ideaSolution" || from == "problemStatement") {
      // setFileListState({ ...fileListState, ideaSolutionFileList: val })
      let fileSize = file.size / 1024 / 1024 < 5;
      if (!fileSize) {
        cogoToast.error("File size must be less than 5 mb");
      }
      return fileSize;
    } else if (from == "ideaName") {
      // props.setProjectImage && props.setProjectImage(val)
      let fileSize = file.size / 1024 / 1024 < 5;
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!fileSize) {
        cogoToast.error("File size must be less than 5 mb");
      } else if (!isJpgOrPng) {
        cogoToast.error("File must be jpg/png");
      }
      return fileSize && isJpgOrPng;
    }
  };

  return (
    <div>
      {props.projectIcon ? (
        <>
          <ImageUploadWrapper {...props} width="72px" height="72px">
            <Upload
              name="file"
              accept="image/png, image/jpeg"
              action={`${process.env.REACT_APP_IDEA_VALIDATION}/api/projects/uploadFile`}
              headers={{
                authorization: getCookie("token"),
              }}
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              maxCount={1}
              multiple={true}
              // defaultFileList
            >
              {fileList.length < 1 && (
                <div>
                  {<CloudUploadOutlined style={{ fontSize: 30 }} />}
                  <div style={{ marginTop: 2 }}>Click to upload</div>
                </div>
              )}
            </Upload>
          </ImageUploadWrapper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span style={{ color: "gray", fontSize: 15 }}>({props.maxFile})</span>
          </div>
        </>
      ) : (
        <>
          <ImageUploadWrapper {...props}>
            {/* <ImgCrop rotate> */}
            <Upload
              name="file"
              accept="image/png, image/jpeg, .doc, .docx, .pdf"
              action={`${process.env.REACT_APP_IDEA_VALIDATION}/api/projects/uploadFile`}
              headers={{
                authorization: getCookie("token"),
              }}
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              onDownload={onDownload}
              showUploadList={{ showDownloadIcon: true }}
              maxCount={10}
              multiple={true}
              beforeUpload={beforeUpload}
            >
              {fileList.length < 10 && props.disable == false && (
                <div>
                  {<CloudUploadOutlined style={{ fontSize: 50 }} />}
                  <div style={{ marginTop: 2 }}>Click here to Upload</div>
                </div>
              )}
            </Upload>
            {/* </ImgCrop> */}
          </ImageUploadWrapper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {props.optional && (
              <span
                style={{ color: "gray", fontSize: 15, float: "right", width: "4em" }}
              >{`Optional `}</span>
            )}
            <span style={{ color: "gray", fontSize: 15 }}>({props.maxFile} file max)</span>
          </div>
        </>
      )}
    </div>
  );
};

export default ImgeUpload;

const ImageUploadWrapper = styled.div`
  margin-top: 5px;
  border-radius: 8px;
  padding: 8px;
  border: 2px dashed ${myColor.light_gray};
  overflow: auto;
  white-space: nowrap;

  .ant-upload.ant-upload-select-picture-card {
    width: ${props => props.width};
    height: ${props => props.height};
    border: none;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px;
    .ant-upload {
      width: 100%;
    }
  }
  .ant-upload-list-picture-card-container {
    width: ${props => props.width};
    height: ${props => props.height};
  }

  .ant-upload-list {
    width: 100%;
  }

  /* width */
  ::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border: 0.1px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b8b8b8;
  }
`;
