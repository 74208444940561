import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Collapse, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Heading from "./header";
// import Link from "next/link";
import { Fade } from "react-awesome-reveal";

const StyledDiv = styled.div`
  background-color: #fff;
  padding: 3% 10%;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #2a2a2a;
    border-top: 1px solid rgb(221, 223, 225) !important;
    border-right: 1px solid rgb(221, 223, 225) !important;
    border-left: 1px solid rgb(221, 223, 225) !important;
    border-bottom: 1px solid rgb(221, 223, 225) !important;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    border-top: 1px solid rgb(221, 223, 225);
    border-right: 1px solid rgb(221, 223, 225);
    border-left: 1px solid rgb(221, 223, 225);
    border-bottom: 1px solid rgb(221, 223, 225);
    padding: 16px 36px 16px 16px;

    font-size: 18px;
    color: rgb(78, 78, 78);
    font-weight: 500;
    cursor: pointer;
  }

  .ant-collapse-content {
    // border-top: 1px solid rgb(221, 223, 225) !important;
    border-right: 1px solid rgb(221, 223, 225) !important;
    border-left: 1px solid rgb(221, 223, 225) !important;
    border-bottom: 1px solid rgb(221, 223, 225) !important;
    p {
      font-size: 14px;
      color: #4c4a4a;
      font-weight: 500;
      line-height: 24px;
      margin: 0px;
    }
  }

  .ant-collapse-header {
    font-size: 18px;
    font-weight: 600;
    color: #2a2a2a;
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      color: #1a5cff !important;
    }
  }

  .ant-collapse-borderless > .ant-collapse-item-active {
    .ant-collapse-header {
      border-bottom: 0px !important;
      transition: 0.5s ease;
    }
  }

  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 16px;
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 0px;
    margin-bottom: 24px;
    background-color: #fff;
  }

  .ant-collapse-borderless {
    background-color: #fff;
  }

  @media (max-width: 991px) {
  }

  @media (max-width: 767px) {
    .ant-collapse-content {
      p {
        font-size: 14x;
      }
    }
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
  }
`;

const FaqSelection = props => {
  const { Panel } = Collapse;
  return (
    <StyledDiv>
      <Fade direction="up" triggerOnce duration={1300} damping={0.2}>
        <Heading title="FAQ's - Frequently asked questions"></Heading>
      </Fade>
      <Row gutter={[40, 10]}>
        {/* 1st faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion={false}
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How does Validatr.io differentiate itself from other idea validation tools?"
              key="1"
            >
              <p>
                By leveraging the power of AI, Validatr.io provides a holistic solution to validate
                and refine your ideas effectively.Validatr.io is the differentiator as it brings
                together various sources of information and expertise, including rephrasing your
                statements, 5 factor rating by your targeted market buyer’s persona, insights from
                VC firm executives, and feedback from industry professionals; all impersonated by
                AI. This comprehensive approach helps streamline your idea validation journey,
                making it easier and more mature.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 2nd faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How can Validatr.io help me make my idea validation journey easy and mature?"
              key="2"
            >
              <p>
                Validatr.io serves as a valuable resource throughout the idea validation process,
                offering AI-powered guidance and support to startups seeking to validate their
                business ideas effectively. By automating tasks such as rephrasing, analysis, and
                gathering feedback, it saves you time and effort. The AI-powered insights and
                recommendations enable you to make informed decisions, refine your ideas, and
                present them more effectively. With Validatr.io, you can navigate your idea
                validation journey with confidence and maturity.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 3rd faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How can Validatr.io help me with my problem or solution statement?"
              key="3"
            >
              <p>
                Validatr's AI can rephrase your problem or solution statement, providing alternative
                ways of expressing your ideas. This helps you refine and clarify your message,
                making it more effective in communicating your concept to others.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 4th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What aspects does Validatr.io analyze for both the problem statement and solution statement, and how does this analysis contribute to idea validation??"
              key="4"
            >
              <p>
                Validatr.io conducts a comprehensive analysis of the problem statement, assessing
                its relevance to the target market. For the solution statement, Validatr.io
                evaluates the factors of usefulness, uniqueness, likeliness to promote, and
                willingness to buy, providing valuable insights for idea validation.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 5th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What difference does it make when validatr.io provides suggestions and comments from VC firm executives?"
              key="5"
            >
              <p>
                When the rating on 5 factors is received from the targeted market buyers, it is sent
                to AI-impersonated VC firm executive for their suggestions and comments and made
                part of the report. You gain essential insights from someone who would analyze your
                business when you go to a Venture Capital firm for raising investments for your
                business. This AI-driven feedback helps you fine-tune your idea for greater success.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 6th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What kind of feedback can I expect from industry professionals on my idea?"
              key="6"
            >
              <p>
                Validatr.io collects feedback from industry professionals that are impersonated by
                AI. Their feedback can range from identifying potential challenges and pitfalls to
                offering insights on market demand and scalability. This feedback is valuable in
                refining your idea and making informed decisions during the idea validation process.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 7th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How many metrics does Validatr offer in simplifying the whole idea validation process?"
              key="7"
            >
              <p>
                Validatr offers five unique metrics that simplify the idea validation process
                significantly.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 8th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How can the Power to Succeed metric assist me in validating my idea?"
              key="8"
            >
              <p>
                The Power to succeed metric provides a comprehensive evaluation of your idea's
                potential for success. By considering multiple factors and the overall confidence of
                evaluators, it gives you an aggregated measure of your idea's likelihood of success.
                This helps you understand the strengths and weaknesses of your idea, allowing you to
                make informed decisions during the validation process.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 9th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How does the Intensity of the Problem metric help me in idea validation?"
              key="9"
            >
              <p>
                The Intensity of the Problem metric helps you assess the significance of the problem
                your idea aims to solve. By understanding the gravity of the problem, you can better
                appreciate its urgency and relevance in the market. This metric assists in
                prioritizing ideas that address pressing problems and have a higher potential for
                success.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 10th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What role does the Wow Factor play in my idea validation journey?"
              key="10"
            >
              <p>
                The Wow Factor focuses on creating a unique and captivating experience for your
                potential customers. It evaluates how well your idea stands out from competitors and
                leaves a positive impression on buyers. By emphasizing the distinctiveness of your
                idea, this metric helps you attract and engage customers, making your validation
                journey more impactful.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 11th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How does the Problem Solution Impact metric contribute to my idea validation process?"
              key="11"
            >
              <p>
                The Problem Solution Fitness factor evaluates the alignment between the identified
                problem and your proposed solution. It ensures that your idea not only addresses a
                relevant problem but also offers a useful solution. By considering both aspects
                together, this factor guides you in validating ideas that have higher fitness
                increasing their potential for success.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 12th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What is the significance of the Idea Propagation Score metric in idea validation?"
              key="12"
            >
              <p>
                The Idea Propagation Score factor helps you assess the potential reach and organic
                spread of your idea among the target audience. It considers factors like virality,
                scalability, and the ability to create a network effect. By prioritizing ideas with
                higher propagation potential, this factor allows you to focus on ideas that can gain
                traction and maximize user engagement, enhancing the overall impact of your
                validation journey.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 13th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How do these metrics enhance the overall impact of my idea validation journey?"
              key="13"
            >
              <p>
                Validatr.io factors help you gain a deeper understanding of your ideas, their
                potential, and their fit in the market. By evaluating key factors and considering
                different perspectives, these metrics guide you toward refining and optimizing your
                ideas for greater success. This comprehensive approach increases the impact of your
                idea validation journey by enabling you to focus on ideas with higher potential and
                alignment, maximizing your chances of achieving desired outcomes.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 14th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="In the idea validation journey, how many factors does Validatr.io 
provide assistance with?"
              key="14"
            >
              <p>
                Validatr.io provides assistance with five essential factors in the validation
                journey: Relevance of the Problem, Usefulness of the Solution, Uniqueness of the
                Solution, Likeliness to Promote, and Willingness to Buy.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 15th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What is the Relevance of the problem, and why is it crucial for idea 
validation?"
              key="15"
            >
              <p>
                The “Relevance of the Problem” refers to how significant and widespread the problem
                is in the target market. Assessing the relevance helps determine if there is a
                genuine need or demand for a solution. By evaluating the relevance, you can validate
                if your idea addresses a problem that people are actively seeking a solution for,
                making the validation journey more impactful and increasing the chances of success.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 16th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How does the Usefulness of the solution affect the idea validation 
process?"
              key="16"
            >
              <p>
                The “Usefulness of the Solution” measures the extent to which your idea effectively
                solves the identified problem. It is essential to assess whether your solution
                provides practical benefits, improves existing processes, or offers a new approach
                altogether. By focusing on the usefulness, you can validate the value proposition of
                your idea, ensuring it fulfills customer needs and making the validation journey
                easier.
              </p>
            </Panel>
          </Collapse>
        </Col>

        {/* 17th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What role does the Uniqueness of the solution play in idea 
validation?"
              key="17"
            >
              <p>
                The “Uniqueness of the Solution” evaluates how different or innovative your idea is
                compared to existing alternatives. Assessing the uniqueness helps you determine if
                your idea stands out in the market, giving it a competitive advantage. By
                emphasizing the uniqueness, you can validate if your idea offers a novel approach
                that differentiates it from competitors, making the validation journey impactful and
                increasing the likelihood of success.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 18th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How does the Likeliness to promote factor affect the idea validation 
process?"
              key="18"
            >
              <p>
                The “likeliness to Promote” refers to the potential for your idea to attract
                attention, generate interest, and gain traction in the market. It is important to
                assess if your idea has the potential to be marketed effectively, whether through
                advertising, word-of-mouth, or other promotional strategies. By considering the
                likeliness to promote, you can validate if your idea has the necessary
                characteristics to generate awareness and interest, making the validation journey
                easier.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 19th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="Why is the willingness to buy an important factor in idea validation?"
              key="19"
            >
              <p>
                The “Willingness to Buy” assesses whether your target customers are willing to pay
                for your solution. It helps validate the demand for your idea and determines if
                customers perceive enough value to make a purchase. By focusing on the willingness
                to buy, you can validate the market potential of your idea, ensuring that there is a
                viable customer base ready to adopt and pay for your solution, making the validation
                journey impactful and increasing the chances of success.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 20th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What benefits can be derived from considering all these factors 
during the idea validation journey?"
              key="20"
            >
              <p>
                When you consider the relevance of the problem, usefulness of the solution,
                uniqueness of the solution, likeliness to promote, and willingness to buy
                collectively, you unlock several benefits. These include a stronger product- market
                fit, increased customer adoption, and satisfaction, a competitive edge over
                alternatives, higher market demand, improved marketing strategies, and enhanced
                revenue potential. By leveraging these factors, you can significantly increase the
                impact and ease of your idea validation journey, setting the stage for a successful
                product or solution launch.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 21th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="Why is identifying the target market an important step in the 
market research phase?"
              key="21"
            >
              <p>
                Identifying the target market helps you define and understand the specific group of
                consumers who are most likely to be interested in and benefit from your product or
                service. By identifying the target market, you can tailor your marketing efforts,
                product features, and messaging to effectively reach and engage the right audience,
                increasing the chances of success for your B2C idea.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 22th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What role does market size play in the market research phase of 
Validatr.io's B2C process?"
              key="22"
            >
              <p>
                Assessing the market size allows you to understand the potential customer base for
                your B2C idea. It helps you gauge the scale of the market opportunity and estimate
                the revenue potential. By evaluating the market size, you can make informed
                decisions about resource allocation, market penetration strategies, and growth
                potential for your B2C product or service.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 23th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="How does understanding market demand contribute to the market 
research phase?"
              key="23"
            >
              <p>
                Understanding market demand involves determining the level of interest and needs for
                your B2C offering in the target market. By analyzing market demand, you can assess
                the potential customer adoption rate, identify any unmet needs or pain points, and
                validate the value proposition of your idea.This insight helps you refine your
                product or service to better meet customer expectations and increase market
                acceptance.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 24th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="Why is assessing competition an essential aspect of market 
research in Validatr.io's B2C process?"
              key="24"
            >
              <p>
                Assessing competition allows you to understand the existing alternatives and
                potential competitors in the market. By evaluating the competitive landscape, you
                can identify key players, analyze their strengths and weaknesses, and uncover
                opportunities for differentiation. This knowledge helps you position your B2C idea
                effectively, develop unique selling points, and create strategies to gain a
                competitive edge.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 25th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="What are the barriers to entry, and why should they be considered 
during market research?"
              key="25"
            >
              <p>
                Barriers to entry refer to factors or obstacles that may make it challenging for new
                entrants to penetrate a market. Understanding the barriers to entry helps you assess
                the level of competition, potential challenges, and potential risks associated with
                entering the market. By considering these barriers, you can develop strategies to
                overcome or mitigate them, ensuring a smoother entry and increasing your chances of
                success in the B2C market.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 26th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel header="How can Validatr.io's input assist in validating B2B ideas?" key="26">
              <p>
                Validatr's input can assist in validating B2B ideas by providing valuable insights
                and analysis based on the factors like market trends, industry dynamics, competitive
                landscape, and customer needs. It helps in evaluating the feasibility, market
                potential, and viability of B2B ideas, enabling entrepreneurs to make informed
                decisions and refine their offerings to meet the demands of the business market.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 27th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="Why is it important to identify the target audience in the B2B 
market research phase?"
              key="27"
            >
              <p>
                Identifying the target audience helps you determine the specific businesses or
                organizations that are most likely to benefit from your B2B product or service. By
                identifying the target audience, you can tailor your marketing efforts, sales
                strategies, and product features to effectively meet the needs and requirements of
                your potential B2B customers, increasing the chances of success.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 28th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel header="What role does market demand play in B2B market research?" key="28">
              <p>
                Assessing market demand in the B2B context involves understanding the needs, pain
                points, and priorities of businesses or organizations within your target market. By
                analyzing market demand, you can identify opportunities for your B2B solution. After
                identifying the needs you can validate the value proposition and refine your
                offering to better align with customer requirements.
              </p>
            </Panel>
          </Collapse>
        </Col>
        {/* 29th faq */}
        <Col
          xxl={props.singleCol == true ? 24 : 12}
          xl={props.singleCol == true ? 24 : 12}
          lg={props.singleCol == true ? 24 : 12}
          md={24}
          sm={24}
          xs={24}
        >
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <PlusOutlined
                style={{ color: "#1A5CFF", fontSize: 16 }}
                rotate={isActive ? 135 : 0}
              />
            )}
          >
            <Panel
              header="Why is it important to analyze competition during the B2B market 
research phase?"
              key="29"
            >
              <p>
                Analyzing competition allows you to understand the landscape of existing B2B
                solutions or providers in your target market. By evaluating the competition, you can
                identify their strengths, weaknesses, and unique selling points. This knowledge
                helps you differentiate your B2B offering, identify areas for improvement, and
                develop strategies to position your product or service as a superior choice for
                potential B2B customers.
              </p>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </StyledDiv>
  );
};

export default FaqSelection;
