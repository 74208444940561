import {ideaValidation} from '../../config/axiosConfig'
import { GET_PROJECTS } from '../constants/projects'
import {getCookie} from '../../utils/cookies'
import {actions} from '../../utils/helperFunctions'

const getAllProjects =  () => async (dispatch ) =>{

    dispatch(actions.loading(GET_PROJECTS))
    try {
        let apiCall = await ideaValidation.get('/api/projects/getProjects',{
            headers:{
                authorization : getCookie('token')
            }
        })
        dispatch(actions.success(GET_PROJECTS,apiCall.data.data))
    } catch (err) {
        dispatch(actions.failure(GET_PROJECTS,err.response?err.response.data.error:err))
    }
}

export const getAllProjectReset = () =>{
    return {
        type: GET_PROJECTS.RESET ,
    }
}

export default getAllProjects;