import React, { useState, useEffect, createRef } from "react";
import { Row, Alert, Form, message } from "antd";
import styled from "styled-components";
import HomeCardButton from "../homeCardButton";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import MarketResearchReport from "../../views/Shell/Reports/marketResearchReport";
import ReactWordcloud from "react-wordcloud";
import { saveSvgAsPng, svgAsPngUri } from "save-svg-as-png";
import { Modal } from "react-bootstrap";

const StyledConfirmModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-close-x {
    display: none;
  }
`;

const ConfirmModal = props => {
  // console.log("market research data", props.data);

  return (
    <StyledConfirmModal
      show={props.visible}
      // onCancel={() => props.setConfirmModalState(false)}
      width={props.width && props.width}
      footer={false}
      centered
    >
      <div style={{ padding: "20px 20px 20px 20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <p
              style={{
                margin: "10px 0 20px 0",
                fontSize: 18,
                fontFamily: "Inter-Medium",
                textAlign: "center",
              }}
            >
              {props.message}
            </p>
          </div>

          {props.secondoryBtn ? (
            <div
              style={{ justifyContent: "center", marginTop: 20, marginBottom: 40 }}
              className="col-lg-12 col-sm-12 d-flex align-items-center"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 20,
                }}
              >
                <HomeCardButton
                  htmlType="submit"
                  text={props.secondoryBtnText || "Cancel"}
                  size={"130px"}
                  bg={props.secondaryBtnBg}
                  // width={props.btnWidth || '30%'}
                  onClick={
                    props.onSecondaryBtnClick
                      ? props.onSecondaryBtnClick
                      : () => props.setConfirmModalState(false)
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 20,
                }}
              >
                {props.marketResearch ? (
                  <PDFDownloadLink
                    document={
                      <MarketResearchReport
                        data={props.data}
                        currentProjectData={props.currentProjectData}
                        userProfileData={props.userProfileData}
                      />
                    }
                    fileName={`${props.currentProjectData?.title}-${
                      props?.data?.businessModel?.type == "B2C" ? "B2C" : "B2B"
                    }-${Date.now()}`}
                  >
                    {({ blob, url, loading, error }) => {
                      return (
                        <HomeCardButton
                          style={{ width: 200 }}
                          isLoading={loading}
                          text="Download Report"
                          bg={true}
                          size={"150px"}
                          onClick={() => {
                            props.onSecondaryBtnClick();
                            message.success("Report successfully download");
                            props.history.push("/");
                          }}
                        />
                      );
                    }}
                  </PDFDownloadLink>
                ) : (
                  <HomeCardButton
                    htmlType="submit"
                    text={props.text || "Ok"}
                    bg={true}
                    size={"150px"}
                    // width={props.btnWidth ||'30%'}
                    onClick={
                      props.onClick ? props.onClick : () => props.setConfirmModalState(false)
                    }
                    isLoading={props.isLoading}
                    disabled={props.isLoading}
                  />
                )}
              </div>
            </div>
          ) : (
            <HomeCardButton
              htmlType="submit"
              text={props.text || "Ok"}
              bg={true}
              size={"130px"}
              // width={props.btnWidth ||'30%'}
              onClick={props.onClick ? props.onClick : () => props.setConfirmModalState(false)}
              isLoading={props.isLoading}
              disabled={props.isLoading}
            />
          )}
        </div>
      </div>
    </StyledConfirmModal>
  );
};

export default ConfirmModal;
