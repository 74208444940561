export const menuData = [
  {
    title: "HOME",
    link: "/",
  },
  {
    title: "KNOWLEDGE BASE",
    link: "/Knowledge-base",
  },
  {
    title: "FAQ's",
    link: "/faqs",
  },
];
