import React from "react";
import { Row, Col, Input, Divider, Button } from "antd";
import styled from "styled-components";
import Logo from "../assets/svg/validatr-logo.svg";

const StyledFooter = styled.footer`
  display: block;
  position: relative;
  /* margin-top: 8%; */
  :before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 5px;
  }

  .footer-item-wrapper {
    text-align: center;
  }

  .footer-item-container {
    margin: 24px auto;
    text-align: left;
    width: 80%;
  }

  .link-header {
    font-weight: 600;
    margin-bottom: 24px;
  }

  .link {
    color: #545d6e;
    text-decoration: none;
    :hover {
      color: #979797;
    }
  }

  .footer-item {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
  }

  //-------------------------------//
  //         Narrow Footer         //
  //-------------------------------//
  .narrow-footer {
    padding: 16px 0;
  }
  .section-title {
    margin: 12px 0;
    font-weight: 600;
  }

  @media only screen and (max-width: 576px) {
    .footer-item-container {
      text-align: center;
    }
    .footerLogo {
      margin-left: -1rem !important;
    }
  }
`;

const FooterContent = () => {
  return (
    <StyledFooter>
      {/* <Divider /> */}

      {/* <Row
        style={{
          borderImage: "linear-gradient(115deg,#EE3753,#F9AE42,#36B04A,#36B04A,#F9AE42,#EE3753)",
          boxSizing: "content-box",
          borderWidth: 2,
          borderStyle: "solid",
          borderImageSlice: 1,
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0,
        }}
        className="col-7 col-md-11 col-lg-11 col-xl-10 m-auto"
        justify="space-between"
      >
        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="footer-item-wrapper">
          <div className="footer-item-container">
            <a href="/">
              <img src={Logo} alt="app_logo" className="mb-3 footerLogo" height={40} />
            </a>
          </div>
        </Col>

        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="footer-item-wrapper">
        </Col>

        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="footer-item-wrapper">
          <div className="footer-item-container">
            <div className="link-header">Let us help</div>
            <div className="footer-item">
              <a className="link">Help center</a>
            </div>
            <div className="footer-item">
              <a className="link">Contact us</a>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="footer-item-wrapper">
          <div className="footer-item-container">
            <div className="link-header">Our company</div>
            <div className="footer-item">
              <a className="link">About us</a>
            </div>
            <div className="footer-item">
              <a className="link">FAQs</a>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="footer-item-wrapper">
          <div className="footer-item-container">
            <div className="link-header">Our policies</div>
            <div className="footer-item">
              <a className="link">Term of use</a>
            </div>
            <div className="footer-item">
              <a className="link">Privacy policy</a>
            </div>
          </div>
        </Col>
      </Row> */}

      <section style={{ padding: "8px 0", backgroundColor: "#263238" }}>
        <Row align="middle" justify="center">
          <Col>
            <div className="text-white py-2">
              Copyrights © validatr.io {new Date().getFullYear()}. All rights reserved.
            </div>
          </Col>
        </Row>
      </section>
    </StyledFooter>
  );
};

export default FooterContent;
