import { ideaValidation } from "../../config/axiosConfig";
import { IDEA_SOLUTION } from "../constants/ideaValidation";
import { getCookie } from "../../utils/cookies";
// import {increaseCurrentIdeaStep,decreaseCurrentIdeaStep} from './getProjectById'
import {
  changeCurrentIdeaStep,
  getProjectById,
  getProjectByIdSuccess,
  openRatingModal,
} from "./getProjectById";
import cogoToast from "cogo-toast";
import { actions } from "../../utils/helperFunctions";

const putIdeaSolution = payLoad => async dispatch => {
  dispatch(actions.loading(IDEA_SOLUTION));
  try {
    let apiCall = await ideaValidation.put(
      `/api/projects/updateProject/${getCookie("currentProject")}`,
      payLoad,
      {
        headers: {
          authorization: getCookie("token"),
        },
      }
    );
    console.log(`UPDATEPPROJECT>>>>>>>> `, apiCall);
    dispatch(actions.success(IDEA_SOLUTION, apiCall.data.data));
    // cogoToast.success(
    //   "Please select which problem and solution best describes the main issue you are trying to solve"
    // );

    // dispatch(getProjectByIdSuccess(apiCall.data.data))
    // dispatch(increaseCurrentIdeaStep())
    if (apiCall.data.data && apiCall.data?.data?.rate < 5) {
      dispatch(changeCurrentIdeaStep(1));
      dispatch(openRatingModal(apiCall?.data?.data, payLoad));
    } else {
      dispatch(changeCurrentIdeaStep(2));
      window.scrollTo(0, 0);
    }
  } catch (err) {
    dispatch(actions.failure(IDEA_SOLUTION, err.response ? err.response.data.error : err));
  }
};

export default putIdeaSolution;
