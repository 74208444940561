import {createApiCallReducer} from '../../utils/helperFunctions'
import {
    ADD_TEAM_MEMBER ,
    GET_ALL_TEAM_MEMBERS ,
    GET_MEMBER_BY_PROJECT ,
} from '../constants/teams'

// export const joinNewTeamMemberReducer = createApiCallReducer(JOIN_NEW_TEAM_MEMBER)
// export const acceptTeamInvitationReducer = createApiCallReducer(ACCEPT_TEAM_INVITATION)
// export const getMemberProjectsByEmailReducer = createApiCallReducer(GET_TEAM_PROJECTS_BY_EMAIL)
// export const removeTeamMemberByProjectIdReducer = createApiCallReducer(REMOVE_TEAM_MEMBER)

const addTeamMemberInitialState = {
    data: null,
    isLoading: false ,
    err: null
}
export const addTeamMemberReducer = createApiCallReducer(ADD_TEAM_MEMBER,true,
    (state=addTeamMemberInitialState,action) =>{
        switch (action.type) {
            case ADD_TEAM_MEMBER.LOADING:
                return{
                    ...state ,
                    isLoading: true
                }
            case ADD_TEAM_MEMBER.SUCCESS:
                return{
                    ...state ,
                    isLoading: false ,
                    data: action.payLoad , 
                    err: null
                }
            case ADD_TEAM_MEMBER.FAILURE:
                return{
                    ...state ,
                    isLoading: false ,
                    err: action.err
                }
            case ADD_TEAM_MEMBER.RESET:
                return{
                    ...state,
                    data: null,
                    isLoading: false ,
                    err: null
                }
            default: return state;
        }
    }
)
export const getAllTeamMemberInitialState = {
    data: null,
    isLoading: false ,
    err: null
}
export const getAllTeamMemberReducer = (state=getAllTeamMemberInitialState,action) =>{
    switch (action.type) {
        case GET_MEMBER_BY_PROJECT.LOADING:
            return{
                ...state ,
                isLoading: true
            }
        case GET_ALL_TEAM_MEMBERS.LOADING :
            return{
                ...state ,
                isLoading: true
            }
        case GET_MEMBER_BY_PROJECT.SUCCESS:
            return{
                ...state ,
                isLoading: false ,
                data: action.payLoad , 
                err: null
            }
        case GET_ALL_TEAM_MEMBERS.SUCCESS:
            return{
                ...state ,
                isLoading: false ,
                data: action.payLoad , 
                err: null
            }
        case GET_MEMBER_BY_PROJECT.FAILURE:
            return{
                ...state ,
                isLoading: false ,
                err: action.err
            }
        case GET_ALL_TEAM_MEMBERS.FAILURE:
            return{
                ...state ,
                isLoading: false ,
                err: action.err
            }
        default: return state;
    }
}

