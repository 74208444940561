import { createConstant } from '../../utils/helperFunctions'

export const DUMY = createConstant('DUMY')

export const LOGIN = createConstant('LOGIN')
export const LOGOUT = createConstant('LOGOUT')
export const SIGNUP = createConstant('SIGNUP')
export const RESET_PASSWORD = createConstant('RESET_PASSWORD')
export const UPDATE_USER = createConstant('UPDATE_USER')
export const UPDATE_PASSWORD = createConstant('UPDATE_PASSWORD')
export const SET_USER_ROLE = createConstant('SET_USER_ROLE')
export const USER_PROFILE = createConstant('USER_PROFILE')
