import React, { useState } from "react";
import styled from "styled-components";
import { Input, Select, message } from "antd";
import { myColor } from "../../GlobalVar";
import AI14 from "../../assets/Assets-Landing/AI-14.png";
import TextArea from "antd/lib/input/TextArea";
import { BsStars } from "react-icons/bs";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const StartWithAIInput = props => {
  const [problem, setProblem] = useState("");
  const history = useHistory();
  let token = Cookies.get("token");

  return (
    <InputStyleWrapper {...props}>
      <div className="input-border-wrapper">
        <img src={AI14} height={"100%"} />
        <Input
          value={problem}
          onChange={e => setProblem(e.target.value)}
          className="input_wrapper"
          placeholder="Write your problem statement"
        />
        <div
          className="gradient-btn"
          onClick={() => {
            if (token && problem) {
              Cookies.set("problemStatement", problem);
              history.push("/projects");
            } else if (problem) {
              Cookies.set("problemStatement", problem);
              history.push("/sign-up");
            } else {
              message.warn("Please enter your problem");
            }
          }}
        >
          <BsStars style={{ fontSize: "1.2rem" }} />
          <p className="mb-0">Start with AI</p>
        </div>
      </div>
    </InputStyleWrapper>
  );
};
export default StartWithAIInput;

const InputStyleWrapper = styled.div`
  background-color: #E1EBFB;
  border: 1px solid #a9b4c6;
  border-radius: 22px;
  height: 100px !important;
  width:850px;
  padding: 8px;
  transition: 0.3s ease;
  margin: 50px 0;

  .input-border-wrapper{
    display: flex;
    background-color: #fff;
    border: 1px solid #a9b4c6;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    padding: 16px;
  }
 
 
  .gradient-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(to right, #4E75FF, #B703CA);
    color: #fff;
    transition:background-color 1s ease;
    p{
      font-family: 'Inter-Bold';
      font-size: 1.2rem;
      margin-left: 5px;
    }
   :hover {
    background: linear-gradient(to right, #4E75FF, #eb29ff);
    color: #fff;
    cursor: pointer;
}
    
  }

  .input_wrapper {
    border: none;
    height: 100%;
    display: flex;
    align-items: center;
    resize: none;
    justify-content: space-between;
    font-size: 18px;
    :focus, :active, :hover{
      box-shadow: none !important;
      border: none !important;
      outline: none !important;
    }
  }
  :hover {
    /* border-color: ${props => (props.bg ? "transparent" : myColor.blue)} !important; */
    transform: translateY(-0.25em) !important;
    transition: 0.3s ease;
  }

  @media (max-width: 850px){
    height: auto !important;
    width: 100% !important;

    .input-border-wrapper{
      align-items: center;
      flex-direction: column;
      img{
        width: 60px;
        height: 60px;
      }
    }

    .gradient-btn {
      margin-top: 2rem;
      width: 100%;
      height: 50px;
    }
  }
`;
