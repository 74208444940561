import { ideaValidation } from "../../config/axiosConfig";
import { GET_PROJECT_REPORT } from "../constants/report";
import { getCookie } from "../../utils/cookies";
import { actions } from "../../utils/helperFunctions";

const getProjectReport = projectId => async dispatch => {
  dispatch(actions.loading(GET_PROJECT_REPORT));
  try {
    let apiCall = await ideaValidation.get(`/api/projects/getReports/${projectId}`, {
      headers: {
        authorization: getCookie("token"),
      },
    });
    dispatch(actions.success(GET_PROJECT_REPORT, apiCall.data.data));
    // console.log("CALLED");
  } catch (err) {
    dispatch(actions.failure(GET_PROJECT_REPORT, err.response ? err.response.data.error : err));
  }
};

export default getProjectReport;
