import { ideaValidation } from "../../config/axiosConfig";
import { ADD_PROJECT } from "../constants/projects";
import { getCookie } from "../../utils/cookies";
import getAllProjects from "./getAllProjects";
import cogoToast from "cogo-toast";
import { actions } from "../../utils/helperFunctions";

const postAddProject = payLoad => async dispatch => {
  dispatch(actions.loading(ADD_PROJECT));
  try {
    let apiCall = await ideaValidation.post("/api/projects/addProject", payLoad, {
      headers: {
        authorization: getCookie("token"),
      },
    });
    dispatch(actions.success(ADD_PROJECT, apiCall.data.data));
    cogoToast.success(`${payLoad.title} has been added successfully`);
    dispatch(getAllProjects());
  } catch (err) {
    cogoToast.error(`${err.response.data.error}`);
    dispatch(actions.failure(ADD_PROJECT, err.response ? err.response.data.error : err));
  }
};

export default postAddProject;
