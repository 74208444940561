import { AntDesignOutlined } from "@ant-design/icons";
import { Avatar, message } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import ImageOne from "../assets/landingpage/image1.jpg";
import ImageTwo from "../assets/landingpage/image2.jpg";
import ImageThree from "../assets/landingpage/image3.jpg";
import ImageFour from "../assets/landingpage/image4.jpg";

import Image1 from "../assets/landingpage/male1.jpg";
import Image2 from "../assets/landingpage/female1.jpg";
import Image3 from "../assets/landingpage/female2.jpg";
import Image4 from "../assets/landingpage/male2.jpg";
import Image5 from "../assets/landingpage/female3.jpg";
import Image6 from "../assets/landingpage/female4.webp";
import Image7 from "../assets/landingpage/male3.jpg";
import Image8 from "../assets/landingpage/male4.jpg";
import Image9 from "../assets/landingpage/male5.jpg";
import Image10 from "../assets/landingpage/male6.jpg";
import Image11 from "../assets/landingpage/male7.jpg";
import Image12 from "../assets/landingpage/male8.jpg";
import Image13 from "../assets/landingpage/female5.jpg";
import Image14 from "../assets/landingpage/male9.png";
import Image15 from "../assets/landingpage/male10.png";

import ValidatrLogo from "../assets/svg/validatr-logo.svg";
import InputWrapper from "../components";
import HomeCardButton from "../components/homeCardButton";
import { myColor } from "../GlobalVar";
import { useSelector } from "react-redux";

const userData = [
  { id: "1", image: Image1 },
  { id: "2", image: Image2 },
  { id: "3", image: Image3 },
  { id: "4", image: Image4 },
  { id: "5", image: Image5 },
  { id: "6", image: Image6 },
  { id: "7", image: Image7 },
  { id: "8", image: Image8 },
  { id: "9", image: Image9 },
  { id: "10", image: Image10 },
  { id: "11", image: Image11 },
  { id: "12", image: Image12 },
  { id: "13", image: Image13 },
];

const MainLandingPage = props => {
  const [email, setEmail] = useState("");
  const data = useSelector(state => state?.getUserProfileReducer);

  const handleSubmit = () => {
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      message.success("Submited successfully");
    } else {
      message.error("Please enter you valid email");
    }
  };
  return (
    <MainStyleWrapper className="">
      {data ? null : (
        <div className="container mt-5 d-flex justify-content-end align-items-end">
          <button type="button" class="btn btn-dark" onClick={() => props.history.push("/sign-in")}>
            Login
          </button>
          <button
            type="button"
            class="btn btn-outline-dark ms-3"
            onClick={() => props.history.push("/sign-up")}
          >
            Register
          </button>
        </div>
      )}
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 pt-5">
            <div className="hero-content-section">
              {data ? null : <img src={ValidatrLogo} style={{ width: 200 }} />}
              <p className="text-65 pe-5">
                Validate your ideas and outsmart the competition with AI.
              </p>
              <p className="text-18">
                Our AI-based tool can validate your startup idea and provide valuable market
                insights.
              </p>
              <div className="d-flex align-items-center">
                <InputWrapper
                  placeholder="Enter your email..."
                  onChange={e => setEmail(e.target.value)}
                />
                <button type="button" class="btn btn-dark ms-2" onClick={() => handleSubmit()}>
                  Submit
                </button>
              </div>

              <div className="d-flex align-items-center avatar-section">
                <img src={Image14} className="img-fluid circle-avatar" />
                <div className="d-flex align-items-start justify-content-start flex-column ms-3">
                  <p className="text-20 mb-0">
                    “This tool has revolutionized the way I validate my startup ideas.”
                  </p>
                  <p className="text-18">Giles Tinkler</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-sm-12 hero-image-section p-0">
            <img src={ImageOne} className="img-fluid h-100 left-border-radius" />
          </div>
        </div>

        <div className="row align-items-center justify-content-between marginTop8rem">
          <div className="col-lg-6 col-sm-12 ps-0">
            <img src={ImageTwo} className="img-fluid right-border-radius" />
          </div>
          <div className="col-lg-6 col-sm-12" style={{ padding: "8%" }}>
            <p className="text-24">Get fast and accurate idea validation.</p>
            <p className="text-18">
              IdeaValidator uses advanced AI algorithms to analyze and validate your startup idea.
              No more guessing or relying on limited feedback.
            </p>
          </div>
        </div>

        <div className="row align-items-center justify-content-between marginTop8rem">
          <div className="col-lg-6 col-sm-12" style={{ padding: "8%" }}>
            <p className="text-24">Generate detailed market analysis reports.</p>
            <p className="text-18">
              MarketIntel allows you to generate comprehensive market analysis reports instantly.
              Identify market trends, competitors and potential customers.
            </p>
          </div>
          <div className="col-lg-6 col-sm-12 pe-0">
            <img src={ImageThree} className="img-fluid left-border-radius" />
          </div>
        </div>

        <div className="row align-items-center justify-content-between marginTop8rem">
          <div className="col-lg-6 col-sm-12 ps-0">
            <img src={ImageFour} className="img-fluid right-border-radius" />
          </div>
          <div className="col-lg-6 col-sm-12" style={{ padding: "8%" }}>
            <p className="text-24">Stay ahead of the competition.</p>
            <p className="text-18">
              With IdeaValidator and MarketIntel, you can make data-driven decisions and outsmart
              the competition. Get insights on market gaps, untapped opportunities and potential
              risks.
            </p>
          </div>
        </div>
      </div>

      <div className=" d-flex justify-content-center align-items-center flex-column black-section px-0 marginTop8rem">
        <p
          className="text-20 w-50"
          style={{ color: "#ffff", fontSize: "1.85rem", textAlign: "center", lineHeight: "2.5rem" }}
        >
          "I was able to save time and money by using IdeaValidator and MarketIntel together."
        </p>
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={Image15}
            className="img-fluid circle-avatar mt-0"
            style={{ borderColor: "#fff" }}
          />
          <p className="text-18 text-white mb-0 ms-2">William Ming</p>
        </div>
      </div>

      <div className="" style={{ padding: "8rem 0" }}>
        <div className="container user-card d-flex justify-content-center flex-column align-items-center">
          <div className="user-section text-center">
            {userData.map((item, index) => {
              return <img key={index} src={item.image} className="img-fluid circle-avatar mt-4" />;
            })}
          </div>
          <p className="text-30 mt-5">Start validating your ideas with AI today.</p>
          <div
            className="d-flex align-items-center subscribe-input"
            style={{ width: "70%", marginTop: "5rem" }}
          >
            <InputWrapper
              width={"100%"}
              placeholder="Enter your email..."
              onChange={e => setEmail(e.target.value)}
            />
            <button type="button" class="btn btn-dark ms-2" onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </MainStyleWrapper>
  );
};

export default MainLandingPage;

const MainStyleWrapper = styled.div`
  .hero-content-section {
    padding-left: 20%;
    padding-right: "7rem";
    .avatar-section {
      div {
        padding-right: 20%;
      }
    }
    img {
      margin-bottom: 8%;
    }
    input {
      width: 70%;
    }
  }

  .hero-image-section {
    img {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

  .black-section {
    padding: 6rem 0px;
    background-color: #515b60;
  }

  .user-card {
    text-align: center;
    padding: 5% 12%;
    border: 2px solid black;
    border-radius: 12px;
    .user-section {
      img {
        margin-left: 4rem;
      }
    }
  }

  .marginTop8rem {
    margin-top: 8rem;
  }

  .right-border-radius {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .left-border-radius {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .circle-avatar {
    margin-top: 20px;
    margin-bottom: 0;
    width: 60px;
    height: 60px;
    border: 2px solid black;
    border-radius: 100px;
  }
  .text-65 {
    font-size: 4.5rem;
    line-height: 1;
    font-weight: 900;
    font-family: "Inter-Bold";
  }

  .text-18 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #959595;
  }
  .text-20 {
    font-weight: 900;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #000;
  }
  .text-24 {
    font-weight: 900;
    font-size: 2.125rem;
    line-height: 2.5rem;
    color: #000;
  }
  .text-30 {
    font-weight: 900;
    font-size: 3rem;
    line-height: 3.5rem;
    color: #000;
    text-align: center;
  }

  @media (max-width: 680px) {
    .hero-content-section {
      .avatar-section {
        div {
          padding-right: 0%;
        }
      }
      padding-left: 0%;
      padding-right: 0rem;
      input {
        width: 100%;
      }
    }

    .black-section {
      padding: 2rem 0px;
      .text-20 {
        width: 100% !important;
      }
    }

    .user-section {
      img {
        margin: 1rem 0.5rem !important;
      }
    }

    .subscribe-input {
      width: 100% !important;
    }
    .marginTop8rem {
      margin-top: 4rem;
    }

    .text-65 {
      font-size: 3.5rem;
      line-height: 1;
      font-weight: 900;
      font-family: "Inter-Bold";
    }
    .text-30 {
      font-weight: 900;
      font-size: 2.5rem;
      line-height: 3rem;
      color: #000;
      text-align: center;
    }
  }
`;
