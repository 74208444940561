import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const StyledInput = styled(Input)`
  border-radius: 6px;
  box-shadow: #49d3ce;
  border-color: ${props => (props.style ? props.style.borderColor : "gray !important")};
  outline: none !important;
  // color: #49d3ce;
`;

const InputWrapper = props => {
  return props.password ? (
    <StyledInput
      as={Input.Password}
      size={props.size ? props.size : "large"}
      {...props}
      value={props.myValue}
    ></StyledInput>
  ) : (
    <StyledInput
      size={props.size ? props.size : "large"}
      {...props}
      value={props.myValue}
    ></StyledInput>
  );
};

export default InputWrapper;
