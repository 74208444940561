import { ideaValidation } from "../../config/axiosConfig";
import { UPDATE_RESPONSE_METHOD } from "../constants/ideaValidation";
import { getCookie } from "../../utils/cookies";
// import {increaseCurrentIdeaStep,decreaseCurrentIdeaStep} from './getProjectById'
import { changeCurrentIdeaStep, getProjectByIdSuccess } from "./getProjectById";
import cogoToast from "cogo-toast";
import { actions } from "../../utils/helperFunctions";
import { message } from "antd";

const putUpdateResponseMethod = payLoad => async dispatch => {
  dispatch(actions.loading(UPDATE_RESPONSE_METHOD));
  try {
    let apiCall = await ideaValidation.put(
      `/api/projects/updateProject/${getCookie("currentProject")}`,
      payLoad,
      {
        headers: {
          authorization: getCookie("token"),
        },
      }
    );
    dispatch(actions.success(UPDATE_RESPONSE_METHOD, apiCall.data.data));
    // cogoToast.success("Response method have been updated");

    // dispatch(getProjectByIdSuccess(apiCall.data.data))
    // dispatch(increaseCurrentIdeaStep())
    dispatch(changeCurrentIdeaStep(3));
    window.scrollTo(0, 0);
  } catch (err) {
    cogoToast.error(err.response?.data?.error);
    dispatch(actions.failure(UPDATE_RESPONSE_METHOD, err.response ? err.response.data.error : err));
  }
};

export default putUpdateResponseMethod;
