import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { GoQuote, GoStar } from "react-icons/go";
import { CgQuote } from "react-icons/cg";
import Heading from "../landingPageHeading";
import Slider from "react-slick";
import { Fade } from "react-awesome-reveal";
// import ClientReviewImage from "../../assets/Assets-Landing/client-review-img.jpg";
import Image from "../../assets/Assets-Landing/3.png";
import alexanderWright from "../../assets/Assets-Landing/Alexander Wright.jpg";
import michaelMitchell from "../../assets/Assets-Landing/Michael Mitchell.jpg";
import anjelinaCole from "../../assets/Assets-Landing/Anjelina Cole.jpg";
import benjaminHughes from "../../assets/Assets-Landing/Benjamin Hughes.jpg";
import williamFoster from "../../assets/Assets-Landing/William Foster.jpg";
import comma from "../../assets/Assets-Landing/comma.svg";

const StyledSection = styled.section`
  .background-shadow {
    width: 100%;
    height: 345px;
    position: absolute;
  }

  .testimony-project-image {
    width: 100%;
    height: 360px;
    position: relative;
    top: 20px;
    left: 20px;
    box-shadow: -6px -6px 14px -6px #888888;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quote {
    font-size: 32px;
    color: #ed3c69;
  }
  .rotate-180 {
    transform: rotate(180deg);
  }

  .reviewer-name {
    font-weight: 600;
    font-style: italic;
  }
  .reviewer-image {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border: 2px solid #808080;
    border-radius: 50%;
    margin-right: 12px;
  }
  .start svg {
    color: #eba82f;
    font-size: 26px;
  }

  .dots-container {
    display: flex;
    justify-content: space-between;
    width: 160px;
    margin: auto;
    position: relative;
    top: 32px;
    list-style: none;

    .dot {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #a5a5a5;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 576px) {
    .background-shadow {
      top: -10px;
      left: -10px;
    }
    .testimony-project-image {
      top: 10px;
      left: 10px;
    }
  }
`;

const logoTestimoniData = [
  {
    image: Image,
    name: "Benjamin Hughes",
    para:
      "As a Startup Founder, I highly recommend Validatr.io. The platform's approach to idea validation is unparalleled, considering the 5 key factors and the metrics. The ability to step into my buyer's shoes and receive authentic ratings on these factors helped me refine my product concept and messaging.",
    personImage: benjaminHughes,
  },
  {
    image: Image,
    name: "William Foster",
    para:
      "As a first-time entrepreneur, I was lost in the idea validation process until I found Validatr.io. It is an AI-based suite of business idea validation tools that provide valuable insights, and their market research helped us understand our target audience better. Thanks to them, we're now on the right track to funding and success.",
    personImage: williamFoster,
  },
  {
    image: Image,
    name: "Anjelina Cole",
    para:
      "Validatr.io is an indispensable tool for product managers like me. Its valuable insights and ratings streamlined idea prioritization and boost confidence in investingwisely. A game-changer for efficient and market-ready solutions Highly recommended!",
    personImage: anjelinaCole,
  },
  {
    image: Image,
    name: "Alexander Wright",
    para:
      "As an investor, Validatr.io has been a game-changer. Its 5Factors evaluation identifies high-potential startups, predicts success, and gauges buyer interest. The buyer’s persona and VC executive impersonation provides invaluable insights. Highly recommended for data-driven decisions and supporting promising startups.",
    personImage: alexanderWright,
  },
  {
    image: Image,
    name: "Michael Mitchell",
    para:
      "Validatr.io impresses like no other! Its in-depth analysis of 5 crucial factors empowers targeted and compelling marketing strategies. The suggestions for improvement that I saw in the validation report really gave us some points to ponder. A game-changer in enhancing clients' product success. A must-have for marketing consultants!",
    personImage: michaelMitchell,
  },
  // {
  //   image: Image,
  //   para:
  //     "eForte worked through the delivery of the project with utmost professionalism, transparency and great technical knowledge. Their effective documentation processes and client communications kept us abreast of our project during the life cycle of the development.",
  //   name: "Sabur Man",
  //   personImage: ClientReviewImage,
  // },
];

const LandingPageTestimony = props => {
  const [state, setState] = useState(1);
  let settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    accessibility: false,
    arrows: false,
    fade: true,
    autoplaySpeed: 5000,
  };
  return (
    <StyledSection className="container my-5">
      <Fade direction="up" triggerOnce duration={1300} damping={0.2}>
        <Heading
          color={props.headingColor}
          fontWeight={props.fontWeight}
          backgroundColor={props.headingLineColor}
          headingFontSize={props.headingFontSize}
        >
          See what some of the happy founders have to
          <br />
          say about validating their ideas through Validatr...
        </Heading>
      </Fade>
      <Slider autoplay={true} {...settings}>
        {logoTestimoniData.map((item, index) => (
          <div>
            <Row
              style={{ marginTop: 24 }}
              className="container mx-auto"
              justify="center"
              align="middle"
              gutter={[24, 24]}
            >
              <Col xs={24} sm={12} md={12} lg={10} xl={8}>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      backgroundColor: props.imageBgColor ? props.imageBgColor : "#eff1f2",
                    }}
                    className="background-shadow"
                  ></div>
                  <div className="testimony-project-image">
                    <img
                      alt="img"
                      src={item.image}
                      style={{ width: "100%" }}
                      // style={{ boxShadow: "-6px -6px 14px 4px #888888" }}
                    />
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={12} md={12} lg={10} xl={10}>
                <div style={{ marginLeft: 34 }} className="ml-md-5 ml-0">
                  <div>
                    <img style={{ height: 30, width: 30 }} src={comma} alt="quotes" />
                    <p
                      style={{
                        color: props.paraColor ? props.paraColor : "#212529",
                        fontStyle: "italic",
                      }}
                    >
                      {item.para}
                    </p>
                    <img
                      style={{
                        height: 30,
                        width: 30,
                        float: "right",
                        transform: "rotate(180deg)",
                      }}
                      src={comma}
                      alt="quotes"
                    />
                  </div>
                  <br />
                  <br />
                  <div className="d-flex">
                    <img src={item.personImage} alt="test-image" className="reviewer-image" />
                    <div>
                      <h6
                        className="reviewer-name"
                        style={{
                          color: props.personNameColor ? props.personNameColor : "#000",
                        }}
                      >
                        {item.name}
                      </h6>
                      {/* <h6 className="m-0 mb-1">— The Good Liquid Brewing Co. —</h6> */}
                      <span className="start">
                        <GoStar />
                        <GoStar />
                        <GoStar />
                        <GoStar />
                        <GoStar />
                      </span>
                    </div>
                  </div>

                  {/* <ul className="d-flex dots-container">
                    {[1, 2, 3, 4].map((item) => (
                      <li
                        key={item}
                        className="dot"
                        style={{
                          background:
                            item === state
                              ? "linear-gradient(to right, #5e2ced, #a485fd)"
                              : "#fff",
                        }}
                        onClick={() => setState(item)}
                      />
                    ))}
                  </ul> */}
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </Slider>
    </StyledSection>
  );
};

export default LandingPageTestimony;
