const showingHeaderFooterInitialState = {
    isHeaderFooterShow : true 
}
export const removingHeaderFooterReducer = (state=showingHeaderFooterInitialState,action) =>{
    switch (action.type) {
        case 'HEADER_FOOTER_HIDE':
            return {
                ...state,
                isHeaderFooterShow: false
            }
        case 'HEADER_FOOTER_NOT_HIDE':
            return{
                ...state,
                isHeaderFooterShow: true
            }
        default: return state ;
    }
}