import Cookies from 'js-cookie';
import { encryption , decryption } from './helperFunctions'

const createCookie = (key, value) =>{
    Cookies.set(key, value)
    // let encryptedVal = encryption(value)
    // let cookie = escape(key) + "=" + escape(encryptedVal) + ";";
    // document.cookie = cookie;
}

const removeCookie = (key) =>{
  Cookies.remove(key)
    // let cookie = escape(key) + "=" + escape(null) + ";";
    // document.cookie = cookie;
}

const getCookie = (cname) =>{
  let cookies = Cookies.get(cname)
  // var name = cname + "=";
  // var decodedCookie = decodeURIComponent(document.cookie);
  // var ca = decodedCookie.split(';');
  // for(var i = 0; i <ca.length; i++) {
  //   var c = ca[i];
  //   while (c.charAt(0) == ' ') {
  //     c = c.substring(1);
  //   }
  //   if (c.indexOf(name) == 0) {
  //     let val = c.substring(name.length, c.length)
  //     let decodeVal = decryption(val)
  //     return decodeVal;
  //   }
  // }
  return cookies;
}

export {
    createCookie , 
    getCookie ,
    removeCookie ,
}