import React, { useState, useEffect } from "react";
import Logo from "../../assets/svg/validatr-logo.svg";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import UserInfoDropdown from "../UserInfoDropdown";
import { Row, Col, Collapse, Menu, Avatar, Image, Dropdown } from "antd";
import getUserProfile from "../../redux/actions/getUserProfile";
import postUserLogOut from "../../redux/actions/postUserLogOut";
import SwitchButton from "../SwitchButton";
import { getCookie } from "../../utils/cookies";
import { postUserRole } from "../../redux/actions/postUserRole";
import cogoToast from "cogo-toast";
import { IoMenuOutline } from "react-icons/io5";
import { withReducer } from "../../redux/withReducer";
import { createApiCallReducer } from "../../utils/helperFunctions";
import { LOGOUT, SET_USER_ROLE, USER_PROFILE } from "../../redux/constants/users";
import MobileDrawer from "./mobileDrawer";
import { menuData } from "./menuData";
import { GoChevronDown } from "react-icons/go";

const { Panel } = Collapse;

const HeaderWrapper2 = props => {
  const history = useHistory();
  const [sidebar, toggleSidebar] = useState(false);
  const [path, setPath] = React.useState(history.location.pathname);
  const [showSwitch, setShowSwitch] = React.useState(true);

  useEffect(() => {
    setPath(history.location.pathname);
    if (
      history.location.pathname == "/dashboard" ||
      history.location.pathname == "/team" ||
      history.location.pathname == "/projects" ||
      history.location.pathname == "/report"
    ) {
      setShowSwitch(true);
    } else {
      setShowSwitch(false);
    }
  }, [history.location.pathname]);

  const {
    data: userRoleData,
    isLoading: userRoleIsLoading,
    err: userRoleErr,
  } = props.setUserRoleReducer;

  const {
    data: getAllProjectsData,
    projectDataAdmin,
    projectDataMember,
  } = props.getAllProjectReducer;

  const [active, setActive] = useState("");
  const [click, setClick] = useState(false);
  const [switchChecked, setSwitchChecked] = React.useState(false);
  const handleClick = () => setClick(!click);

  useEffect(() => {
    props.getUserProfile();
  }, []);

  // userRoleIsLoading && cogoToast.loading(`Switching to ${getCookie('userRole') == 'admin' ? 'member' : 'admin'}`,{hideAfter:1.5}).then(()=>{
  //     cogoToast.success(`Switched to ${getCookie('userRole')}`)
  // })
  useEffect(() => {
    if (getCookie("userRole") == "admin") {
      setSwitchChecked(true);
    } else if (getCookie("userRole") == "member") {
      setSwitchChecked(false);
    }
  }, [userRoleData]);

  const handleSwitchChange = checked => {
    if (
      history.location.pathname == "/dashboard" ||
      history.location.pathname == "/team" ||
      history.location.pathname == "/projects" ||
      history.location.pathname == "/report"
    ) {
      if (checked && getAllProjectsData) {
        if (projectDataAdmin.length > 0) {
          let payLoad = {
            currentProjectType: "admin",
          };
          userRoleIsLoading || props.postUserRole(payLoad);
        } else {
          cogoToast.info("You are not admin of any project", { hideAfter: 2 });
        }
      } else if (checked == false && getAllProjectsData) {
        if (projectDataMember.length > 0) {
          let payLoad = {
            currentProjectType: "member",
          };
          userRoleIsLoading || props.postUserRole(payLoad);
        } else {
          cogoToast.info("You are not a member of any project", { hideAfter: 2 });
        }
      }
    } else {
      cogoToast.info("You can only change rights from dashboard", { hideAfter: 2 });
    }
  };

  const userName = `${props.getUserProfileReducer.data &&
    props.getUserProfileReducer.data.firstName} 
    ${props.getUserProfileReducer.data && props.getUserProfileReducer.data.lastName}`;

  const menu = (
    <Menu>
      {/* <Menu.Item className="fw-bold">
        <span rel="noopener noreferrer">{userName}</span>
      </Menu.Item> */}
      <Menu.Item>
        <span rel="noopener noreferrer">Profile</span>
      </Menu.Item>
      <Menu.Item onClick={() => props.postLogout(history)}>
        <span rel="noopener noreferrer">Log out</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header-wrapper-2">
      <MobileDrawer visible={sidebar} onClose={() => toggleSidebar(false)} />

      <div className="container m-auto" style={{ zIndex: 2 }}>
        <Row justify="space-around" align="middle">
          <Col xs={3} sm={3} md={1} lg={0} xl={0}>
            <IoMenuOutline
              style={{ fontSize: 32, cursor: "pointer" }}
              onClick={() => toggleSidebar(true)}
            />
          </Col>

          <Col xs={16} sm={16} md={10} lg={3} xl={4}>
            <a style={{ textDecoration: "none" }} href="/">
              <img style={{ width: 175 }} src={Logo} className="img-fluid site-logo" />
            </a>
          </Col>

          <Col xs={0} sm={0} md={10} lg={10} xl={12}>
            <menu
              className="menu-list d-none d-lg-flex justify-content-center align-items-center m-0"
              style={{
                backgroundColor: "#fff",
                zIndex: 2,
              }}
            >
              {menuData.map(menu => {
                if (menu.submenu) {
                  return (
                    <Dropdown
                      overlay={
                        <Menu style={{ minWidth: 200, marginTop: -4 }}>
                          {menu.submenu.map((subMenuItem, index) => {
                            if (subMenuItem.submenu) {
                              return (
                                <Menu.SubMenu
                                  key={index}
                                  title={<a className="navigation-link">{subMenuItem.title}</a>}
                                >
                                  {subMenuItem.submenu.map((item, index) => (
                                    <Menu.Item
                                      role="button"
                                      key={`sub-menu-${index}`}
                                      style={{ minWidth: 240 }}
                                    >
                                      <a href={item.link || ""} className="navigation-link">
                                        {item.title}
                                      </a>
                                    </Menu.Item>
                                  ))}
                                </Menu.SubMenu>
                              );
                            } else {
                              return (
                                <Menu.Item key={index}>
                                  <a href={subMenuItem.link || ""} className="navigation-link">
                                    {subMenuItem.title}
                                  </a>
                                </Menu.Item>
                              );
                            }
                          })}
                        </Menu>
                      }
                    >
                      <div
                        className={`menu-item ${
                          window.location.pathname === menu.link ? "active" : ""
                        }`}
                      >
                        <a style={{ textTransform: "uppercase" }}>{menu.title}</a>
                        <GoChevronDown style={{ marginBottom: 4 }} className="ml-2" />
                      </div>
                    </Dropdown>
                  );
                } else {
                  return (
                    <div
                      className={`menu-item ${
                        window.location.pathname === menu.link ? "active" : ""
                      }`}
                    >
                      <Link to={menu.link} style={{ textTransform: "uppercase" }}>
                        {menu.title}
                      </Link>
                    </div>
                  );
                }
              })}
            </menu>
          </Col>

          <Col
            className="d-none d-lg-flex justify-content-end align-items-center"
            xs={0}
            sm={0}
            md={0}
            lg={8}
          >
            <SwitchButton
              admin="Admin"
              member="Member"
              checked={switchChecked}
              onChange={checked => handleSwitchChange(checked)}
              disabled={userRoleIsLoading ? true : false}
            />
            <div style={{ marginLeft: 50, display: "flex", alignItems: "center" }}>
              <UserInfoDropdown menu={menu} userName={userName} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    getUserProfileReducer: state.getUserProfileReducer,
    setUserRoleReducer: state.setUserRoleReducer,
    getAllProjectReducer: state.getAllProjectReducer,
    isHeaderShow: state.removingHeaderFooterReducer,
    isHeaderShow: state.removingHeaderFooterReducer.isHeaderFooterShow,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postLogout: history => dispatch(postUserLogOut(history)),
    getUserProfile: () => dispatch(getUserProfile()),
    postUserRole: data => dispatch(postUserRole(data)),
  };
};

export default withReducer([
  { key: "logOutReducer", reducer: createApiCallReducer(LOGOUT) },
  { key: "setUserRoleReducer", reducer: createApiCallReducer(SET_USER_ROLE) },
  { key: "getUserProfileReducer", reducer: createApiCallReducer(USER_PROFILE) },
])(connect(mapStateToProps, mapDispatchToProps)(HeaderWrapper2));
