import {authModules} from '../../config/axiosConfig'
import { SET_CURRENT_USER_PROJECT } from '../constants/projects'
import {getCookie , createCookie} from '../../utils/cookies'
import {postUserRole} from './postUserRole'
import {actions} from '../../utils/helperFunctions'

const postCurrentUserProject =  (payLoad) => async (dispatch,getState) =>{
    dispatch(actions.loading(SET_CURRENT_USER_PROJECT))
    try {
        let apiCall = await authModules.post('/api/users/setUserCurrentProject',payLoad,{
            headers:{
                authorization : getCookie('token')
            }
        })
        createCookie('currentProject',apiCall.data.data.currentProject)
        dispatch(actions.success(SET_CURRENT_USER_PROJECT,apiCall.data.data))
        let allProjectData = getState().getAllProjectReducer
        let isAdminProject = allProjectData.projectDataAdmin.length>0 ? 
                                allProjectData.projectDataAdmin.find(val=>val.project._id == apiCall.data.data.currentProject) : null
        let isMemberProject = allProjectData.projectDataMember.length>0 ? 
                                allProjectData.projectDataMember.find(val=>val.project._id == apiCall.data.data.currentProject) : null

        if(isAdminProject && getCookie('userRole') == 'member'){
            dispatch(postUserRole({currentProjectType: "admin"}))
        }else if(isMemberProject && getCookie('userRole') == 'admin'){
            dispatch(postUserRole({currentProjectType: "member"}))
        }
    } catch (err) {
        dispatch(actions.failure(SET_CURRENT_USER_PROJECT,err.response?err.response.data.error:err))
    }
}

export default postCurrentUserProject