import React from "react";
import { View, Text, Image, Font, Document, StyleSheet } from "@react-pdf/renderer";

const PercentageBar = props => {
  let percentageInInt = parseInt(props.percent.split("%")[0]);
  // console.log("percentageInInt", percentageInInt);

  const barRatingStep = [
    { name: props.name1, color: props.title == "AI" ? "#95458C" : "#4E56FF" },
    {
      name: props.name2,
      color: props.title == "AI" ? "#95458C" : "#4E56FF",
    },
    {
      name: props.name3,
      color: props.title == "AI" ? "#95458C" : "#4E56FF",
    },
    {
      name: props.name4,
      color: props.title == "AI" ? "#95458C" : "#4E56FF",
    },
    { name: props.name5, color: props.title == "AI" ? "#95458C" : "#4E56FF" },
  ];

  return (
    <View {...props}>
      <Text
        style={{
          fontSize: 13,
          fontFamily: "roboto",
          fontWeight: 700,
          width: "15%",
          color: props.title == "AI" ? "#95458C" : "#4E56FF",
        }}
      >
        {props.title}
      </Text>
      <View style={{ width: "85%" }}>
        <View style={styles.mainBar}>
          <Text
            style={[
              styles.percentBar,
              {
                width: props.percent,
                backgroundColor: props.title == "AI" ? "#95458C" : "#4E56FF",
              },
            ]}
          ></Text>
        </View>
        <View style={styles.nameSteps}>
          {barRatingStep.map((item, index) => {
            return (
              <Text style={{ color: item.color, fontSize: 9, fontWeight: "200" }}>
                {item.name}{" "}
              </Text>
            );
          })}
        </View>
      </View>
      {/* <Text style={styles.para}>{props.text}</Text> */}
    </View>
  );
};

export default PercentageBar;

const styles = StyleSheet.create({
  mainBar: {
    height: 14,
    width: "100%",
    backgroundColor: "lightgray",
    borderRadius: 20,
  },
  percentBar: {
    height: 14,
    // backgroundColor: "#36B24A",
    borderRadius: 20,
  },
  nameSteps: {
    marginTop: 6,
    marginBottom: 10,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  para: {
    marginTop: 20,
    fontSize: 13,
    color: "#111111",
    lineHeight: 1.5,
  },
});
