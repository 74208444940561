import React from "react";
import styled from "styled-components";
import { Input, InputNumber } from "antd";
import { myColor } from "../GlobalVar";
const BorderBottomInput = props => {
  const blockInvalidChar = e =>
    props.type == "number" && ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  return (
    <InputWrapper
      {...props}
      // onKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
      onKeyDown={blockInvalidChar}
    />
  );
};
export default BorderBottomInput;

const InputWrapper = styled(Input)`
  /* width: ${props => (props.width ? props.width : "72%")}; */
  /* margin: 16px 0px; */
  /* padding: 0px 10px 0px 5px; */
  border-top: 1px solid #A9B4C6;
  border-left: 1px solid #A9B4C6;
  border-right: 1px solid #A9B4C6;
  border-bottom: 1px solid #A9B4C6;
  font-size: 16px;
  height: 52px;
  border-radius: 3px;
  width: 90%;
  /* font-weight: 600; */
  box-shadow: none !important;
  color: #000;
  input{
    height: 50px;
    font-size: 16px;
  }

  .ant-input-number-handler-wrap{
    display: none !important;
  }
  .ant-input-group{
    input{
      box-shadow: none !important;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    // color: red;
    /* opacity: 0.5; */
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  :focus {
    outline: 0;
    border-color: ${myColor.blue};
  }
  :hover{
    border-color: ${myColor.blue};
  }
  .ant-input:hover{
    border-color: transparent !important;
  }
  .ant-input:active{
    border-color: transparent !important;
  }
  .ant-input:focus{
    border-color: transparent !important;
  }
`;
