import React from "react";
import { Switch } from "antd";
import styled from "styled-components";
import { myColor } from "../GlobalVar";

const SwitchWrapper = styled(Switch)`
  height: 26px;
  background-color: ${myColor.blue} !important;
  width: 70px;
  .ant-switch-inner {
    font-size: 10px;
  }
  .ant-switch-handle {
    top: 4px;
    left: 4px;
  }

  &.ant-switch-checked {
    .ant-switch-handle {
      left: calc(100% - 20px - 2px) !important;
    }
  }
`;

const SwitchButton = props => {
  return (
    <SwitchWrapper
      checkedChildren={props.admin}
      unCheckedChildren={props.member}
      // defaultChecked
      disabled={props.disabled}
      checked={props.checked}
      onChange={props.onChange}
    />
  );
};

export default SwitchButton;
