import React, { useEffect, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Note,
  PDFViewer,
  Canvas,
  Font,
  Image,
} from "@react-pdf/renderer";
import bottomImage from "../../../../assets/img/Report-image.png";
import bgImage from "../../../../assets/img/project-cover-Backgroun.png";
import ValidatrLogo from "../../../../assets/img/Validator-watermark-color.png";
import ValidatrLogoPng from "../../../../assets/img/validatorLogo.png";

import BorderImg from "../../../../assets/img/boder-img.png";
import Page1Img from "../../../../assets/img/page-1.png";
import Page1HeadingImg from "../../../../assets/img/page1_heading.png";
import FooterLine from "../../../../assets/img/Footer-Line.png";
import TargetMarketHead from "../../../../assets/img/target_market/one.png";
import TargetMarketBottom from "../../../../assets/img/target_market/two.png";
import MarketSizeHead from "../../../../assets/img/Market-Size-Assets/Header-Market-Size.png";
import MarketSizeIcon1 from "../../../../assets/img/Market-Size-Assets/TotalMarketVolume.png";
import MarketSizeIcon2 from "../../../../assets/img/Market-Size-Assets/TargetMarketVolume.png";
import MarketSizeIcon3 from "../../../../assets/img/Market-Size-Assets/MarketPenetration.png";
import MarketSizeIcon4 from "../../../../assets/img/Market-Size-Assets/Market-Value.png";
import MarketSizeIcon5 from "../../../../assets/img/Market-Size-Assets/MarketValue01.png";
import MarketDemandIcon1 from "../../../../assets/img/MarketDemand/MarketDemand.png";
import MarketDemandHeading from "../../../../assets/img/MarketHeading.png";
import BarrierstoEntry from "../../../../assets/img/BarrierstoEntry.png";

import MarketDemandIcon2 from "../../../../assets/img/MarketDemand/06.png";
import MarketDemandIcon3 from "../../../../assets/img/MarketDemand/07.png";
import MarketDemandIcon4 from "../../../../assets/img/MarketDemand/08.png";
import MarketDemandIcon5 from "../../../../assets/img/MarketDemand/09.png";
import MarketDemandIcon6 from "../../../../assets/img/MarketDemand/10.png";

import BoxImage from "../../../../assets/img/All-Pages-Top.png";

import robotoBold from "../../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import robotoMedium from "../../../../assets/fonts/Roboto/Roboto-Medium.ttf";
import robotoRegular from "../../../../assets/fonts/Roboto/Roboto-Regular.ttf";
import robotoLight from "../../../../assets/fonts/Roboto/Roboto-Light.ttf";
import robotoItalic from "../../../../assets/fonts/Roboto/Roboto-Italic.ttf";

Font.register({
  family: "roboto",
  format: "truetype",
  fonts: [
    {
      src: robotoBold,
      fontWeight: "bold",
    },
    {
      src: robotoMedium,
    },
    {
      src: robotoRegular,
    },
    {
      src: robotoLight,
      fontWeight: "light",
    },
    {
      src: robotoItalic,
      fontWeight: "light",
      fontStyle: "italic",
    },
  ],
});

const glossaryData = [
  {
    title: "Accessible customer",
    description: "= total market volume x % of customers who have access to the product",
  },
  {
    title: "Market value",
    description: "= accessible customers x % of the customers accessible through marketing budget.",
  },
  { title: "Total Market Value", description: "= accessible customers x average price" },
  { title: "Accessible Market Value", description: "= market value x average price" },
  {
    title: "Students:",
    description:
      "Dependable, under learning with objective of acquiring knowledge, enthusiastic and abstain the need of continuous change.",
  },
  {
    title: "Entry-level employees:",
    description:
      "Learners, minimal experienced/ fresh candidates, developing young professionals who intend to achieve employment at desired field.",
  },
  {
    title: "Skilled Workers:",
    description:
      "Skilful, Engineers, Craftsmen, experts, professionals, in-demand, creative, have special skill, training, knowledge that can be applied to their work.",
  },
  {
    title: "Managers:",
    description:
      "Planners, Organizers, Team leaders, possess adequate knowledge and experience in professional environment, confident in their abilities, experienced professionals, and decision makers.",
  },
  {
    title: "Senior Executives:",
    description:
      "Highly Experienced, Influencers, Leaders, approachable “People Persons” and Calculated Risk Takers and strong decision makers",
  },
  {
    title: "Homemakers:",
    description:
      "Self-Managers, Intelligent, Committed, Imaginative, Enthusiastic, perseverance nature, adoptable, resourceful,",
  },
  {
    title: "Retired Personnel:",
    description:
      "Experienced, seniors, Investors, Fulfilled, Enjoy life, Travelers, Family persons, pensioner, residential, social.",
  },
  {
    title: "Low-level Income group:",
    description: "mostly the lower-level staff like clerks, housekeeping, security guards — etc.",
  },
  {
    title: "Mid-level Income group:",
    description:
      "in general; freshly employed, management trainees, graduate trainees, technicians, technical service providers, accountants — etc.",
  },
  {
    title: "High-level Income group:",
    description:
      "in general; managers, senior executives, head of departments, directors, C-level executives— etc.",
  },
  {
    title: "Industry Trend - Stable:",
    description:
      "This means that the targeted product/service has passed the growth phase. The earnings and sales tend to grow slower in stable/mature industries as compared to growing industry.",
  },
  {
    title: "Industry Trend - Volatile:",
    description:
      "This means that the industry trend for the targeted product/service is unpredictable. It is necessary for some movement within the market to sell commodities, however a volatile market represents the most risk to investors.",
  },
  {
    title: "Industry Trend - Growing:",
    description:
      "This means that the market related to the potential product/service is expanding and competition continues to develop. Growth industries are often associated with new or pioneer industries that did not exist in the past.",
  },
  {
    title: "Industry Trend - Declining:",
    description:
      "This means that the targeted product/service-related industry is experiencing negative growth or remains stagnant due to decline in their demand for varied reasons.",
  },
  {
    title: "Price:",
    description:
      "The product or service being offered is at a great price by targeting the cost aspect of it, so as compared to competitors and substitutes, a good market share is likely to be captured.",
  },
  {
    title: "Quality:",
    description:
      "The quality or product or service being offered should be impeccable to gain competitive edge.",
  },
  {
    title: "Technology:",
    description: "The product or service is being offered with advanced technological features.",
  },
  {
    title: "Customer Service:",
    description:
      "The product or service being offered provides value added services to make the business stand-out, such as being readily responsive to customers concerns.",
  },
  {
    title: "Accessibility:",
    description: "The product or service is accessible to its target market.",
  },
  {
    title: "Performance",
    description:
      "The performance of offered product or service can achieve the desired outcome in terms of sales volume and revenues generated.",
  },
];
const swotData = [
  {
    title: "Strengths:",
    description:
      "Characteristics of product or service that give a competitive advantage over others. E.g. Highly skilled labour, strong marketing strategy, etc.",
  },
  {
    title: "Weakness:",
    description:
      "Characteristics of product or service that gives business disadvantage to its potential competitors, e.g., limited resources, lack of distribution channels.",
  },
  {
    title: "Opportunities:",
    description:
      "External factors that improve efficiency / product demand. E.g. Rising marketing trends.",
  },
  {
    title: "Threats:",
    description:
      "External factors that may be out of control. E.g. Change in regulation, change in consumer behaviour.",
  },
];

const WaterMark = () => {
  return (
    <View
      style={{
        position: "absolute",
        top: "45%",
        height: "100%",
        width: "100%",
      }}
    >
      <Image
        src={ValidatrLogo}
        style={{
          transform: "rotate(-65deg)",
          width: 700,
        }}
      />
    </View>
  );
};

// Create Document Component
const MarketResearchReportView = props => {
  return (
    <PDFViewer style={styles.pdfViewer}>
      <Document>
        <Page size="A4">
          <Image src={bgImage} width={"100%"} height={"100%"} style={styles.backgroundImage} />
          <Image
            src={ValidatrLogoPng}
            style={{
              width: 150,
              position: "absolute",
              top: 100,
              left: 60,
            }}
          />
          <Text
            style={{
              zIndex: 2,
              position: "absolute",
              top: 200,
              left: 60,
              fontSize: 28,
              fontWeight: 600,
              fontFamily: "roboto",
            }}
          >
            Market Research Report
          </Text>
          <Text
            style={[
              styles.para,
              {
                fontSize: 18,
                fontFamily: "roboto",
                fontStyle: "italic",
                position: "absolute",
                top: 250,
                left: 120,
              },
            ]}
          >
            for
          </Text>
          <Text
            style={{
              fontSize: 21,
              fontFamily: "roboto",
              position: "absolute",
              fontWeight: 600,
              top: 290,
              left: 60,
            }}
          >
            "ABC Solutions"
          </Text>

          <Text
            style={[
              styles.para,
              {
                position: "absolute",
                top: 330,
                left: 60,
                fontSize: 14,
                fontFamily: "roboto",
                color: "#686868",
              },
            ]}
          >
            Date:
          </Text>
        </Page>

        <Page size="A4" style={{ paddingHorizontal: 60, paddingVertical: 50 }}>
          <View style={{}}>
            <Text style={styles.title25}>Table of Contents</Text>
            <View style={{ width: "100%", height: 1, backgroundColor: "#3C4048", marginTop: 10 }} />
          </View>

          <View style={styles.headingRow}>
            <View>
              <Text style={styles.title20}>Market Research</Text>
              <View
                style={{ width: "100%", height: 1.8, backgroundColor: "#3C4048", marginTop: 1 }}
              />
            </View>
            <Text style={styles.tableNumber}>01</Text>
          </View>

          <View style={styles.headingRow}>
            <View>
              <Text style={styles.title20}>Target Market</Text>
              <View
                style={{
                  width: "100%",
                  height: 1.8,
                  backgroundColor: "#3C4048",
                  marginTop: 1,
                  marginBottom: 15,
                }}
              />
              <Text style={styles.font12}>1.&nbsp;&nbsp; Location</Text>
              <Text style={styles.font12}>2.&nbsp;&nbsp; Gender/Age/Marital Status</Text>
              <Text style={styles.font12}>3.&nbsp;&nbsp; Education/Occupation Level</Text>
              <Text style={styles.font12}>4.&nbsp;&nbsp; Income Level</Text>
              <Text style={styles.font12}>5.&nbsp;&nbsp; Creating Buyer’s Persona</Text>
              <Text style={styles.font12}>6.&nbsp;&nbsp; Location</Text>
            </View>
            <Text style={styles.tableNumber}>02 - 03</Text>
          </View>

          <View style={styles.headingRow}>
            <View>
              <Text style={styles.title20}>Market Size</Text>
              <View
                style={{
                  width: "60%",
                  height: 1.8,
                  backgroundColor: "#3C4048",
                  marginTop: 1,
                  marginBottom: 15,
                }}
              />
              <Text style={styles.font12}>1.&nbsp;&nbsp; Total Market Volume</Text>
              <Text style={styles.font12}>2.&nbsp;&nbsp; Target Market Volume</Text>
              <Text style={styles.font12}>3.&nbsp;&nbsp; Market Penetration</Text>
              <Text style={styles.font12}>
                4.&nbsp;&nbsp; Average Price of similar products/services
              </Text>
              <Text style={styles.font12}>5.&nbsp;&nbsp; Market Value</Text>
            </View>
            <Text style={styles.tableNumber}>04</Text>
          </View>

          <View style={styles.headingRow}>
            <View>
              <Text style={styles.title20}>Market Demand</Text>
              <View
                style={{
                  width: "100%",
                  height: 1.8,
                  backgroundColor: "#3C4048",
                  marginTop: 1,
                  marginBottom: 15,
                }}
              />
              <Text style={styles.font12}>1.&nbsp;&nbsp; Product-Fit in the Current Market</Text>
              <Text style={styles.font12}>2.&nbsp;&nbsp; Industry Trend</Text>
            </View>
            <Text style={styles.tableNumber}>05 - 06</Text>
          </View>

          <View style={styles.headingRow}>
            <View>
              <Text style={styles.title20}>Competition Analysis</Text>
              <View
                style={{
                  width: "100%",
                  height: 1.8,
                  backgroundColor: "#3C4048",
                  marginTop: 1,
                  marginBottom: 15,
                }}
              />
              <Text style={styles.font12}>1.&nbsp;&nbsp; Core Competencies</Text>
              <Text style={styles.font12}>2.&nbsp;&nbsp; Core Competency of Competitor</Text>
              <Text style={styles.font12}>2.&nbsp;&nbsp; SWOT Analysis</Text>
            </View>
            <Text style={styles.tableNumber}>07 - 09</Text>
          </View>

          <View style={styles.headingRow}>
            <View>
              <Text style={styles.title20}>Barriers to Entry</Text>
              <View
                style={{
                  width: "100%",
                  height: 1.8,
                  backgroundColor: "#3C4048",
                  marginTop: 1,
                  marginBottom: 15,
                }}
              />
              <Text style={styles.font12}>a.&nbsp;&nbsp; Investment</Text>
              <Text style={styles.font12}>b.&nbsp;&nbsp; Technology</Text>
              <Text style={styles.font12}>c.&nbsp;&nbsp; Brand Marketing</Text>
            </View>
            <Text style={styles.tableNumber}>10 - 11</Text>
          </View>

          <View style={styles.headingRow}>
            <View>
              <Text style={styles.title20}>Glossary</Text>
              <View
                style={{ width: "100%", height: 1.8, backgroundColor: "#3C4048", marginTop: 1 }}
              />
            </View>
            <Text style={styles.tableNumber}>12 - 13</Text>
          </View>
        </Page>

        <Page size="A4">
          {/* <WaterMark /> */}
          {/* =================Market Research===================== */}
          <View
            style={{
              paddingLeft: 65,
              paddingRight: 65,
              paddingTop: 65,
              textAlign: "justify",
            }}
          >
            <View
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <Text style={styles.title}>Market Research</Text>
              <Image src={Page1HeadingImg} style={{ width: 70, height: 70 }} />
            </View>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <Image src={BorderImg} style={{ width: 5, height: "95%", marginRight: 18 }} />
              <View style={{ paddingRight: 20 }}>
                <Text style={[styles.para, { lineHeight: 1.5 }]}>
                  Market Research is the process of gathering, analysing and interpretation of
                  relative information about the buyer’s personas and target market through
                  meticulous research, in order to determine the viability factors of a new product
                  or service being offered. This can be achieved through analysis of many dynamic
                  aspects that play a key role in influencing the purchase decision and behaviour of
                  potential targeted audience.
                </Text>
                <Text style={[styles.para, { lineHeight: 1.5, marginTop: 10 }]}>
                  The determination of a business model holds significance in providing the
                  viability of targeted business. General business model types are business to
                  business and business to customer defined as follows:
                </Text>

                {/* =========bullits=========== */}
                <View style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
                  <View
                    style={{
                      height: 10,
                      width: 10,
                      borderRadius: 12,
                      borderWidth: 1,
                      borderColor: "#7B8FA1",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 8,
                      marginTop: 2,
                    }}
                  >
                    <View
                      style={{
                        height: 5,
                        width: 5,
                        borderRadius: 6,
                        backgroundColor: "#0081C9",
                      }}
                    />
                  </View>
                  <View
                    style={{
                      width: 1,
                      height: 22,
                      backgroundColor: "#7B8FA1",
                      position: "absolute",
                      left: 4.5,
                      top: 11,
                    }}
                  />
                  <Text style={[styles.bullitPara, { width: "95%" }]}>
                    Business to Business (B2B) – This model refers to selling products and services
                    between businesses.
                  </Text>
                </View>
                <View style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
                  <View
                    style={{
                      height: 10,
                      width: 10,
                      borderRadius: 12,
                      borderWidth: 1,
                      borderColor: "#7B8FA1",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 8,
                      marginTop: 2,
                    }}
                  >
                    <View
                      style={{
                        height: 5,
                        width: 5,
                        borderRadius: 6,
                        backgroundColor: "#F55050",
                      }}
                    />
                  </View>
                  <Text style={[styles.bullitPara, { width: "95%" }]}>
                    Business to Customer (B2C) – This model refers to selling products and services
                    between business and customers.
                  </Text>
                </View>

                <Text style={[styles.para, { marginTop: 8, lineHeight: 1.5 }]}>
                  This report provides a detailed evaluation in formulating a solid foundation for
                  the start-up/existing business based on selected model.
                </Text>
              </View>
            </View>
          </View>
          <Image src={Page1Img} style={{ width: "100%", position: "absolute", bottom: 0 }} />

          {/* ============FOOTER========= */}
          <View style={styles.pageNumber}>
            <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
            <Text
              render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
              fixed
            ></Text>
          </View>
        </Page>

        <Page size={"A4"} style={{ paddingBottom: 60 }}>
          <Image src={TargetMarketHead} style={{ width: "100%" }} />
          <View
            style={{
              paddingHorizontal: 60,
              textAlign: "justify",
            }}
          >
            <Text style={styles.title}>Target Market</Text>
            <Text style={styles.para}>
              In an effort to conduct successful market research, having the understanding of target
              market helps to formulate the selling strategy to reach the potential clients and
              generate business. Target market refers to the specific group of consumers to whom the
              products or services are offered, based on geographic, demographic and psychographic
              segmentation.
            </Text>
            <View style={[styles.row, { marginLeft: 0 }]}>
              <View style={styles.radioBtn}>
                <View
                  style={[
                    styles.radioDot,
                    {
                      backgroundColor: "#F55050",
                    },
                  ]}
                />
              </View>
              <Text style={styles.subTitle}>Location</Text>
            </View>
            <Text style={styles.subPara}>
              The geographic segmentation refers to grouping of the potential customers based on
              their physical location. In this scenario, the target audience of geographically
              caters across the .
            </Text>

            <View style={[styles.row, { marginLeft: 0 }]}>
              <View style={styles.radioBtn}>
                <View
                  style={[
                    styles.radioDot,
                    {
                      backgroundColor: "#F55050",
                    },
                  ]}
                />
              </View>
              <Text style={styles.subTitle}>Gender/Age/Marital Status</Text>
            </View>
            <Text style={styles.subPara}>
              The demographic segmentation helps in formulating overall marketing strategies as the
              consumer preferences are prone to change with age and marital status. Based on the key
              demographics of Second Project, the target audience is Male gender, who maybe Infants/
              Kids/ Teenagers of any age group and hold marital status of being Single
            </Text>

            <View style={[styles.row, { marginLeft: 0 }]}>
              <View style={styles.radioBtn}>
                <View
                  style={[
                    styles.radioDot,
                    {
                      backgroundColor: "#F55050",
                    },
                  ]}
                />
              </View>
              <Text style={styles.subTitle}>Education/Occupation Level</Text>
            </View>
            <Text style={styles.subPara}>
              The literacy level of the targeted audience also has a significant influence on the
              marketing strategies for the product or services being offered. For instance, the
              target market for Second Project includes group of individuals whose minimum level of
              education is Undergraduate. This also supports in crafting relevant marketing messages
              that the target audience may understand and relate to Targeting the occupation is also
              a considerable factor as speaking the language of profession that target segment
              belongs to, goes a long way. In addition to the literacy level, the target audience
              for Second Project in terms of their occupation are Students/Entry level employees
            </Text>
          </View>
          {/* ============FOOTER========= */}
          <View style={styles.pageNumber}>
            <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
            <Text
              render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
              fixed
            ></Text>
          </View>
        </Page>
        <Page size={"A4"} style={{ paddingBottom: 60 }}>
          <Image
            src={BoxImage}
            style={{ width: "100%", position: "absolute", right: 0, width: 150, height: 150 }}
          />

          <View
            style={{
              paddingHorizontal: 60,
              marginTop: 100,
              textAlign: "justify",
            }}
          >
            <View style={styles.row}>
              <Text style={styles.title}>Target Market </Text>
              <Text style={{ fontSize: 12, fontStyle: "italic", fontFamily: "roboto" }}>
                Continued
              </Text>
            </View>
            <View style={[styles.row, { marginLeft: 0 }]}>
              <View style={styles.radioBtn}>
                <View
                  style={[
                    styles.radioDot,
                    {
                      backgroundColor: "#F55050",
                    },
                  ]}
                />
              </View>
              <Text style={styles.subTitle}>Income Level</Text>
            </View>
            <Text style={styles.subPara}>
              The income level of potential customers allows to better understand the buying
              capacity of the targeted audience. That helps in coming up with a good pricing
              strategy and also results in getting to know the target market spending habits. For
              the Second Project, the target audience ideally belongs to the High-level income/
              Lower-level income/ Middle-level income group, since there is expected to be a higher
              buying potential within this group.
            </Text>

            <View style={[styles.row, { marginLeft: 0 }]}>
              <View style={styles.radioBtn}>
                <View
                  style={[
                    styles.radioDot,
                    {
                      backgroundColor: "#F55050",
                    },
                  ]}
                />
              </View>
              <Text style={styles.subTitle}>Creating Buyer’s Persona</Text>
            </View>
            <Text style={styles.subPara}>
              In order to create the buyer’s persona, psychological traits and behavioural
              characteristics of targeted audience play a significant role to further refining the
              marketing and business strategy. For the Second Project, the target group holds
              general characteristics of being Innovator, Thinker, Believer, Achiever
            </Text>
            <Text style={styles.subPara}>
              The interests and hobbies exhibit the personality of a targeted audience through their
              behavioural characteristics. The targeted group tends to be interested in Art
              Galleries, Game, Sports the buying power of this group of individuals’ lies own hands.
            </Text>
          </View>
          <Image
            src={TargetMarketBottom}
            style={{ width: "100%", position: "absolute", bottom: 0 }}
          />

          {/* ============FOOTER========= */}
          <View style={styles.pageNumber}>
            <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
            <Text
              render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
              fixed
            ></Text>
          </View>
        </Page>

        <Page size={"A4"}>
          <View
            style={{
              paddingLeft: 60,
              paddingTop: 40,
              textAlign: "justify",
              backgroundColor: "lightgray",
              height: 248,
            }}
          >
            <Text style={styles.title}>Market Size</Text>
            <View style={styles.row}>
              <Text
                style={{
                  width: "45%",
                  marginLeft: -8,
                  letterSpacing: 0.4,
                  fontFamily: "roboto",
                  fontSize: 12,
                  color: "#2A2A2A",
                  fontWeight: "light",
                  lineHeight: 1.4,
                }}
              >
                For a new business it is detrimental to measure the market size before launching a
                new product or service. Market size is an estimation of the customer potential of a
                market i.e. evaluating the total number of individuals in a specific market segment
                who are potential buyers. The market size is determined by two factors, the market
                volume and the market value.
              </Text>
              <Image
                src={MarketSizeHead}
                style={{ width: "55%", height: 190, position: "absolute", bottom: -15, right: 0 }}
              />
            </View>
          </View>

          <View
            style={{
              paddingHorizontal: 60,
              textAlign: "justify",
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "flex-start", marginTop: 20 }}>
              <Image src={MarketSizeIcon1} style={{ width: 40, height: 40 }} />
              <View style={{ marginLeft: 15, paddingRight: 40, textAlign: "justify" }}>
                <Text style={[styles.subTitle, { marginTop: 0 }]}>Total Market Volume</Text>
                <Text style={[styles.subPara, { marginLeft: 0 }]}>
                  Market volume refers to the number of potential customers to be attracted towards
                  purchasing a product, based on the profiling of the customer base. The total
                  Market Volume of Second Project is 3,434,343,434 individuals.
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row", alignItems: "flex-start", marginTop: 20 }}>
              <Image src={MarketSizeIcon2} style={{ width: 40, height: 40 }} />
              <View style={{ marginLeft: 15, paddingRight: 40, textAlign: "justify" }}>
                <Text style={[styles.subTitle, { marginTop: 0 }]}>Target Market Volume</Text>
                <Text style={[styles.subPara, { marginLeft: 0 }]}>
                  Target Market Volume of Second Project is 1,540 individuals
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row", alignItems: "flex-start", marginTop: 20 }}>
              <Image src={MarketSizeIcon3} style={{ width: 40, height: 40 }} />
              <View style={{ marginLeft: 15, paddingRight: 40, textAlign: "justify" }}>
                <Text style={[styles.subTitle, { marginTop: 0 }]}>Market Penetration</Text>
                <Text style={[styles.subPara, { marginLeft: 0 }]}>
                  Market penetration is a measure of how much a product or service is being used by
                  customers compared to the total estimated market volume for that product or
                  service. For Second Project, the Market Penetration rate is 23 %.
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row", alignItems: "flex-start", marginTop: 20 }}>
              <Image src={MarketSizeIcon4} style={{ width: 40, height: 40 }} />
              <View style={{ marginLeft: 15, paddingRight: 40, textAlign: "justify" }}>
                <Text style={[styles.subTitle, { marginTop: 0 }]}>
                  Average Price of similar products/services
                </Text>
                <Text style={[styles.subPara, { marginLeft: 0 }]}>
                  To be competitive, it is essential to research on the prices of the similar
                  products or service in the market as it helps in achieving competitive pricing. In
                  accordance with the conducted research on market size, the average price for
                  product/service correspondent to product or service is 232,323 USD
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row", alignItems: "flex-start", marginTop: 20 }}>
              <Image src={MarketSizeIcon5} style={{ width: 40, height: 40 }} />
              <View style={{ marginLeft: 15, paddingRight: 40, textAlign: "justify" }}>
                <Text style={[styles.subTitle, { marginTop: 0 }]}>Market Value</Text>
                <Text style={[styles.subPara, { marginLeft: 0 }]}>
                  The total market value of this product is 12,345 USD which indicates the value you
                  can get if you could attract all the customers that have access to the product.
                </Text>
              </View>
            </View>
          </View>

          {/* ============FOOTER========= */}
          <View style={styles.pageNumber}>
            <Image src={FooterLine} style={{ width: "60%", height: 2, marginBottom: 5 }} />
            <Text
              render={({ pageNumber, totalPages }) => `Market Research Report  •  ${pageNumber}`}
              fixed
            ></Text>
          </View>
        </Page>

        <Page size={"A4"}>
          <Image src={MarketDemandIcon1} style={{ width: "100%" }} />
          <View
            style={{
              paddingHorizontal: 60,
              textAlign: "justify",
            }}
          >
            <View
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                flexDirection: "row",
                marginBottom: 10,
                marginTop: 20,
              }}
            >
              <Text style={styles.title}>Market Demand</Text>
              <Image
                src={MarketDemandHeading}
                style={{ width: 80, height: 100, position: "absolute", bottom: 10, right: 0 }}
              />
            </View>
            <Text style={[styles.subPara, { marginLeft: 0 }]}>
              Market demand is the total amount of goods and services that all consumers are willing
              and able to purchase at a specific price in a marketplace. This results toward
              identification of the market volume which is fundamental aspect in deciding on
              creating a new product.
            </Text>

            <Text style={styles.subTitle}>•&nbsp;&nbsp; Product-Fit in the Current Market</Text>
            <Text style={styles.para}>
              When the market volume of the product or a service is known, it is easier to evaluate
              how well that product is going to fit into the current market as per the user
              experience, feature set and targeted customers. Acquiring a market share requires a
              point of differentiation or unique selling point to overcome any strategic
              disadvantage.
            </Text>
            <Text style={styles.para}>In this scenario, the the current market, i.e.</Text>

            <View
              style={{
                backgroundColor: "#2B3A55",
                height: 12,
                width: "100%",
                borderBottomWidth: 2,
                borderBottomColor: "red",
              }}
            />
            <View style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
              <Text style={styles.bullitPara}>Customer segmentation (Target Market)</Text>
            </View>
            <View style={{ backgroundColor: "#fff", padding: 5 }}>
              <Text style={styles.bullitPara}>Product features</Text>
            </View>
            <View style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
              <Text style={styles.bullitPara}>Purchase/Buying situation</Text>
            </View>
            <View style={{ backgroundColor: "#fff", padding: 5 }}>
              <Text style={styles.bullitPara}>Complementary products and services analysis</Text>
            </View>
            <View style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
              <Text style={styles.bullitPara}>
                Expected retaliation and barriers to entry, i.e., ability of competitors to control
                access to resources, key suppliers and market channels.
              </Text>
            </View>
          </View>
        </Page>

        <Page size={"A4"}>
          <Image src={MarketDemandIcon1} style={{ width: "100%" }} />
          <View
            style={{
              paddingHorizontal: 60,
              textAlign: "justify",
            }}
          >
            <Text style={styles.title}>Competition Analysis</Text>
            <Text style={[styles.subPara, { marginLeft: 0 }]}>
              Knowing the market competition, makes the launch of products and services of a
              specific industry to stand out in the market. The competition analysis tends to help
              the customers to get the best possible prices, quantity and quality of products or
              services.
            </Text>
            <View style={[styles.row, { marginLeft: 0 }]}>
              <View style={styles.radioBtn}>
                <View
                  style={[
                    styles.radioDot,
                    {
                      backgroundColor: "#F55050",
                    },
                  ]}
                />
              </View>
              <Text style={styles.subTitle}>Core Competencies</Text>
            </View>
            <Text style={styles.subPara}>
              Core competencies are the defining capabilities or advantages that a business has
              which distinguishes it from its competition A company’s core competency in the
              marketplace is important to define its strength which make them stand out amongst its
              competitors. The competitiveness of in the market is .
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingVertical: 5,
              position: "absolute",
              bottom: 200,
            }}
          >
            <Image src={MarketDemandIcon2} style={{ width: 75, height: 75 }} />
            <Image src={MarketDemandIcon3} style={{ width: 75, height: 75 }} />
            <Image src={MarketDemandIcon4} style={{ width: 75, height: 75 }} />
            <Image src={MarketDemandIcon5} style={{ width: 75, height: 75 }} />
            <Image src={MarketDemandIcon6} style={{ width: 75, height: 75 }} />
          </View>
          <Image
            src={MarketDemandIcon1}
            style={{ width: "100%", position: "absolute", bottom: 0 }}
          />
        </Page>

        <Page size={"A4"}>
          <Image src={BarrierstoEntry} style={{ width: "100%" }} />
          <View
            style={{
              paddingHorizontal: 60,
              textAlign: "justify",
            }}
          >
            <Text style={styles.title}>Barriers to Entry</Text>
            <Text style={[styles.subPara, { marginLeft: 0 }]}>
              Barriers to entry is described as the forces that deter company or businesses from
              entering the new market. This may come in the form of high start-up costs, government
              regulations, the need for licenses, and to compete with large corporations. Following
              are the common barriers to entry for the Second Project
            </Text>

            <View
              style={{
                backgroundColor: "#D7E9F7",
                borderLeftWidth: 5,
                borderLeftColor: "#A62349",
                paddingHorizontal: 30,
                marginBottom: 15,
              }}
            >
              <Text style={styles.subTitle}>a) Investment</Text>
              <Text style={styles.para}>
                Investment is the procurement of money that is acquired by the company to pursue its
                organizational objectives. As per the analysis,{" "}
              </Text>
            </View>
            <View
              style={{
                backgroundColor: "#D7E9F7",
                borderLeftWidth: 5,
                borderLeftColor: "#6C00FF",
                paddingHorizontal: 30,
                marginBottom: 15,
              }}
            >
              <Text style={styles.subTitle}>a) Technology</Text>
              <Text style={styles.para}>
                The barriers to entry may be technological, that prevent potential customers from
                entering the market. The technology results in enhancing product performance and
                serves as facilitator for the competitive advantage. Because of superior technology,
                the existing firms enjoy a cost
                <Text style={styles.highlitFont}>advantage over the potential entrants.</Text>
              </Text>
            </View>
          </View>
        </Page>

        <Page size={"A4"}>
          <View
            style={{
              position: "absolute",
              top: "50%",
              height: "100%",
              width: "100%",
            }}
          >
            <Image
              src={ValidatrLogo}
              width={500}
              height={500}
              style={{
                transform: "rotate(-50deg)",
              }}
            />
          </View>
          <View
            style={[
              styles.section,
              { width: 5, height: "20%", backgroundColor: "gray", marginRight: 20 },
            ]}
          >
            <Text style={styles.title}>Market Research</Text>
            <Text style={styles.para}>
              Market Research is the process of gathering, analysing and robotopretation of relative
              information about the buyer’s personas and target market through meticulous research,
              in order to determine the viability factors of a new product or service being offered.
              This can be achieved through analysis of many dynamic aspects that play a key role in
              influencing the purchase decision and behaviour of potential targeted audience.
            </Text>
            <Text style={styles.para}>
              The determination of a business model holds significance in providing the viability of
              targeted business. General business model types are business to business and business
              to customer defined as follows:
            </Text>

            {/* =========bullits=========== */}
            <View style={styles.row}>
              <View
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 12,
                  borderWidth: 1,
                  borderColor: "#7B8FA1",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 5,
                  marginTop: 2,
                }}
              >
                <View
                  style={{
                    height: 5,
                    width: 5,
                    borderRadius: 6,
                    backgroundColor: "#0081C9",
                  }}
                />
              </View>
              <View
                style={{
                  width: 1,
                  height: 25,
                  backgroundColor: "#7B8FA1",
                  position: "absolute",
                }}
              />
              <Text style={styles.bullitPara}>
                Business to Business (B2B) – This model refers to selling products and services
                between businesses.
              </Text>
            </View>
            <View style={styles.row}>
              <View
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 12,
                  borderWidth: 1,
                  borderColor: "#7B8FA1",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 5,
                  marginTop: 2,
                }}
              >
                <View
                  style={{
                    height: 5,
                    width: 5,
                    borderRadius: 6,
                    backgroundColor: "#F55050",
                  }}
                />
              </View>
              <Text style={styles.bullitPara}>
                Business to Customer (B2C) – This model refers to selling products and services
                between business and customers.
              </Text>
            </View>

            <Text style={[styles.para, { marginTop: 8 }]}>
              This report provides a detailed evaluation in formulating a solid foundation for the
              start-up/existing business based on selected model.
            </Text>
          </View>
          {/* =================Glossary===================== */}
          <View
            style={{
              width: 300,
              height: 200,
              margin: 100,
            }}
          >
            {/* <View style={styles.big}> */}
            <View style={[styles.circle_one, styles.circle]}>
              <Text style={styles.circleText}>Innovator</Text>
            </View>
            <View style={[styles.circle_two, styles.circle]}>
              <Text style={styles.circleText}>Thinker</Text>
            </View>
            <View style={[styles.circle_three, styles.circle]}>
              <Text style={styles.circleText}>Believer</Text>
            </View>
            <View style={[styles.circle_four, styles.circle]}>
              <Text style={styles.circleText}>Achiever</Text>
            </View>
            <View style={[styles.circle_five, styles.circle]}>
              <Text style={styles.circleText}>Striver</Text>
            </View>
            <View style={[styles.circle_six, styles.circle]}>
              <Text style={styles.circleText}>Experiencer</Text>
            </View>
            <View style={[styles.circle_seven, styles.circle]}>
              <Text style={styles.circleText}>Makers</Text>
            </View>
            <View style={[styles.circle_eight, styles.circle]}>
              <Text style={styles.circleText}>Survivors</Text>
            </View>
            {/* <Text style={styles.circleText}>Creating Buyer’s Persona</Text> */}
          </View>
          {/* </View> */}

          <View style={styles.section}>
            <Text style={styles.title}>Glossary</Text>
            {glossaryData.map((item, index) => {
              return (
                <View style={styles.glossaryRow} key={index}>
                  <View style={styles.bullet}>
                    <Text style={styles.glossarySubTitle}>•&nbsp;&nbsp; </Text>
                  </View>
                  <Text style={styles.glossaryBullitPara}>
                    <Text style={styles.glossarySubTitle}>{item.title} </Text>
                    {item.description}
                  </Text>
                </View>
              );
            })}

            {swotData.map((item, index) => {
              return (
                <View style={[styles.glossaryRow, { marginLeft: 30 }]} key={index}>
                  <View style={styles.bullet}>
                    <Text style={styles.glossarySubTitle}>•&nbsp;&nbsp; </Text>
                  </View>
                  <Text style={styles.glossaryBullitPara}>
                    <Text style={styles.glossarySubTitle}>{item.title} </Text>
                    {item.description}
                  </Text>
                </View>
              );
            })}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default MarketResearchReportView;

// Create styles
const styles = StyleSheet.create({
  pdfViewer: {
    height: "100vh",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5F5",
    padding: 10,
    position: "absolute",
    top: 0,
  },
  headerText: {
    fontSize: 18,
    textAlign: "center",
  },
  body: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingHorizontal: 65,
    textAlign: "justify",
  },
  title: {
    marginTop: 12,
    marginBottom: 12,
    fontSize: 16,
    fontFamily: "roboto",
    fontWeight: "bold",
    color: "#2A2A2A",
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "roboto",
    color: "#2A2A2A",
    marginTop: 10,
    marginBottom: 8,
    zIndex: 1,
  },
  para: {
    display: "inline-block",
    marginBottom: 6,
    fontSize: 12,
    color: "#2A2A2A",
    lineHeight: 1.3,
    marginLeft: 0,
    fontFamily: "roboto",
    fontWeight: "light",
  },
  bullitPara: {
    display: "inline-block",
    // marginBottom: 4,
    fontSize: 12,
    color: "#2A2A2A",
    lineHeight: 1.3,
    marginLeft: 0,
    fontFamily: "roboto",
    fontWeight: "light",
  },
  subPara: {
    display: "inline-block",
    marginBottom: 6,
    fontSize: 12,
    color: "#2A2A2A",
    lineHeight: 1.3,
    letterSpacing: 0.2,
    marginLeft: 16,
    fontFamily: "roboto",
    fontWeight: "light",
  },

  circleText: {
    fontSize: 10,
    color: "#fff",
  },

  highlitFont: {
    color: "#2A2A2A",
    fontSize: 12,
    fontFamily: "roboto",
    fontWeight: 400,
  },
  rowDirection: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },

  image: {
    height: 80,
    width: "80%",
    marginVertical: 30,
    // marginHorizontal: 100,
  },
  pageNumber: {
    alignItems: "center",
    position: "absolute",
    fontSize: 12,
    fontFamily: "roboto",
    bottom: 20,
    // left: "10%",
    width: "100%",
    color: "grey",
    // textAlign: "center",
  },

  // ==================card================

  card: {
    marginRight: 10,
    marginBottom: 20,
    paddingHorizontal: 12,
    paddingVertical: 15,
    // border: "1px solid #a9b4c6",
    borderWidth: 1,
    borderColor: "#a9b4c6",
    elevation: 5,
    borderRadius: 8,
    // boxShadow: "0px 0px 3px #a9b4c6",
    // backgroundColor: "red",
    width: 150,
  },
  card_title: {
    fontSize: 8,
    color: "#565656",
    // fontWeight: 500,
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // width: "80%",
  },
  value: {
    fontSize: 8,
    // color: "#4e4e4e",
  },
  //   ----------List item
  row: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 10,
    alignItems: "center",
  },
  bullet: {
    height: "100%",
  },

  glossaryRow: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 10,
    marginTop: 8,
  },
  glossarySubTitle: {
    fontSize: 12,
    fontWeight: 700,
    color: "#000",
    marginBottom: 6,
    zIndex: 1,
  },
  glossaryBullitPara: {
    display: "inline-block",
    fontSize: 10,
    color: "#565656",
    lineHeight: 1.3,
    marginLeft: 0,
  },

  backgroundImage: {
    zIndex: 0,
    // position: "relative",
  },

  canvas: {
    backgroundColor: "black",
    height: 500,
    width: 500,
  },

  big: {
    width: 100,
    height: 100,
    backgroundColor: "blue",
    borderRadius: 100,
    // position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  circle: {
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    borderColor: "#E7F6F2",
    borderWidth: 2,
  },

  circle_one: {
    left: 0,
    top: 0,
    width: 62,
    height: 62,
    backgroundColor: "#7286D3",
  },

  circle_two: {
    top: 10,
    left: 70,
    width: 60,
    height: 60,
    backgroundColor: "#7978FF",
  },

  circle_three: {
    top: 0,
    left: 130,
    width: 50,
    height: 50,
    backgroundColor: "#8D8DAA",
  },

  circle_four: {
    top: 0,
    left: 190,
    width: 65,
    backgroundColor: "#7286D3",
    height: 65,
  },

  circle_five: {
    top: 50,
    left: 40,
    width: 40,
    backgroundColor: "#E83D68",
    height: 40,
  },

  circle_six: {
    top: 60,
    left: 100,
    width: 80,
    backgroundColor: "#00D7FF",
    height: 80,
  },

  circle_seven: {
    top: 90,
    left: 25,
    width: 68,
    backgroundColor: "#7286D3",
    height: 68,
  },

  circle_eight: {
    top: 80,
    right: 25,
    width: 95,
    backgroundColor: "#11D0B1",
    height: 95,
  },

  radioBtn: {
    height: 10,
    width: 10,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "#7B8FA1",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
    marginTop: 3,
  },

  radioDot: {
    height: 5,
    width: 5,
    borderRadius: 6,
  },

  title25: {
    fontSize: 21,
    fontWeight: 400,
    fontFamily: "roboto",
    color: "#2A2A2A",
    textAlign: "center",
  },
  title20: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "roboto",
    color: "#6F1AB6",
  },
  headingRow: {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
  },
  tableNumber: {
    fontSize: 22,
    fontWeight: 400,
    fontFamily: "roboto",
    color: "#B2B2B2 ",
  },
  font12: {
    fontSize: 10,
    fontWeight: "light",
    fontFamily: "roboto",
    color: "#2A2A2A",
    marginBottom: 3,
  },
});
