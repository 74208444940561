import React from "react";
import "antd/dist/antd.css";
import { getCookie } from "../../utils/cookies";
import { getAllProjectReset } from "../../redux/actions/getAllProjects";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loadable from "react-loadable";
import NotFound from "../../components/NotFound";
import Dashboard from "../Shell/Dashboard/Dashboard";
import JoinTeamNew from "../JoinTeamNew";
import TeamInvitation from "../../components/TeamInvitation";
import LandingPage from "../Responder/LandingPage";
import ResponderSteps from "../Responder/ResponderSteps";
import ExitPage from "../Responder/ExitPage";
import ResponderConfirmPage from "../../components/ResponderConfirmPage";
import { useStore } from "react-redux";
import { Layout } from "antd";
import HeaderWrapper2 from "../../components/headerNew/headerWrapper2";
import HeaderWrapperLogout from "../../components/logout_header/headerWrapper2";
import FooterContent from "../../components/footerWrapper2";
import MarketResearchReport from "../Shell/Reports/marketResearchReport";
import MarketResearchReportView from "../Shell/Reports/marketResearchReport/indexView";
import MainLandingPage from "../MainLandingPage";
import NewMainLandingPage from "../NewMainLandingPage";
import FAQs from "../Faqs";
import PrivacyPolicy from "../PrivacyPolicy";
import TermAndConditions from "../TermAndConditions";
import { LandingPageFooter } from "../../components/landingPageFooter";
const SignIn = Loadable({ loader: () => import("../SignIn"), loading: () => null });
const SignUp = Loadable({ loader: () => import("../SignUp"), loading: () => null });
// const Pricing = Loadable({loader:() => import('../Pricing') , loading: ()=> null})

const Routes = props => {
  const store = useStore();
  const history = useHistory();
  const [auth, setAuth] = React.useState(false);
  const prevAuth = React.useRef(auth);

  React.useEffect(() => {
    setInterval(() => {
      let token = getCookie("token");
      if (!token && prevAuth.current) {
        props.resetProject();
        history.push("/");
        // removeItem('persist:root')
        window.location.reload();
        // store.resetReducer()
        setAuth(false);
        prevAuth.current = false;
      }
    }, 1000);
  }, []);

  React.useEffect(() => {
    let token = getCookie("token");
    if (token) {
      setAuth(true);
      prevAuth.current = true;
    } else {
      setAuth(false);
    }
  }, [props.loginState, props.signUpState, props.logoutState]);

  // React.useEffect(() => {
  //   if (prevAuth.current == false) {
  //     history.push("/");
  //   }
  // }, []);

  const routesFunction = () => {
    if (auth) {
      // const Dashboard = Loadable({loader:() => import('../Shell/Dashboard/Dashboard') , loading: () => null})
      const IdeaValidation = Loadable({
        loader: () => import("../Shell/Dashboard/IdeaValidation/IdeaValidation"),
        loading: () => null,
      });
      const MarketResearch = Loadable({
        loader: () => import("../Shell/Dashboard/marketResearch/MarketResearch"),
        loading: () => null,
      });
      const Projects = Loadable({
        loader: () => import("../Shell/projects/Projects"),
        loading: () => null,
      });
      const Team = Loadable({ loader: () => import("../Shell/team/Team"), loading: () => null });
      const Report = Loadable({
        loader: () => import("../Shell/Reports/Report"),
        loading: () => null,
      });

      return (
        <div
          style={
            {
              // display: "flex",
              // flexDirection: "column",
              // minHeight: "100vh",
              // justifyContent: "space-between",
            }
          }
        >
          <HeaderWrapper2 />
          <Switch>
            <Route exact path="/" component={NewMainLandingPage} />
            <Route path="/dashboard" component={Dashboard} />
            <Route exact path="/ideaValidation" component={IdeaValidation} />
            <Route path="/market-research" component={MarketResearch} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/team" component={Team} />
            <Route exact path={"/report"} component={Report} />
            <Route exact path={"/market_research_report"} component={MarketResearchReportView} />
            <Route exact path={"/faqs"} component={FAQs} />
            <Route exact path={"/privacy_policy"} component={PrivacyPolicy} />
            <Route exact path={"/terms_and_conditions"} component={TermAndConditions} />

            {/* <Route path="/acceptTeamInvitation/:memberId/:teamId" component={TeamInvitation} /> */}
            {/*====================== Routest on both login and not-login ============*/}
            <Route path="/join-team-new/:memberId/:teamId" component={JoinTeamNew} />
            <Route path="/acceptTeamInvitation/:memberId/:teamId" component={TeamInvitation} />
            <Route path="/response" component={LandingPage} />
            <Route path="/response-welcome/:id/:email" component={LandingPage} />
            <Route path="/responderSteps/:id/:email" component={ResponderSteps} />
            <Route path="/thankyou/:id/:email" component={ExitPage} />
            <Route path="/responder-confirm-page" component={ResponderConfirmPage} />
            {/*=======================================================================*/}

            <Route exact path="*" component={NotFound} />
          </Switch>
          {/* <FooterContent /> */}
        </div>
      );
    } else {
      const ResetPassword = Loadable({
        loader: () => import("../ResetPassword"),
        loading: () => null,
      });
      // const TeamInvitation = Loadable({ loader: () => import('../../components/TeamInvitation'), loading: () => null })
      const UpdatePassword = Loadable({
        loader: () => import("../UpdatePassword"),
        loading: () => null,
      });

      return (
        <>
          <HeaderWrapperLogout />
          <Switch>
            <Route exact path="/" component={NewMainLandingPage} />
            {/*change '/' to '/sign-in' */}
            <Route exact path="/sign-in" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/join-team-new/:memberId/:teamId" component={JoinTeamNew} />
            <Route path="/acceptTeamInvitation/:memberId/:teamId" component={TeamInvitation} />
            <Route path="/response" component={LandingPage} />
            <Route path="/update-password/:id" component={UpdatePassword} />
            <Route path="/response-welcome/:id/:email" component={LandingPage} />
            <Route path="/responderSteps/:id/:email" component={ResponderSteps} />
            <Route path="/thankyou/:id/:email" component={ExitPage} />
            <Route path="/responder-confirm-page" component={ResponderConfirmPage} />
            <Route exact path={"/faqs"} component={FAQs} />
            <Route exact path={"/privacy_policy"} component={PrivacyPolicy} />
            <Route exact path={"/terms_and_conditions"} component={TermAndConditions} />

            <Route exact path="*" component={NotFound} />
          </Switch>
          <LandingPageFooter />
        </>
      );
    }
  };

  return <div>{routesFunction()}</div>;
};

const mapStateToProps = state => {
  return {
    loginState: state.loginReducer,
    signUpState: state.signUpReducer,
    logoutState: state.logOutReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetProject: () => dispatch(getAllProjectReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
