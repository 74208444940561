import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Divider } from "antd";
import MyButton from "../../../components/MyButton";
import ResponderTextArea from "../../../components/ResponderTextArea";
import Response from "../Response";
import StarRating from "../../../components/StarRating";
import { myColor } from "../../../GlobalVar";
import cogoToast from "cogo-toast";
import { Upload } from "antd";
import HomeCardButton from "../../../components/homeCardButton";

const QuestionOne = props => {
  const [fileListState, setFileListState] = React.useState(null);

  // const { description } = props.statement;
  let x = "  ";

  React.useEffect(() => {
    if (props.statement.fileName?.length > 0) {
      let myFiles = [];
      props.statement.fileName.map((val, idx) => {
        let myObj = {
          uid: `-${idx}`,
          name: props.statement.fileOrignalName[idx],
          status: "done",
          url: `${process.env.REACT_APP_REMOTE_FILE_FOLDER}/${val}`,
        };
        myFiles.push(myObj);
      });
      setFileListState(myFiles);
    } else {
      setFileListState(null);
    }
  }, [props]);
  const emptyCheckHandle = () => {
    if (props.response1.rating == "") {
      cogoToast.warn("Please give some rating");
    }
    // else if (props.response1.feedback.trim() == "") {
    //   cogoToast.warn("Please give us your feedback");
    // }
    else {
      props.questionHandle();
    }
  };

  return (
    <div style={{ marginTop: 70 }}>
      <div className="row justify-content-between">
        <div className="col-lg-5 col-sm-12 mb-4">
          <div>
            <h2 style={{ color: myColor.font_color, fontFamily: "Inter-SemiBold" }}>
              {props.name}
            </h2>
            <DescriptionWrapper style={{ height: 300, overflow: "auto" }}>
              <p
                style={{ fontSize: 20, fontFamily: "Inter-Regular", marginTop: 30, lineHeight: 2 }}
              >
                {props.description}
              </p>
            </DescriptionWrapper>
          </div>
          {/* <Divider style={{ background: myColor.light_gray, borderWidth: 2 }} />
          <div>
            {fileListState ? (
              <Upload
                listType="picture-card"
                fileList={fileListState}
                showUploadList={{ showRemoveIcon: false }}
              />
            ) : (
              <div>
                <h5>No Files</h5>
              </div>
            )}
          </div> */}
        </div>

        <div className="col-lg-5 col-sm-12 mb-4">
          <div>
            <h2 style={{ color: myColor.font_color, fontFamily: "Inter-SemiBold" }}>
              {props.question}
            </h2>
            <div style={{ margin: "50px 0 80px 0" }}>
              <StarRating
                ratingValue={props.response1}
                setRatingValue={props.setResponse1}
                matrixValues={props.matrixValues}
              />
            </div>
            <div>
              <span>Feedback</span>
              <span style={{ fontSize: 14, color: "gray" }}> (Optional)</span>
              <ResponderTextArea
                rows={5}
                maxLength={150}
                placeholder="Type here...."
                textAreaState={props.response1}
                setTextAreaState={props.setResponse1}
              />
            </div>
          </div>
        </div>
      </div>
      <Row justify="center" style={{ margin: "45px 0px" }}>
        <Col style={{ marginLeft: "10px", marginBottom: 20 }}>
          <HomeCardButton
            text={props.buttonText}
            bg={true}
            width="auto"
            onClick={emptyCheckHandle}
          />
        </Col>
      </Row>
    </div>
  );
};

export default QuestionOne;

const DescriptionWrapper = styled.div`
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border: 0.1px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b8b8b8;
  }
`;
