
const myColor = {
    black: '#000000',
    red: '#EE3553',
    blue: '#1A5CFF',
    hover_blue: '#0042E6',
    green: '#37B34A',
    yellow: '#FAAF40',
    gray: '#4D4D4F',
    light_gray: 'lightgray',
    font_color: '#111111'
}

export {myColor}