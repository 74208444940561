import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row, Col, Collapse } from "antd";
import Logo from "../../assets/svg/validatr-logo.svg";
import { GoLocation } from "react-icons/go";
import { MdPhoneAndroid } from "react-icons/md";
import { GoMail } from "react-icons/go";
import {
  FaFacebookF,
  FaInstagram,
  FaInstagramSquare,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { PlusOutlined } from "@ant-design/icons";
import { Fade } from "react-awesome-reveal";
import { AiFillInstagram } from "react-icons/ai";

const FooterContent1Div = styled.div`
  /* background-color: #2c3444; */
  border-top: 1px solid #b9c1c7;
  padding: 48px 10% 24px;

  .ant-collapse-borderless {
    background-color: #2c3444;
  }
  .custom-collapse-panel {
    border: 0px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background-color: #232a38;
    color: #03192a;
    border-radius: 4px;
    padding: 6px 20px;
    margin: 14px 0px 14px 0px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .first-col {
    img {
      width: 160px;
    }
    p {
      color: #0b3558;
      margin: 0px;
      font-weight: 500;
    }
    a {
      color: #0b3558;
      margin: 0px;
      font-weight: 500;
      text-decoration: none;
      &:hover {
        color: #03192a;
      }
    }
    .first-col-div {
      display: flex;
      align-items: center;
      margin-top: 24px;
    }
  }

  .second-col {
    h4 {
      font-size: 20px;
      color: #0b3558;
      padding-bottom: 10px;
      font-weight: 600;
      margin-bottom: 13px;
      display: inline-block;
    }
    a {
      color: #0b3558;
    }
    a:hover {
      color: #03192a;
    }
    ul {
      padding: 0px;
      li {
        list-style-type: none;
        margin-bottom: 10px;
        width: fit-content;
        &:hover {
          color: #03192a;
        }
        a {
          cursor: pointer;
          color: #0b3558;
          text-decoration: none;
          ::before {
            position: absolute;
            top: 25px;
            content: "";
            height: 2px;
            width: 0;
            background-color: #03192a;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
          }
          &:hover {
            color: #03192a;
            ::before {
              width: 60%;
            }
          }
        }
      }
    }
  }

  .third-col {
    display: flex;
    justify-content: center;
    h4 {
      font-size: 20px;
      color: #0b3558;
      padding-bottom: 10px;
      font-weight: 600;
      margin-bottom: 13px;
      display: inline-block;
    }
    a {
      color: #0b3558;
    }
    a:hover {
      color: #03192a;
    }
    ul {
      padding: 0px;
      li {
        list-style-type: none;
        margin-bottom: 10px;
        width: fit-content;
        &:hover {
          color: #03192a;
        }
        a {
          cursor: pointer;
          color: #0b3558;
          text-decoration: none;
          font-weight: 500;
          ::before {
            position: absolute;
            top: 25px;
            content: "";
            height: 2px;
            width: 0;
            background-color: #03192a;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
          }
          &:hover {
            color: #03192a;
            ::before {
              width: 60%;
            }
          }
        }
      }
    }
  }

  .fourth-col {
    display: flex;
    justify-content: flex-end;
    h4 {
      font-size: 20px;
      color: #0b3558;
      padding-bottom: 10px;
      font-weight: 600;
      margin-bottom: 13px;
      display: inline-block;
    }
    a {
      color: #0b3558;
    }
    a:hover {
      color: #03192a;
    }
    ul {
      padding: 0px;
      li {
        list-style-type: none;
        margin-bottom: 10px;
        width: fit-content;
        &:hover {
          color: #03192a;
        }
        a {
          cursor: pointer;
          color: #0b3558;
          text-decoration: none;
          ::before {
            position: absolute;
            top: 25px;
            content: "";
            height: 2px;
            width: 0;
            background-color: #03192a;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
          }
          &:hover {
            color: #03192a;
            ::before {
              width: 60%;
            }
          }
        }
      }
    }
  }

  .fifth-col {
  }

  @media (max-width: 991px) {
    .main-row {
      text-align: center;
    }
    .first-col {
      margin-bottom: 32px;
      .first-col-div {
        justify-content: center;
        p {
          text-align: left;
          width: 200px;
        }
        a {
          text-align: left;
          width: 200px;
        }
      }
    }

    .third-col {
      ul {
        padding: 0px;
        li {
          width: 100%;
        }
      }
    }

    .fourth-col {
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 767px) {
    .fifth-col {
      margin-top: 32px;
    }
  }
  @media (max-width: 480px) {
  }
`;

export const LandingPageFooter = () => {
  const { Panel } = Collapse;
  const [mobileView, setMobileView] = useState();

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== "undefined") {
        if (window.innerWidth <= 767) {
          setMobileView(true);
        }
        if (window.innerWidth > 767) {
          setMobileView(false);
        }
      }
    };
    handleResize();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
    }
  }, []);
  return (
    <FooterContent1Div>
      <Row className="main-row">
        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} className="first-col">
          <Fade triggerOnce direction="up" cascade duration={1300} damping={0.2}>
            <Link href="/">
              <a>
                <img alt="logo" src={Logo} />
              </a>
            </Link>
            <div className="first-col-div">
              <div
                style={{
                  border: "1px solid #0B3558",
                  borderRadius: "50%",
                  height: 22,
                  width: 22,
                  position: "relative",
                  marginRight: 18,
                }}
              >
                <GoLocation
                  style={{
                    position: "absolute",
                    top: 4,
                    left: 4,
                    fontSize: 11,
                    color: "#0B3558",
                  }}
                />
              </div>
              <p>
                919 North Market Street,
                <br />
                Suite 950, Wilmington, DE 19801
              </p>
            </div>

            <div className="first-col-div">
              <div
                style={{
                  border: "1px solid #0B3558",
                  borderRadius: "50%",
                  height: 22,
                  width: 22,
                  position: "relative",
                  marginRight: 18,
                }}
              >
                <MdPhoneAndroid
                  style={{
                    position: "absolute",
                    top: 4,
                    left: 4,
                    fontSize: 11,
                    color: "#0B3558",
                  }}
                />
              </div>
              <div style={{ textAlign: "left" }}>
                <a href="tel:+14086220105">+1-408-622-0105</a>
                <p style={{ fontSize: 12, fontStyle: "italic", color: "#0B3558" }}>
                  (9:00 am to 6:00 pm Pacific Time)
                </p>
              </div>
            </div>

            <div className="first-col-div">
              <div
                style={{
                  border: "1px solid #0B3558",
                  borderRadius: "50%",
                  height: 22,
                  width: 22,
                  position: "relative",
                  marginRight: 18,
                }}
              >
                <GoMail
                  style={{
                    position: "absolute",
                    top: 4,
                    left: 4,
                    fontSize: 11,
                    color: "#0B3558",
                  }}
                />
              </div>
              <a href="mailto:social@validatr.io">social@validatr.io</a>
            </div>
          </Fade>
        </Col>

        {/* <Col xxl={4} xl={4} lg={4} md={8} sm={24} xs={24} className="second-col">
          {mobileView ? (
            <Fade triggerOnce direction="up" cascade duration={1300} damping={0.2}>
              <Collapse
                bordered={false}
                expandIconPosition="right"
                expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 135 : 0} />}
                className="custom-collapse"
              >
                <Panel header="Menu" key="1" className="custom-collapse-panel">
                  <ul>
                    <li>
                      <Link href="/">
                        <a>Home</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/about-us">
                        <a>About Us</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/reviews">
                        <a>Reviews</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/contact-us">
                        <a>Contact Us</a>
                      </Link>
                    </li>
                  </ul>
                </Panel>
              </Collapse>
            </Fade>
          ) : (
            <Fade triggerOnce direction="up" cascade duration={1300} damping={0.2}>
              <h4>Menu</h4>
              <ul>
                <li>
                  <Link href="/">
                    <a>Home</a>
                  </Link>
                </li>
                <li>
                  <Link href="/about-us">
                    <a>About Us</a>
                  </Link>
                </li>
                <li>
                  <Link href="/reviews">
                    <a>Reviews</a>
                  </Link>
                </li>
                <li>
                  <Link href="/contact-us">
                    <a>Contact Us</a>
                  </Link>
                </li>
              </ul>
            </Fade>
          )}
        </Col> */}

        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} className="third-col">
          {/* {mobileView ? (
            <div>
              <Fade triggerOnce direction="up" cascade duration={1300} damping={0.2}>
                <Collapse
                  bordered={false}
                  expandIconPosition="right"
                  expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 135 : 0} />}
                  className="custom-collapse"
                >
                  <Panel header="Resources" key="2" className="custom-collapse-panel">
                    <ul>
                      <li>
                        <Link href="/">
                          <a>Home</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/">
                          <a>Knowledge Base</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/">
                          <a>FAQs</a>
                        </Link>
                      </li>
                    </ul>
                  </Panel>
                </Collapse>
              </Fade>
            </div>
          ) : ( */}
          <div style={{ textAlign: mobileView ? "center" : "left" }}>
            <Fade triggerOnce direction="up" cascade duration={1300} damping={0.2}>
              <h4>Resources</h4>
              <ul>
                <li>
                  <Link href="/">
                    <a>Home</a>
                  </Link>
                </li>
                <li>
                  <Link href="/">
                    <a>Knowledge Base</a>
                  </Link>
                </li>
                <li>
                  {/* <Link href="/faqs"> */}
                  <a href="/faqs">FAQs</a>
                  {/* </Link> */}
                </li>
              </ul>
            </Fade>
          </div>
          {/* )} */}
        </Col>

        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} className="fourth-col">
          {/* {mobileView ? (
            <Fade triggerOnce direction="up" cascade duration={1300} damping={0.2}>
              <Collapse
                bordered={false}
                expandIconPosition="right"
                expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 135 : 0} />}
                className="custom-collapse"
              >
                <Panel header="Links" key="3" className="custom-collapse-panel">
                  <ul>
                    <li>
                      <Link href="/why-choose-us">
                        <a>Why Choose Us</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/faqs">
                        <a>{"FAQ's"}</a>
                      </Link>
                    </li>

                    <li>
                      <Link href="/blog/page/1">
                        <a>{"Blog"}</a>
                      </Link>
                    </li>
                  </ul>
                </Panel>
              </Collapse>
            </Fade>
          ) : (
            <Fade triggerOnce direction="up" cascade duration={1300} damping={0.2}>
              <h4>Links</h4>
              <ul>
                <li>
                  <Link href="/why-choose-us">
                    <a>Why Choose Us</a>
                  </Link>
                </li>
                <li>
                  <Link href="/faqs">
                    <a>{"FAQ's"}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/blog/page/1">
                    <a>{"Blog"}</a>
                  </Link>
                </li>
              </ul>
            </Fade>
          )} */}
          <div>
            <Fade triggerOnce direction="up" cascade duration={1300} damping={0.2}>
              <h4 style={{ marginTop: mobileView ? 40 : 0 }}>Social Links</h4>
              <div>
                <a
                  href="https://www.facebook.com/people/Validatrio/100094951682316/"
                  target="_blank"
                >
                  <FaFacebookF style={{ fontSize: 24, marginRight: 20 }} />
                </a>
                <a href="https://www.twitter.com/validatr_io" target="_blank">
                  <FaTwitter style={{ fontSize: 24, marginRight: 20 }} />
                </a>
                <a href="https://www.linkedin.com/showcase/validatr-io/" target="_blank">
                  <FaLinkedinIn style={{ fontSize: 24, marginRight: 20 }} />
                </a>
                <a href="https://www.instagram.com" target="_blank">
                  <AiFillInstagram style={{ fontSize: 24 }} />
                </a>
              </div>
            </Fade>
          </div>
        </Col>
        {/* <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} className="fifth-col">
          <div>
            <Fade triggerOnce direction="up" cascade duration={1300} damping={0.2}>
              <h4 style={{ marginTop: mobileView ? 40 : 0 }}>Social Links</h4>
              <div>
                <a href="https://www.facebook.com/eForteSolutions/" target="_blank">
                  <FaFacebookF style={{ fontSize: 24, marginRight: 20 }} />
                </a>
                <a href="https://twitter.com/eforte_net" target="_blank">
                  <FaTwitter style={{ fontSize: 24, marginRight: 20 }} />
                </a>
                <a
                  href="https://pk.linkedin.com/company/eforte-solutions?original_referer=https%3A%2F%2Fwww.google.com%2F"
                  target="_blank"
                >
                  <FaLinkedinIn style={{ fontSize: 24 }} />
                </a>
              </div>
            </Fade>
          </div>
        </Col> */}
      </Row>
      <div style={{ borderTop: "1px solid #B9C1C7", padding: "20px 0px 0px", marginTop: 40 }}>
        <p style={{ margin: 0, color: "#0B3558", fontWeight: 500 }}>
          Copyright © 2023 <span style={{ fontWeight: 600 }}>validatr.io</span> | All rights
          reserved.{" "}
          <a
            href="terms_and_conditions"
            style={{ textDecoration: "none", color: "#0B3558", fontWeight: 600 }}
          >
            Terms and Conditions
          </a>{" "}
          |{" "}
          <a
            href="/privacy_policy"
            style={{ textDecoration: "none", color: "#0B3558", fontWeight: 600 }}
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </FooterContent1Div>
  );
};
