import iqc_img from "../../assets/Assets-Landing/v2-10.png";
import gdpr_img from "../../assets/Assets-Landing/v2-13.png";
import ai_img from "../../assets/Assets-Landing/v2-11.png";
import trustpilot_img from "../../assets/Assets-Landing/v2-12.png";
import styled from "styled-components";
import { BiCheck } from "react-icons/bi";
import Heading from "../landingPageHeading";

const LandingPageAffiliate = () => {
  return (
    <MainWrapper className="container-fluid">
      <div className="row main-row">
        <div className="col-lg-6 col-sm-12">
          <div className="row">
            <div className="col-6 d-flex justify-content-center mb-4">
              <img src={iqc_img} width={"70%"} />
            </div>
            <div className="col-6 d-flex justify-content-center mb-4">
              <img src={gdpr_img} width={"70%"} />
            </div>
            <div className="col-6 d-flex justify-content-center mb-4">
              <img src={ai_img} width={"70%"} />
            </div>
            <div className="col-6 d-flex justify-content-center mb-4">
              <img src={trustpilot_img} width={"70%"} />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12">
          <div>
            <Heading>
              We are affiliated with the right industry leaders and technology for providing
              validation to your ideas!
            </Heading>
            {/* <h1
              style={{
                fontWeight: 600,
                margin: "0px 0px 10px",
                lineHeight: "60px",
                fontSize: 38,
                color: "#0b3558",
              }}
            >
              We are affiliated with the right industry leaders and technology for providing
              validation to your ideas!
            </h1> */}

            <div style={{ marginTop: "5%" }} className="d-flex align-items-start">
              <BiCheck style={{ fontSize: 48 }} />
              <div className="ms-3">
                {/* <h4 className="mb-0" style={{ color: "#0B3558", fontFamily: "Inter-Bold" }}>
                  Lorem Ipsum
                </h4> */}
                <h6
                  style={{
                    color: "#0B3558",
                    fontFamily: "Inter-Regular",
                    marginTop: "2%",
                  }}
                >
                  At Validatr.io, we prioritize data protection, following GDPR standards to
                  safeguard your privacy.
                </h6>
              </div>
            </div>
            <div style={{ marginTop: "5%" }} className="d-flex align-items-start">
              <BiCheck style={{ fontSize: 30 }} />
              <div className="ms-3">
                {/* <h4 className="mb-0" style={{ color: "#0B3558", fontFamily: "Inter-Bold" }}>
                  Lorem Ipsum
                </h4> */}
                <h6
                  style={{
                    color: "#0B3558",
                    fontFamily: "Inter-Regular",
                    marginTop: "2%",
                  }}
                >
                  At Validatr.io, we utilize Trustpilot for authentic user reviews.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};
export default LandingPageAffiliate;

const MainWrapper = styled.div`
  background-color: #f7faff;
  padding: 5% 10%;
  h4 {
    font-size: 1.4rem;
  }
  h6 {
    font-size: 1.3rem;
  }

  @media (max-width: 600px) {
    .main-row {
      flex-direction: column-reverse;
    }
    h4 {
      font-size: 18px;
    }
    h6 {
      font-size: 14px;
    }
  }
`;
