import { ideaValidation } from "../../config/axiosConfig";
import { PROBLEM_STATEMENT } from "../constants/ideaValidation";
import { getCookie } from "../../utils/cookies";
// import {increaseCurrentIdeaStep,decreaseCurrentIdeaStep} from './getProjectById'
import { changeCurrentIdeaStep, getProjectByIdSuccess } from "./getProjectById";
import cogoToast from "cogo-toast";
import { actions } from "../../utils/helperFunctions";

const putProblemStatement = payLoad => async dispatch => {
  dispatch(actions.loading(PROBLEM_STATEMENT));
  try {
    let apiCall = await ideaValidation.put(
      `/api/projects/updateProject/${getCookie("currentProject")}`,
      payLoad,
      {
        headers: {
          authorization: getCookie("token"),
        },
      }
    );
    dispatch(actions.success(PROBLEM_STATEMENT, apiCall.data.data));
    cogoToast.success("Problem statement has been updated");

    // dispatch(getProjectByIdSuccess(apiCall.data.data))

    // dispatch(increaseCurrentIdeaStep())
    dispatch(changeCurrentIdeaStep(1));
    window.scrollTo(0, 0);
  } catch (err) {
    dispatch(actions.failure(PROBLEM_STATEMENT, err.response ? err.response.data.error : err));
  }
};

export default putProblemStatement;
