import { Checkbox } from "antd";
import styled from "styled-components";
import { myColor } from "../GlobalVar";

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${myColor.blue} !important;
    border: 1px solid ${myColor.blue};
  }

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid ${myColor.blue} !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid ${myColor.blue} !important;
  }
`;

export const CheckboxLarge = styled(Checkbox)`
  :hover {
    border-radius: 6px !important;
    outline: none !important;
  }
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-inner {
    width: 45px;
    height: 45px;
    border: 2px solid #d9d9d9;
    border-radius: 6px;
  }
  .ant-checkbox-inner::after {
    width: 15px;
    height: 30px;
    top: 46%;
  }
  .ant-checkbox-inner::before {
    border-radius: 6px;
  }
`;
