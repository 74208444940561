import { createApiCallReducer } from '../../../utils/helperFunctions'
import {
    SELECT_BUSSINESS_MODEL,
    SELECT_GEOGRAPHICAL_LOCATION,
    SELECT_GENDER,
    SELECT_AGE_RANGE,
    SELECT_MARTIAL_STATUS,
    SELECT_EDUCATION,
    SELECT_OCCUPATION_LEVEL,
    SELECT_INCOME_LEVEL,
    SELECT_BEHAVIORAL_CHARACTERSTICS,
    ADD_INTEREST_HOBBIES,
    SELECT_BUYING_POWER,
    // GET_MARKET_RESEARCH_BY_PROJECT
} from '../../constants/marketResearch/targetMarket'

import { GET_MARKET_RESEARCH_BY_PROJECT } from '../../constants/marketResearch/getmarketResearchByProjectId'

export const bussinessModelReducer = createApiCallReducer(SELECT_BUSSINESS_MODEL)
export const geographicalLocationReducer = createApiCallReducer(SELECT_GEOGRAPHICAL_LOCATION)
export const selectGenderReucer = createApiCallReducer(SELECT_GENDER)
export const selectAgeRangeReducer = createApiCallReducer(SELECT_AGE_RANGE)
export const selectMartialStatusReducer = createApiCallReducer(SELECT_MARTIAL_STATUS)
export const selectEducationReducer = createApiCallReducer(SELECT_EDUCATION)
export const selectOccupationLevel = createApiCallReducer(SELECT_OCCUPATION_LEVEL)
export const selectIncomeLevelReducer = createApiCallReducer(SELECT_INCOME_LEVEL)
export const selectBehavioralReducer = createApiCallReducer(SELECT_BEHAVIORAL_CHARACTERSTICS)
export const addInterestHobbiesReducer = createApiCallReducer(ADD_INTEREST_HOBBIES)
export const selectBuyingPowerReducer = createApiCallReducer(SELECT_BUYING_POWER)

const getMarketResearchInitialData = {
    data: null,
    isLoading: false,
    err: null
}
export const getMarketResearchDataReducer = (state = getMarketResearchInitialData, action) => {
    switch (action.type) {
        case GET_MARKET_RESEARCH_BY_PROJECT.LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case GET_MARKET_RESEARCH_BY_PROJECT.SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payLoad,
                err: null
            }
        case GET_MARKET_RESEARCH_BY_PROJECT.FAILURE:
            return {
                ...state,
                isLoading: false,
                data: null,
                err: action.err
            }
        default: return state
    }
}

const endQuestionTrigerInitialState = {
    isTrigger: false
}

export const triggerEndQuestionReducer = (state = endQuestionTrigerInitialState, action) => {
    switch (action.type) {
        case 'TARGET_MARKET_END_QUESTION':
            return {
                ...state,
                isTrigger: action.isTrigger
            };
        default: return state;
    }
}