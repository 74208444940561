import React from "react";
import { Input } from "antd";
import styled from "styled-components";
import { myColor } from "../GlobalVar";
const { TextArea } = Input;

const TextAreaWrapper = styled(TextArea)`
  textarea.ant-input {
    background-color: #fff;
    resize: none;
    padding: 12px;
    font-size: 14px;
    box-shadow: none;
    /* box-shadow: ${props => (!props.note ? "0 0 15px -3px rgba(0,0,0,0.3)" : "")}; */
    border-radius: 6px !important;
    /* border-right-width: 2px !important; */
    border: 1px solid #A9B4C6;
    /* margin: 3px; */
    /* width */
    ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #d4d4d4;
      border: 0.1px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b8b8b8;
    }
  }
  .ant-input:hover {
    /* border-color: ${myColor.light_gray}; */
    /* border-right-width: 2px !important; */
  }
`;

const ResponderTextArea = props => {
  // const { from , textAreaState , setTextAreaState } = props

  return (
    <TextAreaWrapper
      {...props}
      style={props.style}
      showCount
      note={props.note}
      allowClear={true}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      rows={5}
      // defaultValue={props.value}
      value={props.textAreaState.feedback}
      onChange={e => props.setTextAreaState({ ...props.textAreaState, feedback: e.target.value })}
      onInput={e => props.onInput && props.onInput(e)}
    />
  );
};

export default ResponderTextArea;
