import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFViewer,
  Font,
  Link,
  PDFRenderer,
} from "@react-pdf/renderer";
import moment from "moment";
import bgImage from "../../../../assets/img/idea-validation-cover.jpg";
import tableOfContentBg from "../../../../assets/img/idea-validation-Table-of Content.jpg";
import ValidatrLogoPng from "../../../../assets/img/validatrLogoInverted.png";
import BusinessHypothesis from "../../../../assets/idea-validation-report/page2-image1.png";
import AIIcon from "../../../../assets/idea-validation-report/AI-icon.png";
import UserIcon from "../../../../assets/idea-validation-report/user-icon.png";

import GridLines from "../../../../assets/img/grid.png";

import LikeDislike from "../../../../assets/idea-validation-report/like-dislike.png";

import Hlevel0 from "../../../../assets/idea-validation-report/human-level0.png";
import Hlevel1 from "../../../../assets/idea-validation-report/human-level1.png";
import Hlevel2 from "../../../../assets/idea-validation-report/human-level2.png";
import Hlevel3 from "../../../../assets/idea-validation-report/human-level3.png";
import Hlevel4 from "../../../../assets/idea-validation-report/human-level4.png";
import Hlevel5 from "../../../../assets/idea-validation-report/human-level5.png";

import HShield1 from "../../../../assets/idea-validation-report/human-shield-1.png";
import HShield2 from "../../../../assets/idea-validation-report/human-shield-2.png";
import HShield3 from "../../../../assets/idea-validation-report/human-shield-3.png";
import HShield4 from "../../../../assets/idea-validation-report/human-shield-4.png";
import HShield5 from "../../../../assets/idea-validation-report/human-shield-5.png";

import Hmetter1 from "../../../../assets/idea-validation-report/human-metter1.png";
import Hmetter2 from "../../../../assets/idea-validation-report/human-metter2.png";
import Hmetter3 from "../../../../assets/idea-validation-report/human-metter3.png";
import Hmetter4 from "../../../../assets/idea-validation-report/human-metter4.png";
import Hmetter5 from "../../../../assets/idea-validation-report/human-metter5.png";

import GrayMetter from "../../../../assets/idea-validation-report/gray-metter.png";
import HumanMetter from "../../../../assets/idea-validation-report/human-metrics-.png";
import GrayLevel from "../../../../assets/idea-validation-report/gray-level.png";
import GrayFactor from "../../../../assets/idea-validation-report/gray-factor.png";
import GraySheild from "../../../../assets/idea-validation-report/gray-sheild.png";

import Hfactor1 from "../../../../assets/idea-validation-report/human-factor1.png";
import Hfactor2 from "../../../../assets/idea-validation-report/human-factor2.png";
import Hfactor3 from "../../../../assets/idea-validation-report/human-factor3.png";
import Hfactor4 from "../../../../assets/idea-validation-report/human-factor4.png";
import Hfactor5 from "../../../../assets/idea-validation-report/human-factor5.png";

import AIlevel0 from "../../../../assets/idea-validation-report/ai-level0.png";
import AIlevel1 from "../../../../assets/idea-validation-report/ai-level1.png";
import AIlevel2 from "../../../../assets/idea-validation-report/ai-level2.png";
import AIlevel3 from "../../../../assets/idea-validation-report/ai-level3.png";
import AIlevel4 from "../../../../assets/idea-validation-report/ai-level4.png";
import AIlevel5 from "../../../../assets/idea-validation-report/ai-level5.png";

import AIShield1 from "../../../../assets/idea-validation-report/ai-shield-1.png";
import AIShield2 from "../../../../assets/idea-validation-report/ai-shield-2.png";
import AIShield3 from "../../../../assets/idea-validation-report/ai-shield-3.png";
import AIShield4 from "../../../../assets/idea-validation-report/ai-shield-4.png";
import AIShield5 from "../../../../assets/idea-validation-report/ai-shield-5.png";

import AImetter1 from "../../../../assets/idea-validation-report/ai-metter1.png";
import AImetter2 from "../../../../assets/idea-validation-report/ai-metter2.png";
import AImetter3 from "../../../../assets/idea-validation-report/ai-metter3.png";
import AImetter4 from "../../../../assets/idea-validation-report/ai-metter4.png";
import AImetter5 from "../../../../assets/idea-validation-report/ai-metter5.png";

import AIfactor1 from "../../../../assets/idea-validation-report/ai-factor1.png";
import AIfactor2 from "../../../../assets/idea-validation-report/ai-factor2.png";
import AIfactor3 from "../../../../assets/idea-validation-report/ai-factor3.png";
import AIfactor4 from "../../../../assets/idea-validation-report/ai-factor4.png";
import AIfactor5 from "../../../../assets/idea-validation-report/ai-factor5.png";

import PainScale1 from "../../../../assets/idea-validation-report/pain-scale1.png";
import PainScale2 from "../../../../assets/idea-validation-report/pain-scale2.png";
import PainScale3 from "../../../../assets/idea-validation-report/pain-scale3.png";
import PainScale4 from "../../../../assets/idea-validation-report/pain-scale4.png";
import PainScale5 from "../../../../assets/idea-validation-report/pain-scale5.png";

import Quotes from "../../../../assets/idea-validation-report/comma.png";
import ConclusionIcon1 from "../../../../assets/idea-validation-report/conclusion-icon-1.png";
import ConclusionIcon2 from "../../../../assets/idea-validation-report/conclusion-icon-2.png";
import ConclusionIcon3 from "../../../../assets/idea-validation-report/conclusion-icon-3.png";
import ConclusionIcon4 from "../../../../assets/idea-validation-report/conclusion-icon-4.png";
import ConclusionIcon5 from "../../../../assets/idea-validation-report/conclusion-icon-5.png";
import ConclusionIcon6 from "../../../../assets/idea-validation-report/conclusion-icon-6.png";
import ConclusionIcon7 from "../../../../assets/idea-validation-report/conclusion-icon-7.png";
import ConclusionIcon8 from "../../../../assets/idea-validation-report/conclusion-icon-8.png";
import ConclusionIcon9 from "../../../../assets/idea-validation-report/conclusion-icon-9.png";

import Analysis from "../pdfgenerate/assets/Analysis.png";

import robotoBold from "../../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import robotoMedium from "../../../../assets/fonts/Roboto/Roboto-Medium.ttf";
import robotoRegular from "../../../../assets/fonts/Roboto/Roboto-Regular.ttf";
import robotoLight from "../../../../assets/fonts/Roboto/Roboto-Light.ttf";
import robotoItalic from "../../../../assets/fonts/Roboto/Roboto-Italic.ttf";
import Header, { NewHeader } from "../pdfgenerate/components/Header";
import PercentageBar from "../pdfgenerate/components/PercentageBar";
import CircleWithDifferentBorders from "../pdfgenerate/components/CircleWithDifferentBorders";
import CircleChart from "../pdfgenerate/components/CircleWithDifferentBorders";
import DonutChart from "../pdfgenerate/components/CircleWithDifferentBorders";
import { useEffect, useState } from "react";

const pdfStyles = StyleSheet.create({
  container: {
    width: "50%",
    justifyContent: "flex-start",
  },
  heading: {
    fontSize: 12,
    fontFamily: "roboto",
    marginLeft: 20,
  },
  chartContainer: {
    height: "150px",
    width: "100%",
    padding: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  yAxisLabels: {
    flexDirection: "column",
    justifyContent: "space-around",
    height: "100%",
    borderRightWidth: 1,
    borderRightColor: "#e0e6f6",
    width: 8,
  },
  xAxisLabels: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-end",
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#e0e6f6",
    height: "100%",
  },
  barContainer: {
    height: "100%",
  },
  bar: {
    width: 15,
    flexDirection: "row",
    marginHorizontal: 6,
    height: "100%",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  barSegment: {
    width: 6,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  legendContainer: {
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 15,
  },
  legend: {
    flexDirection: "row",
    alignItems: "center",
  },
  legendDot: {
    width: 10,
    height: 10,
    borderRadius: 100,
  },
  legendText: {
    fontSize: 8,
    marginLeft: 4,
  },
});

Font.register({
  family: "roboto",
  format: "truetype",
  fonts: [
    {
      src: robotoBold,
      fontWeight: "bold",
    },
    {
      src: robotoMedium,
      fontWeight: 500,
    },
    {
      src: robotoRegular,
      fontWeight: 600,
    },
    {
      src: robotoLight,
      fontWeight: "light",
    },
    {
      src: robotoItalic,
      fontWeight: "light",
      fontStyle: "italic",
    },
  ],
});

const bestResponses = [
  {
    userName: "Profile1",
    rating: 3,
    comment:
      "Problem/Insight: Not very relevant, as I'm a frequent flyer, but I found that problem a couple of times when having arelative taking you or picking you up at the airport was not an option.",
  },
  {
    userName: "Profile2",
    rating: 4,
    comment:
      "Problem/Insight: Not very relevant, as I'm a frequent flyer, but I found that problem a couple of times when having arelative taking you or picking you up at the airport was not an option.",
  },
  {
    userName: "Profile3",
    rating: 5,
    comment:
      "Problem/Insight: Not very relevant, as I'm a frequent flyer, but I found that problem a couple of times when having arelative taking you or picking you up at the airport was not an option.",
  },
  {
    userName: "Profile4",
    rating: 2,
    comment:
      "Problem/Insight: Not very relevant, as I'm a frequent flyer, but I found that problem a couple of times when having arelative taking you or picking you up at the airport was not an option.",
  },
  {
    userName: "Profile5",
    rating: 5,
    comment:
      "Problem/Insight: Not very relevant, as I'm a frequent flyer, but I found that problem a couple of times when having arelative taking you or picking you up at the airport was not an option.",
  },
];

const TagItem = ({ bgColor, color, text, width, opacity }) => {
  return (
    <View
      style={{
        backgroundColor: bgColor,
        paddingVertical: 3,
        paddingHorizontal: 5,
        borderRadius: 12,
        width: width,
        marginLeft: 10,
        marginRight: 10,
        textAlign: "center",
        opacity: opacity,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontFamily: "roboto",
          color: color,
          opacity: 1,
        }}
      >
        {text}
      </Text>
    </View>
  );
};

export const IdeaValidationReport = props => {
  // console.log("reportData", props.reportData);
  const { AIResponses, projectDetails, userResponse } = props.reportData;

  //=========== Human And AI Response Chart =============

  const chartResponse = [
    {
      title: "Relevance",
      ai: AIResponses?.averageAIRating?.problemRelevance,
      human: userResponse?.averageUserRating?.problemRelevance || 0,
    },
    {
      title: "Usefulness",
      ai: AIResponses?.averageAIRating?.ideaUsefulness,
      human: userResponse?.averageUserRating?.ideaUsefulness || 0,
    },
    {
      title: "Uniqueness",
      ai: AIResponses?.averageAIRating?.ideaUniqueness,
      human: userResponse?.averageUserRating?.ideaUniqueness || 0,
    },
    {
      title: `Likeliness \n to Promote`,
      ai: AIResponses?.averageAIRating?.ideaVariability,
      human: userResponse?.averageUserRating?.ideaVariability || 0,
    },
    {
      title: "Willingness \n to Buy",
      ai: AIResponses?.averageAIRating?.willingnessToBuy,
      human: userResponse?.averageUserRating?.willingnessToBuy || 0,
    },
  ];

  // const strengths = projectDetails.analysis?.split("Strengths")[1]?.split("Opportunities")[0];
  // const getOportunities = projectDetails.analysis
  //   ?.split("Opportunities")[1]
  //   ?.split("Improvement Suggestions")[0];
  // const improvementSuggentions = projectDetails.analysis?.split("Improvement Suggestions")[1];
  const seperatedByColonRegex = /([A-Za-z\s.]+):/;

  // let replaceOddSpaces = projectDetails.analysis?.replaceAll("\n \n", "\n\n")

  // const splittedAnalysis = projectDetails.analysis
  //   ?.trim()
  //   ?.replaceAll("\n \n", "\n\n")
  //   ?.split(/^.*bullet points:.*$/im);

  const splittedAnalysis = projectDetails.analysis
    ?.trim()
    ?.replaceAll("\n \n", "\n\n")
    ?.split("\n\n");
  const splittedAnalysisArr = splittedAnalysis.filter(str => str !== "");
  let analysisName = [];
  let analysisPoints = [];
  // splittedAnalysis.forEach(statement => {
  //   const match = statement.match(seperatedByColonRegex);
  //   if (match) {
  //     analysisName.push(match[1]);
  //     const comment = statement.replace(match[0], "").trim();
  //     analysisPoints.push(comment);
  //   }
  // });

  // splittedAnalysis.forEach((data, index) => {
  //   if (data == "") {
  //     splittedAnalysis.splice(index, 1);
  //   }
  // });
  // console.log("splittedAnalysis--->", splittedAnalysis);

  // console.log("analysisName", analysisName);
  // console.log("analysisPoints", analysisPoints);

  const categories = [
    // "Analysis",
    "Problem Relevance",
    "Solution Usefulness",
    "Solution Uniqueness",
    "Viral Effect",
    "Willingness to Buy",
    "Strengths",
    "Opportunities",
    "Improvement Suggestions",
  ];

  const organizedData = {};

  categories.forEach(category => {
    const categoryData = splittedAnalysis.find(item => item.startsWith(category));
    if (categoryData) {
      organizedData[category] = categoryData.replace(category + ":\n", "");
    }
  });

  const orderedOrganizedData = {};
  categories.forEach((category, index) => {
    orderedOrganizedData[`category${index + 1}`] = organizedData[category];
  });

  // const problemRelevance = orderedOrganizedData.category1
  //   ?.split("\n")
  //   ?.slice(1)
  //   ?.join("\n")
  //   ?.trim();
  const problemRelevance = splittedAnalysis[0]
    ?.split("\n") // Split the string into an array of lines
    ?.slice(1) // Remove the first line (heading)
    ?.join("\n") // Join the remaining lines back into a string
    ?.trim();
  // const solutionUsefullness = orderedOrganizedData.category2
  //   ?.split("\n")
  //   ?.slice(1)
  //   ?.join("\n")
  //   ?.trim();
  const solutionUsefullness = splittedAnalysis[1]
    ?.split("\n") // Split the string into an array of lines
    ?.slice(1) // Remove the first line (heading)
    ?.join("\n") // Join the remaining lines back into a string
    ?.trim();
  // const solutionUniqueness = orderedOrganizedData.category3
  //   ?.split("\n") // Split the string into an array of lines
  //   ?.slice(1) // Remove the first line (heading)
  //   ?.join("\n") // Join the remaining lines back into a string
  //   ?.trim();
  const solutionUniqueness = splittedAnalysis[2]
    ?.split("\n") // Split the string into an array of lines
    ?.slice(1) // Remove the first line (heading)
    ?.join("\n") // Join the remaining lines back into a string
    ?.trim();
  // const viralEffect = orderedOrganizedData.category4
  //   ?.split("\n") // Split the string into an array of lines
  //   ?.slice(1) // Remove the first line (heading)
  //   ?.join("\n") // Join the remaining lines back into a string
  //   ?.trim();
  const viralEffect = splittedAnalysis[3]
    ?.split("\n") // Split the string into an array of lines
    ?.slice(1) // Remove the first line (heading)
    ?.join("\n") // Join the remaining lines back into a string
    ?.trim();
  // const willingness = orderedOrganizedData.category5
  //   ?.split("\n") // Split the string into an array of lines
  //   ?.slice(1) // Remove the first line (heading)
  //   ?.join("\n") // Join the remaining lines back into a string
  //   ?.trim();
  const willingness = splittedAnalysis[4]
    ?.split("\n") // Split the string into an array of lines
    ?.slice(1) // Remove the first line (heading)
    ?.join("\n") // Join the remaining lines back into a string
    ?.trim();
  // const strengths = orderedOrganizedData.category6
  //   ?.split("\n") // Split the string into an array of lines
  //   ?.slice(1) // Remove the first line (heading)
  //   ?.join("\n") // Join the remaining lines back into a string
  //   ?.trim();
  const strengths = splittedAnalysis[5]
    ?.split("\n") // Split the string into an array of lines
    ?.slice(1) // Remove the first line (heading)
    ?.join("\n") // Join the remaining lines back into a string
    ?.trim();
  // const getOportunities = orderedOrganizedData.category7
  //   ?.split("\n") // Split the string into an array of lines
  //   ?.slice(1) // Remove the first line (heading)
  //   ?.join("\n") // Join the remaining lines back into a string
  //   ?.trim();
  const getOportunities = splittedAnalysis[6]
    ?.split("\n") // Split the string into an array of lines
    ?.slice(1) // Remove the first line (heading)
    ?.join("\n") // Join the remaining lines back into a string
    ?.trim();
  // const improvementSuggentions = orderedOrganizedData.category8
  //   ?.split("\n") // Split the string into an array of lines
  //   ?.slice(1) // Remove the first line (heading)
  //   ?.join("\n") // Join the remaining lines back into a string
  //   ?.trim();
  const improvementSuggentions = splittedAnalysis[7]
    ?.split("\n") // Split the string into an array of lines
    ?.slice(1) // Remove the first line (heading)
    ?.join("\n") // Join the remaining lines back into a string
    ?.trim();

  // console.log("splittedAnalysis", splittedAnalysis);
  // console.log("splittedAnalysisArr", splittedAnalysisArr);
  // console.log("organizedData", organizedData);
  // console.log("orderedOrganizedData", orderedOrganizedData);

  // console.log("-------------ANALYSIS-----------------");
  // console.log("problemRelevance", problemRelevance);
  // console.log("solutionUsefullness", solutionUsefullness);
  // console.log("solutionUniqueness", solutionUniqueness);
  // console.log("viralEffect", viralEffect);
  // console.log("willingness", willingness);
  // console.log("-------------STRENGTHS | OPPORTUNITIES | SUGGESTIONS-----------------");
  // console.log("strengths", strengths);
  // console.log("getOportunities", getOportunities);
  // console.log("improvementSuggentions", improvementSuggentions);

  // console.log("splittedAnalysis", splittedAnalysis);

  const splittedPersonality = projectDetails?.AIPersonalities?.trim()?.split("\n");
  // const getFirstPesonality = splittedPersonality[0];
  // const getSecondPesonality = splittedPersonality[1];
  // const getThirdPesonality = splittedPersonality[2];
  // const getFourthPesonality = splittedPersonality[3];

  let names = [];
  let comments = [];
  splittedPersonality.forEach(statement => {
    const match = statement.match(seperatedByColonRegex);
    if (match) {
      names.push(match[1]);
      const comment = statement.replace(match[0], "").trim();
      comments.push(comment);
    }
  });

  // console.log("ORIGINAL", projectDetails?.AIPersonalities);
  // console.log("splittedPersonality", splittedPersonality);
  // console.log("names----->", names);
  // console.log("comments----->", comments);

  const industryVoicesViaAI = [
    {
      userName: names[0],
      comment: comments[0],
    },
    {
      userName: names[1],
      comment: comments[1],
    },
    {
      userName: names[2],
      comment: comments[2],
    },
    {
      userName: names[3],
      comment: comments[3],
    },
  ];

  // console.log("splittedPersonality", splittedPersonality);

  // ==========LEVEL SELECTION=========
  const humanLevelSelection = value => {
    if (value == 0) {
      return <Image src={Hlevel0} style={{ width: 60 }} />;
    } else if (value == 1) {
      return <Image src={Hlevel1} style={{ width: 60 }} />;
    } else if (value == 2) {
      return <Image src={Hlevel2} style={{ width: 60 }} />;
    } else if (value == 3) {
      return <Image src={Hlevel3} style={{ width: 60 }} />;
    } else if (value == 4) {
      return <Image src={Hlevel4} style={{ width: 60 }} />;
    } else if (value == 5) {
      return <Image src={Hlevel5} style={{ width: 60 }} />;
    } else {
      return <Image src={GrayLevel} style={{ width: 60 }} />;
    }
  };

  // ==========AI LEVEL SELECTION=========
  const AILevelSelection = value => {
    if (value == 0) {
      return <Image src={AIlevel0} style={{ width: 60 }} />;
    } else if (value == 1) {
      return <Image src={AIlevel1} style={{ width: 60 }} />;
    } else if (value == 2) {
      return <Image src={AIlevel2} style={{ width: 60 }} />;
    } else if (value == 3) {
      return <Image src={AIlevel3} style={{ width: 60 }} />;
    } else if (value == 4) {
      return <Image src={AIlevel4} style={{ width: 60 }} />;
    } else if (value == 5) {
      return <Image src={AIlevel5} style={{ width: 60 }} />;
    }
  };

  // ==========SHIELD SELECTION=========
  const humanShieldSelection = () => {
    // let { ideaUniqueness, ideaUsefulness, ideaVariability, problemRelevance, willingnessToBuy } =
    //   userResponse?.averageUserRating !== null && userResponse?.averageUserRating;
    let totalAggregatedRating = Math.round(
      (userResponse?.averageUserRating?.ideaUniqueness +
        userResponse?.averageUserRating?.ideaUsefulness +
        userResponse?.averageUserRating?.ideaVariability +
        userResponse?.averageUserRating?.problemRelevance +
        userResponse?.averageUserRating?.willingnessToBuy) /
        5
    );
    if (totalAggregatedRating == 1) {
      return <Image src={HShield1} style={{ width: 90, height: 100 }} />;
    } else if (totalAggregatedRating == 2) {
      return <Image src={HShield2} style={{ width: 90, height: 100 }} />;
    } else if (totalAggregatedRating == 3) {
      return <Image src={HShield3} style={{ width: 90, height: 100 }} />;
    } else if (totalAggregatedRating == 4) {
      return <Image src={HShield4} style={{ width: 90, height: 100 }} />;
    } else if (totalAggregatedRating == 5) {
      return <Image src={HShield5} style={{ width: 90, height: 100 }} />;
    } else {
      return <Image src={GraySheild} style={{ width: 90, height: 100 }} />;
    }
  };

  // =========AI SHIELD SELECTION============

  let AIAvgRating;

  const aiShiedldSelection = () => {
    let {
      ideaUniqueness,
      ideaUsefulness,
      ideaVariability,
      problemRelevance,
      willingnessToBuy,
    } = AIResponses?.averageAIRating;
    let totalAggregatedRating = Math.round(
      (ideaUniqueness + ideaUsefulness + ideaVariability + problemRelevance + willingnessToBuy) / 5
    );
    AIAvgRating = Math.round(
      (ideaUniqueness + ideaUsefulness + ideaVariability + problemRelevance + willingnessToBuy) / 5
    );
    if (totalAggregatedRating == 1) {
      return <Image src={AIShield1} style={{ width: 90, height: 100 }} />;
    } else if (totalAggregatedRating == 2) {
      return <Image src={AIShield2} style={{ width: 90, height: 100 }} />;
    } else if (totalAggregatedRating == 3) {
      return <Image src={AIShield3} style={{ width: 90, height: 100 }} />;
    } else if (totalAggregatedRating == 4) {
      return <Image src={AIShield4} style={{ width: 90, height: 100 }} />;
    } else if (totalAggregatedRating == 5) {
      return <Image src={AIShield5} style={{ width: 90, height: 100 }} />;
    }
  };

  // ==========METTER SELECTION=========
  const humanMetterSelection = value => {
    if (value == 1) {
      return <Image src={Hmetter1} style={{ width: 100, height: 100, marginLeft: 10 }} />;
    } else if (value == 2) {
      return <Image src={Hmetter2} style={{ width: 100, height: 100, marginLeft: 10 }} />;
    } else if (value == 3) {
      return <Image src={Hmetter3} style={{ width: 100, height: 100, marginLeft: 10 }} />;
    } else if (value == 4) {
      return <Image src={Hmetter4} style={{ width: 100, height: 100, marginLeft: 10 }} />;
    } else if (value == 5) {
      return <Image src={Hmetter5} style={{ width: 100, height: 100, marginLeft: 10 }} />;
    } else {
      return <Image src={HumanMetter} style={{ width: 100, height: 100, marginLeft: 10 }} />;
      // return <Image src={GrayMetter} style={{ width: 100, height: 100, marginLeft: 10 }} />;
    }
  };

  // ==========AI METTER SELECTION=========
  const aiMetterSelection = value => {
    if (value == 1) {
      return <Image src={AImetter1} style={{ width: 100, height: 100 }} />;
    } else if (value == 2) {
      return <Image src={AImetter2} style={{ width: 100, height: 100 }} />;
    } else if (value == 3) {
      return <Image src={AImetter3} style={{ width: 100, height: 100 }} />;
    } else if (value == 4) {
      return <Image src={AImetter4} style={{ width: 100, height: 100 }} />;
    } else if (value == 5) {
      return <Image src={AImetter5} style={{ width: 100, height: 100 }} />;
    }
  };

  // ==========AI INTENSITY SELECTION=========
  const aiIntensitySelection = value => {
    if (value <= 1.5) {
      return <Image src={PainScale1} style={{ width: "90%", height: 60 }} />;
    } else if (value >= 1.6 && value <= 2.5) {
      return <Image src={PainScale2} style={{ width: "90%", height: 60 }} />;
    } else if (value >= 2.6 && value <= 3.5) {
      return <Image src={PainScale3} style={{ width: "90%", height: 60 }} />;
    } else if (value >= 3.6 && value <= 4.5) {
      return <Image src={PainScale4} style={{ width: "90%", height: 60 }} />;
    } else if (value >= 4.6) {
      return <Image src={PainScale5} style={{ width: "90%", height: 60 }} />;
    }
  };

  // ==========FACTOR SELECTION=========
  const humanFactorSelection = value => {
    if (value > 1 && value <= 20) {
      return <Image src={Hfactor1} style={{ width: "100%", height: "100%" }} />;
    } else if (value > 20 && value <= 40) {
      return <Image src={Hfactor2} style={{ width: "100%", height: "100%" }} />;
    } else if (value > 40 && value <= 60) {
      return <Image src={Hfactor3} style={{ width: "100%", height: "100%" }} />;
    } else if (value > 60 && value <= 80) {
      return <Image src={Hfactor4} style={{ width: "100%", height: "100%" }} />;
    } else if (value > 80 && value <= 100) {
      return <Image src={Hfactor5} style={{ width: "100%", height: "100%" }} />;
    } else {
      return <Image src={GrayFactor} style={{ width: "100%", height: "100%" }} />;
    }
  };

  // ==========AI FACTOR SELECTION=========
  const aiFactorSelection = value => {
    if (value > 1 && value <= 20) {
      return <Image src={AIfactor1} style={{ width: "100%", height: "100%" }} />;
    } else if (value > 20 && value <= 40) {
      return <Image src={AIfactor2} style={{ width: "100%", height: "100%" }} />;
    } else if (value > 40 && value <= 60) {
      return <Image src={AIfactor3} style={{ width: "100%", height: "100%" }} />;
    } else if (value > 60 && value <= 80) {
      return <Image src={AIfactor4} style={{ width: "100%", height: "100%" }} />;
    } else if (value > 80 && value <= 100) {
      return <Image src={AIfactor5} style={{ width: "100%", height: "100%" }} />;
    }
  };

  // ============HUMAN RESPONDENTS TEXT SHOW==========
  const humanTextShow = value => {
    if (value) {
      return value;
    }
    return "Human survey was not conducted.";
  };
  let getAIIPS = AIResponses?.promoters - AIResponses?.skeptics;
  let getUserIPS = userResponse?.promoters - userResponse?.skeptics;

  const factors = {
    "Problem Relevance": {
      1: "Not Relevant",
      2: "Slightly Relevant",
      3: "Somewhat Relevant",
      4: "Moderately Relevant",
      5: "Very Relevant",
    },
    "Solution Usefulness": {
      1: "Not Useful",
      2: "Somewhat Useful",
      3: "Moderately Useful",
      4: "Very Useful",
      5: "Extemely Useful",
    },
    "Solution Uniqueness": {
      1: "Not Unique",
      2: "Slightly Unique",
      3: "Somewhat Unique",
      4: "Fairly Unique",
      5: "Highly Unique",
    },
    "Likeliness to Promote": {
      1: "Not Likely",
      2: "Slightly Likely",
      3: "Somewhat Likely",
      4: "Likely",
      5: "Highly Likely",
    },
    "Willingness To Buy": {
      1: "Not Willing",
      2: "Slightly Willing",
      3: "Somewhat Willing",
      4: "Willing",
      5: "Highly Willing",
    },
  };

  return (
    // <PDFViewer style={styles.pdfViewer}>
    <Document>
      {/* ================ Cover Page ==================== */}
      <Page size="A4">
        <Image src={bgImage} width={"100%"} height={"100%"} style={{ zIndex: 1 }} />
        <Image
          src={ValidatrLogoPng}
          style={{
            width: 150,
            position: "absolute",
            top: 50,
            left: 60,
          }}
        />
        <Text
          style={{
            zIndex: 2,
            position: "absolute",
            top: 150,
            // left: 60,
            fontSize: 28,
            fontWeight: 700,
            fontFamily: "roboto",
            textAlign: "center",
            color: "#fff",
          }}
        >
          Idea Validation Report
        </Text>

        <Text
          style={{
            zIndex: 2,
            position: "absolute",
            top: 195,
            left: 0,
            fontSize: 18,
            fontWeight: 400,
            fontFamily: "roboto",
            fontStyle: "italic",
            textAlign: "center",
            color: "#fff",
          }}
        >
          for
        </Text>

        <Text
          style={{
            zIndex: 2,
            position: "absolute",
            top: 230,
            left: 0,
            fontSize: 28,
            fontWeight: 700,
            fontFamily: "roboto",
            textAlign: "center",
            color: "#fff",
          }}
        >
          "{projectDetails?.title}"
        </Text>

        <Text
          style={[
            styles.para,
            {
              position: "absolute",
              bottom: 60,
              left: 0,
              fontSize: 12,
              fontFamily: "roboto",
              fontWeight: "light",
              textAlign: "center",
              color: "#060139",
              letterSpacing: 1.2,
            },
          ]}
        >
          Copyright © 2023 validatr.io All rights reserved.
        </Text>
        <Text
          style={[
            styles.para,
            {
              position: "absolute",
              bottom: 30,
              right: 50,
              fontSize: 11,
              fontFamily: "roboto",
              fontStyle: "italic",
              fontWeight: 300,
              // textAlign: "center",
              color: "#060139",
            },
          ]}
        >
          Date created: {moment(projectDetails?.createdAt).format("DD MMM YYYY")}
        </Text>
      </Page>

      {/* ================ 2nd Page ==================== */}
      <Page size="A4" style={{ paddingHorizontal: 50, paddingVertical: 40 }}>
        <Text style={{ fontSize: 14 }}>Welcome to the complete process of idea validation.</Text>
        <Text
          style={{
            fontSize: 20,
            fontWeight: "bold",
            fontFamily: "roboto",
            marginVertical: 10,
            color: "#060139",
          }}
        >
          IDEA VALIDATION REPORT
        </Text>
        <Text style={{ fontSize: 12 }}>Report # 1 (Respondent's Feedback)</Text>

        <Text style={{ fontSize: 18, marginTop: 20 }}>How Validatr.io helps validates ideas:</Text>
        <Text style={{ fontSize: 12, marginTop: 14, lineHeight: 1.5, letterSpacing: 0.3 }}>
          Eliminate guesswork and work towards a successful product. Our platform empowers you to
          refine and validate ideas effectively. Validatr.io is a powerful hybrid tool that blends
          AI technology with human inputs, offering the perfect synergy to assess the potential of
          your idea and solution. It expedites the idea validation process for new projects and
          startups, providing a structured framework with modules for validating the problem and
          solution, conducting market research, and determining MVP Features. Additionally, it
          empowers users to craft a compelling Unique Value Proposition (UVP), develop MVP
          prototypes, and create the final MVP product, guiding startups toward funding and success.
        </Text>

        <Text
          style={{
            fontSize: 17,
            fontFamily: "roboto",
            fontWeight: 600,
            marginTop: 16,
            textAlign: "center",
            lineHeight: 1.2,
          }}
        >
          Flowchart:
        </Text>
        <Text
          style={{
            fontSize: 17,
            fontFamily: "roboto",
            fontWeight: 600,
            textAlign: "center",
            marginTop: 0,
            marginBottom: 18,
          }}
        >
          Refine and Validate Ideas with AI and Human Insights
        </Text>
        <Image src={BusinessHypothesis} />
        <Text style={{ fontSize: 18, marginTop: 20 }}>About Idea Validation Report:</Text>
        <Text style={{ fontSize: 12, marginTop: 14, lineHeight: 1.5, letterSpacing: 0.3 }}>
          The report evaluates respondents' feedback on five key success factors and various
          exclusive Validatr metrics that objectively demonstrate the solution's viability. Through
          our hybrid process, the problem and solution are assessed not only by human respondents
          but also by AI posing as the target market respondents. Moreover, the AI plays the role of
          a VC firm executive, providing comments and feedback on the solution's validity. This
          high-level perspective adds significant value and confidence about the idea's potential.
          The Idea Validation Report serves as a valuable resource for decision-making and for
          demonstrating the strength in the proposed solution, gaining confidence, support, and
          funding. It provides essential insights for further refining your idea toward a
          market-acceptable solution. With Validatr.io, make informed decisions for your
          entrepreneurial journey.
        </Text>

        <Text style={{ fontSize: 12, marginTop: 8, lineHeight: 1.5, letterSpacing: 0.3 }}>
          We hope this report will be useful in your journey towards building a successful product
          or service.
        </Text>
      </Page>

      {/* ================ Table of Content ==================== */}
      <Page size="A4">
        <Image
          src={tableOfContentBg}
          style={{ position: "absolute", top: 0, zIndex: 1, height: "100%", width: "100%" }}
        />

        <View style={{ paddingHorizontal: 80, paddingVertical: 60 }}>
          <Text style={{ fontSize: 22, color: "#060139", marginBottom: 8, fontWeight: 400 }}>
            TABLE OF CONTENTS
          </Text>

          <View style={styles.tableOfContentRow}>
            <Text style={styles.tableOfContentPara}>Introduction to Validatr.io</Text>
            <Text style={styles.tableOfContentPara}>01</Text>
          </View>

          <View style={styles.tableOfContentRow}>
            <View>
              <Text style={styles.tableOfContentPara}>Problem & Solution Statements</Text>
              <Text style={styles.tableOfContentSubPara}>
                Strengths & Opportunities, Suggestions and Comments
              </Text>
            </View>
            <Text style={styles.tableOfContentPara}>03</Text>
          </View>

          <View style={styles.tableOfContentRow}>
            <Text style={styles.tableOfContentPara}>Summary of Hybrid Validation Process</Text>
            <Text style={styles.tableOfContentPara}>04</Text>
          </View>

          <View style={styles.tableOfContentRow}>
            <Text style={styles.tableOfContentPara}>Factor # 1: Relevance of Problem</Text>
            <Text style={styles.tableOfContentPara}>05</Text>
          </View>

          <View style={styles.tableOfContentRow}>
            <Text style={styles.tableOfContentPara}>Factor # 2: Usefulness of Solution</Text>
            <Text style={styles.tableOfContentPara}>06</Text>
          </View>

          <View style={styles.tableOfContentRow}>
            <Text style={styles.tableOfContentPara}>Factor # 3: Uniqueness of Solution</Text>
            <Text style={styles.tableOfContentPara}>07</Text>
          </View>

          <View style={styles.tableOfContentRow}>
            <Text style={styles.tableOfContentPara}>
              Factor # 4: Likeliness to Promote Solution
            </Text>
            <Text style={styles.tableOfContentPara}>08</Text>
          </View>

          <View style={styles.tableOfContentRow}>
            <Text style={styles.tableOfContentPara}>Factor # 5: Willingness to Buy Solution</Text>
            <Text style={styles.tableOfContentPara}>09</Text>
          </View>

          <View style={styles.tableOfContentRow}>
            <View>
              <Text style={styles.tableOfContentPara}>Comments from Top Industry</Text>
              <Text style={styles.tableOfContentSubPara}>Professionals - Impersonated by AI</Text>
            </View>
            <Text style={styles.tableOfContentPara}>10</Text>
          </View>

          {userResponse?.bestFiveResponses?.length < 1 ? null : (
            <View style={styles.tableOfContentRow}>
              <Text style={styles.tableOfContentPara}>Best Comments from Human</Text>
              <Text style={styles.tableOfContentPara}>11</Text>
            </View>
          )}

          {userResponse?.worstFiveResponses?.length < 1 ? null : (
            <View style={styles.tableOfContentRow}>
              <Text style={styles.tableOfContentPara}>Worst Comments from Human</Text>
              <Text style={styles.tableOfContentPara}>12</Text>
            </View>
          )}

          <View style={styles.tableOfContentRow}>
            <Text style={styles.tableOfContentPara}>Conclusion & Next Steps</Text>
            <Text style={styles.tableOfContentPara}>
              {userResponse?.bestFiveResponses?.length < 1 &&
              userResponse?.worstFiveResponses?.length < 1
                ? "11"
                : "13"}
            </Text>
          </View>

          <View style={styles.tableOfContentRow}>
            <Text style={styles.tableOfContentPara}>Glossary</Text>
            <Text style={styles.tableOfContentPara}>
              {userResponse?.bestFiveResponses?.length < 1 &&
              userResponse?.worstFiveResponses?.length < 1
                ? "12"
                : "14"}
            </Text>
          </View>
        </View>
      </Page>

      {/* ================ Problem & Solution Statements ==================== */}
      <Page size="A4">
        <View style={{ paddingHorizontal: 40, paddingTop: 30, paddingBottom: 20 }}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: 700,
              fontFamily: "roboto",
              marginVertical: 5,
              color: "#060139",
              textAlign: "center",
            }}
          >
            Problem & Solution Statements
          </Text>
          <Text
            style={{
              lineHeight: 1.1,
              marginTop: 8,
              marginBottom: 2,
              fontSize: 16,
              fontWeight: 400,
              fontFamily: "roboto",
            }}
          >
            Problem:
          </Text>
          <Text
            style={{
              fontSize: 12,
              lineHeight: 1.3,
              letterSpacing: 0.3,
              color: "#686868",
            }}
          >
            {projectDetails?.statement?.final == "PROBLEM_STATEMENT_BY_AI"
              ? projectDetails?.statement.descriptionByAI
              : projectDetails?.statement.description}
          </Text>

          <Text
            style={{
              lineHeight: 1.1,
              marginTop: 8,
              marginBottom: 2,
              fontSize: 16,
              fontWeight: 400,
              fontFamily: "roboto",
            }}
          >
            Solution:
          </Text>
          <Text
            style={{
              fontSize: 12,
              lineHeight: 1.3,
              letterSpacing: 0.3,
              color: "#686868",
            }}
          >
            {projectDetails?.solution?.final == "SOLUTION_STATEMENT_BY_AI"
              ? projectDetails?.solution.descriptionByAI
              : projectDetails?.solution.description}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFF9",
            paddingHorizontal: 40,
            paddingVertical: 10,
          }}
        >
          <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Image src={AIIcon} style={{ width: 40, height: 40 }} />
            <View style={{ marginLeft: 20 }}>
              <Text style={{ fontSize: 14, fontFamily: "roboto" }}>
                Strengths and Opportunities{" "}
                <Text style={{ fontSize: 8, fontWeight: 300, fontFamily: "roboto", marginLeft: 6 }}>
                  Identified by VC Firm Executive*
                </Text>
              </Text>
              {/* <Text
                  style={{ fontSize: 12, letterSpacing: 0.3, marginVertical: 8, color: "#686868" }}
                >
                  Strengths of the proposed solution in relation to the problem include:
                </Text> */}
            </View>
          </View>
          {/* =========bullits=========== */}
          <View style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
            <Text
              style={{
                fontSize: 12,
                letterSpacing: 0.3,
                marginVertical: 8,
                color: "#686868",
                textAlign: "justify",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontFamily: "roboto",
                  color: "#545454",
                  lineHeight: 1.3,
                }}
              >
                Strengths{"\n"}
              </Text>

              {strengths}
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
            <Text
              style={{
                fontSize: 12,
                letterSpacing: 0.3,

                color: "#686868",
                textAlign: "justify",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontFamily: "roboto",
                  color: "#545454",
                  lineHeight: 1.3,
                }}
              >
                Opportunities{"\n"}
              </Text>
              {getOportunities}
            </Text>
          </View>
        </View>
        <View
          style={{
            paddingHorizontal: 40,
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            marginLeft: 0,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              letterSpacing: 0.3,
              color: "#686868",
              textAlign: "justify",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontFamily: "roboto",
                color: "#000",
                lineHeight: 1.3,
              }}
            >
              Suggestions and Comments{" "}
              <Text style={{ fontSize: 8, fontWeight: 300, fontFamily: "roboto", marginLeft: 6 }}>
                Identified by VC Firm Executive*{"\n"}
              </Text>
            </Text>
            {improvementSuggentions}
          </Text>
        </View>
        <Text style={{ position: "absolute", bottom: 8, fontSize: 8, right: 50, fontWeight: 300 }}>
          * Impersonated by AI
        </Text>
      </Page>

      {/* ================ Final Analysis ==================== */}
      <Page size="A4" style={{ paddingHorizontal: 50, paddingTop: 20, paddingBottom: 30 }}>
        <NewHeader title={`Summary of Idea Validation\nusing a hybrid validation process`} />
        <Text
          style={{
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.3,
            textAlign: "center",
          }}
        >
          AI and Human Assessment Insights{" "}
        </Text>

        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <View
            style={{
              width: "70%",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontFamily: "roboto",
                marginBottom: 20,
              }}
            >
              POWER TO SUCCEED
            </Text>
            <Text
              style={{
                fontSize: 12,
              }}
            >
              {`Reflects evaluators' confidence in the positive\nattributes of the idea, ignoring negative ratings.`}
            </Text>
          </View>
          <View
            style={{
              width: "30%",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: 10,
            }}
          >
            {AILevelSelection(AIResponses?.powerToSucceed)}
            <View
              style={{
                width: 1,
                height: "100%",
                marginHorizontal: 15,
              }}
            ></View>
            {humanLevelSelection(userResponse?.powerToSucceed)}
          </View>
        </View>

        {/* <View
            style={{
              width: "100%",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "40%" }}>
              <Text
                style={{
                  marginVertical: 10,
                  fontSize: 16,
                  fontWeight: 400,
                  fontFamily: "roboto",
                }}
              >
                POWER TO SUCCEED
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  lineHeight: 1.3,
                  letterSpacing: 0.3,
                  width: "60%",
                }}
              >
                {`Reflects evaluators' confidence in the positive\nattributes of the idea, ignoring negative ratings.`}
              </Text>
            </View>
            <View style={{ width: "50%" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "40%",
                }}
              >
                {AILevelSelection(AIResponses?.powerToSucceed)}
                <View
                  style={{
                    width: 1,
                    height: "100%",
                    // backgroundColor: "#ededed",
                    marginHorizontal: 16,
                  }}
                ></View>
                {humanLevelSelection(userResponse?.powerToSucceed)}
              </View>
            </View>
          </View> */}
        {/* <Image src={LikeDislike} style={{ marginTop: -30, width: 200 }} /> */}

        <View
          style={{
            width: "100%",
            backgroundColor: "#EFEFF9",
            borderLeftColor: "blue",
            borderLeftWidth: 2,
            padding: 20,
            marginTop: 20,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              textAlign: "center",
              fontFamily: "roboto",
            }}
          >
            The Aggregated Rating For Your Idea
          </Text>

          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: 0,
            }}
          >
            <View
              style={{
                width: "50%",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "flex-end",
              }}
            >
              <View>
                <Image src={AIIcon} style={{ width: 30, height: 30 }} />
                <Text
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    letterSpacing: 0.3,
                    fontFamily: "roboto",
                  }}
                >
                  {`Artificial \n intelligence`}
                </Text>
              </View>
              {aiShiedldSelection()}
            </View>
            <View style={{ width: 1, height: "100%", backgroundColor: "#EEEEEE" }}></View>
            <View
              style={{
                marginTop: 20,
                width: "50%",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "flex-end",
                //   borderLeftColor: "gray",
                //   borderLeftWidth: 1,
              }}
            >
              {humanShieldSelection()}
              <View>
                <Image src={UserIcon} style={{ width: 30, height: 30 }} />
                <Text
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    letterSpacing: 0.3,
                    fontFamily: "roboto",
                  }}
                >
                  {`Human \n Respondents`}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            width: "100%",
            marginTop: 20,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                fontFamily: "roboto",
              }}
            >
              Problem Solution Impact
            </Text>
            <Text
              style={{
                marginTop: 10,
                fontSize: 12,
                lineHeight: 1.5,
                letterSpacing: 0.3,
              }}
            >
              {`Expresses evaluators confidence on\nthe positive attributes of the idea.`}
            </Text>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              {aiMetterSelection(AIResponses?.productSolutionFit)}
              {humanMetterSelection(userResponse?.productSolutionFit)}
            </View>
          </View>
          <View style={{ width: 1, height: "100%", backgroundColor: "#EEEEEE" }}></View>
          <View
            style={{
              width: "50%",
              // flexDirection: "row",
              justifyContent: "flex-start",
              // alignItems: "flex-end",
              // backgroundColor: "blue",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                fontFamily: "roboto",
                marginLeft: 20,
              }}
            >
              Rating of 5 factors
            </Text>

            <Image
              src={GridLines}
              style={{ width: "215px", height: "125px", marginLeft: "20px", marginBottom: "10px" }}
            />
            <View
              style={{
                height: "150px",
                width: "100%",
                padding: 20,
                position: "absolute",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "100%",
                  // borderRightWidth: 1,
                  // borderRightColor: "#e0e6f6",
                  width: 8,
                }}
              >
                {[5, 4, 3, 2, 1].map(item => {
                  return (
                    <Text key={item} style={{ fontSize: 6, fontWeight: 300, fontFamily: "roboto" }}>
                      {item}
                    </Text>
                  );
                })}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "flex-end",
                  width: "100%",
                  // borderBottomWidth: 1,
                  // borderBottomColor: "#e0e6f6",
                  height: "100%",
                }}
              >
                {chartResponse.map(item => {
                  let getAiPer = item.ai * 20 - 8;
                  let getHumanPer = item.human * 20 - 8;

                  return (
                    <View
                      key={item.ai}
                      style={{
                        height: "100%",
                      }}
                    >
                      <View
                        style={{
                          width: 15,
                          flexDirection: "row",
                          marginHorizontal: 6,
                          height: "100%",
                          alignItems: "flex-end",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            width: 6,
                            height: `${getAiPer}%`,
                            backgroundColor: "#95458C",
                            borderTopRightRadius: 4,
                            borderTopLeftRadius: 4,
                          }}
                        ></View>
                        <View
                          style={{
                            width: 6,
                            height: `${getHumanPer}%`,
                            backgroundColor: "#4E56FF",
                            borderTopRightRadius: 4,
                            borderTopLeftRadius: 4,
                          }}
                        ></View>
                      </View>
                      <Text
                        style={{
                          fontSize: 6,
                          marginTop: 10,
                          fontWeight: 300,
                          fontFamily: "roboto",
                        }}
                      >
                        {item.title}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                flexDirection: "row",
                marginTop: 15,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View
                  style={{ backgroundColor: "#95458C", width: 10, height: 10, borderRadius: 100 }}
                ></View>
                <Text style={{ fontSize: 8, marginLeft: 4 }}>AI</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 20 }}>
                {chartResponse.reduce((sum, item) => sum + (item.human || 0), 0) === 0 ? (
                  <View
                    style={{ backgroundColor: "#9a9a9a", width: 10, height: 10, borderRadius: 100 }}
                  ></View>
                ) : (
                  <View
                    style={{ backgroundColor: "#4E56FF", width: 10, height: 10, borderRadius: 100 }}
                  ></View>
                )}
                <Text style={{ fontSize: 8, marginLeft: 4 }}>HUMAN</Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#EFEFF9",
            borderLeftColor: "blue",
            borderLeftWidth: 2,
            paddingVertical: 10,
            paddingHorizontal: 20,
          }}
        >
          <View
            style={{
              width: "40%",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                fontFamily: "roboto",
              }}
            >
              WOW FACTOR
            </Text>
            <Text
              style={{
                marginTop: 10,
                fontSize: 12,
                lineHeight: 1.5,
                letterSpacing: 0.3,
              }}
            >
              {`Demonstrates the capacity to\ncaptivate buyers through the\ncombination of solution\nuniqueness and usefulness.`}
            </Text>
          </View>

          <View
            style={{
              marginTop: 20,
              width: "60%",
              flexDirection: "row",
              justifyContent: "center",
              // alignItems: "flex-end",
            }}
          >
            <View
              style={{
                width: "45%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {aiFactorSelection(AIResponses?.wowFactor)}
              <Text
                style={{
                  fontWeight: "bold",
                  fontFamily: "roboto",
                  color: "#95458C",
                  position: "absolute",
                  textAlign: "center",
                  zIndex: 2,
                  left: "40%",
                  top: "40%",
                  flexDirection: "column",
                }}
              >
                {`${AIResponses?.wowFactor}% \n`}
                {/* <Text
                    style={{
                      fontSize: 8,
                      color: "#000",
                      marginLeft: -5,
                      fontFamily: "roboto",
                      fontWeight: 300,
                    }}
                  >
                    Excellent
                  </Text> */}
              </Text>
            </View>
            <View
              style={{
                width: 1,
                height: "100%",
                backgroundColor: "#EEEEEE",
                marginHorizontal: 10,
              }}
            ></View>

            <View
              style={{
                width: "45%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {humanFactorSelection(userResponse?.wowFactor)}
              <Text
                style={{
                  fontWeight: "bold",
                  fontFamily: "roboto",
                  color: "#9A9A9A",
                  position: "absolute",
                  textAlign: "center",
                  zIndex: 2,
                  left: "40%",
                  top: "40%",
                  flexDirection: "column",
                }}
              >
                {userResponse?.wowFactor ? `${userResponse?.wowFactor}% \n` : "N/A"}
                {/* <Text
                    style={{
                      fontSize: 8,
                      color: "#000",
                      marginLeft: -5,
                      fontFamily: "roboto",
                      fontWeight: 300,
                    }}
                  >
                    Excellent
                  </Text> */}
              </Text>
            </View>
          </View>
        </View>
      </Page>

      {/* ================ Metric # 1 =================== */}
      <Page size="A4" style={{ paddingHorizontal: 50, paddingTop: 30, paddingBottom: 30 }}>
        <NewHeader title="Factor #1: Relevance of Problem" />
        <Text
          style={{
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.3,
            textAlign: "center",
          }}
        >
          Respondents were asked to answer the following question:
        </Text>
        <Text
          style={{
            marginVertical: 10,
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "roboto",
            textAlign: "center",
          }}
        >
          Do you think the problem will be relevant to the target market?
        </Text>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontFamily: "roboto",
                marginBottom: 20,
              }}
            >
              INTENSITY OF PROBLEM
            </Text>
            <Text
              style={{
                fontSize: 12,
              }}
            >
              Severity or magnitude of the problem.
            </Text>
            <Text
              style={{
                fontSize: 12,
                fontFamily: "roboto",
                marginTop: 10,
              }}
            >
              Score: {AIResponses?.averageAIRating?.problemIntensity} - Intense
            </Text>
          </View>
          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontFamily: "roboto",
                marginBottom: 20,
              }}
            >
              Pain Scale
            </Text>
            {aiIntensitySelection(AIResponses?.averageAIRating?.problemIntensity)}
          </View>
        </View>

        <View
          style={{
            borderLeftColor: "blue",
            borderLeftWidth: 2,
            backgroundColor: "#EFEFF9",
            padding: 15,
            marginTop: 8,
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image src={AIIcon} style={{ width: 30, height: 30 }} />
            <Text style={{ fontSize: 14, fontFamily: "roboto", marginLeft: 10 }}>
              Analysis of Problem Relevance{" "}
              <Text style={{ fontSize: 8, fontWeight: 300, fontFamily: "roboto", marginLeft: 6 }}>
                Identified by VC Firm Executive*
              </Text>
            </Text>
          </View>
          <Text
            style={{
              lineHeight: 1.3,
              letterSpacing: 0.3,
              fontSize: 10,
              marginTop: 8,
            }}
          >
            {problemRelevance}
          </Text>
        </View>

        <Text
          style={{
            fontSize: 14,
            fontFamily: "roboto",
            marginTop: 25,
          }}
        >
          How "Relevant" did the target market find the problem:
        </Text>

        <PercentageBar
          style={{ width: "100%", marginTop: 20, flexDirection: "row", alignItems: "flex-start" }}
          percent={`${(AIResponses?.averageAIRating?.problemRelevance / 5) * 100}%`}
          title="AI"
          name1="Not Relevant"
          name2="Slightly Relevant"
          name3="Somewhat Relevant"
          name4="Moderately Relevant"
          name5="Very Relevant"
        />
        <PercentageBar
          style={{ width: "100%", marginTop: 12, flexDirection: "row", alignItems: "flex-start" }}
          percent={`${(userResponse?.averageUserRating?.problemRelevance / 5) * 100}%`}
          title="HUMAN"
          name1="Not Relevant"
          name2="Slightly Relevant"
          name3="Somewhat Relevant"
          name4="Moderately Relevant"
          name5="Very Relevant"
        />

        <View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}>
          <Image src={UserIcon} style={{ width: 30, height: 30 }} />
          <Text style={{ fontSize: 14, fontFamily: "roboto", marginLeft: 10 }}>
            Analysis of Ratings by Human Respondents:
          </Text>
        </View>
        <Text
          style={{
            lineHeight: 1.5,
            letterSpacing: 0.3,
            fontSize: 10,
            marginTop: 8,
            color: userResponse?.analysis?.text1 ? "black" : "#73777B",
          }}
        >
          {humanTextShow(userResponse?.analysis?.text1)}
        </Text>
      </Page>

      {/* ================Metric #2=================== */}
      <Page size="A4" style={{ paddingHorizontal: 50, paddingTop: 30, paddingBottom: 30 }}>
        <NewHeader title="Factor #2: Usefulness of Solution" />
        <Text
          style={{
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.3,
            textAlign: "center",
            marginTop: -2,
          }}
        >
          {`Usefulness of the solution with respect to Legality, Safety, Practicality and health aspects.\nRespondents were asked to answer the following question:`}
        </Text>

        <Text
          style={{
            marginVertical: 10,
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "roboto",
            textAlign: "center",
          }}
        >
          Do you think the solution is useful to the target group?
        </Text>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontFamily: "roboto",
                marginBottom: 20,
              }}
            >
              PROBLEM SOLUTION IMPACT
            </Text>
            <Text
              style={{
                fontSize: 12,
              }}
            >
              {`Indicates the degree to which the solution\neffectively tackles the problem.`}
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 10,
            }}
          >
            {aiMetterSelection(AIResponses?.productSolutionFit)}
            <View style={{ width: 1, height: "100%", marginHorizontal: 15 }}></View>
            {humanMetterSelection(userResponse?.productSolutionFit)}
          </View>
        </View>

        <View
          style={{
            borderLeftColor: "blue",
            borderLeftWidth: 2,
            backgroundColor: "#EFEFF9",
            padding: 15,
            marginTop: 8,
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image src={AIIcon} style={{ width: 30, height: 30 }} />
            <Text style={{ fontSize: 14, fontFamily: "roboto", marginLeft: 10 }}>
              Analysis of Usefulness of Solution{" "}
              <Text style={{ fontSize: 8, fontWeight: 300, fontFamily: "roboto", marginLeft: 6 }}>
                Identified by VC Firm Executive*
              </Text>
            </Text>
          </View>
          <Text style={{ lineHeight: 1.5, letterSpacing: 0.3, fontSize: 10, marginTop: 5 }}>
            {solutionUsefullness}
          </Text>
        </View>

        <Text
          style={{
            fontSize: 14,
            fontFamily: "roboto",
            marginTop: 25,
          }}
        >
          How "Useful" did the target market find the solution:
        </Text>

        <PercentageBar
          style={{ width: "100%", marginTop: 20, flexDirection: "row", alignItems: "flex-start" }}
          percent={`${(AIResponses?.averageAIRating?.ideaUsefulness / 5) * 100}%`}
          title="AI"
          name1="Not Useful"
          name2="Somewhat Useful"
          name3="Moderately Useful"
          name4="Very Useful"
          name5="Extemely Useful"
        />
        <PercentageBar
          style={{ width: "100%", marginTop: 20, flexDirection: "row", alignItems: "flex-start" }}
          percent={`${(userResponse?.averageUserRating?.ideaUsefulness / 5) * 100}%`}
          title="HUMAN"
          name1="Not Useful"
          name2="Somewhat Useful"
          name3="Moderately Useful"
          name4="Very Useful"
          name5="Extemely Useful"
        />

        <View style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}>
          <Image src={UserIcon} style={{ width: 30, height: 30 }} />
          <Text style={{ fontSize: 14, fontFamily: "roboto", marginLeft: 10 }}>
            Analysis of Ratings by Human Respondents:
          </Text>
        </View>
        <Text
          style={{
            lineHeight: 1.5,
            letterSpacing: 0.3,
            fontSize: 10,
            marginTop: 8,
            color: userResponse?.analysis?.text2 ? "black" : "#73777B",
          }}
        >
          {humanTextShow(userResponse?.analysis?.text2)}
        </Text>
      </Page>

      {/* ================Metric #3=================== */}
      <Page size="A4" style={{ paddingHorizontal: 50, paddingTop: 30, paddingBottom: 30 }}>
        <NewHeader title="Factor #3: Uniqueness of Solution" />
        <Text
          style={{
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.3,
            textAlign: "center",
          }}
        >
          Respondents were asked to answer the following question:
        </Text>
        <Text
          style={{
            marginVertical: 10,
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "roboto",
            textAlign: "center",
          }}
        >
          Will the target market find the suggested solution unique and new?
        </Text>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <View
            style={{
              width: "42%",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontFamily: "roboto",
                marginBottom: 20,
              }}
            >
              WOW FACTOR
            </Text>
            <Text
              style={{
                fontSize: 12,
              }}
            >
              {`Demonstrates the capacity to captivate\nbuyers through the combination of\nsolution uniqueness and usefulness.`}
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <View
              style={{
                width: "45%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {aiFactorSelection(AIResponses?.wowFactor)}

              <Text
                style={{
                  fontWeight: "bold",
                  fontFamily: "roboto",
                  color: "#B54A90",
                  position: "absolute",
                  textAlign: "center",
                  zIndex: 2,
                  left: "35%",
                  top: "40%",
                  flexDirection: "column",
                }}
              >
                {`${AIResponses?.wowFactor}% \n`}
                {/* <Text
                    style={{
                      fontSize: 8,
                      color: "#000",
                      marginLeft: -5,
                      fontFamily: "roboto",
                      fontWeight: 300,
                    }}
                  >
                    Excellent
                  </Text> */}
              </Text>
            </View>
            <View
              style={{
                width: 1,
                height: "100%",
                backgroundColor: "#EEEEEE",
                marginHorizontal: 10,
              }}
            ></View>
            <View
              style={{
                width: "45%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {humanFactorSelection(userResponse?.wowFactor)}
              <Text
                style={{
                  fontWeight: "bold",
                  fontFamily: "roboto",
                  color: "#9a9a9a",
                  position: "absolute",
                  textAlign: "center",
                  zIndex: 2,
                  left: "35%",
                  top: "40%",
                  flexDirection: "column",
                }}
              >
                {userResponse?.wowFactor ? `${userResponse?.wowFactor}% \n` : "N/A"}
                {/* <Text
                    style={{
                      fontSize: 8,
                      color: "#000",
                      marginLeft: -5,
                      fontFamily: "roboto",
                      fontWeight: 300,
                    }}
                  >
                    Excellent
                  </Text> */}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            borderLeftColor: "blue",
            borderLeftWidth: 2,
            backgroundColor: "#EFEFF9",
            padding: 15,
            marginTop: 8,
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image src={AIIcon} style={{ width: 30, height: 30 }} />

            <Text style={{ fontSize: 14, fontFamily: "roboto", marginLeft: 10 }}>
              Analysis of Uniqueness of Solution{" "}
              <Text style={{ fontSize: 8, fontWeight: 300, fontFamily: "roboto", marginLeft: 6 }}>
                Identified by VC Firm Executive*
              </Text>
            </Text>
          </View>
          <Text style={{ lineHeight: 1.5, letterSpacing: 0.3, fontSize: 10 }}>
            {solutionUniqueness}
          </Text>
        </View>

        <Text
          style={{
            fontSize: 14,
            fontFamily: "roboto",
            marginTop: 25,
          }}
        >
          How "Unique" did the target market find the Solution:
        </Text>

        <PercentageBar
          style={{ width: "100%", marginTop: 20, flexDirection: "row", alignItems: "flex-start" }}
          percent={`${(AIResponses?.averageAIRating?.ideaUniqueness / 5) * 100}%`}
          title="AI"
          name1="Not Unique"
          name2="Slightly Unique"
          name3="Somewhat Unique"
          name4="Fairly Unique"
          name5="Highly Unique"
        />
        <PercentageBar
          style={{ width: "100%", marginTop: 10, flexDirection: "row", alignItems: "flex-start" }}
          percent={`${(userResponse?.averageUserRating?.ideaUniqueness / 5) * 100}%`}
          title="HUMAN"
          name1="Not Unique"
          name2="Slightly Unique"
          name3="Somewhat Unique"
          name4="Fairly Unique"
          name5="Highly Unique"
        />

        <View style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}>
          <Image src={UserIcon} style={{ width: 30, height: 30 }} />
          <Text style={{ fontSize: 14, fontFamily: "roboto", marginLeft: 10 }}>
            Analysis of Ratings by Human Respondents:
          </Text>
        </View>
        <Text
          style={{
            lineHeight: 1.5,
            letterSpacing: 0.3,
            fontSize: 10,
            marginTop: 8,
            color: userResponse?.analysis?.text3 ? "black" : "#73777B",
          }}
        >
          {humanTextShow(userResponse?.analysis?.text3)}
        </Text>
      </Page>

      {/* ================Metric #4=================== */}
      <Page size="A4" style={{ paddingHorizontal: 50, paddingTop: 30, paddingBottom: 30 }}>
        <NewHeader title="Factor #4: Likeliness to Promote" />
        <Text
          style={{
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.3,
            textAlign: "center",
          }}
        >
          Respondents were asked to answer the following question:
        </Text>
        <Text
          style={{
            marginVertical: 8,
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "roboto",
            textAlign: "center",
          }}
        >
          How likely will the target market tell their friends about this idea?
        </Text>
        {/* <Text style={{ fontSize: 12, fontFamily: "roboto", textAlign: "center" }}>
            IDEA PROPAGATION SCORE (IPS){" "}
          </Text> */}
        <View
          style={{ flexDirection: "row", width: "100%", justifyContent: "center", marginTop: 10 }}
        >
          <Text
            style={{
              letterSpacing: 1.1,
              fontSize: 12,
              fontFamily: "roboto",
              fontWeight: "bold",
              color: "#060139",
            }}
          >
            IPS ={" "}
          </Text>
          <Text
            style={{
              letterSpacing: 1.1,
              fontSize: 12,
              fontFamily: "roboto",
              color: "#060139",
              fontWeight: "bold",
            }}
          >
            {" "}
            % PROMOTERS{" "}
          </Text>
          <Text
            style={{
              letterSpacing: 1.1,
              fontSize: 12,
              fontFamily: "roboto",
              fontWeight: "bold",
              color: "#060139",
            }}
          >
            {" "}
            -{" "}
          </Text>
          <Text
            style={{
              letterSpacing: 1.1,
              fontSize: 12,
              fontFamily: "roboto",
              color: "#060139",
              fontWeight: "bold",
            }}
          >
            % SKEPTICS
          </Text>
        </View>
        <View
          style={{
            marginTop: 10,
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              height: 100,
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: 65,
                height: 65,
                // borderColor:
                //   getAIIPS < 0
                //     ? "#ED3955"
                //     : getAIIPS > 1 && getAIIPS <= 50
                //     ? "#FAAF40"
                //     : "#36B24A",
                borderColor: "#95458C",
                borderWidth: 10,
                borderRadius: 50,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  fontFamily: "roboto",
                  color: "#95458C",
                }}
              >
                {getAIIPS}
              </Text>
            </View>
            <View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <TagItem
                  opacity={0.5}
                  bgColor={"#95458C"}
                  color="#fff"
                  text="Skeptics"
                  width={80}
                />
                <TagItem
                  opacity={0.5}
                  bgColor={"#95458C"}
                  color="#fff"
                  text={`${AIResponses?.skeptics}%`}
                  width={46}
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginTop: 10,
                }}
              >
                <TagItem
                  opacity={0.75}
                  bgColor={"#95458C"}
                  color="#fff"
                  text="Passives"
                  width={80}
                />
                <TagItem
                  opacity={0.75}
                  bgColor={"#95458C"}
                  color="#fff"
                  text={`${AIResponses?.passives}%`}
                  width={46}
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginTop: 10,
                }}
              >
                <TagItem opacity={1} bgColor={"#95458C"} color="#fff" text="Promoters" width={80} />
                <TagItem
                  opacity={1}
                  bgColor={"#95458C"}
                  color="#fff"
                  text={`${AIResponses?.promoters}%`}
                  width={46}
                />
              </View>
            </View>
          </View>
          {userResponse?.responsesByUser?.length == 0 ? (
            <View
              style={{
                width: "50%",
                height: 100,
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                borderLeftWidth: 1,
                borderLeftColor: "#ededed",
              }}
            >
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <TagItem
                    opacity={0.5}
                    bgColor={"#9A9A9A"}
                    color="#fff"
                    text="Skeptics"
                    width={80}
                  />
                  <TagItem
                    opacity={0.5}
                    bgColor={"#9A9A9A"}
                    color="#fff"
                    // text={`${userResponse?.skeptics}%`}
                    text={`${userResponse?.skeptics !== null ? userResponse?.skeptics : ""}N/A`}
                    width={46}
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginTop: 10,
                  }}
                >
                  <TagItem
                    opacity={0.75}
                    bgColor={"#9A9A9A"}
                    color="#fff"
                    text="Passives"
                    width={80}
                  />
                  <TagItem
                    opacity={0.75}
                    bgColor={"#9A9A9A"}
                    color="#fff"
                    // text={`${userResponse?.passives}%`}
                    text={`${userResponse?.passives !== null ? userResponse?.passives : ""}N/A`}
                    width={46}
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginTop: 10,
                  }}
                >
                  <TagItem
                    opacity={1}
                    bgColor={"#9A9A9A"}
                    color="#fff"
                    text="Promoters"
                    width={80}
                  />
                  <TagItem
                    opacity={1}
                    bgColor={"#9A9A9A"}
                    color="#fff"
                    // text={`${userResponse?.promoters}%`}
                    text={`${userResponse?.promoters !== null ? userResponse?.promoters : ""}N/A`}
                    width={46}
                  />
                </View>
              </View>
              <View
                style={{
                  width: 65,
                  height: 65,
                  // borderColor:
                  //   getUserIPS < 0
                  //     ? "#ED3955"
                  //     : getUserIPS > 1 && getUserIPS <= 50
                  //     ? "#FAAF40"
                  //     : "#36B24A",
                  borderColor: "#9A9A9A",
                  borderWidth: 10,
                  borderRadius: 50,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "roboto",
                    color: "#9A9A9A",
                  }}
                >
                  {getUserIPS == 0 ? "N/A" : getUserIPS}
                </Text>
              </View>
            </View>
          ) : (
            <View
              style={{
                width: "50%",
                height: 100,
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                borderLeftWidth: 1,
                borderLeftColor: "#ededed",
              }}
            >
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <TagItem
                    opacity={0.5}
                    bgColor={"#4E56FF"}
                    color="#fff"
                    text="Skeptics"
                    width={80}
                  />
                  <TagItem
                    opacity={0.5}
                    bgColor={"#4E56FF"}
                    color="#fff"
                    text={`${userResponse?.skeptics}%`}
                    width={46}
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginTop: 10,
                  }}
                >
                  <TagItem
                    opacity={0.75}
                    bgColor={"#4E56FF"}
                    color="#fff"
                    text="Passives"
                    width={80}
                  />
                  <TagItem
                    opacity={0.75}
                    bgColor={"#4E56FF"}
                    color="#fff"
                    text={`${userResponse?.passives}%`}
                    width={46}
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginTop: 10,
                  }}
                >
                  <TagItem
                    opacity={1}
                    bgColor={"#4E56FF"}
                    color="#fff"
                    text="Promoters"
                    width={80}
                  />
                  <TagItem
                    opacity={1}
                    bgColor={"#4E56FF"}
                    color="#fff"
                    text={`${userResponse?.promoters}%`}
                    width={46}
                  />
                </View>
              </View>
              <View
                style={{
                  width: 65,
                  height: 65,
                  // borderColor:
                  //   getUserIPS < 0
                  //     ? "#ED3955"
                  //     : getUserIPS > 1 && getUserIPS <= 50
                  //     ? "#FAAF40"
                  //     : "#36B24A",
                  borderColor: "#4E56FF",
                  borderWidth: 10,
                  borderRadius: 50,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "roboto",
                    color: "#4E56FF",
                  }}
                >
                  {getUserIPS}
                </Text>
              </View>
            </View>
          )}
        </View>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: "#95458C",
                fontWeight: 700,
                fontFamily: "roboto",
              }}
            >
              AI
            </Text>
          </View>

          {userResponse?.responsesByUser?.length == 0 ? (
            <View
              style={{
                width: "50%",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "#9a9a9a",
                  fontWeight: 700,
                  fontFamily: "roboto",
                }}
              >
                HUMAN
              </Text>
            </View>
          ) : (
            <View
              style={{
                width: "50%",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "#4E56FF",
                  fontWeight: 700,
                  fontFamily: "roboto",
                }}
              >
                HUMAN
              </Text>
            </View>
          )}
        </View>

        <View
          style={{
            borderLeftColor: "blue",
            borderLeftWidth: 2,
            backgroundColor: "#EFEFF9",
            padding: 15,
            marginTop: 8,
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image src={AIIcon} style={{ width: 30, height: 30 }} />
            <Text style={{ fontSize: 14, fontFamily: "roboto", marginLeft: 10 }}>
              Analysis of Likeliness to Promote Solution{" "}
              <Text style={{ fontSize: 8, fontWeight: 300, fontFamily: "roboto", marginLeft: 6 }}>
                Identified by VC Firm Executive*
              </Text>
            </Text>
          </View>
          <Text style={{ lineHeight: 1.3, letterSpacing: 0.3, fontSize: 10 }}>{viralEffect}</Text>
        </View>

        <Text
          style={{
            fontSize: 14,
            fontFamily: "roboto",
            marginTop: 25,
          }}
        >
          How "Likely" will the target market tell their friends about the Solution:
        </Text>

        <PercentageBar
          style={{ width: "100%", marginTop: 20, flexDirection: "row", alignItems: "flex-start" }}
          percent={`${(AIResponses?.averageAIRating?.ideaVariability / 5) * 100}%`}
          title="AI"
          name1="Not Likely"
          name2="Slightly Likely"
          name3="Somewhat Likely"
          name4="Likely"
          name5="Highly Likely"
        />
        <PercentageBar
          style={{ width: "100%", marginTop: 10, flexDirection: "row", alignItems: "flex-start" }}
          percent={`${(userResponse?.averageUserRating?.ideaVariability / 5) * 100}%`}
          title="HUMAN"
          name1="Not Likely"
          name2="Slightly Likely"
          name3="Somewhat Likely"
          name4="Likely"
          name5="Highly Likely"
        />

        <View style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}>
          <Image src={UserIcon} style={{ width: 30, height: 30 }} />
          <Text style={{ fontSize: 14, fontFamily: "roboto", marginLeft: 10 }}>
            Analysis of Ratings by Human Respondents:
          </Text>
        </View>
        <Text
          style={{
            lineHeight: 1.5,
            letterSpacing: 0.3,
            fontSize: 10,
            marginTop: 8,
            color: userResponse?.analysis?.text4 ? "black" : "#73777B",
          }}
        >
          {humanTextShow(userResponse?.analysis?.text4)}
        </Text>
      </Page>

      {/* ================Metric #5=================== */}
      <Page size="A4" style={{ paddingHorizontal: 50, paddingTop: 30, paddingBottom: 30 }}>
        <NewHeader title="Factor #5: Willingness to Buy Solution" />
        <Text
          style={{
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.3,
            textAlign: "center",
          }}
        >
          Respondents were asked to answer the following question:
        </Text>
        <Text
          style={{
            marginVertical: 10,
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "roboto",
            textAlign: "center",
          }}
        >
          {/* How likely is the target market willing to buy this product? */}
          How willing is the target market to pay for this Product/Service?
        </Text>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontFamily: "roboto",
                marginBottom: 20,
              }}
            >
              POWER TO SUCCEED
            </Text>
            <Text
              style={{
                fontSize: 12,
              }}
            >
              {`Reflects evaluators' confidence in the positive\nattributes of the idea, ignoring negative ratings.`}
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: 10,
            }}
          >
            {AILevelSelection(AIResponses?.powerToSucceed)}
            {/* <Image src={AIlevel1} style={{ width: 60 }} /> */}
            <View
              style={{
                width: 1,
                height: "100%",
                marginHorizontal: 15,
              }}
            ></View>
            {humanLevelSelection(userResponse?.powerToSucceed)}
          </View>
        </View>

        <View
          style={{
            borderLeftColor: "blue",
            borderLeftWidth: 2,
            backgroundColor: "#EFEFF9",
            padding: 15,
            marginTop: 8,
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image src={AIIcon} style={{ width: 30, height: 30 }} />
            <Text style={{ fontSize: 14, fontFamily: "roboto", marginLeft: 10 }}>
              Analysis of Willingness to Buy Solution{" "}
              <Text style={{ fontSize: 8, fontWeight: 300, fontFamily: "roboto", marginLeft: 6 }}>
                Identified by VC Firm Executive*
              </Text>
            </Text>
          </View>
          <Text style={{ lineHeight: 1.5, letterSpacing: 0.3, fontSize: 10, marginTop: 5 }}>
            {willingness}
          </Text>
        </View>

        <Text
          style={{
            fontSize: 14,
            fontFamily: "roboto",
            marginTop: 25,
          }}
        >
          How likely is the target market "Willing" to buy the solution:
        </Text>

        <PercentageBar
          style={{ width: "100%", marginTop: 20, flexDirection: "row", alignItems: "flex-start" }}
          percent={`${(AIResponses?.averageAIRating?.willingnessToBuy / 5) * 100}%`}
          title="AI"
          name1="Not Willing"
          name2="Slightly Willing"
          name3="Somewhat Willing"
          name4="Willing"
          name5="Highly Willing"
        />
        <PercentageBar
          style={{ width: "100%", marginTop: 10, flexDirection: "row", alignItems: "flex-start" }}
          percent={`${(userResponse?.averageUserRating?.willingnessToBuy / 5) * 100}%`}
          title="HUMAN"
          name1="Not Willing"
          name2="Slightly Willing"
          name3="Somewhat Willing"
          name4="Willing"
          name5="Highly Willing"
        />

        <View style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}>
          <Image src={UserIcon} style={{ width: 30, height: 30 }} />
          <Text style={{ fontSize: 14, fontFamily: "roboto", marginLeft: 10 }}>
            Analysis of Ratings by Human Respondents:
          </Text>
        </View>
        <Text
          style={{
            lineHeight: 1.5,
            letterSpacing: 0.3,
            fontSize: 10,
            marginTop: 8,
            color: userResponse?.analysis?.text5 ? "black" : "#73777B",
          }}
        >
          {humanTextShow(userResponse?.analysis?.text5)}
        </Text>
      </Page>

      {/* ================ Validating Your Idea with Renowned Industry Voices via AI ==================== */}
      <Page size="A4" style={{ paddingHorizontal: 50, paddingVertical: 40 }}>
        <NewHeader title={`Industry Professionals Feedback on your Idea*`} />

        <View style={{ marginVertical: 6 }}>
          {industryVoicesViaAI &&
            industryVoicesViaAI.map((item, index) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: index == industryVoicesViaAI.length - 1 ? "#fff" : "#e8e8e8",
                    paddingVertical: 16,
                  }}
                >
                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View>
                      <Text style={{ fontSize: 12, fontWeight: "600", fontFamily: "roboto" }}>
                        {`${item.userName}`}
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: "roboto",
                        marginBottom: 5,
                      }}
                    >
                      Feedback
                    </Text>
                    <Text
                      style={{
                        textAlign: "justify",
                        fontSize: 10,
                        lineHeight: 1.4,
                        letterSpacing: 0.3,
                        width: 330,
                      }}
                    >
                      {`${item.comment}`}
                    </Text>
                  </View>
                </View>
              );
            })}
        </View>
      </Page>

      {/* ================ Feedback from Human BEST Respondents ==================== */}
      {userResponse?.bestFiveResponses?.length < 1 ? null : (
        <Page size="A4" style={{ paddingHorizontal: 50, paddingVertical: 40 }}>
          <NewHeader title="Feedback from Human Respondents" />
          <Text
            style={{
              fontSize: 18,
              marginVertical: 10,
              fontWeight: "600",
              fontFamily: "roboto",
              textAlign: "center",
            }}
          >
            Five best comments for your idea
          </Text>
          <View style={{ marginVertical: 10 }}>
            {userResponse &&
              userResponse?.bestFiveResponses.map((item, index) => {
                return (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                      borderBottomColor: index == 4 ? "#fff" : "#e8e8e8",
                      paddingVertical: 24,
                    }}
                  >
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                      <Image src={UserIcon} style={{ width: 25, height: 25, marginRight: 16 }} />
                      <View>
                        <Text style={{ fontSize: 12, fontWeight: "600", fontFamily: "roboto" }}>
                          Profile {index + 1}:
                        </Text>
                        <Text
                          style={{
                            fontSize: 13,
                            fontWeight: "600",
                            fontFamily: "roboto",
                            marginTop: 10,
                            color: "#36B24A",
                            width: 150,
                          }}
                        >
                          {factors[item.responseType][item.response.rating]}
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={{ fontSize: 12, fontWeight: "600", fontFamily: "roboto" }}>
                        {item.responseType}
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#5B5B5C",
                          lineHeight: 1.5,
                          letterSpacing: 0.3,
                          width: 330,
                          marginTop: 5,
                        }}
                      >
                        {item.response.feedback}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>
        </Page>
      )}

      {/* ================ Feedback from Human WORST Respondents ==================== */}
      {userResponse?.worstFiveResponses?.length < 1 ? null : (
        <Page size="A4" style={{ paddingHorizontal: 50, paddingVertical: 40 }}>
          <NewHeader title="Feedback from Human Respondents" />
          <Text
            style={{
              fontSize: 18,
              marginVertical: 10,
              fontWeight: "600",
              fontFamily: "roboto",
              textAlign: "center",
            }}
          >
            Five Worst comments for your idea
          </Text>
          <View style={{ marginVertical: 10 }}>
            {userResponse &&
              userResponse?.worstFiveResponses.map((item, index) => {
                return (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                      borderBottomColor: index == 4 ? "#fff" : "#e8e8e8",
                      paddingVertical: 24,
                    }}
                  >
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                      <Image src={UserIcon} style={{ width: 25, height: 25, marginRight: 16 }} />
                      <View>
                        <Text style={{ fontSize: 12, fontWeight: "600", fontFamily: "roboto" }}>
                          Profile {index + 1}:
                        </Text>
                        <Text
                          style={{
                            fontSize: 13,
                            fontWeight: "600",
                            fontFamily: "roboto",
                            marginTop: 10,
                            color: "#ED3955",
                            width: 150,
                          }}
                        >
                          {factors[item.responseType][item.response.rating]}
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text style={{ fontSize: 12, fontWeight: "600", fontFamily: "roboto" }}>
                        {item.responseType}
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#5B5B5C",
                          lineHeight: 1.5,
                          letterSpacing: 0.3,
                          width: 330,
                          marginTop: 5,
                        }}
                      >
                        {item.response.feedback}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>
        </Page>
      )}

      {/* ================ Conclusion & Next Steps ==================== */}
      <Page size="A4" style={{ paddingHorizontal: 50, paddingVertical: 40 }}>
        <NewHeader title="Conclusions & Next Steps" />

        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <View
            style={{
              width: "60%",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontFamily: "roboto",
              }}
            >
              {AIAvgRating <= 1
                ? "Not Validated"
                : AIAvgRating == 2
                ? "Weakly Validated"
                : AIAvgRating == 3
                ? "Sufficiently Validated"
                : AIAvgRating == 4
                ? "Validated"
                : AIAvgRating == 5
                ? "Strongly Validated"
                : ""}
            </Text>
            <Text
              style={{
                marginTop: 10,
                fontSize: 12,
                lineHeight: 1.5,
                letterSpacing: 0.3,
              }}
            >
              {AIAvgRating <= 1
                ? "At this stage, your idea has not achieved\nthe desired level of validation. It's an\nopportunity to reflect and consider potential\nadjustments."
                : AIAvgRating == 2
                ? "Your idea has shown limited validation\nat this stage, indicating the need for\nsignificant adjustments."
                : AIAvgRating == 3
                ? "Congratulations! Your idea has received\nstrong validationacross multiple dimensions.\nThe positive responses underscore the\npotential of your concept."
                : AIAvgRating == 4
                ? "Your idea has demonstrated promising\npotential in various aspects. The positive\nvalidation suggests a solid foundation to\nbuild upon."
                : AIAvgRating == 5
                ? "Congratulations! Your idea has received\nstrong validation across multiple dimensions.\nThe positive responses underscore the\npotential of your concept."
                : ""}
            </Text>
          </View>

          <View
            style={{
              width: "40%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {aiShiedldSelection()}
          </View>
        </View>

        <View
          style={{
            width: "100%",
            backgroundColor: "#EFEFF9",
            borderLeftColor: "blue",
            borderLeftWidth: 2,
            paddingVertical: 16,
            paddingHorizontal: 22,
            marginVertical: 25,
            textAlign: "left",
          }}
        >
          <Text
            style={{
              fontSize: 14,
              fontFamily: "roboto",
              color: "#232C65",
              fontWeight: 700,
            }}
          >
            Next Steps:
          </Text>

          {AIAvgRating == 3 || AIAvgRating == 4 || AIAvgRating >= 5 ? (
            <>
              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <Image
                  style={{ width: 24, height: 24, zIndex: 2, marginRight: 14 }}
                  src={ConclusionIcon1}
                />
                <View>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: "roboto",
                      color: "#232C65",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    Refine Through Market Research:
                  </Text>
                  <Text style={{ fontSize: 11, fontFamily: "roboto", color: "#2a2a2a" }}>
                    {`Proceed to the Market Research module to gather deeper insights into your\ntarget audience and their needs.`}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <Image
                  style={{ width: 24, height: 24, zIndex: 2, marginRight: 14 }}
                  src={ConclusionIcon2}
                />
                <View>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: "roboto",
                      color: "#232C65",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    Craft a Compelling UVP:
                  </Text>
                  <Text style={{ fontSize: 11, fontFamily: "roboto", color: "#2a2a2a" }}>
                    {`Utilize the insights from Market Research to develop a Unique Value Proposition (UVP)\nthat resonates with your audience.`}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <Image
                  style={{ width: 24, height: 24, zIndex: 2, marginRight: 14 }}
                  src={ConclusionIcon3}
                />
                <View>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: "roboto",
                      color: "#232C65",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    Determine MVP Features:
                  </Text>
                  <Text style={{ fontSize: 11, fontFamily: "roboto", color: "#2a2a2a" }}>
                    {`Based on the refined UVP and market insights, define the core features that\nwill make up your Minimum Viable Product (MVP).`}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <Image
                  style={{ width: 24, height: 24, zIndex: 2, marginRight: 14 }}
                  src={ConclusionIcon4}
                />
                <View>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: "roboto",
                      color: "#232C65",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    Begin MVP Prototyping:
                  </Text>
                  <Text style={{ fontSize: 11, fontFamily: "roboto", color: "#2a2a2a" }}>
                    {`Move towards creating a prototype of your MVP, focusing on the features\nidentified in the previous step.`}
                  </Text>
                </View>
              </View>
            </>
          ) : (
            <>
              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <Image
                  style={{ width: 24, height: 24, zIndex: 2, marginRight: 14 }}
                  src={ConclusionIcon5}
                />
                <View>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: "roboto",
                      color: "#232C65",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    Reassess the Problem:
                  </Text>
                  <Text style={{ fontSize: 11, fontFamily: "roboto", color: "#2a2a2a" }}>
                    {`Take a critical look at the problem you're aiming to solve. Gather additional\nfeedback and insights to gain a deeper understanding of its significance`}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <Image
                  style={{ width: 24, height: 24, zIndex: 2, marginRight: 14 }}
                  src={ConclusionIcon6}
                />
                <View>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: "roboto",
                      color: "#232C65",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    Rethink the Solution:
                  </Text>
                  <Text style={{ fontSize: 11, fontFamily: "roboto", color: "#2a2a2a" }}>
                    {`Consider a more comprehensive review of your proposed solution. Is it truly\naddressing the core pain points? Are there alternative approaches worth\nexploring?`}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <Image
                  style={{ width: 24, height: 24, zIndex: 2, marginRight: 14 }}
                  src={ConclusionIcon7}
                />
                <View>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: "roboto",
                      color: "#232C65",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    Refine with Intention:
                  </Text>
                  <Text style={{ fontSize: 11, fontFamily: "roboto", color: "#2a2a2a" }}>
                    {`Based on the re-evaluation, make intentional refinements to your concept.\nAim to align your solution more closely with the identified problem.`}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <Image
                  style={{ width: 24, height: 24, zIndex: 2, marginRight: 14 }}
                  src={ConclusionIcon8}
                />
                <View>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: "roboto",
                      color: "#232C65",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    Explore Market Research:
                  </Text>
                  <Text style={{ fontSize: 11, fontFamily: "roboto", color: "#2a2a2a" }}>
                    {`After refining your problem-solution fit, proceed to the Market Research\nmodule to gather more robust insights.`}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <Image
                  style={{ width: 24, height: 24, zIndex: 2, marginRight: 14 }}
                  src={ConclusionIcon9}
                />
                <View>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: "roboto",
                      color: "#232C65",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    Consider Pivoting:
                  </Text>
                  <Text style={{ fontSize: 11, fontFamily: "roboto", color: "#2a2a2a" }}>
                    {`Given the insights gained, be open to the possibility of pivoting your\nconcept to better address market needs and opportunities.`}
                  </Text>
                </View>
              </View>
            </>
          )}
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Link src={`https://validatr.io`}>
            <View
              style={{
                borderRadius: 6,
                backgroundColor: "#95458C",
                paddingHorizontal: 10,
                paddingVertical: 8,
                width: 220,
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <Text style={{ fontFamily: "roboto", fontSize: 13, color: "#fff" }}>
                Go to the Validatr Dashboard
              </Text>
            </View>
          </Link>
        </View>

        <View style={{ textAlign: "center", paddingHorizontal: 36, marginTop: 20 }}>
          <Image src={Quotes} style={{ width: 22, zIndex: 2 }} />

          <Text style={{ fontSize: 11, fontFamily: "roboto", color: "#2a2a2a", marginVertical: 4 }}>
            "
            {AIAvgRating <= 1
              ? `Failure is a stepping stone, not a stumbling block. Your journey is\nabout learning, adapting, and persevering. Embrace this moment\nas a catalyst for your future success.`
              : AIAvgRating == 2
              ? `Challenges are opportunities in disguise. Your idea may be at a crossroads,\nbut every setback is a chance to pivot, innovate, and evolve.`
              : AIAvgRating == 3
              ? `Every step forward is a step towards success. Your journey involves\nstrategic and constant growth. Stay persistent and let your\ninsights drive you.`
              : AIAvgRating == 4
              ? `Validation is a launchpad, not a destination. Your validated concept\nis a testament to your vision. Keep refining, iterating,\nand reaching for new heights.`
              : AIAvgRating == 5
              ? `It's not about ideas. It's about making ideas happen.`
              : ``}
            "
          </Text>
          <View style={{ width: "100%", justifyContent: "flex-end", flexDirection: "row" }}>
            <Image
              src={Quotes}
              style={{
                width: 22,
                zIndex: 2,
                transform: "rotate(180deg)",
              }}
            />
          </View>

          <Text
            style={{
              fontSize: 11,
              fontFamily: "roboto",
              fontStyle: "italic",
              fontWeight: 400,
              color: "#2a2a2a",
              marginTop: 12,
            }}
          >
            {AIAvgRating <= 1
              ? `- Michael Jordan, NBA Legend`
              : AIAvgRating == 2
              ? `- Richard Branson, Founder of Virgin Group`
              : AIAvgRating == 3
              ? `- Elon Musk, CEO of SpaceX and Tesla`
              : AIAvgRating == 4
              ? `- Reid Hoffman, Co-Founder of LinkedIn`
              : AIAvgRating == 5
              ? `- Scott Belsky, Co-Founder of Behance`
              : ``}
          </Text>
        </View>
      </Page>

      {/* ================ Glossary ==================== */}
      <Page size="A4" style={{ paddingHorizontal: 50, paddingVertical: 40 }}>
        <NewHeader title="Glossary" />
        <Text
          style={{
            fontSize: 18,
            marginVertical: 10,
            fontWeight: "600",
            fontFamily: "roboto",
            textAlign: "center",
          }}
        >
          Understanding Key Concepts and Definitions
        </Text>

        <View style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Image src={LikeDislike} style={{ width: 170 }} />
        </View>
        <Text
          style={{
            marginTop: 18,
            marginBottom: 3,
            fontSize: 14,
            fontFamily: "roboto",
          }}
        >
          Power to Succeed:
        </Text>
        <Text style={{ fontSize: 12, color: "#5B5B5C", lineHeight: 1.5, letterSpacing: 0.3 }}>
          A comprehensive metric that considers all 5 factors and reflects evaluators' confidence in
          the positive attributes of the idea, disregarding negative ratings. To calculate the Power
          to Succeed, determine the percentage of evaluators who rated the idea with a score of 4 or
          above on all factors. Power to Succeed rating of 5 represents the highest level of
          confidence and endorsement for the idea's success.
        </Text>

        <Text
          style={{
            marginTop: 18,
            marginBottom: 3,
            fontSize: 14,
            fontFamily: "roboto",
          }}
        >
          Idea Propagation Score/Likeliness to Promote:
        </Text>
        <Text style={{ fontSize: 12, color: "#5B5B5C", lineHeight: 1.5, letterSpacing: 0.3 }}>
          Shows how likely someone will recommend your idea/solution to others. Based on the
          responses, respondents can be classified into three categories: •Promoters (score
          4.5-5.0): Highly likely to recommend and promote the idea. "Passives (score 3.5-4.0):
          Neutral or somewhat likely to recommend. "Skeptics (score 0-3.0): Unlikely to recommend or
          may even discourage others. IPS is calculated as percentage of promoters - percentage of
          Skeptics.
        </Text>

        <Text
          style={{
            marginTop: 18,
            marginBottom: 3,
            fontSize: 14,
            fontFamily: "roboto",
          }}
        >
          Problem Solution Impact:
        </Text>
        <Text style={{ fontSize: 12, color: "#5B5B5C", lineHeight: 1.5, letterSpacing: 0.3 }}>
          {`Problem Solution Impact indicates the degree to which the solution effectively tackles 
            the identified problem.\n To calculate the Problem Solution Impact, take the average of the ratings for metric #1: 
            relevance of the problem and metric #2: usefulness of the solution.`}
        </Text>

        <Text
          style={{
            marginTop: 18,
            marginBottom: 3,
            fontSize: 14,
            fontFamily: "roboto",
          }}
        >
          WOW Factor:
        </Text>
        <Text style={{ fontSize: 12, color: "#5B5B5C", lineHeight: 1.5, letterSpacing: 0.3 }}>
          {`Demonstrates the capacity to captivate buyers through the combination of solution 
              uniqueness and usefulness.\nTo calculate the WOW Factor, take the average of the ratings for metric #3 
              (uniqueness of the solution) and metric #2 (usefulness of the solution).`}
        </Text>

        <Text
          style={{
            marginTop: 18,
            marginBottom: 3,
            fontSize: 14,
            fontFamily: "roboto",
          }}
        >
          Intensity of Problem:
        </Text>
        <Text style={{ fontSize: 12, color: "#5B5B5C", lineHeight: 1.5, letterSpacing: 0.3 }}>
          Shows the severity or magnitude of the problem on a scale of 1 to 5, with 1 representing
          "No pain" and 5 representing "Unbearable pain" or the highest level of intensity.
        </Text>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

const styles = StyleSheet.create({
  pdfViewer: {
    height: "100vh",
    width: "100%",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  tableOfContentPara: {
    color: "#2a2a2a",
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "roboto",
  },
  tableOfContentSubPara: {
    color: "#2a2a2a",
    fontSize: 10,
    fontWeight: 300,
    fontFamily: "roboto",
  },
  tableOfContentRow: {
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    // marginTop: 20,
    // marginBottom: 8,
    fontSize: 18,
    // fontFamily: 'Oswald'
  },
  para: {
    marginTop: 8,
    marginBottom: 20,
    fontSize: 14,
    color: "gray",
  },

  title25: {
    fontSize: 21,
    fontWeight: 400,
    fontFamily: "roboto",
    color: "#2A2A2A",
    textAlign: "center",
  },
  title20: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "roboto",
    color: "#060139",
  },
  headingRow: {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 25,
  },
  tableNumber: {
    fontSize: 22,
    fontWeight: 400,
    fontFamily: "roboto",
    color: "#686868 ",
  },

  circle: {
    width: 200,
    height: 200,
    borderRadius: 100,
    overflow: "hidden",
  },
  color1: {
    flex: 1,
    backgroundColor: "#ED3955",
  },
  color2: {
    flex: 1,
    backgroundColor: "blue",
  },
  color3: {
    flex: 1,
    backgroundColor: "#36B24A",
  },
});
